const notFound = () => {
  const lyrics = [
    "Kahin toh, kahin toh, hogi woh duniya...",
    "Kho gaya, gum ho gaya...",
    "Main shayad tumhare liye ajnabi hoon...",
    "Mujhko bhi toh bata de, yeh dhoondhne ki cheez hai kahaan?",
    "Woh jo dhoondh raha tha, shayad kabhi tha hi nahi...",
    "Dil ne phir dhoonda, par bas ek khaali si jagah mili...",
    "Yaadein, yaadein, bas yaadein reh jaati hain...",
    "Mujhse milne aayi thi woh baatein, par yahan sirf khamoshi hai...",
    "Dhoondta hoon main magar, khud se bhi gum hoon...",
    "Chhod aaye hum woh galiyan...",
    "Ajeeb daastaan hai yeh...",
    "Dil ki baatein, dil hi jaane...",
    "Tumhare bina, sab kuch suna suna hai...",
  ];

  //  GET A RANDOM INDEX AS PER THE ARRAY LENGTH
  const randomIndex = Math.floor(Math.random() * lyrics.length);

  //  RETURN ONE OF THE IMAGES FROM THE ARRAY
  return lyrics[randomIndex];
}

export default notFound;