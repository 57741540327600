import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop"; // when path changes i.e. the page change
import "./App.css";
import Header from "./screens/Header";
import Footer from "./screens/Footer";
import GlobalPlayer from "./screens/GlobalPlayer";
import { GlobalPlayerProvider } from "./utils/GlobalPlayerContext";
import { ToastProvider } from "./utils/ToastContext";
import ToastComponent from "./components/ToastComponent/ToastComponent";
import ProtectedRoutes from "./screens/ProtectedRoutes";
import config from "./utils/config";
import ScrollToTopButton from "./screens/ScrollToTopButton"; // on user click action
import BMMModal from "./screens/BMMModal";

//  FOR MS CLARITY
import Clarity from "@microsoft/clarity";

//  FOR MIXPANEL
import mixpanel from "mixpanel-browser";

//  INITIALISE CLARITY
const projectId = "pqnlvyi68x";
Clarity.init(projectId);

Clarity.consent(false);

//  INITIALISE MIXPANEL
mixpanel.init(config.mixpanelKey, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE HEADER ON THE CHECKOUT PAGE
*/
const HeaderWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Header />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE FOOTER ON THE CHECKOUT PAGE
*/
const FooterWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <Footer />;
};

/*
  THIS HAS BEEN CREATED WITH THE INTENT OF HIDING THE GLOBAL PLAYER ON THE CHECKOUT PAGE
*/
const GPWrapper = () => {
  const location = useLocation();

  // Hide header on checkout page
  if (location.pathname === "/checkout") {
    return null;
  }

  return <GlobalPlayer />;
};

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isUserLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const inactivityTimer = useRef(null);
  const logoutTimer = useRef(null);

  // checking on mount which state of the modal to display
  useEffect(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (bmmkey) {
      setIsModalVisible(true);
    }
  }, []);

  // bmm modal displaying condition
  const showModal = useCallback(() => {
    // console.log("run on every render ?");
    setIsModalVisible(true);
  }, []);

  // bmm modal hiding condition
  // const closeModal = useCallback(() => {
  //   // setIsModalVisible(false);
  //   // localStorage.setItem("bmm_skip", "true");
  // }, []);

  // logged in case for bmm modal
  const resetInactivityTimer = useCallback(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (!bmmkey) {
      clearTimeout(inactivityTimer.current);
      if (isUserLoggedIn) {
        inactivityTimer.current = setTimeout(showModal, 120000); // 2 minutes
      }
    }
  }, [isUserLoggedIn, showModal]);

  // logged out case for bmm modal
  useEffect(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (!bmmkey) {
      if (!isUserLoggedIn) {
        clearTimeout(inactivityTimer.current);
        setIsModalVisible(false);
        logoutTimer.current = setTimeout(showModal, 120000); // Show modal after 2 minutes of logout
      } else {
        clearTimeout(logoutTimer.current);
      }
    }
  }, [isUserLoggedIn, showModal]);

  // user goes outside the viewport
  useEffect(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (!bmmkey) {
      const handleMouseLeave = (event) => {
        if (!isModalVisible && event.clientY <= 0) {
          showModal(); // Show modal if user moves mouse outside the viewport (top of screen)
        }
      };

      document.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        document.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [isModalVisible, showModal]);

  // logged in case user activity tracking for bmm modal
  useEffect(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (!bmmkey) {
      if (isUserLoggedIn) {
        window.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("keydown", resetInactivityTimer);
        window.addEventListener("scroll", resetInactivityTimer);
      }
      return () => {
        window.removeEventListener("mousemove", resetInactivityTimer);
        window.removeEventListener("keydown", resetInactivityTimer);
        window.removeEventListener("scroll", resetInactivityTimer);
      };
    }
  }, [isUserLoggedIn, resetInactivityTimer]);

  // mix panel trigger
  useEffect(() => {
    const loadMixpanelScript = () => {
      return new Promise((resolve, reject) => {
        const checkInterval = setInterval(() => {
          if (typeof mixpanel !== "undefined") {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100); // Keep checking every 100ms until Mixpanel loads

        // setTimeout(() => {
        //   clearInterval(checkInterval);
        //   reject("Mixpanel script load timed out.");
        // }, 5000);
      });
    };

    const initializeMixpanel = async () => {
      if (window.__MIXPANEL_INITIALIZED__) return; // Prevent multiple initializations

      try {
        await loadMixpanelScript();
        // if (!mixpanelInitialized && typeof mixpanel !== "undefined") {
        mixpanel.init(config.mixpanelKey, {
          debug: true,
          track_pageview: true,
          persistence: "localStorage",
        });
        identifyAndSetUserProperties();
        window.__MIXPANEL_INITIALIZED__ = true; // Flag to prevent re-init
        // setMixpanelInitialized(true);
        // console.log("Mixpanel Initialized");
        // }
      } catch (error) {
        console.log("Mixpanel initialization failed:", error);
      }
    };

    const identifyAndSetUserProperties = () => {
      const userInfoString = localStorage.getItem("userData");
      if (userInfoString) {
        try {
          const userInfo = JSON.parse(userInfoString);
          const userId = userInfo.id;
          const userName = userInfo.name;
          const userEmail = userInfo.email;
          const userMobile = userInfo.mobile;

          if (userId) {
            mixpanel.identify(userId);
          }

          const userProperties = {};
          if (userName) userProperties.$name = userName;
          if (userEmail) userProperties.$email = userEmail;
          if (userMobile) userProperties.$mobile = userMobile;

          if (Object.keys(userProperties).length > 0) {
            mixpanel.people.set(userProperties);
          }
        } catch (error) {
          console.log("Error parsing user info from localStorage", error);
        }
      }
    };

    // if (!mixpanelInitialized) {
    initializeMixpanel();
    // }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ToastProvider>
        <ToastComponent />
        <GlobalPlayerProvider>
          <div className="parentDiv">
            <div
              id="bgs"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
                width: "100%",
                height: "99999px",
                display: "none", // Initially hidden
                position: "absolute",
                zIndex: 1,
                backdropFilter: "blur(5px)",
              }}
            ></div>
            {/* <div class="snowflakes" aria-hidden="true"> */}
            {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake"><img src="https://images.emojiterra.com/google/noto-emoji/unicode-16.0/color/512px/1f1ee-1f1f3.png" height="20px" width="20px" /></div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}
            {/* <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❆</div>
              <div class="snowflake sf-green">❄</div>
              <div class="snowflake sf-orange">❅</div>
              <div class="snowflake sf-blue">❄️</div>
              <div class="snowflake">❄</div>
              <div class="snowflake sf-green">❅</div>
              <div class="snowflake sf-orange">❆</div>
              <div class="snowflake sf-green">❄</div> */}
            {/* Always visible component */}
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                zIndex: 999,
              }}
            >
              {/* <Header /> */}
              <HeaderWrapper />
            </div>
            {/* Route-based pages */}
            <ProtectedRoutes />
            {/* Always visible component */}
            <FooterWrapper />
            <GPWrapper />
            <ScrollToTopButton /> {/* Add the ScrollToTopButton component */}
            <BMMModal isVisible={isModalVisible} />
          </div>
          {/* </div> */}
        </GlobalPlayerProvider>
      </ToastProvider>
    </Router>
  );
};

export default App;
