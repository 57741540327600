import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/Cart.module.css";
import { IoIosClose, IoIosArrowUp } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomImg from "../components/CustomImg/CustomImg";
import { FiMinus, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
} from "../utils/redux/cartSlice";
import { useNavigate } from "react-router-dom";
import { getCart, putCart } from "../service/TransactionService";
import { ToastContext } from "../utils/ToastContext";
import {
  addToCart,
  selectItemCount,
  clearCart,
} from "../utils/redux/cartSlice";
import config from "../utils/config";
import { Link } from "react-router-dom";
import useMixpanel from "../service/MixpanelService";
import { activateModal, deactivateModal } from "../utils/redux/modalSlice";

const Cart = ({ isOpen, onClose }) => {
  const [isCartOpen, setIsCartOpen] = useState(isOpen); // Sync with parent initially
  // const [cartItems, setCartItems] = useState([]); // Manage all cart items
  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store
  const dispatch = useDispatch(); // Hook to dispatch actions
  const navigate = useNavigate(); // Hook to navigate programmatically
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const totalItemCount = useSelector(selectItemCount); // Get the item count from Redux store
  const [billTotal, setBillTotal] = useState({}); // cart bill summary

  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  // console.log(cartItems, "cartItems");

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsCartOpen(isOpen);
    // console.log(isOpen, "modal state");
    if (isOpen == true) {
      dispatch(activateModal()); // action send to redux to hide the scrolltop button
    }
  }, [isOpen]);

  // use effect for fetch the cart data from api
  useEffect(() => {
    const fetchCart = async () => {
      const fetchCart = await getCart();
      // console.log(fetchCart, "fetching cart data");
      if (fetchCart?.error?.code === 0) {
        const billingInfo = fetchCart?.data?.totals;
        setBillTotal(billingInfo);
        const cartItemsData = fetchCart.data.items || [];
        const cartInfo = cartItemsData.map((item) => {
          // Extract data from API response to build cartInfo object
          return {
            cardType: item.sku.itemType === "T" ? "track" : "pack",
            preText: item.item.name, // track name
            // secText: item.item.tracksArtistRole
            //   .map((artist) => artist.artist.name)
            //   .join(", "), // artist names
            secText: Array.isArray(item?.item.tracksArtistRole)
              ? item.item.tracksArtistRole
                  .map((artist) => artist?.artist?.name)
                  .join(", ")
              : "", // artist name for track and description for pack
            code:
              item.sku.itemType === "T"
                ? item.item.trackCode
                : item.item.playlistCode, // track /pack code
            sp: item.sku.sellingPrice.toLocaleString("en-IN"), // selling price
            cp: item.sku.costPrice.toLocaleString("en-IN"), // assuming selling price is the same as cost price
            cardImg: item.item.image || "", // use item ID or another field for the image
            // trackJson: "",
            // trackUrl: "",
            skuID: item.sku.id, // sku id
          };
        });
        // clear only the redux to store the data from the api resp
        dispatch(clearCart()); //as api is need to be called on component render and when open triggers to get the total amount api need to call

        // Dispatch each cart item the number of times specified in `item.qty`
        cartInfo.forEach((item, index) => {
          const qty = fetchCart.data.items[index].qty || 1; // Get quantity
          for (let i = 0; i < qty; i++) {
            dispatch(addToCart({ ...item })); // Dispatch multiple times if quantity > 1
          }
        });
        // Log the cartItems or use it as needed
        // console.log(cartInfo, "formatted cart items");
      } else {
        // showToast("Error", "Failed to get cart", "error"); // Show error toast if API fails
      }
    };
    // if (isOpen) {
    fetchCart();
    // }
  }, [isOpen]);

  // fucntion to call on increment,decrement and deletion of item in the cart
  const updateCart = async (payload) => {
    // prepare payload
    // const payload = {
    //   items: [{ skuId: "id", qty: "Quantity", cartType: "C" }],
    // };

    const addCart = await putCart(payload);
    return addCart;
  };

  // Function to close the modal
  const closeModal = () => {
    dispatch(deactivateModal()); // update redux to display the scroll button
    setIsCartOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  if (!isCartOpen) return null; // Don't render if the modal is closed

  const handleIncrement = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const currentQuantity = currentItem.quantity;
    const newQuantity = currentQuantity + 1; // Increment quantity
    const skuID = currentItem.skuID; // Get the SKU ID for the item

    // Prepare payload
    const payload = {
      items: [{ skuId: skuID, qty: newQuantity, cartType: "C" }],
    };
    // console.log(payload, "increment");

    const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
    // Check if the API update was successful
    // console.log(updateResponse, "api resp");

    if (
      updateResponse &&
      updateResponse.error &&
      updateResponse.error.code === 0
    ) {
      dispatch(incrementQuantity(code));
      setBillTotal(updateResponse?.data?.totals);
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      // Track the increment event
      trackUserEvent("cart-increment", currentItem);
    } else {
      showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
    }

    // dispatch(incrementQuantity(code)); // Increment quantity in Redux
  };

  const handleDecrement = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const currentQuantity = currentItem.quantity;
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1; // Decrement quantity
      const skuID = currentItem.skuID; // Get the SKU ID for the item

      // Prepare payload
      const payload = {
        items: [{ skuId: skuID, qty: newQuantity, cartType: "C" }],
      };

      const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
      // Check if the API update was successful
      if (
        updateResponse &&
        updateResponse.error &&
        updateResponse.error.code === 0
      ) {
        dispatch(decrementQuantity(code));
        setBillTotal(updateResponse?.data?.totals);
        showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
        // Track the decrement event
        trackUserEvent("cart-decrement", currentItem);
      } else {
        showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
      }

      // dispatch(decrementQuantity(code)); // Decrement quantity in Redux
    }
  };

  const handleRemoveItem = async (code) => {
    // Find the current item in the cartItems array
    const currentItem = cartItems.find((item) => item.code === code);
    const skuID = currentItem.skuID; // Get the SKU ID for the item

    // qty will be in int not in a string
    // Prepare payload
    const payload = {
      items: [{ skuId: skuID, qty: 0, cartType: "C" }],
    };
    // console.log(payload, "delete");

    const updateResponse = await updateCart(payload); // Call the updateCart function to update the backend
    // Check if the API update was successful

    if (
      updateResponse &&
      updateResponse.error &&
      updateResponse.error.code === 0
    ) {
      dispatch(removeFromCart(code));
      setBillTotal(updateResponse?.data?.totals);
      showToast("Deleted", "Track Removed", "success"); //display toast msg
      // Track the remove item event
      trackUserEvent("cart-remove", currentItem);
    } else {
      showToast("Error", "Failed to update the cart", "error"); // Show error toast if API fails
    }

    // dispatch(removeFromCart(code)); // Remove item from Redux cart
  };

  const handleCheckout = () => {
    closeModal(); // Close the modal
    trackUserEvent("checkout-initiated");
    dispatch(deactivateModal()); // update redux to display the scroll button
    // setTimeout(() => {
    navigate("/checkout"); // Navigate after modal closes
    // }, 300); // Add a small delay for modal animation if necessary
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
      // onOutsideClick && onOutsideClick(); // Notify the parent about the outside click
    }
  };

  const trackUserEvent = (eventName, item) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const eventData = {
      url: window.location.href,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
    };

    if (item) {
      const name = item.preText;
      eventData[item.cardType === "track" ? "songName" : "packName"] = name;
    }

    trackEvent(eventName, eventData);
  };

  // console.log("Global Player Active in Cart: ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  // console.log(cartItems, "cartItems");

  // sub total calculation
  // const subtotal = cartItems.reduce((total, item) => {
  //   const sellingPrice = String(item.sp).replaceAll(",", ""); // Convert to string before replaceAll
  //   return parseFloat(
  //     (total + parseInt(sellingPrice, 10) * (item.quantity || 1)) / 1.18
  //   ).toFixed(2);
  // }, 0);

  // tax amount calculation
  // const tax = cartItems.reduce((total, item) => {
  //   const sellingPrice = String(item.sp).replaceAll(",", ""); // Ensure string
  //   return parseFloat(
  //     ((total + parseInt(sellingPrice, 10) * (item.quantity || 1)) / 1.18) *
  //       0.18
  //   ).toFixed(2);
  // }, 0);

  // Ensure the image URLs are correctly generated based on the updated cartItems
  const getImageUrl = (item) => {
    return item.cardType === "track"
      ? `${config.s3imgUrl}web/tracks/${item.code}.webp`
      : `${config.s3imgUrl}web/playlists/${item.code}.webp`;
  };

  return (
    <div className={overlayClass} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        {/* cart header section */}
        <div className={styles.header}>
          <div className={styles.heading}>
            <p className={styles.headingtext}>My Cart</p>
            <div className={styles.cartCount}>{totalItemCount}</div>
          </div>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>
        {cartItems.length > 0 ? (
          <>
            {/* billing cards */}
            <div className={styles.billItems}>
              {/* yellow band */}
              {/* {cartItems.length > 0 && (
                <div className={styles.cHtxt}>
                  <p>short form video</p>
                </div>
              )} */}
              {cartItems.length > 0 &&
                cartItems.map((item, index) => (
                  // <div key={index} className={styles.cardContainer}>
                  <div key={item.skuID} className={styles.cardContainer}>
                    <div
                      className={`${styles.cardDetails} ${
                        item.cardType === "pack" ? styles.alignCenter : ""
                      }`}
                    >
                      <CustomImg
                        type={item.cardType}
                        id={item.code}
                        alt={item.preText}
                        imgClass={
                          item.cardType === "track"
                            ? styles.trackImage
                            : styles.packImage
                        }
                        src={getImageUrl(item)}
                        // src={
                        //   item.cardType === "track"
                        //     ? config.s3imgUrl +
                        //       "web/tracks/" +
                        //       item.code +
                        //       ".webp"
                        //     : config.s3imgUrl +
                        //       "web/playlists/" +
                        //       item.code +
                        //       ".webp"
                        // }
                      />
                      <div className={styles.cardInfo}>
                        <p className={styles.cardPriText}>{item.preText}</p>
                        <p className={styles.cardSectext}>{item.secText}</p>
                        <div className={styles.itemControls}>
                          <div className={styles.itemIncBtn}>
                            <FiMinus
                              size={14}
                              color="#000"
                              cursor="pointer"
                              onClick={() => handleDecrement(item.code)}
                            />
                            <span className={styles.itemCount}>
                              {item.quantity || 1}
                            </span>
                            <FiPlus
                              size={14}
                              color="#000"
                              cursor="pointer"
                              onClick={() => handleIncrement(item.code)}
                            />
                          </div>
                          <AiOutlineDelete
                            onClick={() => handleRemoveItem(item.code)}
                            size={22}
                            // color="#3f3f3f"
                            cursor="pointer"
                            className={
                              styles.deleteIcon
                            } /* Apply the new CSS class */
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemTotalPrice}>
                      <span className={styles.cardTotalPriceText}>
                        Pay (Inc. GST)
                      </span>
                      <p className={styles.cardTotalPrice}>₹{item.sp}</p>
                    </div>
                  </div>
                ))}
            </div>
            {/* total bill summary section */}
            <div className={styles.billSummary}>
              <p className={styles.sumHeadingText}>Billing Summary</p>
            </div>
            <div className={styles.billInfo}>
              <div className={styles.billDetail}>
                <div className={styles.detailHeading}>
                  <p>Subtotal</p>
                </div>
                {/* displaying the cost price here */}
                <div className={styles.price}>
                  {/* <span>₹{subtotal.toLocaleString("en-IN")}</span> */}₹
                  {billTotal?.subTotal?.toLocaleString("en-IN")}
                </div>
              </div>
              <div className={styles.billDetail}>
                <div className={styles.detailHeading}>
                  <p>Tax (18% GST)</p>
                </div>
                <div className={styles.price}>
                  <span>
                    {" "}
                    {/* ₹{tax.toLocaleString("en-IN")} */}₹
                    {billTotal?.gst?.toLocaleString("en-IN")}
                    {/* {Number(
                      (
                        cartItems.reduce(
                          (total, item) =>
                            total +
                            parseInt(item.sp.replace(",", ""), 10) *
                              item.quantity,
                          0
                        ) -
                        cartItems.reduce(
                          (subtotal, item) =>
                            subtotal +
                            (parseInt(item.sp.replace(",", ""), 10) *
                              (item.quantity || 1)) /
                              1.18,
                          0
                        )
                      ).toFixed(2) // Ensure two decimal places for precision
                    ).toLocaleString("en-IN")} */}
                  </span>
                </div>
              </div>
              <div className={styles.billDetail}>
                <div
                  style={{ fontWeight: "bold" }}
                  className={styles.detailHeading}
                >
                  <p>Total</p>
                </div>
                <div style={{ fontWeight: "bold" }} className={styles.price}>
                  <span>
                    ₹{billTotal?.payableAmount?.toLocaleString("en-IN")}
                  </span>
                </div>
              </div>
            </div>
            {/* total price and checkout section */}
            <div className={styles.bllingTotal}>
              {/* total amount section */}
              <div>
                <p className={styles.totalCost}>
                  ₹{billTotal?.payableAmount?.toLocaleString("en-IN")}
                </p>
                {/* <div className={styles.savings}>You are saving ₹1899</div> */}
                {/* <div className={styles.savings}>
              You Saved ₹
              {cartItems
                .reduce(
                  (totalSavings, item) =>
                    totalSavings +
                    (parseInt(item.cp.replace(",", "")) -
                      parseInt(item.sp.replace(",", ""))) *
                      item.quantity,
                  0
                )
                .toLocaleString("en-IN")}
            </div> */}
              </div>
              {/* checkout button */}
              <div>
                <CustomButton
                  text="Checkout"
                  backgroundColor="var(--button-primary)"
                  borderRadius="50px"
                  borderColor="1px solid #CCCCCC"
                  width="212px"
                  height="48px"
                  textColor="#fff"
                  iconColor="#272727"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="550"
                  onClick={handleCheckout} // Call the checkout handler
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.Emptycontainer}>
            <img
              src={`${config.s3imgUrl}emptystates/emptycart.png`}
              alt=""
              className={styles.EmptycartImg}
            />
            <p className={styles.Emptyheading}>Your cart is empty!</p>
            <p className={styles.EmptysubHeading}>
              Start discovering popular tracks to license for your projects
            </p>
            <Link to={"/"}>
              <CustomButton
                text="Continue Browsing"
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="238px"
                height="48px"
                textColor="#fff"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="550"
                onClick={closeModal}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
