import config from "./config";
import priceFilter from "./FilterBase64";

const GetMktCard = (listType, bannerType, index) => {
  //  ALLOW TYPE TO COME IN ANY CASE, REMOVE DEVELOPER OVERHEAD

  //  ALLOWED LISTS TYPES ARE THE FOLLOWING
  /*
    h-in: HOME INDIE
    track: ALL TRACKS, COULD BE USED WHEREVER YOU ARE SHOWING TRACKS IN GENERAL
  */
  listType = listType.toLowerCase();

  /*
    THIS INDICATES THE TYPE OF BANNER, FOR NOW ONLY ALLOWS TWO OPTIONS
    c: CARD
    b: BANNER (IS GENERALLY FULL WIDTH)
  */
  bannerType = bannerType.toLowerCase();
  index = parseInt(index);

  //  SET BASE FOLDER PATH FOR THE IMAGES
  const awsBaseURL = `${config.s3imgUrl}web/promotional/${listType}s`;

  //  LIST OF IMAGES FOR TRACK LISTING CARDS AND BANNERS
  //  WILL NEED BOTH FOR OTHER TYPES
  const aTCards = [
    { image: "scrolling.gif", url: "/playlists/top-10-smash-hits/108" },
    { image: "spotlight.gif", url: "/playlists/today's-spotlight-tracks/109" },
    {
      image: "boost.gif",
      url: "/categories/shorts-and-reels/c5cbe26e-074b-447e-bf9f-256055854732",
    },
    {
      image: "budget.gif",
      url: `/search?searchKeyword=&priceFilters=${priceFilter({
        min: "",
        max: 5000,
      })}&hs_ref=budgetmkt`,
    },
    { image: "looking.gif", url: "#" },
  ];
  const aTBanners = [{ image: "b-ott.webp", url: "#" }];

  //  IMAGE FOR INDIE SECTION CARD
  //  NO BANNERS ARE EXPECTED TO BE PRESENT
  const aIndie = [
    { image: "cfa.gif", url: "https://forms.gle/Trec9poXtsNpHnJJ8" },
  ];

  let cardSet = [];
  let bannerSet = [];

  //  SWITCH CASE WAS PREFERRED INSTEAD OF THE IF-ELSE CLAUSES
  switch (listType) {
    case "track":
      cardSet = aTCards;
      bannerSet = aTBanners;
      break;
    case "indie":
      cardSet = aIndie;
      bannerSet = [];
      break;
    // case 'pack':
    // case 'playlist':
    //   useArray = aPacks;
    //   break;
    //  case 'mood':
    //     useArray = aTracks;
    //   break;
    default:
      cardSet = aTCards;
      bannerSet = aTBanners;
  }

  let imageName = "";
  let linkTo = "";

  if (bannerType === "b") {
    if (index > bannerSet.length - 1) {
      index = index % bannerSet.length;
    }

    imageName = bannerSet[index]["image"];
    linkTo = bannerSet[index]["url"];
  } else if (bannerType === "c") {
    if (index >= cardSet.length - 1) {
      index = index % cardSet.length; // Proper looping
    }

    imageName = cardSet[index]["image"];
    linkTo = cardSet[index]["url"];
  }

  //  RETURN OBJECT CONTAINING BASE URL, THE IMAGE TO BE USED AND THE ONCLICK LINK
  return {
    baseUrl: awsBaseURL,
    image: `${awsBaseURL}/${imageName}`,
    link: linkTo,
    type: bannerType,
  };
};

export default GetMktCard;
