import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTrackFilters } from "../../service/TrackService";
// Async thunk to fetch track filters
export const fetchTrackFilters = createAsyncThunk(
  "trackFilters/fetchTrackFilters",
  async () => {
    const response = await getTrackFilters();
    const {
      // subgenre = [],
      usecase = [],
      mood = [],
      instrument = [],
      artists = [],
      language = [],
      genre = [],
    } = response.data.filters;

    return {
      genres: genre.map((genre) => ({
        value: genre.id,
        label: genre.name,
        slug: genre.slug,
      })),
      useCases: usecase.map((usecase) => ({
        value: usecase.id,
        label: usecase.name,
        slug: usecase.slug,
      })),
      moods: mood.map((mood) => ({
        value: mood.id,
        label: mood.name,
        slug: mood.slug,
      })),
      instruments: instrument.map((instrument) => ({
        value: instrument.id,
        label: instrument.name,
        slug: instrument.slug,
      })),
      artists: artists.map((artist) => ({
        value: artist.id,
        label: artist.name,
        slug: artist.slug,
      })),
      languages: language.map((language) => ({
        value: language.id,
        label: language.name,
        slug: language.slug,
      })),
    };
  }
);

const trackFiltersSlice = createSlice({
  name: "trackFilters",
  initialState: {
    genres: [],
    useCases: [],
    moods: [],
    instruments: [],
    artists: [],
    languages: [],
    status: "idle", // idle | loading | succeeded | failed
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackFilters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrackFilters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.genres = action.payload.genres;
        state.useCases = action.payload.useCases;
        state.moods = action.payload.moods;
        state.instruments = action.payload.instruments;
        state.artists = action.payload.artists;
        state.languages = action.payload.languages;
      })
      .addCase(fetchTrackFilters.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default trackFiltersSlice.reducer;
