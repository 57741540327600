const languages = {
    "b340c9ef-d2c4-4b54-87da-795639d18bb4": {
      "name": "Aalaap",
      "slug": "aalaap",
      "description": "Step into a world of timeless classical vocals with this breathtaking Aalaap playlist—where melodies do all the talking! Featuring soul-stirring, non-lyrical classical vocals, this easy-to-license collection is ideal for luxury brands, cinematic storytelling, meditative content, and high-end productions. Whether you need soothing ragas or powerful, evocative vocal expressions, these copyright-safe tracks add depth, emotion, and authenticity to your visuals. No lyrics—just pure, mesmerizing vocal artistry that blends seamlessly into any creative project. From digital content to film soundtracks, this playlist is your go-to for elevated, sophisticated soundscapes. Trendy yet timeless, traditional yet versatile—this is the ultimate classical vocal experience! Explore and let the magic of Aalaap transform your content!",
    },
    "d4ed9034-92c1-40b2-9c6d-473c48c05631": {
      "name": "Arabic",
      "slug": "arabic",
      "description": "We are the genie you’ve been waiting for to bring the magic of the Middle East to your content with this Arabic music playlist! Packed with soulful melodies, hypnotic rhythms, and high-energy hits, this collection is great for brands, ads, and creators looking for Arabic music. Whether it's a luxury brand campaign, a travel video, or a chic lifestyle ad, these trending tracks will elevate your visuals with their mystical charm and cultural richness. From sensual oud instrumentals to modern Arabic pop, it has everything you need to create eye-catching, engaging content. Easy to license and copyright-safe, these tracks make it easy to add an exotic, cinematic touch to any project. Ready to transport your audience to the Arabian Nights?",
    },
    "767a5f1b-aa39-4010-af9b-228fd28441a9": {
      "name": "Assamese",
      "slug": "assamese",
      "description": "Transport your audience to the lush landscapes of Assam with this stunning Assamese playlist! Featuring popular Bihu beats, soulful folk tunes, and contemporary Assamese pop, this copyright-safe music is best suitable for brand campaigns, cultural projects, food & travel vlogs, and more. Whether you need traditional Assamese rhythms or modern chartbusters, this playlist is packed with trendy, engaging, and mood-setting tracks. License these tracks and let the essence of Assam’s vibrant music scene add authenticity and energy to your content. With beautiful harmonies, rhythmic beats, and folk-inspired vibes, these Assamese hits will make your visuals stand out. No copyright worries, just pure musical magic—ready for a hot cup of Assamese content?",
    },
    // "893ef834-8dde-4dab-b325-dcf6e17dd3b5":{
    //   "name": "Awadhi",
    //   "slug": "awadhi",
    //   "description": "",
    // },
    "27829c2d-cf12-49d5-9373-180acb3ba2b0": {
      "name": "Bengali",
      "slug": "bengali",
      "description": "Let your content flow like the Hooghly River with this soulful Bengali playlist! Featuring a blend of Rabindra Sangeet, modern Bengali hits, and energetic folk beats, this collection is ideal for food brands, cultural projects, ethnic fashion campaigns, travel vlogs, and cinematic storytelling. Whether you need soothing melodies or high-energy Baul rhythms, these copyright-safe, easy-to-license tracks bring Bengal’s rich musical heritage to your visuals. From classic Bengali compositions to trending Bollywood and popular Tollywood bangers, this playlist has everything to set the right mood for your project. Ready to celebrate your content like Pujo? Explore and let the music do the talking!",
    },
    "c9dc16b7-78fa-4049-92d6-6543eb71349b": {
      "name": "Bhojpuri",
      "slug": "bhojpuri",
      "description": "Why settle for basics? Amp it up with legendary Bhojpuri hits! Turn up the desi swag with this high-energy playlist! Packed with peppy beats, folk-inspired melodies, and chart-topping Bhojpuri hits, this collection is ideal for fun, vibrant, and engaging content. Whether you're creating a lively campaign, a festive video, or an energetic brand ad, these copyright-safe tracks will make your content pop! From foot-tapping dhol beats to soulful folk vibes, this playlist brings the raw energy of Bhojpuri music straight to your project. License these tunes and say goodbye to copyright worries and hello to viral-worthy music that sets the perfect tone. Discover, turn up the volume, and let your content groove!",
  },
    "aae29a80-0eb6-4d6d-82f1-8f2730d6de7b": {
      "name": "Chhattisgarhi",
      "slug": "chhattisgarhi",
      "description": "Why settle for basics? Amp it up with legendary Bhojpuri hits! Turn up the desi swag with this high-energy playlist! Packed with peppy beats, folk-inspired melodies, and chart-topping Bhojpuri hits, this collection is ideal for fun, vibrant, and engaging content. Whether you're creating a lively campaign, a festive video, or an energetic brand ad, these copyright-safe tracks will make your content pop! From foot-tapping dhol beats to soulful folk vibes, this playlist brings the raw energy of Bhojpuri music straight to your project. License these tunes and say goodbye to copyright worries and hello to viral-worthy music that sets the perfect tone. Discover, turn up the volume, and let your content groove!",
    },
    "aa5edb2c-7b2a-46c7-8f8d-6408de531d6a": {
      "name": "English",
      "slug": "english",
      "description": "Keep it global and chart-topping with this ultimate English music playlist! Whether you’re looking for soulful tunes, upbeat pop, or high-energy dance anthems, this collection has everything you need to create modern, stylish, and engaging content. Best suitable for brand campaigns, social media reels, and promotional ads, this copyright-safe, easy-to-license tracks will give your visuals a polished, international feel. From smooth acoustic tunes to electrifying EDM beats, this playlist ensures your content is trendy, fresh, and full of energy. No licensing worries, just premium music for your next viral hit! Ready to elevate your content? Browse and let the music take over!",
    },
    "e581ee70-a31a-488f-95d2-5eba68bc67ca":{
      "name": "Gujarati",
      "slug": "gujarati",
      "description": "Make your content flavourful like Jalebi Fafda with this vibrant Gujarati playlist! From soulful Garba beats to modern-day Gujarati pop, this collection is perfect for festive content, cultural storytelling, and high-energy brand ads. Whether you need folk-inspired melodies or trendsetting Gujarati chartbusters, these copyright-safe tracks will add life and rhythm to your visuals. License our tracks and get ready to infuse your content with Sanedo (affection in Gujarati), foot-tapping energy, and an unbeatable vibe. Want your content to feel like a grand Navratri celebration? Explore and let the beats take over!",
    },
    "055cbc74-3eda-4d56-ae56-4b6b85abd50e": {
      "name": "Haryanvi",
      "slug": "haryanvi",
      "description": "Trending now: Haryanvi bangers that make your content stand out! Give your content a bold, raw, and high-energy twist with this Haryanvi hits playlist! Featuring desi beats, folk-inspired rhythms, and trending Haryanvi chartbusters, this collection is great for viral reels, brand campaigns, and engaging social media content. Whether you need earthy folk tunes or modern-day swag, these copyright-safe, easy-to-license tracks will make your visuals stand out. With bassy beats, catchy hooks, and true Haryanvi essence, this playlist is a must-have for brands looking to tap into regional music trends. Why settle for basics? Go full-on desi with these powerful tracks!",
    },
    "5979e031-b2a7-4acc-ab8f-518a1c19af6e":{
      "name": "Hindi",
      "slug": "hindi",
      "description": "No more basic content—these Bollywood hits will give it superstar energy! Whether you need soulful romantic melodies, high-energy dance anthems, or mood-setting background scores, this Hindi collection has it all. Perfect for brand commercials, film projects, and social media content, these copyright-safe tracks add glamour, drama, and rhythm to your visuals. From popular Bollywood tracks, modern indie beats to trending tracks, license this playlist to ensure your content feels fresh, engaging, and unforgettable. Skip the copyright stress and unlock virality!",
    },
    "aa23c047-c242-4d88-a69e-79cafc57f90d": {
      "name": "Instrumental",
      "slug": "instrumental",
      "description": "Let your visuals speak louder with soul-stirring instrumentals that add depth, drama, and emotion to your content. From orchestral scores to chill lo-fi beats, this playlist is ideal for brand films, background music, and cinematic storytelling. Whether you need soothing piano melodies or epic soundscapes, these copyright-safe, easy-to-license tracks will elevate your content with just the right vibe. No lyrics, just pure emotion—let the power of instrumentals create the perfect mood for your visuals. Discover the right track and feel the music!",
    },
    "946310b6-2b80-4c8d-8cfa-2465a8ab76c9":{
      "name": "Jamaican Patois",
      "slug": "jamaican-patois",
      "description": "Bring a delightful cadence to your content with this reggae and dancehall-inspired playlist! Packed with rhythmic beats, smooth grooves, and high-energy anthems, these tracks are best suitable for brand campaigns, travel vlogs, and lifestyle content. Whether you want a laid-back chill vibe or a bold, energetic sound, this copyright-safe, easy-to-license music delivers pure Caribbean magic. Let your visuals feel tropical, popular, and full of swagger! No copyright worries—just feel-good, catchy music that keeps your content fresh and engaging. Find the right track and bring the heat!",
    },
    // "79bf00c8-b560-4f46-b5c0-2ced332e303c":{
    //   "id": "79bf00c8-b560-4f46-b5c0-2ced332e303c",
    //   "name": "Japanese",
    //   "slug": "japanese",
    //   "description": "Japanese music is the music of Japan, an island country in East Asia. It is a rich and diverse music culture that has been influenced by many other cultures and music styles.",
    // },
    "727e0390-9635-4c61-88d8-c276ac197e5f":{
      "name": "Kannada",
      "slug": "kannada",
      "description": "Capture the essence of Karnataka with this energetic and soulful Kannada playlist! Whether it's trending Sandalwood beats, folk-inspired melodies, or modern Kannada pop, this copyright-safe collection is perfect for brand campaigns, festive reels, and cultural storytelling. These easy-to-license tracks bring the true spirit of Kannada music to your project—without any copyright worries. From romantic melodies to high-energy bangers, this playlist has it all to keep your content fresh, vibrant, and engaging. Explore the right track and let your visuals groove!",
    },
    "ab229062-86dc-4378-8769-7dd5522b8e7b": {
      "name": "Kumaoni",
      "slug": "kumaoni",
      "description": "Take your audience on a musical journey through the Himalayas with this soulful Kumaoni playlist! Featuring traditional folk vibes, modern fusion tracks, and heartwarming melodies, this collection is ideal for travel films, brand commercials, and cultural storytelling. These copyright-safe, easy-to-license tracks bring the true essence of Uttarakhand to life, making your content feel authentic, engaging, and popular. Whether you want soothing sounds or high-energy beats, this playlist sets the perfect tone. No copyright worries, just pure musical charm! Discover and let your visuals shine!",
    },
    "0d8d4ef1-e2d6-477d-9b88-1e04d31416a9": {
      "name": "Malayalam",
      "slug": "malayalam",
      "description": "Immerse your content in the rich, cinematic soundscape of Kerala with this popular Malayalam playlist! From soul-stirring melodies to high-energy hits, this collection is ideal for ad campaigns, film projects, and digital content. Whether you need romantic ballads or power-packed anthems, these copyright-safe tracks will give your visuals authenticity and depth. With a mix of classic and modern Malayalam music, license this playlist to ensure your content feels fresh, engaging, and full of life. No copyright stress—just pure musical brilliance!",
    },
    "23e1af83-4935-462b-83b7-74da4d712b1f": {
      "name": "Marathi",
      "slug": "marathi",
      "description": "Want to add Marathi Tadka to your content with this vibrant, high-energy playlist? Featuring a mix of folk-inspired beats, contemporary hits, and soulful melodies, this popular collection is perfect for brands, filmmakers, and digital creators looking for copyright-safe Marathi tracks. Whether you want dhol-tasha vibes for Ganesh Chaturthi or modern-day chartbusters, these tracks bring the true spirit of Maharashtra to your visuals. Fresh, powerful, and energetic, license this playlist to keep your audience hooked from start to finish. Discover and let your content shine!",
    },
    "ad4b92ca-3d84-465b-8269-7cf2a17887e3": {
      "name": "Marwari",
      "slug": "marwari",
      "description": "Because nothing screams ‘royal’ like epic Marwari hits! This trending playlist features soulful folk melodies, celebratory beats, and high-energy anthems. This copyright-safe collection is best suitable for travel vlogs, cultural projects, wedding films, and ethnic brand ads. These easy-to-license tracks add authentic Rajasthani flair to your visuals, making them stand out with grandeur and vibrance. Whether you need traditional-inspired rhythms or modern Marwari hits, this playlist is your go-to for impactful storytelling.",
    },
    "b0bf9455-a68f-431d-9189-f5e5beb8c1fa": {
      "name": "Odia",
      "slug": "odia",
      "description": "Step into the rich musical world of Odisha with this soulful Odia playlist! Packed with traditional beats, trending hits, and high-energy melodies, this collection is perfect for brand campaigns, cultural projects, and social media content. These copyright-safe, easy-to-license tracks bring the true essence of Odisha to life, making your content feel authentic and engaging. From folk-inspired vibes to modern-day anthems, this playlist ensures your visuals pop with rhythm and energy. Explore the right track and let your content groove!",
    },
    "4687d721-b049-4eb7-8b2a-14ac0421e665": {
      "name": "Punjabi",
      "slug": "punjabi",
      "description": "Get the energy of Balle Balle with this ultimate Punjabi playlist! Featuring Bhangra beats, soulful melodies, and modern chartbusters, this trendy collection is ideal for high-energy brand ads, viral reels, and cinematic storytelling. Whether you need power-packed anthems or melodic ballads, these copyright-safe, easy-to-license tracks deliver pure Punjabi swag. Say goodbye to copyright headaches and hello to viral-worthy soundtracks. Discover and let the Shava Shava beats take over!",
    },
    "a6ea0418-f18a-49b5-bfd3-2c2b63372d3e": {
      "name": "Rajasthani",
      "slug": "rajasthani",
      "description": "",
    },
    "f3d276ef-3870-4e3b-902a-58a1f9cab1ce": {
      "name": "Sanskrit",
      "slug": "sanskrit",
      "description": "Elevate your content with the timeless beauty of Sanskrit music! Featuring soulful chants, spiritual hymns, and classical-inspired melodies, this playlist is ideally popular for wellness brands, cultural projects, and cinematic storytelling. These copyright-safe, easy-to-license tracks bring a sense of serenity, power, and tradition to your visuals. Whether you’re creating meditative content or a spiritual campaign, this playlist sets the perfect mood. No copyright worries—just pure, authentic musical magic!",
    },
    "88a7e067-a7e2-4788-8faa-2ef1fa02be19": {
      "name": "Sinhala",
      "slug": "sinhala",
      "description": "Give your content a Sri Lankan twist with this energetic Sinhala playlist! From traditional rhythms to trending contemporary beats, this collection is best suitable for brand ads, travel vlogs, and cinematic projects. These copyright-safe, easy-to-license tracks bring a cultural richness and rhythmic charm that will elevate your visuals effortlessly. Fresh, vibrant, and packed with energy—this playlist is a must-have for content creators! Explore and find the right track for you now!",
    },
    "35f90ab6-2b42-4a58-90cb-77532d97d7aa": {
      "name": "Spanish",
      "slug": "spanish",
      "description": "Content glow-up unlocked: the best Spanish tracks are here! From passionate melodies to trendsetting beats, this collection is perfect for brands, filmmakers, and content creators looking for trending copyright-safe Spanish tracks. Whether it’s a fiery rhythm or a smooth melody, these easy-to-license tracks will make your visuals pop with international charm.",
    },
    "0f019ef3-fd64-4e74-856e-e4cdd113605c": {
      "name": "Tamil",
      "slug": "tamil",
      "description": "Turn casual scrollers into superfans with these Kollywood soundscapes! Featuring soulful melodies, electrifying beats, and high-energy anthems, this collection is ideal for brand ads, trending reels, and cinematic storytelling. Whether you need a heart-touching ballad or a foot-stomping party track, these copyright-safe songs will add authentic Tamil magic to your visuals. From legendary classics to modern-day chartbusters, license this playlist to ensure your content is fresh, engaging, and unforgettable. Ready to be a Bahubali of content? Explore the right track and let your visuals roar like a mass entertainer!",
    },
    "ee1e80f2-5947-4bdf-945b-0c4482495f35": {
      "name": "Telugu",
      "slug": "telugu",
      "description": "Take your content to the next level with Tollywood’s finest trending beats! This power-packed Telugu playlist is loaded with soulful melodies, energetic dance numbers, and cinematic bangers—perfect for ad campaigns, social media reels, and film projects. Whether you need a romantic track or a peppy mass hit, these copyright-safe songs will give your visuals authenticity and style. From melodic ballads to high-octane beats, license this playlist to ensure your content stands out in true superstar style. Discover and let your content shine like a blockbuster!",
    },
    "bb62105f-ee28-401b-b088-ea8cba70f616": {
      "name": "Urdu",
      "slug": "urdu",
      "description": "Add elegance, poetry, and timeless charm to your content with this soul-stirring Urdu playlist! Featuring ghazals, contemporary hits, and emotionally rich melodies, this collection is best suitable for brand films, artistic storytelling, and cinematic projects. Whether you need soothing, heartfelt tunes or powerful, evocative anthems, these copyright-safe, easy-to-license tracks bring grace, depth, and beauty to your visuals. From classic legends to modern Urdu hits, this trending playlist makes sure your content feels poetic, powerful, and truly mesmerizing.",
    }
}

export default languages;