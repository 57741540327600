import React, { useState } from "react";
import styles from "../styles/AboutUs.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import config from "../utils/config";
import Carousel from "../components/Carousel/Carousel";
import brands from "../utils/data/brands";
import testimonials from "../utils/data/testimonials";

const AboutUs = () => {
  const [activeFeature, setActiveFeature] = useState("copyright"); // Default image
  // Mapping feature keys to image filenames
  const featureImages = {
    popular: "ad-popular.webp",
    flexible: "ad-flexible.webp",
    copyright: "ad-copyright.webp",
    affordable: "ad-affordable.webp",
  };

  return (
    <div className={`${styles.container} ${styles.outerFull}`}>
      <div className={styles.topSection}>
        <h1 className={styles.topHeading}>
          {/* Trending Music for Impactful Content */}
          Trending Music. Impactful Content!
        </h1>
        {/* <p className={styles.subHeading}>
        At Hoopr Smash, we power your content with trending music that drives 3X brand engagement. From Bollywood hits to regional gems and viral indie tracks, our music licensing platform connects you to premium, copyright-safe tunes. Perfect for short form videos designed to ignite trends and go viral!
        </p> */}
        <Link to={"/"}>
          <CustomButton
            text="Start Now"
            backgroundColor="#F84451"
            borderRadius="50px"
            width="242px"
            height="50px"
            textColor="#fff"
            fontSize="18px"
            fontFamily="Raleway"
            fontWeight="550"
          />
        </Link>
        <div className={styles.topImageSection}>
          <video
            poster={`${config.s3imgUrl}static/about/top-about.webp`}
            playsInline
            disablePictureInPicture
            disableRemotePlayback
            controls
            className={styles.abtSearchImgL}
          >
            <source
              src={`${config.s3imgUrl}static/about/about-smash.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <p className={styles.context}>
          At Hoopr Smash, we power your content with trending music that drives
          3X brand engagement. From Bollywood hits to regional gems and viral
          indie tracks, our music licensing platform connects you to premium,
          copyright-safe tunes. Perfect for short form videos designed to ignite
          trends and go viral!
        </p>
        {/* <p className={styles.context}>
          With Hoopr Smash, you're not just licensing music—you're gaining the
          peace of mind to use premium tracks without any copyright worries.
          Whether it's for social media campaigns, short-form videos, or content
          designed to inspire, we make it easy for you to find the perfect music
          for every project.
        </p> */}
      </div>
      {/* trusted brand Section */}
      <div className={styles.trustedBrandSection}>
        <p className={styles.brandHeading}>Trusted by 125+ brands</p>
        {/* <div className={styles.brandImagesContainer}></div> */}
        <div className={`${styles.brandImagesContainer} ${styles.inner}`}>
          <Carousel
            gap={0}
            autoScroll={true}
            scrollInterval={2000}
            type="brands"
          >
            {brands.list.map((image, index) => (
              <img
                key={index}
                src={`${brands.basePath}/${image}`}
                alt={image.replace(".png", "")} // Remove file extension for better alt text
                className={styles.brandImages} // Add styling if needed
              />
            ))}
          </Carousel>
        </div>
      </div>
      {/* BRAND TESTIMONIALS */}
        <p className={`${styles.brandTestHeader}`}>Testimonials</p>
        <div className={`${styles.brandTestContainer} ${styles.outerFull}`}>
          <div className={`${styles.inner}`}>
            <Carousel
              gap={10}
              autoScroll={false}
              // scrollInterval={5000}
              // type="brands"
            >
              {testimonials.list.map((image, index) => (
                <img
                  key={index}
                  src={`${testimonials.basePath}/${image}`}
                  alt={image.replace(".png", "")} // Remove file extension for better alt text
                  className={styles.brandTestImages} // Add styling if needed
                />
              ))}
              </Carousel>
            </div>
        </div>
      {/* why choose hoopr Section */}
      <div className={styles.wchSection}>
        {/* <p className={styles.brandHeadingWCH}>Why Choose Hoopr Smash?</p> */}
        <p className={styles.wchHeading}>Why Choose Hoopr Smash?</p>
        <p className={styles.wchContext}>
          Hoopr Smash offers the perfect music licensing solutions to drive
          engagement and deliver results for your social media.
        </p>

        <div className={styles.wchImagesSection}>
          <div className={styles.wchImg}>
            <img src={`${config.s3imgUrl}static/about/choose1.webp`} />
          </div>
          <div className={styles.wchImg}>
            <img src={`${config.s3imgUrl}static/about/choose2.webp`} />
          </div>
          <div className={styles.wchImg}>
            <img src={`${config.s3imgUrl}static/about/choose3.webp`} />
          </div>
        </div>
      </div>
      {/* why choose hoopr Section */}
      <div className={styles.wchSection}>
        {/* <p className={styles.brandHeadingWCH}>Why Choose Hoopr Smash?</p> */}
        <p className={styles.wchHeading}>A Curated Library for Every Story</p>
        <div className={styles.wchImagesSection}>
          <div className={styles.wchImg1}>
            <img src={`${config.s3imgUrl}static/about/curate-usecases.webp`} />
          </div>
          <div className={styles.wchImg2}>
            <div className={styles.img2topSection}>
              <img
                src={`${config.s3imgUrl}static/about/curate-occasions.webp`}
              />
            </div>
            <div className={styles.subimagesSection}>
              <div className={styles.subimg1}>
                <img
                  src={`${config.s3imgUrl}static/about/curate-genres.webp`}
                />
              </div>
              <div className={styles.subimg1}>
                <img src={`${config.s3imgUrl}static/about/curate-moods.webp`} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* How does it work Section */}
      <div className={`${styles.hwsSection} ${styles.inner}`}>
        <p className={styles.hwsHeading}>How Does It Work?</p>
        {/* setp 1 */}
        <div className={styles.stepContainer}>
          <div className={styles.hwsImage}>
            {/* <img src={`${config.s3imgUrl}static/about/search.gif`} className={styles.abtSearchImg} /> */}
            <video
              autoPlay
              muted
              loop
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              className={styles.abtSearchImgL}
            >
              <source
                src={`${config.s3imgUrl}static/about/search-select.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.hwsStepText}>
            <div className={styles.stepsIcon}>
              <div className={styles.iconContainer}>
                <img
                  className={styles.starIcon}
                  src={`${config.s3imgUrl}icons/search.webp`}
                />
              </div>
              <p className={styles.heading}>Search & Select</p>
            </div>
            <p className={styles.stepSubHeading}>
              Browse through 5000+ trending tracks for your brand’s content.
              Boost engagement by choosing between individual tracks and curated
              packs.
            </p>
          </div>
        </div>
        {/* step 2 */}
        <div className={styles.stepContainer}>
          <div className={styles.hwsStepText}>
            <div className={styles.stepsIcon}>
              <div className={styles.iconContainer}>
                <img
                  className={styles.starIcon}
                  src={`${config.s3imgUrl}icons/license.webp`}
                />
              </div>
              <p className={styles.heading}>License & Create</p>
            </div>
            <p className={styles.stepSubHeading}>
              Get full copyright safety and India-wide clearance with
              transparent pricing. Use our music worry-free for Instagram Reels
              and YouTube Shorts.
            </p>
          </div>
          <div className={styles.hwsImage}>
            <video
              autoPlay
              muted
              loop
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              className={styles.abtSearchImgR}
            >
              <source
                src={`${config.s3imgUrl}static/about/license-create.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        {/* setp 3 */}
        <div className={styles.stepContainer}>
          <div className={styles.hwsImage}>
            <video
              autoPlay
              muted
              loop
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              className={styles.abtSearchImgL}
            >
              <source
                src={`${config.s3imgUrl}static/about/quick-clearance.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.hwsStepText}>
            <div className={styles.stepsIcon}>
              <div className={styles.iconContainer}>
                <img
                  className={styles.starIcon}
                  src={`${config.s3imgUrl}icons/clearance.webp`}
                />
              </div>
              <p className={styles.heading}>Get Quick Clearance</p>
            </div>
            <p className={styles.stepSubHeading}>
              Instantly whitelist your content by adding their links to gain
              perpetuity clearance.
            </p>
          </div>
        </div>
      </div>
      {/* advantages Section */}
      <div className={`${styles.advContainer} ${styles.inner}`}>
        <div className={styles.advatagesInfo}>
          <p className={styles.advText}>ADVANTAGE</p>
          <p className={styles.advHeading}>Features That Set Us Apart</p>
          <div className={styles.advList}>
            {/* Feature Items */}
            <div
              className={`${styles.advStepContainer} ${
                activeFeature === "popular" ? styles.highlightAdv : ""
              }`}
              onMouseEnter={() => setActiveFeature("popular")}
              onClick={() => setActiveFeature("popular")}
            >
              <div className={styles.stepHeading}>
                <img
                  className={styles.advIcon}
                  src={`${config.s3imgUrl}icons/au-popular.png`}
                  alt="Popular Tracks"
                />
                <p className={styles.stepHeadingText}>Popular Tracks</p>
              </div>
              <p className={styles.stepInfoText}>
                Music from top Indian labels and artists, including Bollywood
                and trending regional tracks.
              </p>
            </div>

            <div
              className={`${styles.advStepContainer} ${
                activeFeature === "flexible" ? styles.highlightAdv : ""
              }`}
              onMouseEnter={() => setActiveFeature("flexible")}
              onClick={() => setActiveFeature("flexible")}
            >
              <div className={styles.stepHeading}>
                <img
                  className={styles.advIcon}
                  src={`${config.s3imgUrl}icons/au-flexible.png`}
                  alt="Flexible Licensing"
                />
                <p className={styles.stepHeadingText}>
                  Flexible Licensing Options
                </p>
              </div>
              <p className={styles.stepInfoText}>
                Choose between per-track licensing or bundled packs for even
                greater value.
              </p>
            </div>

            <div
              className={`${styles.advStepContainer} ${
                activeFeature === "copyright" ? styles.highlightAdv : ""
              }`}
              onMouseEnter={() => setActiveFeature("copyright")}
              onClick={() => setActiveFeature("copyright")}
            >
              <div className={styles.stepHeading}>
                <img
                  className={styles.advIcon}
                  src={`${config.s3imgUrl}icons/au-affordable.png`}
                  alt="100% Copyright Safe"
                />
                <p className={styles.stepHeadingText}>100% Copyright Safe</p>
              </div>
              <p className={styles.stepInfoText}>
                All music comes with complete copyright clearance and zero legal
                hassles.
              </p>
            </div>

            <div
              className={`${styles.advStepContainer} ${
                activeFeature === "affordable" ? styles.highlightAdv : ""
              }`}
              onMouseEnter={() => setActiveFeature("affordable")}
              onClick={() => setActiveFeature("affordable")}
            >
              <div className={styles.stepHeading}>
                <img
                  className={styles.advIcon}
                  src={`${config.s3imgUrl}icons/au-copyright.png`}
                  alt="Affordable Pricing"
                />
                <p className={styles.stepHeadingText}>Affordable Pricing</p>
              </div>
              <p className={styles.stepInfoText}>
                Unlock premium tracks at unbeatable rates and save up to 90%.
              </p>
            </div>
          </div>
        </div>
        {/* Dynamic Image Display */}
        <div className={styles.advantageImg}>
          <img
            src={`${config.s3imgUrl}static/about/${featureImages[activeFeature]}`}
            alt="Advantage Feature"
          />
        </div>
      </div>
      {/* more about hoopr */}
      <div className={`${styles.moreContainer}`}>
        <div className={`${styles.inner}`}>
          <p className={styles.moreHeadertext}>More About Our Platform</p>
          <div className={styles.moreSteps}>
            <div className={styles.msContainer}>
              <div className={styles.moreContainerIcon}>
                <img
                  className={styles.moreContainerImg}
                  src={`${config.s3imgUrl}icons/aboutmorecircle.png`}
                />
              </div>
              <p className={styles.moreHeadingtext}>
                What can I use Hoopr Smash music for?
              </p>
              <p className={styles.moreSubHeading}>
                Hoopr Smash is crafted for brands to effortlessly license
                Bollywood and trending music for their short-form videos on
                platforms like YouTube Shorts, Instagram Reels, and Facebook
                Reels. Boost your brand's engagement and amplify discovery with
                the perfect soundtrack.
              </p>
            </div>
            <div className={styles.msContainer}>
              <div className={styles.moreContainerIcon}>
                <img
                  className={styles.moreContainerImg}
                  src={`${config.s3imgUrl}icons/license.png`}
                />
              </div>
              <p className={styles.moreHeadingtext}>
                How long does a license remain valid?{" "}
              </p>
              <p className={styles.moreSubHeading}>
                The licensing agreement is typically valid for 1 year from the
                date of purchase, with perpetual clearance for any content
                published within this period. However, it can be customized to
                meet your brand’s specific needs.
              </p>
            </div>
            <div className={styles.msContainer}>
              <div className={styles.moreContainerIcon}>
                <img
                  className={styles.moreContainerImg}
                  src={`${config.s3imgUrl}icons/rupee.png`}
                />
              </div>
              <p className={styles.moreHeadingtext}>
                Are there any hidden costs?
              </p>
              <p className={styles.moreSubHeading}>
                Our pricing is fully transparent. No hidden costs. And you need
                not worry about additional fees.
              </p>
            </div>
          </div>
          <div className={styles.moreCards}>
            <div
              style={{
                backgroundImage: `url(${config.s3imgUrl}static/about/explore.webp)`,
              }}
              className={`${styles.cardContainer}`}
            >
              <p className={styles.cardheadingL}>
                Get 3X Brand Engagement!
                {/* Ready to 3X Your Brand's Engagement? */}
              </p>
              <p className={styles.cardSubHeadingL}>
                Explore our curated library of 5000+ tracks to elevate your
                content.
              </p>
              <Link to={"/"}>
                <CustomButton
                  text="Explore Tracks"
                  backgroundColor="#F84451"
                  borderRadius="50px"
                  width="238px"
                  height="50px"
                  textColor="#fff"
                  fontSize="18px"
                  fontFamily="Raleway"
                  fontWeight="550"
                />
              </Link>
            </div>
            <div
              style={{
                backgroundImage: `url(${config.s3imgUrl}static/about/faq.webp)`,
              }}
              className={`${styles.cardContainer}`}
            >
              <p className={styles.cardheadingR}>Still Got Questions?</p>
              <p className={styles.cardSubHeadingR}>
                Dive into our FAQs to learn how Hoopr Smash can help you create
                magic.
              </p>
              <Link to={"/faqs"}>
                <CustomButton
                  text="Read FAQs"
                  backgroundColor="#FFF"
                  borderRadius="50px"
                  width="238px"
                  height="50px"
                  textColor="#F84451"
                  fontSize="18px"
                  fontFamily="Raleway"
                  fontWeight="550"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
