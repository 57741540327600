import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "../styles/Faq.css";

// note a reference of the text how can it can be bold italic or add a href an example array is below the complete code.
const faqData = [
  // {
  //   name: "Catalogue",
  //   questions: [
  //     // {
  //     //   text: "What is Hoopr Smash?",
  //     //   answer:
  //     //     "Hoopr Smash is our premium music licensing service that provides high-quality, licensed music for businesses, brands, and creators. With Hoopr Smash, you can access exclusive and trending tracks for your projects, ensuring you have the legal rights to use the music worry-free.",
  //     // },
  //     {
  //       text: "What is Hoopr Smash?",
  //       answer: (
  //         <>
  //           Hoopr Smash is our <b>premium music licensing service</b> that
  //           provides high-quality, licensed music for businesses, brands, and
  //           creators. With Hoopr Smash, you can access{" "}
  //           <i>exclusive and trending tracks</i> for your projects, ensuring you
  //           have the legal rights to use the music worry-free.
  //         </>
  //       ),
  //     },
  //     // {
  //     //   text: "Why do I need a music license?",
  //     //   answer:
  //     //     "A music license protects you from copyright infringement issues and ensures you’re legally authorised to use the music in your content. Using unlicensed music can lead to takedown notices, legal fees, or other penalties. Licensing ",
  //     // },
  //     {
  //       text: "Why do I need a music license?",
  //       answer: (
  //         <>
  //           A music license protects you from copyright infringement issues and
  //           ensures you’re <b style={{ color: "blue" }}>legally authorised</b>{" "}
  //           to use the music in your content. Using unlicensed music can lead to{" "}
  //           <a
  //             href="https://dev.hooprsmash.com"
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             style={{ color: "blue", textDecoration: "underline" }}
  //           >
  //             takedown notices
  //           </a>
  //           , legal fees, or other penalties.
  //         </>
  //       ),
  //     },

  //     {
  //       text: "What are the steps to license music on Hoopr Smash?",
  //       answer:
  //         "Select the music track(s) you’d like to license.\nAdd your selection of music track(s) to the cart.\nCheckout and complete the payment process.",
  //     },
  //     {
  //       text: "What are the benefits of licensing music with Hoopr Smash?",
  //       answer: (
  //         <>
  //           "Yes, we will notify you before the licensing period ends. You can also visit <b>'My Account'</b> &gt;&gt; <b>'My Licenses'</b> to access this information.
  //           The start and end dates of your license validity will be mentioned next to each track individually."
  //         </>
  //       ),
  //     },
  //   ],
  // },
  {
    name: "General Information",
    questions: [
      {
        text: "What is Hoopr Smash?",
        answer: (
          <>
            Hoopr Smash is a music licensing platform that provides high-quality, premium, bollywood, regional, trending tracks and music created by independent artists. This music is available for licensing to businesses, brands, agencies, content studios and creators. With Hoopr Smash, you can access exclusive and trending tracks for your projects, ensuring you have the legal rights to use the music worry-free. Licensees will receive India-wide clearance on music with 100% copyright safety.
          </>
        )
      },
      {
        text: "How is Hoopr Smash different from hoopr.ai?",
        answer: (
          <>
            Hoopr Smash provides licensing solutions for premium Bollywood, regional, and trending tracks from top labels and artists, available per track or in bundles, with customization options. On the other hand, Hoopr.ai offers a subscription-based model, granting access to 12,500+ proprietary tracks, instrumentals, background music, and sound effects, created by a network of 6,500+ independent artists.
          </>
        )
      },
      {
        text: "What types of catalogues does Hoopr Smash offer?",
        answer: (
          <>
            Hoopr Smash offers a wide catalogue of premium bollywood music, regional music, trending tracks and music created by independent artists from top Indian labels and artists. This catalogue is spread across 30+ use cases, 30+ genres, 40+ moods, and 70+ events and occasions.
          </>
        )
      },
      {
        text: "Will there be any public-domain music on Hoopr Smash for licensing?",
        answer: (
          <>
            Yes, currently we offer a limited catalogue of original renditions/covers of public-domain music on Hoopr Smash.
          </>
        )
      },
      {
        text: "How does Hoopr Smash legally license this music to users?",
        answer: (
          <>
            Hoopr works with labels and music owners as the authorised licensing platform for all soundtracks available on Hoopr Smash.
          </>
        )
      },
      {
        text: "Where can I use music from Hoopr Smash?",
        answer: (
          <>
           With a license, you can use Hoopr Smash music only on social media for short form video content such as Facebook Reels, Instagram Reels or Youtube Shorts. To understand detailed dos and don'ts, read the usage guidelines listed against each track.
          </>
        )
      },
      {
        text: "Does Hoopr Smash have an app or is it available only as a website?",
        answer: (
          <>
            Presently, Hoopr Smash is only available as a website.
          </>
        )
      },
      {
        text: "What kind of music can you license on Hoopr Smash?",
        answer: (
          <>
            On Hoopr Smash, you can license a wide range of music, including
            <ol style={{ marginLeft: "18px" }} >
              <li>Bollywood Music</li>
              <li>Regional Music</li>
              <li>Trending Music</li>
              <li>Music from Independent Artists</li>
            </ol>
            <br/>

            This diverse collection ensures you have access to music that fits your content’s mood and theme perfectly!
          </>
        )
      },
      {
        text: "What are the different categories/usecases for which I can license music?",
        answer: (
          <>
            Hoopr Smash offers a versatile library of music catering to diverse genres, use cases, and moods, ensuring there’s something for every type of content.<br/><br/>

            <u>Genres</u>: Hip-Hop, Bollywood, Rap, and many more!<br/>
            <u>Use Cases</u>: Travel, Weddings, Food Vlogging, General Vlogging, Fashion, and beyond.<br/>
            <u>Moods</u>: Happy, Sad, Energetic, and every emotion in between.<br /><br />

            No matter your content style or theme, Hoopr Smash has the perfect soundtrack to elevate it!
          </>
        )
      },
      {
        text: "Can you explain the different branded content uses that are permitted?",
        answer: (
          <>
            Currently, music licensed from Hoopr Smash can be used for short video formats, for social media promotions by brands.<br/>
            For any other platforms or uses outside of social media (e.g., TV, OTT, or advertisements), brands or users are encouraged to reach out to our team with their specific requirements, by clicking on the "Request a Quote button" on the respective track page. We’ll be happy to assist with a custom licensing solution!
          </>
        )
      },
      {
        text: "How does short form video content licensing work on Hoopr Smash?",
        answer: (
          <>
            Music licensed from Hoopr Smash is perfect for short-form video content like Instagram Reels or YouTube Shorts. Once you've licensed the track, you're all set to use it in your short-form videos while ensuring your content stays copyright-compliant. Once you have posted your content with the respective track integrated, make sure to submit your content links for verification and perpetuity clearance through
            <b>My Account</b> &gt;&gt; <b>My Purchases</b> &gt;&gt; <b>+Add Link</b>
          </>
        )
      },
      {
        text: "Is licensing from Hoopr Smash cheaper than directly licensing from music owners and labels?",
        answer: (
          <>
            Yes, absolutely! Licensing music directly from owners or labels can cost anywhere between a few lakhs to a crore per song, depending on the track's popularity and usage. On the other hand Hoopr Smash works as the authorized licensing partner, directly working with music owners and labels which allows us to offer the same music at significantly more competitive rates. <br/>
            This means you can access premium tracks for your content without breaking the bank, all while staying copyright-compliant.
          </>
        )
      },
      {
        text: "Can I request a song that is not available on the platform?",
        answer: (
          <>
            Yes, you can! Simply use the <b>Request a Song</b> button under Support on the header on the platform and send us your detailed requirements by completing the request form. <br/>
            Our team will review your request and do their best to add the song or provide alternatives that suit your needs.
          </>
        )
      },
      {
        text: "Do I have to pay additional charges for royalty and IPRS after I license music from Hoopr Smash?",
        answer: (
          <>
            No, you don’t! All costs are fully inclusive when you license music from Hoopr Smash. This means you don’t need to worry about any additional royalties, IPRS fees, or hidden charges. We've got you covered!
          </>
        )
      },
      {
        text: "What is the duration of the licensing agreement for tracks licensed from Hoopr Smash? What happens to my content after the licensing period ends?",
        answer: (
          <>
            The licensing agreement for each track lasts for one year from th date of purchase. Any content created and published using the licensed song within this period remains copyright-free for perpetuity, even after the agreement ends.
          </>
        )
      },
      {
        text: "Is music licensed from Hoopr Smash cleared for commercial use like DVCs,TVCs and performance marketing videos?",
        answer: (
          <>
            No, music licensed from Hoopr Smash is not cleared for such commercial uses. However, if you have specific requirements for DVCs, TVCs, or performance marketing videos, you can request a custom quote from our team, and we’ll get back to you within 2 business days with a custom quote.
          </>
        )
      },
      {
        text: "What is the difference between a track and a pack on the Hoopr Smash platform?",
        answer: (
          <>
            A track is a single music piece, either vocal or instrumental. A pack is a curated collection of themed tracks, ideal for cohesive content creation. Packs offer cost-effective bundling, providing significant savings compared to individual track purchases.
          </>
        )
      },
      {
        text: "What are the benefits of licensing music with Hoopr Smash?",
        answer: (
          <>
            Licensing music from Hoopr Smash offers full copyright compliance to brands/ creators along with:<br/><br/>

            <u>Legal Protection</u>: Avoiding potential copyright claims and legal issues.<br/>
            <u>Simplified Process</u>: Hoopr Smash manages all the licensing paperwork, making it easy for you.<br/>
            <u>Cost Savings</u>: Licensing music saves you up to 10x in potential legal costs compared to using unlicensed music.<br/>
            <u>Quality Tracks</u>: Access a curated selection of professionally produced music that enhances your content.
          </>
        )
      },
    ],
  },
  {
    name: "Account Help",
    questions: [
      {
        text: "How do I create my Hoopr Smash account?",
        answer: (
          <>
            To create a Hoopr Smash account:<br/>
            Visit our website: <a
              href="https://hooprsmash.com"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >Hoopr Smash</a><br/>
            Go to the Sign-Up section and provide the necessary details, such as name, email, and password.<br/>
            Once registered, you can log in to access your account and begin browsing.
          </>
        )
      },
      {
        text: "How to edit/ update my information?",
        answer: (
          <>
            To edit or update your information:<br/>
            Navigate to <b>My Account</b> &gt;&gt; <b>Profile</b> <br/>
            Click on the edit option, where you can update information like your name, email, phone number, and other details. <br/>
            Save the changes to update your profile.
          </>
        )
      },
      {
        text: "What is the My likes section?",
        answer: (
          <>
            The <b>My Likes</b> section is a historical collection of your favourited/ liked tracks. It helps you quickly access and organise your preferred songs, playlists and packs for easy retrieval.
          </>
        )
      },
      {
        text: "How to save songs, packs or playlists to the My Likes section?",
        answer: (
          <>
            Click the <b>♡ Icon</b> next to the track you want to like or add to favourites.<br/>
            The song will then appear in your My Likes section for future reference. You can check all songs, packs and playlists which have been favourited by you.
          </>
        )
      },
      {
        text: "How do I view my purchases and track them?",
        answer: (
          <>
            To view and track your purchases: <br/>
            Go to <b>My Account</b> &gt;&gt; <b>My Purchases</b> <br/>
            Here, you will be able to see a list of all your purchased licenses. You can also view detailed transaction history and license validity details.
          </>
        )
      },
      {
        text: "Where can I check the validity and expiry of my licenses?",
        answer: (
          <>
            To check the validity and expiry of your licenses, go to <b>My Account</b> &gt;&gt; <b>My Purchases</b> <br/>
            This section displays the details of each license purchased by you, including the validity period and expiry date.
          </>
        )
      },
      {
        text: "Where can I check my orders and invoices?",
        answer: (
          <>
            To check your orders and invoices, go to <b>My Account</b> &gt;&gt; <b>Purchases</b> <br/>
            You'll find a record of all your purchases with corresponding invoices available for download.
          </>
        )
      },
      {
        text: "What is the process for getting content clearance for music licensed from Hoopr Smash?",
        answer: (
          <>
            When you license a song from Hoopr Smash, each track comes with 3 usages, indicating how many instances you can use the song in. Please read the answer to the next question to understand instances better. Here’s how the process works:
            <br/><br/>
            <u>Choose Your Track</u><br/>
            Browse and purchase your preferred song from the Hoopr Smash library.
            <br/><br/>
            <u>Track Your Usage</u><br/>
            Each song has a designated number of credits, which represent the number of times it can be used.
            <br/><br/>
            <u>Add Content Links</u><br/>
            For every use, upload your content and add the link(s) of the content where the song is featured. These links must be added next to the corresponding credit used for proper clearance.
            <br/><br/>
            This ensures smooth content clearance and compliance with licensing requirements, letting you focus on creating worry-free content.
          </>
        )
      },
      {
        text: "How many usage instances are permitted per licensed track?",
        answer: (
          <>
            Each track license allows up to 3 usage instances, covering both video count and publishing platforms. Examples include:<br />
            <ul style={{ marginLeft: "18px" }}>
              <li>The same video posted separately on up to 3 platforms (e.g., Instagram, YouTube, Snapchat).</li>
              <li>The same video on multiple platforms, using different segments of the track.</li>
              <li>The same video in different languages, each using segments of the track across platforms.</li>
            </ul>
            Each video usage counts toward the limit, regardless of the platform.
          </>
        )
      },
      {
        text: "Who can I contact for any support?",
        answer: (
          <>
            Please contact us via email at <a href="mailto:smashsupport@gsharp.media">smashsupport@gsharp.media</a> or call us at <a href="tel:+917400226274">+91 7400226274</a>.
          </>
        )
      },
      {
        text: "How long can I expect a reply from the customer support team regarding my query or issue?",
        answer: (
          <>
            Our customer support team strives to respond to your queries as quickly as possible. While we aim to address most requests promptly, please allow up to 1-2 business days for a reply.<br/>
            Due to the spontaneous nature of incoming requests, there may be a slight delay during peak times. Rest assured, your concerns are important to us, and we’ll get back to you as soon as we can.<br/>
            Thank you for your patience and understanding!
          </>
        )
      },
    ],
  },
  {
    name: "Getting Started",
    questions: [
      {
        text: "How do I search for the right music for my requirements?",
        answer: (
          <>
            Once you're on the homepage, there are two ways to explore and discover tracks as per your requirements:<br /><br />
            <ol style={{ marginLeft: "18px" }} >
              <li>Hyper Menu: At the top, you'll find a detailed menu with multiple tabs like Moods, Genres, Use Cases and Occasions. Clicking on these will take you to curated selections tailored to your needs.</li>
              <li>Curated Tracks and Packs: When you scroll down further on the Home Page, and you will come across specially curated collections of tracks and packs, designed to suit different themes and occasions.</li>
            </ol>
          </>
        )
      },
      {
        text: "What is the difference between a track and a pack?",
        answer: (
          <>
            A track is a single music entity that can be either a vocal song or an instrumental background music piece.<br/>
            On the other hand, a pack is a specially curated collection of multiple tracks grouped around a specific theme, making it perfect for occasion-based or thematic content creation.
          </>
        )
      },
      {
        text: "Who should buy a track vs. who should buy a pack?",
        answer: (
          <>
            For a single song or instrumental, you can license tracks individually. But if you’re creating <b>30+ content pieces a year</b>, a pack is the better choice—it offers a curated selection tailored to your themes and a better overall deal.
          </>
        )
      },
      {
        text: "What do I do if I cannot find the track that I am looking for?",
        answer: (
          <>
            Simply click on the 'Support' Tab on the header, click on "Request a song" and the respective form with the details of the song you are looking to license. Once submitted, our team will reach out to you within 2-3 business days.
          </>
        )
      },
      {
        text: "What if I want to use the song for a use case other than short form content on social media?",
        answer: (
          <>
            On the track details page, simply click on the 'Request a Quote' option and fill out the respective form, and our team will reach out to you with pricing and details basis your specific requirement. This could take about 2-3 business days.
          </>
        )
      },
      {
        text: "What are the different ways in which I can search for a track?",
        answer: (
          <>
            The Hoopr Smash platform has been designed keeping in the mind the seamless discovery of relevant tracks for every brand's/ individual's requirements. Here are a few ways that you can discover tracks easily from the header menu.<br/><br/>

            <u>SmashHits</u> <br/>Explore trending tracks, independent hits, popular Bollywood music, and songs making waves on social media. You can also discover music curated for upcoming events and occasions.<br/><br/>

            <u>Categories</u> <br/>Browse tracks based on mood, genre, or specific use cases to find the perfect match for your content.<br/><br/>

            <u>Occasions</u> <br/>Dive deeper into event-specific music tailored for particular months or seasonal celebrations.<br/><br/>

            <u>License</u> <br/>Discover curated tracks and packs of songs that group relevant tracks thematically, for easier access and at great deals. You can also explore Playlists to find relevant tracks.<br/><br/>

            <u>Featured banners on the home page</u> <br/>Browse featured tracks and collections directly from the homepage. These include top-performing songs and those frequently licensed by creators.<br/><br/>

            Other search options include, using the search bar and the track filters to arrive at a narrowed down selection of music suitable for your requirements. Each option is designed to make your search seamless and efficient, ensuring you find exactly what you need for your project.
          </>
        )
      },
      {
        text: "How do I search for tracks within my budget?",
        answer: (
          <>
            You can search for tracks within your budget by clicking on <b>Sort and Filter</b> on the left on the <b>Tracks</b> page and <b>Packs</b> page. This feature allows you to apply a price filter, helping you find tracks that fit your budget.
          </>
        )
      },
      {
        text: "Where can I find popular Bollywood music?",
        answer: (
          <>
            You can find popular Bollywood tracks under SmashHits in the header menu. Additionally, keep an eye on the Homepage and featured banners, where independent artist tracks are often highlighted among the top-performing and most licensed music selections.
          </>
        )
      },
      {
        text: "How do I search for tracks that belong to a certain language?",
        answer: (
          <>
            You can search for tracks that belong to a certain language by clicking on <b>Sort and Filter</b> on the left on the <b>Tracks</b> page and <b>Packs</b> page.. This feature allows you to apply a price filter, helping you find tracks that fit your budget.
          </>
        )
      },
      {
        text: "How do I search for tracks for a specific occasion?",
        answer: (
          <>
            To find tracks for a specific occasion, simply head to <b>Occasions</b> in the website header. Here, you can browse music curated for various occasions like weddings, festivals, parties, and more, ensuring you find the perfect track for your content's theme. Alternatively you can also access tracks relevant to popular occasions using the <b>Smash Hits</b> tab on the website header.
          </>
        )
      },
      {
        text: "How do I search for tracks of a specific mood?",
        answer: (
          <>
            Hover over <b>Categories</b> on the website header, and you will find 20+ <b>Moods</b> arranged in alphabetical order. You can browse to find the right track that suits your content requirement.
          </>
        )
      },
      {
        text: "How do I search for tracks of a specific genre?",
        answer: (
          <>
            Hover over <b>Categories</b> on the website header, and you will find 20+ <b>Genres</b> arranged in alphabetical order. You can browse to find the right track that suits your content requirement.
          </>
        )
      },
      {
        text: "How do I search for tracks of a specific use case?",
        answer: (
          <>
            Hover over <b>Categories</b> on the website header, and you will find 30+ <b>Use Cases</b> arranged in alphabetical order. You can browse to find the right track that suits your content requirement.
          </>
        )
      },
      {
        text: "Where can I find music by independent artists?",
        answer: (
          <>
            You can find music by independent artists under <b>SmashHits</b> in the header menu, which features trending tracks, independent hits, and fresh releases from up-and-coming artists.<br/>
            Additionally, keep an eye on the Homepage/ featured Banners, where independent artist tracks are often highlighted among the top-performing and most licensed music selections.
          </>
        )
      },
      {
        text: "Where can I find music that is trending?",
        answer: (
          <>
            You can find the latest trending music in the SmashHits section, where we feature the hottest tracks that are currently making waves. Stay updated with the most popular tunes and elevate your content with the latest hits!
          </>
        )
      },
      {
        text: "Where can I find newly/ recently added music?",
        answer: (
          <>
            You can find newly or recently added music in the <b>SmashHits</b> Tab, which showcases tracks that are New on Smash, along with trending and popular releases.<br/>
            Additionally, the home page banners often highlight fresh additions, making it easy for you to discover the latest tracks.
          </>
        )
      },
    ],
  },
  {
    name: "About Music Licensing",
    questions: [
      {
        text: "Why do I need a music license?",
        answer: (
          <>
            A music license protects you from copyright infringement issues and ensures that you’re legally authorised to use the music in your content. Using unlicensed music can lead to takedown notices, legal fees, or other penalties. Licensing music legally guarantees that you will not face any rude surprises down the line.
          </>
        )
      },
      {
        text: "Why do licenses matter?",
        answer: (
          <>
            Licensing compensates artists for their work. For brands and businesses, it helps protect brand reputation and avoids legal issues by ensuring proper rights usage.
          </>
        )
      },
      {
        text: "What kind of licenses or clearances does Hoopr Smash provide?",
        answer: (
          <>
            Hoopr Smash offers music licensing for Bollywood, regional, and trending tracks, primarily for short-form video content on platforms like Instagram Reels, Facebook Reels, and YouTube Shorts. Licenses can be purchased by individual creators, brands, or agencies on behalf of brands. If you need a license for other types of content, simply go to the <b>'Request a Quote'</b> section under any track, share your details, and our team will provide a custom quote.
          </>
        )
      },
      {
        text: "Is there a limit on licensing songs/music on Hoopr Smash?",
        answer: (
          <>
           No, there is no limit to the number of song licenses that one can purchase at a time. However, usage is subject to the number of usages mentioned for the respective track and the terms of the accompanying licensing agreement. Please read the terms of use carefully for each track before making a purchase.

          </>
        )
      },
      {
        text: "Will I receive legal documentation as proof of licensing a song?",
        answer: (
          <>
            Yes, a licensing agreement detailing the terms of usage will be provided for each and every track that you license through the platform. Use cases may vary from track to track and we urge you to read all terms of use carefully before making a purchase.
          </>
        )
      },
      {
        text: "Are licenses valid worldwide?",
        answer: (
          <>
            Our standard licenses are valid only for use in India. Please contact us to discuss custom options for clearance in other regions or worldwide.
          </>
        )
      },
      {
        text: "Do I need a license to use music on a personal website?",
        answer: (
          <>
            Yes, a license is required to use music on your personal website.
          </>
        )
      },
      {
        text: "Will I receive copyright claims or strikes on YouTube for licensed music?",
        answer: (
          <>
            Once you license, download a track and use it in a video asset, you are required to mandatorily come back to the Hoopr Smash platform and share the published link for verification and clearance.
            <br/><br/>
            Follow these steps: Visit the <b>'My Account'</b> &gt;&gt; <b>'My Licenses'</b> &gt;&gt; <b>'+ Add Link'</b> under the specific track being added for clearance.
          </>
        )
      },
      {
        text: "How does Hoopr Smash handle licensing for songs that they do not own?",
        answer: (
          <>
            Hoopr Smash acts as the authorised licensing platform for labels and independent music owners. The platform has been legally authorised to license music on behalf of these listed entities.
          </>
        )
      },
      {
        text: "What should I do if I receive a copyright claim despite having licensed the music?",
        answer: (
          <>
            Contact us immediately with the content video URL. We’ll take care of any claim associated with your licensed track.
          </>
        )
      },
      {
        text: "What are the steps to license music on Hoopr Smash?",
        answer: (
          <>
            Select the music track(s) you’d like to license. <br/>
            Add your selection to the cart.<br/>
            Checkout and complete the payment process.
          </>
        )
      },
      {
        text: "Can I license a song that is currently not present on the platform?",
        answer: (
          <>
            Currently, we have music from select labels and independent music owners. However, we're always adding new music and artists to the platform. If you don’t find what you’re looking for, simply click on the 'Request a Song' tab on the Hoopr Smash home page, and our team will reach out with pricing and other details within 2-3 business days.
          </>
        )
      },
      {
        text: "Can we request a song from a specific label?",
        answer: (
          <>
            Yes, you can request a track from a specific label. Once shared, our team will reach out to discuss pricing options or suggest alternatives incase the song is not available for licensing.
          </>
        )
      },
      {
        text: "What should I do if I receive a copyright claim despite having licensed the music?",
        answer: (
          <>
            Ensure you have added link to video in My Licenses. If you had missed it and have added it now, write to us and we'll have the claim cleared.<br/>
            In case you had already done this, and still received a claim, do not refute it or raise a dispute. Write to us and we will check and clear it from our end.<br/><br/>

            Claim clearance is subject to the license terms provided to you during your purchase.<br/>
            You can write to us at <a
              href="mailto:claims.hooprsmash@gsharp.media"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >claims.hooprsmash@gsharp.media</a>

          </>
        )
      },
    ],
  },
  {
    name: "Music Licence and Copyright Clearances",
    questions: [
      {
        text: "Is there a limit on licensing songs/music on Hoopr Smash?",
        answer: (
          <>
            There’s no limit to how many song licenses you can buy at once. However, usage depends on the credits included and the licensing terms. Be sure to check the terms for each track before purchasing!
          </>
        )
      },
      {
        text: "Can I edit the music to fit my project under the same license?",
        answer: (
          <>
            No, editing the music violates the terms of the agreement between the label/artist, Hoopr Smash, and the licensee. Editing is currently not permitted. You are free to cut and use relevant segments of the track (as downloaded in its original & listed form) as per your use case.
          </>
        )
      },
      {
        text: "Can I use the music in my Instagram reel and Youtube Shorts?",
        answer: (
          <>
            Yes, you may. However, to know the detailed dos and don'ts of individual track usage, refer to the usage guidelines mentioned against each track.
          </>
        )
      },
      {
        text: "Can I transfer my license to someone else?",
        answer: (
          <>
           When you purchase a track for commercial use, you’re buying a <b>license</b> that outlines its permitted uses. Each track comes with a licensing agreement, so be sure to read it before purchasing. Some tracks allow single or multiple uses, but all uses must be verified by sharing usage links on the Hoopr Smash platform. If you buy multiple tracks, you can use them for different branded activities, which may involve license transfers. However, a single license can only be used for <b>one brand or individual</b> at a time. For example, if a track allows <b>three uses</b>, all must be for the same brand or individual.
          </>
        )
      },
      {
        text: "How many songs can I license in a year?",
        answer: (
          <>
            You can license as many songs as you want in a year — it all depends on how many you choose to purchase!<br />
            With Hoopr Smash, there’s no cap on the number of tracks you can license, giving you the flexibility to access the music you need, whenever you need it.
          </>
        )
      },
    ],
  },
  {
    name: "Cost and Payments",
    questions: [
      {
        text: "How does the cost of licensed music compare to unlicensed music?",
        answer: (
          <>
            Using licensed music may seem like an investment, but it ultimately saves you money by avoiding legal fees and takedown risks. With Hoopr Smash, you get high-quality tracks without the hidden costs of copyright issues.
          </>
        )
      },
      {
        text: "Are licensing costs different for various media types (e.g., TV, online, radio)?",
        answer: (
          <>
            Yes, costs vary based on the media type, campaign duration, territory of use and use case.
          </>
        )
      },
      {
        text: "Are there different rates for non-profit projects?",
        answer: (
          <>
            Yes, you can request a quote and include details about the non-profit project’s use case.
          </>
        )
      },
      {
        text: "Do I need to pay royalties if I’ve paid the licensing fee to Hoopr Smash?",
        answer: (
          <>
            No, you don’t have to pay additional royalties if you’ve licensed music from Hoopr Smash.
          </>
        )
      },
      {
        text: "What payment methods are accepted?",
        answer: (
          <>
            All payment methods such as Credit/ Debit Card, Net Banking and UPI are accepted. Cash payments are not accepted.
          </>
        )
      },
      {
        text: "What if my license is expired and I have unused credits? Will they be carried forward or will I get an extension?",
        answer: (
          <>
            Use your credits before your license expires—they won’t roll over or be extended. To get the most value, be sure to use them within the validity period!
          </>
        )
      },
      {
        text: "Are the prices of songs on Hoopr Smash inclusive of GST?",
        answer: (
          <>
            Yes, all the prices mentioned on the platform are inclusive of GST.
          </>
        )
      },
    ],
  },
  {
    name: "Catalogue and Availability",
    questions: [
      {
        text: "How often do you add new songs to your catalogue?",
        answer: (
          <>
            Our catalog updates in real-time with fresh music and new artists joining regularly. Find the latest additions under <b>'New on Smash'</b> in the <b>'Smash Hits'</b> section—just head to the top menu on the Home Page!
          </>
        )
      },
      {
        text: "What is the average approval wait time after requesting a song/ quote for a custom requirement?",
        answer: (
          <>
            The average wait time is 2-3 business days, but this may vary depending on the time of the year. We’ll keep you updated on the process. There may also be delays during festive or holiday periods as labels/ artists maybe unavailable to respond promptly in this period.
          </>
        )
      },
      {
        text: "Can individual creators license Bollywood music on Hoopr Smash?",
        answer: (
          <>
            Yes, individual creators can license Bollywood music or any other trending tracks on the platform.
          </>
        )
      },
      {
        text: "Will there be a subscription for business users on Hoopr Smash?",
        answer: (
          <>
            No, we don’t charge a subscription fee for streaming and discovery on Hoopr Smash.
          </>
        )
      },
    ],
  },
  {
    name: "Terms and Conditions",
    questions: [
      {
        text: "How long is my license valid once I license music from Hoopr Smash?",
        answer: (
          <>
            A standard song license is vald for 12 months from date of purchase. However, for custom requests, where the duration of use is longer, a custom quote and licensing agreement will be provided.
          </>
        )
      },
      {
        text: "Can I view my past licenses?",
        answer: (
          <>
            Yes, all licensing agreements will be provided to you for record-keeping. You can access individual licensing agreements under <b>My Account</b> &gt;&gt; <b>My Purchases</b>.
          </>
        )
      },
      {
        text: "Can I cancel or modify a licensing request?",
        answer: (
          <>
            You can cancel or modify your order anytime before payment and license issuance. Once the license is issued, changes or cancellations are no longer permitted.
          </>
        )
      },
      {
        text: "Can I get a refund if I didn’t use the licensed music?",
        answer: (
          <>
            No, refunds are not possible once a license is purchased. Please ensure your usage within the licensed duration.
          </>
        )
      },
      {
        text: "Will I get a reminder before my licensing period ends?",
        answer: (
          <>
            Yes, we will notify you before the licensing period ends. You can also visit <b>My Account</b> &gt;&gt; <b>My Purchases</b> to access this information.<br/>
            The start and end dates of your license validity will be mentioned next to each track individually.
          </>
        )
      },
    ],
  },
];

const FAQPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const toggleQuestion = (sectionIndex, questionIndex) => {
    setExpandedQuestion(
      expandedQuestion === `${sectionIndex}-${questionIndex}`
        ? null
        : `${sectionIndex}-${questionIndex}`
    );
  };

  return (
    <div className="outer-full-faq">
      <div className="innerfaq">
        <h2 className="faq-heading">FAQs</h2>
        <div className="faq-container">
          {faqData.map((section, sectionIndex) => (
            <div key={sectionIndex} className="faq-section">
              <div
                className={`faq-section-header ${
                  expandedSection === sectionIndex ? "active" : ""
                }`}
                onClick={() => toggleSection(sectionIndex)}
                style={{ cursor: "pointer" }}
              >
                <span>{section.name}</span>
                {expandedSection === sectionIndex ? (
                  <IoIosArrowUp size={24} className="icon" />
                ) : (
                  <IoIosArrowDown size={24} className="icon" />
                )}
              </div>

              {expandedSection === sectionIndex && (
                <div className="faq-questions">
                  {section.questions.map((question, questionIndex) => (
                    <div key={questionIndex} className="faq-question">
                      <div
                        className={`faq-question-header ${
                          expandedQuestion ===
                          `${sectionIndex}-${questionIndex}`
                            ? "active-question"
                            : ""
                        }`}
                        onClick={() =>
                          toggleQuestion(sectionIndex, questionIndex)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <span>{question.text}</span>
                        {expandedQuestion ===
                        `${sectionIndex}-${questionIndex}` ? (
                          <FaMinus className="icon-plus" size={18} />
                        ) : (
                          <FaPlus className="icon-plus" size={18} />
                        )}
                      </div>

                      {expandedQuestion ===
                        `${sectionIndex}-${questionIndex}` && (
                        <div className="faq-answer">{question.answer}</div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;

//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// sample faq format in html version for bold,italic,href,ol,ul

// const faqData = [
//   {
//     name: "Catalogue",
//     questions: [
//       {
//         text: "What is Hoopr Smash?",
//         answer: (
//           <>
//             Hoopr Smash is our <b>premium music licensing service</b> that provides high-quality, licensed music for businesses, brands, and creators. With Hoopr Smash, you can access <i>exclusive and trending tracks</i> for your projects, ensuring you have the legal rights to use the music worry-free.
//           </>
//         ),
//       },
//       {
//         text: "Why do I need a music license?",
//         answer: (
//           <>
//             A music license protects you from copyright infringement issues and ensures you’re <b>legally authorised</b> to use the music in your content. Using unlicensed music can lead to <a href="https://example.com/takedown-notices" target="_blank" rel="noopener noreferrer">takedown notices</a>, legal fees, or other penalties.
//           </>
//         ),
//       },
//       {
//         text: "What are the steps to license music on Hoopr Smash?",
//         answer: (
//           <ol>
//             <li>Select the music track(s) you’d like to license.</li>
//             <li>Add your selection of music track(s) to the cart.</li>
//             <li>Checkout and complete the payment process.</li>
//           </ol>
//         ),
//       },
//       {
//         text: "What are the benefits of licensing music with Hoopr Smash?",
//         answer: (
//           <ul>
//             <li><b>Legal Protection:</b> You avoid potential copyright claims and legal issues.</li>
//             <li><b>Simplified Process:</b> Hoopr Smash manages all the licensing paperwork, making it easy for you.</li>
//             <li><b>Cost Savings:</b> Licensing music saves you up to 10x in potential legal costs compared to using unlicensed music.</li>
//             <li><b>Quality Tracks:</b> Access a curated selection of professionally produced music that enhances your content.</li>
//           </ul>
//         ),
//       },
//     ],
//   },
// ];
