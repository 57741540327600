/////// ***************************** commented code for pack tracks **************************************//////////
import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/MyAccountCss/Purchases.module.css";
import MiniTrackCard from "../../components/CardsComponent/TrackCard/MiniTrackCard";
// import { TbFileInvoice } from "react-icons/tb";
import { LuDownload } from "react-icons/lu";
import { IoMdAdd } from "react-icons/io";
import { FiCheck, FiUpload } from "react-icons/fi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
// import randomDefaultImage from "../../utils/RandomDefaultImage";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
// import { getPack } from "../../service/PackService";
import { getUserPurchases, userDownload } from "../../service/UserService";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import getDirectLink from "../../utils/AudioUrlFormated";
import { ToastContext } from "../../utils/ToastContext";
import { addLicenseLink } from "../../service/TransactionService";
import config from "../../utils/config";
import useRestoreCart from "../../utils/RestoreCartTemp"; // temp access for manage the direct buy now function
import { TbLoader } from "react-icons/tb";
// import { track } from "mixpanel-browser";

const Purchases = () => {
  // const [selectedTab, setSelectedTab] = useState("All"); // state to manage the pill/button selected
  const [selectedTab, setSelectedTab] = useState("Tracks"); // state to manage the pill/button selected
  const [creditLimit, setCreditLimit] = useState(3); // Track the credit limit
  const [purchaseData, setPurchaseData] = useState([]); // State to store data from localStorage
  const [expandedCardIndex, setExpandedCardIndex] = useState(null); // Tracks which card is expanded
  const [cardInputs, setCardInputs] = useState({}); // Track inputs for each card
  const [viewPackTracks, setViewPackTracks] = useState(false); // to display all the track in the packs
  const [packTrack, setPackTracks] = useState([]); // to store the pack tracks
  const [packName, setPackName] = useState(""); // to store the pack name for which all packs tracks are being displayed
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [savedStates, setSavedStates] = useState({}); // state to manage to display the success icon adding the links for track
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [pendingLicenseLink, setPendingLicenseLink] = useState(null); // Stores cardIndex & inputIndex
  const [downloadingTrackId, setDownloadingTrackId] = useState(null);
  const [addTrackLicense, setAddTrackLicense] = useState(null); // state to manage the select track data for adding licence link
  const [tempTrackInputs, setTempTrackInputs] = useState({}); // Temporary variable for track inputs

  // ***************************************** temp access for manage the direct buy now function
  const restoreCart = useRestoreCart(); // Hook to dispatch actions //temp access for manage the direct buy now function

  useEffect(() => {
    restoreCart(); // Call the function
  }, []); // Runs only once when the component mounts
  // ***************************************** temp access for manage the direct buy now function

  // fetching the user purchase data
  useEffect(() => {
    const fetchPurchaseData = async () => {
      const fetchdata = await getUserPurchases();
      // console.log(fetchdata, "data");

      const transactions = fetchdata?.data?.transactions || [];

      const transformedData = transactions.flatMap((transaction) =>
        transaction.transactionInfo.map((info) => {
          const existingLinks = info.SKU.itemType === "T" ? info.license : []; // Extract existing license links
          const maxUsageLimit = info.SKU.maxUsage * info.qty; // Dynamically set credit limit

          // console.log(info);
          return {
            cardType: info.SKU.itemType === "T" ? "track" : "pack",
            preText: info.name ? `${info.name}` : "",
            secText: "",
            code:
              info.SKU.itemType === "T" ? info.trackCode : info.playlistCode,
            sp: info.SKU.sellingPrice || "",
            cp: info.SKU.costPrice || "",
            cardImg: "",
            trackJson: info.waveformLink,
            trackUrl:
              info.mp3Link ||
              info.waveformLink ||
              getDirectLink(info.sourceLink),
            skuID: info.SKU.id || "",
            quantity: info.qty,
            orderId: transaction.order_id,
            totalAmount: transaction.totalAmount, // Include totalAmount
            licenseLinks: existingLinks, // Only store licenses for tracks
            purchaseDate: info.dateOfPurchase,
            expiryDate: info.useBefore,
            creditLimit: maxUsageLimit, // Assign the dynamic credit limit
            packTrack: info.SKU.itemType === "T" ? [] : info.tracks,
            markerType: info.SKU.itemType === "T" ? "" : info.markerType,
          };
        })
      );
      // console.log(transformedData, "transform data");

      setPurchaseData(transformedData);
      // console.log(fetchdata);

      // Initialize state for credit limits per track
      const creditLimitsMap = transformedData.reduce((acc, item, index) => {
        acc[index] = item.creditLimit;
        return acc;
      }, {});

      // console.log(creditLimitsMap, "102");

      setCreditLimit(creditLimitsMap); // Store credit limits for each card separately

      // Initialize cardInputs with licenses for tracks only
      const initializedInputs = {};
      transformedData.forEach((item) => {
        if (item.cardType === "track") {
          const filledInputs = item.licenseLinks.map((link) => ({
            value: link.url,
            isValid: true,
            disabled: true,
          }));
          initializedInputs[item.code] = [...filledInputs];
        }
      });

      setCardInputs(initializedInputs);
    };
    fetchPurchaseData();
  }, []);

  // function to handle tab swtich
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setAddTrackLicense(null);
    setPendingLicenseLink(null);
  };

  // Toggle the expanded state of the clicked card
  const toggleCardExpansion = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Handle adding a new link input field for a specific card
  const handleAddLink = (cardIndex) => {
    setCardInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      const currentInputs = updatedInputs[cardIndex] || [];

      // Get the specific credit limit for this card
      const maxLimit = creditLimit[cardIndex] || 3; // Default to 3 if not found

      if (currentInputs.length >= maxLimit) {
        showToast("Add Link", "No more credits left!", "error");
        return prevInputs; // Return previous state to prevent breaking state updates
      }
      // Add a new empty input field
      updatedInputs[cardIndex] = [
        ...currentInputs,
        { value: "", isValid: false, disabled: false },
      ];

      return updatedInputs;
    });
  };

  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([A-Za-z0-9_-]+)/;
  // const instagramRegex =
  //   /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:[A-Za-z0-9_.]+\/)?reel\/([A-Za-z0-9_-]+)/;
  const instagramRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com\/)(?:reels?\/|p\/|reel\/)([A-Za-z0-9_-]+)(?:\/)?(?:\?.*)?$/;
  const snapchatRegex =
    /^(?:https?:\/\/)?(?:www\.)?story\.snapchat\.com\/s\/([A-Za-z0-9_-]+)/;
  const facebookRegex =
    /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/reel\/([A-Za-z0-9_-]+)/;

  // Handle input change for a specific card
  const handleInputChange = (cardCode, inputIndex, value) => {
    setCardInputs((prevInputs) => {
      const updatedInputs = { ...prevInputs };
      const currentInputs = updatedInputs[cardCode] || [];
      currentInputs[inputIndex] = {
        value, // Store the input value
        isValid:
          youtubeRegex.test(value) ||
          instagramRegex.test(value) ||
          snapchatRegex.test(value) ||
          facebookRegex.test(value), // Validate the URL
      };
      updatedInputs[cardCode] = currentInputs;
      return updatedInputs;
    });
  };

  // fucntion to download the track
  const handleTrackDownload = async (item) => {
    // console.log(item, "downlaod info");

    setDownloadingTrackId(item.code); // Set loading state for the track

    try {
      // Create payload
      const payload = {
        trackCode: item.code,
        packCode: item.playlistCode,
        skuID: item.skuID,
      };

      // Call userDownload API
      const download = await userDownload(payload);
      // console.log(download, "download resp");

      // Check if download is successful and contains required data
      if (download?.data?.licenseLink && download?.data?.filename) {
        const { licenseLink, filename } = download.data;

        // Create an invisible link element
        const link = document.createElement("a");
        link.href = licenseLink;
        link.setAttribute("download", filename); // Suggested filename
        document.body.appendChild(link);

        // Trigger download
        link.click();

        // Clean up
        document.body.removeChild(link);
        setDownloadingTrackId(null);
      } else {
        console.error("Download failed or missing license link.");
        setDownloadingTrackId(null);
      }
    } catch (error) {
      console.error("Error in downloading track:", error);
      setDownloadingTrackId(null);
    }
  };

  // track card ui
  const renderTrackCard = (item) => {
    // console.log(item, "item track");

    return (
      <div
        key={item.code}
        className={`${styles.cardContainer} ${
          expandedCardIndex === item.code ? styles.expanded : ""
        }`}
      >
        <div className={styles.cardHeader}>{item.preText}</div>
        <div className={styles.cardDetails}>
          {/* passing the track data for global player */}
          <div style={{ width: 126 }}>
            <MiniTrackCard trackInfo={item} />
          </div>
          {item.purchaseDate && (
            <div className={styles.cardSubDetails}>
              <p className={styles.csdHeader}>Date of purchase</p>
              <span className={styles.csdSubHeader}>
                {" "}
                {new Date(item.purchaseDate)
                  .toISOString()
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("-")}
              </span>
            </div>
          )}
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Use Before</p>
            <span className={styles.csdSubHeader}>
              {" "}
              {new Date(item.expiryDate)
                .toISOString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")}
            </span>
          </div>
          {item.totalAmount && (
            <div className={styles.cardSubDetails}>
              <p className={styles.csdHeader}>Total Cost:</p>
              <span className={styles.csdSubHeader}>
                {/* {item.sp.toLocaleString("en-IN")} */}
                {item.totalAmount.toLocaleString("en-IN")}
              </span>
            </div>
          )}
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>License Type:</p>
            <span className={styles.csdSubHeader}>Short Form Videos</span>
          </div>
          <div className={styles.purchaseDetails}>
            {/* <div className={styles.iconContainer}>
              <TbFileInvoice color="#696969" size={20} />
            </div> */}
            <div
              onClick={() => handleTrackDownload(item)}
              className={styles.iconContainerDownload}
            >
              {downloadingTrackId === item.code ? (
                <>
                  Download <TbLoader className={styles.loaderIcon} size={20} />
                </>
              ) : (
                <>
                  Download <LuDownload color="#696969" size={20} />
                </>
              )}
              {/* Download <LuDownload color="#696969" size={20} /> */}
            </div>
          </div>
        </div>
        <div
          onClick={() => toggleCardExpansion(item.code)}
          className={styles.creditLinks}
        >
          <div className={styles.creditLimit}>
            <div className={styles.limitNumber}>{item.creditLimit}</div>
            <p className={styles.limitText}>CREDITS</p>
          </div>
          <div className={styles.linkText}>
            <p>
              {expandedCardIndex === item.code
                ? "Click to Collapse"
                : "Click to Expand"}
            </p>
            {expandedCardIndex === item.code ? (
              <IoIosArrowUp size={20} />
            ) : (
              <IoIosArrowDown size={20} />
            )}
          </div>
          <div className={styles.linkTextAddBtn}>
            <p> Add Usage Link</p>
            <IoMdAdd />
          </div>
        </div>
        {/* New content revealed when the card is expanded */}
        {expandedCardIndex === item.code && (
          <div className={styles.expandedContent}>
            <div className={styles.useageLinkContainer}>
              {/* <p className={styles.useageHeader}>Add Usage Links</p> */}
              <div
                onClick={() => handleAddLink(item.code)}
                className={styles.addlinkText}
              >
                <p> Add Usage Link</p>
                <IoMdAdd />
              </div>
              <div className={styles.creditLimit}>
                <p className={styles.limitTextExpand}>CREDITS LEFT:</p>
                <div className={styles.limitNumber}>
                  {item.creditLimit - (cardInputs[item.code]?.length || 0)}
                </div>
              </div>
            </div>
            {/* Dynamic Input Fields */}
            {(cardInputs[item.code] || []).map((input, inputIndex) => (
              <>
                <div className={styles.inputContainer} key={inputIndex}>
                  <div className={styles.inputSection}>
                    <p className={styles.inputLabel}>{`Link ${
                      inputIndex + 1
                    }`}</p>
                    <input
                      type="text"
                      placeholder={`Add Link ${inputIndex + 1}`}
                      className={styles.inputBox}
                      value={input.value || ""}
                      onChange={(e) =>
                        handleInputChange(item.code, inputIndex, e.target.value)
                      }
                      disabled={input.disabled} // Disable if pre-filled
                    />
                  </div>
                  <div>
                    {!savedStates[item.code]?.[inputIndex] &&
                    input.value &&
                    !input.disabled ? (
                      <p
                        onClick={() =>
                          handleAddLicenseLink(item.code, inputIndex, item)
                        }
                        className={styles.savetext}
                      >
                        Save
                      </p>
                    ) : (
                      <div
                        className={styles.iconButton}
                        // style={{
                        //   backgroundColor: savedStates[index]?.[inputIndex]
                        //     ? "#40FC00"
                        //     : "#7ECDFF",
                        // }}
                        style={{
                          backgroundColor:
                            savedStates[item.code]?.[inputIndex] ||
                            input.disabled // Pre-filled links should show
                              ? "#40FC00"
                              : "#7ECDFF",
                        }}
                      >
                        {savedStates[item.code]?.[inputIndex] ||
                        input.disabled ? (
                          <FiCheck color="black" size={15} />
                        ) : (
                          <FiUpload color="black" size={15} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!input.isValid && input.value && (
                  <p className={styles.errorMessage}>Incorrect URL</p>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  // pack card ui
  const renderPackCard = (item, index) => {
    // console.log(item, "pack item");

    return (
      <div
        key={index}
        className={`${styles.cardContainer} ${
          expandedCardIndex === index ? styles.expanded : ""
        }`}
      >
        <div className={styles.cardHeader}>{item.preText}</div>
        <div className={styles.cardDetails}>
          <div
            className={styles.packCard}
            style={{
              backgroundImage: `url(${config.s3imgUrl}web/playlists/${item.code}.webp)`,
            }}
          ></div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Date of purchase</p>
            <span className={styles.csdSubHeader}>
              {" "}
              {new Date(item.purchaseDate)
                .toISOString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")}
            </span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Use Before</p>
            <span className={styles.csdSubHeader}>
              {" "}
              {new Date(item.expiryDate)
                .toISOString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")}
            </span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>Total Cost:</p>
            <span className={styles.csdSubHeader}>
              {/* {Number(item.sp).toLocaleString("en-IN")} */}
              {item.totalAmount.toLocaleString("en-IN")}
            </span>
          </div>
          <div className={styles.cardSubDetails}>
            <p className={styles.csdHeader}>License Type:</p>
            <span className={styles.csdSubHeader}>Short Form Videos</span>
          </div>
          {/* <div className={styles.purchaseDetails}>
            <div className={styles.iconContainer}>
              <TbFileInvoice color="#696969" size={20} />
            </div>
            <div
              onClick={() => handleTrackDownload(item)}
              className={styles.iconContainerDownload}
            >
              {downloadingTrackId === item.code ? (
                <>
                  Download <TbLoader className={styles.loaderIcon} size={20} />
                </>
              ) : (
                <>
                  Download <LuDownload color="#696969" size={20} />
                </>
              )}
            </div>
          </div> */}
        </div>
        <div
          onClick={() => handleViewAllTracks(item)}
          className={styles.viewPackTracks}
        >
          View Tracks <FaChevronRight size={16} />
        </div>
      </div>
    );
  };

  // fucntion to display the track in packs
  const handleViewAllTracks = async (packItem) => {
    if (!packItem || !packItem.packTrack) return;

    // Save current track inputs to temp variable
    setTempTrackInputs(cardInputs);

    const orderId = packItem.orderId;
    const expiryDate = packItem.expiryDate;
    const playlistCode = packItem.code;
    const packSKU = packItem.skuID;
    const markerType = packItem.markerType;

    // Format packTrack data into the same structure as track
    const formattedTracks = packItem.packTrack.map((track) => {
      // console.log(track);

      // console.log(track.license);

      return {
        cardType: "track",
        preText: track.name ? `${track.name}` : "",
        secText: "",
        code: track.SKU.itemType === "T" ? track.trackCode : track.playlistCode,
        sp: track.SKU.sellingPrice || "",
        cp: track.SKU.costPrice || "",
        cardImg: "",
        trackJson: track.waveformLink,
        trackUrl:
          track.mp3Link ||
          track.waveformLink ||
          getDirectLink(track.sourceLink),
        skuID: packSKU,
        quantity: track.qty,
        orderId: orderId,
        totalAmount: "", // Include totalAmount
        licenseLinks: track.license || [], // Store existing links
        purchaseDate: "",
        expiryDate: expiryDate,
        creditLimit: track.SKU.maxUsage * track.qty, // Assign the dynamic credit limit
        playlistCode: playlistCode,
        markerType: markerType,
      };
    });

    // console.log(formattedTracks, "pack tracks formated");

    const packInputs = {};
    formattedTracks.forEach((track) => {
      const filledInputs = track.licenseLinks.map((link) => ({
        value: link.url,
        isValid: true,
        disabled: true,
      }));
      packInputs[track.code] = [...filledInputs];
    });
    setCardInputs(packInputs);

    setPackTracks(formattedTracks);
    setPackName(packItem.preText);
    setViewPackTracks(true);
    // console.log("Pack code:", code);
  };

  // console.log(cardInputs, "inputs check");

  // function to handle the back button when track are visible from the packs.
  const handleBack = () => {
    setViewPackTracks(false);
    setPackTracks([]);
    setCardInputs(tempTrackInputs); // Restore track inputs from temp variable
  };

  // function to handle to add the link against each trak
  const handleAddLicenseLink = async (cardIndex, inputIndex, item) => {
    //Access the selected card data
    const selectedTrack = item;
    setAddTrackLicense(selectedTrack);
    // console.log(
    //   cardIndex,
    //   inputIndex,
    //   item,
    //   "selected track for adding licence link"
    // );

    // Access the selected input for this card and link
    const selectedInput = cardInputs?.[cardIndex]?.[inputIndex];

    // If selectedInput is undefined, return with an error
    if (!selectedInput) {
      showToast("Add Link", "Link not found. Please try again.", "error");
      return;
    }

    // Check if the selected input is valid
    if (!selectedInput.isValid) {
      showToast(
        "Add Link",
        "Invalid URL! Please enter a correct link.",
        "error"
      );
      return;
    }

    // Store reference to the pending link and open modal
    setPendingLicenseLink({ cardIndex, inputIndex });
    setIsModalOpen(true);
  };

  // Function to submit the link when user confirms in the modal
  const handleSubmitLicenseLink = async () => {
    // console.log(
    //   addTrackLicense,
    //   "track data for the license links to be added"
    // );

    if (!pendingLicenseLink && addTrackLicense) return;

    const { cardIndex, inputIndex } = pendingLicenseLink;
    const selectedInput = cardInputs?.[cardIndex]?.[inputIndex];

    const payload = {
      order_id: addTrackLicense.orderId,
      sku_id: addTrackLicense.skuID,
      url: selectedInput.value,
      trackCode:
        addTrackLicense.markerType === "pack" ? addTrackLicense.code : "",
    };

    // console.log(payload);

    try {
      const addlink = await addLicenseLink(payload);
      if (addlink?.error?.code === 0) {
        showToast("Add Link", "Link added successfully.", "success");
        // Disable the input field after success
        setCardInputs((prevInputs) => {
          const updatedInputs = { ...prevInputs };
          if (updatedInputs[cardIndex]) {
            updatedInputs[cardIndex][inputIndex] = {
              ...updatedInputs[cardIndex][inputIndex],
              disabled: true, // Disable input
            };
          }
          return updatedInputs;
        });
        setSavedStates((prevState) => ({
          ...prevState,
          [cardIndex]: {
            ...prevState[cardIndex],
            [inputIndex]: true,
          },
        }));
        setIsModalOpen(false); // Close confirmation modal
        setPendingLicenseLink(null);

        setIsThankYouModalOpen(true); // Show Thank You modal

        // Hide Thank You modal after 3 seconds
        setTimeout(() => {
          setIsThankYouModalOpen(false);
        }, 3000);
      } else {
        showToast(
          "Add Link",
          "Failed to save link. Please try again.",
          "error"
        );
      }
    } catch (error) {
      // console.error("Error saving link:", error);
      showToast("Add Link", "Failed to save link. Please try again.", "error");
    }

    setIsModalOpen(false); // Close the modal after submission
    setPendingLicenseLink(null); // Clear pending link data
    setAddTrackLicense(null); // clear the selected track licence data
  };

  // fucntion to render the confirmation link modal pop up
  const renderConfirmationModal = () => {
    if (!isModalOpen || !pendingLicenseLink) return null;

    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContainer}>
          <img
            className={styles.confirmIcon}
            src={`${config.s3imgUrl}icons/confirmation.png `}
          />
          <h2 className={styles.mcHeading}>Confirm Your Link!</h2>
          <p className={styles.mcSubHeading}>
            Once submitted, you cannot undo/make changes to the link.
          </p>
          <div className={styles.modalButtons}>
            <CustomButton
              text="Back"
              backgroundColor="#fff"
              borderRadius="50px"
              borderColor="1px solid #0D0D0D"
              width="213px"
              height="45px"
              textColor="#000"
              fontSize="18px"
              fontFamily="Raleway"
              fontWeight="550"
              onClick={() => setIsModalOpen(false)}
            />
            <CustomButton
              text="Submit"
              backgroundColor="#000"
              borderRadius="50px"
              width="213px"
              height="45px"
              textColor="#fff"
              fontSize="18px"
              fontFamily="Raleway"
              fontWeight="550"
              onClick={handleSubmitLicenseLink}
            />
          </div>
        </div>
      </div>
    );
  };

  // function to render the thank you modal
  const renderThankYouModal = () => {
    if (!isThankYouModalOpen) return null;

    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContainer}>
          <img
            className={styles.thankyougif}
            src={`${config.s3imgUrl}icons/thankyou.gif`}
          />
          <h2 className={styles.mcHeading}>Thank You!</h2>
          <p className={styles.mcSubHeading}>Your link has been submitted!</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.purchaseContainer}>
      {/* confirmation popup modal */}
      {isModalOpen && renderConfirmationModal()}
      {/* thank you modal */}
      {isThankYouModalOpen && renderThankYouModal()}
      <div className={styles.purchaseHeaderSection}>
        <h1 className={styles.containerHeading}>My Purchases</h1>
        {purchaseData.length > 0 ? (
          <>
            <p className={styles.containerSubHeading}>
              Add your content links to complete verification and activate full
              licensing coverage. Accurate link submissions ensure a seamless
              claim-free experience.
            </p>
            {/* displaying tabs section */}
            {viewPackTracks === false && (
              <div className={styles.containerPills}>
                {/* {["All", "Tracks", "Packs"].map((tab) => ( */}
                {["Tracks", "Packs"].map((tab) => (
                  <div
                    key={tab}
                    className={`${styles.pills} ${
                      selectedTab === tab ? styles.selectedPill : ""
                    }`}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </div>
            )}
            {/* Content Section Based on Selected Tab */}
            {viewPackTracks === false && (
              <div className={styles.purchaseDataSection}>
                {purchaseData.filter((item) =>
                  selectedTab === "All"
                    ? true
                    : selectedTab === "Tracks"
                    ? item.cardType === "track"
                    : selectedTab === "Packs"
                    ? item.cardType === "pack"
                    : false
                ).length === 0 ? (
                  <p className={styles.noData}>No data found</p>
                ) : (
                  purchaseData
                    .filter((item) =>
                      selectedTab === "All"
                        ? true
                        : selectedTab === "Tracks"
                        ? item.cardType === "track"
                        : selectedTab === "Packs"
                        ? item.cardType === "pack"
                        : false
                    )
                    .map((item, index) =>
                      item.cardType === "track"
                        ? renderTrackCard(item, index)
                        : renderPackCard(item, index)
                    )
                )}
              </div>
            )}
            {/* selected pack track */}
            {viewPackTracks && (
              <div className={styles.purchaseDataSection}>
                <h1 onClick={handleBack} className={styles.packName}>
                  <FaChevronLeft size={18} />
                  {packName}{" "}
                </h1>
                {packTrack.map((item, index) => renderTrackCard(item, index))}
              </div>
            )}
          </>
        ) : (
          <>
            <img
              src="https://cdn.hooprsmash.com/emptystates/no-purchases.webp"
              className={styles.emptyImage}
            />
            <h3 className={styles.emptyh3}>No purchases found!</h3>
            <p className={styles.emptyHeading}>
              Start licensing trending songs and they will show up here
            </p>
            <span className={styles.browseBtn}>
              <Link to={"/"}>
                <CustomButton
                  text="License Songs"
                  backgroundColor="var(--button-primary)"
                  borderRadius="50px"
                  width="270px"
                  height="52px"
                  textColor="#fff"
                  fontSize="18px"
                  fontFamily="Raleway"
                  fontWeight="550"
                />
              </Link>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Purchases;
