import { useDispatch } from "react-redux";
import { putCart } from "../service/TransactionService";
import { addToCart } from "./redux/cartSlice";

const useRestoreCart = () => {
  const dispatch = useDispatch(); //  useDispatch is now inside a Hook!

  const restoreCart = async () => {
    const prevCartData = JSON.parse(localStorage.getItem("prevcart"));

    if (!prevCartData || prevCartData.length === 0) return;

    try {
      const results = await Promise.all(
        prevCartData.map(async (track) => {
          const payload = {
            items: [
              {
                skuId: track.skuID || "",
                qty: track.quantity || 1,
                cartType: "C",
              },
            ],
          };

          const addCart = await putCart(payload);

          if (addCart?.error?.code === 0) {
            dispatch(addToCart(track)); //  Now useDispatch is used correctly
            return true;
          } else {
            console.error("Failed to add track to cart:", track);
            return false;
          }
        })
      );

      if (results.every((success) => success)) {
        localStorage.removeItem("prevcart");
      }
    } catch (error) {
      console.error("Error restoring cart:", error);
    }
  };

  return restoreCart; // Return the function
};

export default useRestoreCart;
