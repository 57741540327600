// import axios from "axios";
// import config from "../utils/config";
import { getSearchResultFaceted } from "./SearchService";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import getDirectLink from "../utils/AudioUrlFormated";
import CleanSearchKey from "../utils/CleanSearchKey";

const similarTracks = async (
  objSearch,
  searchQuery = "",
  excludeTrackCode = null
) => {
  let searchData = await getSearchResultFaceted(searchQuery, objSearch, 1);

  // If excludeTrackCode is provided, filter out the matching track
  if (excludeTrackCode !== null) {
    searchData = searchData.filter(
      (track) => track.track_code !== excludeTrackCode
    );
  }

  // Now slice to get top 5 tracks after removing the excluded one
  searchData = searchData.slice(0, 5);

  const allTracks = searchData?.map((track) => {
    const trackSKu = track.sku[0];
    // console.log(trackSKu);
    const parsedSKU = JSON.parse(trackSKu);
    const SKU = [
      {
        costPrice: parsedSKU.cost_price,
        sellingPrice: parsedSKU.selling_price,
        gstPercent: parsedSKU.gst_percent,
        maxUsage: parsedSKU.max_usage,
        id: parsedSKU.id,
      },
    ];

    // console.log(SKU);

    // Extract costPrice and sellingPrice
    // const { costPrice, sellingPrice, id } = SKU[0];
    const { costPrice, sellingPrice } = SKU[0];

    // Calculate discount percentage
    const discountPercent = (
      ((costPrice - sellingPrice) / costPrice) *
      100
    ).toFixed(0);

    return {
      image: track.image_url || RandomDefaultImage("track"),
      title: track.name,
      description: JSON.parse(track.tracks_artist_role || "") // Ensure valid JSON
        // .map((role) => role.artist?.name || "")
        // .join(", ");
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", "),
      buttonText: "License",
      discountPercent: discountPercent || "",
      costPrice: costPrice.toLocaleString("en-IN") || "",
      sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
      // name: track.name_slug,
      name: CleanSearchKey(track.name),
      trackCode: track.track_code,
      trackUrl: track.mp3_link || getDirectLink(track.link),
      waveJson: track.waveform_link,
      vocal: track.vocal,
    };
  });

  return allTracks || [];
  // console.log(allTracks, "All Tracks");
};

export { similarTracks };
