import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styles from "../styles/PackPage.module.css";
import config from "../utils/config";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { FaPlay, FaPause } from "react-icons/fa";
import CustomButton from "../components/CustomButton/CustomButton";
import { BiAddToQueue } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { getPack } from "../service/PackService";
import setMetaTags from "../utils/SeoService";
import { FiArrowUpRight } from "react-icons/fi";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import CleanSearchKey from "../utils/CleanSearchKey";
import Carousel from "../components/Carousel/Carousel";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContext } from "../utils/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import getDirectLink from "../utils/AudioUrlFormated";
import { putCart } from "../service/TransactionService";
import { addToCart, clearCart } from "../utils/redux/cartSlice"; // temp access of clear cart for buy now function
import {
  createUserLike,
  getPlaylistLike,
  createCollection,
  trackCollection,
  getUserCollections,
} from "../service/UserService";
import { LuPlus } from "react-icons/lu";
import RequestLicense from "./RequestLicense";
import { HiMusicalNote } from "react-icons/hi2";
// import { PiVideo } from "react-icons/pi";
import { TbHandClick } from "react-icons/tb";
import { CgInfo } from "react-icons/cg";
import { AiOutlineStop } from "react-icons/ai";
import { useNavigate } from "react-router-dom"; // temp access for the buy now function
import restrictionUtils from "../utils/data/restrictions";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import TrendingCard from "../components/CardsComponent/TrackCard/TrendingCard";
import { useHandleShareClick } from "../utils/Share";
import useMixpanel from "../service/MixpanelService";
import Login from "./Login";

const PackPage = () => {
  const [packData, setPackData] = useState(null);
  const { packNameSlug, packCode } = useParams(); // getting the data from the routes
  const [active, setActive] = useState(0); // active by default for carousel
  const activeRef = useRef(0);
  const [ownerCode, setOwnerCode] = useState(null); // Default state is null using owner code for displaying restrictions data against the track
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const items = useSelector((state) => state.cart.items); // Redux selector
  const dispatch = useDispatch(); // Hook to dispatch actions
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const [likeStatus, setLikeStatus] = useState("N"); // state to manage the track liked status and display the icon accordingly
  const [createPlaylistPopup, setCreatePlaylistPopup] = useState(false); // state to display popup of create playlist
  const [createInputPlaylist, setCreateInputPlaylist] = useState(false); // state to manage to display the input box for create playlist
  const [playlists, setPlaylists] = useState([]); // State for managing the existing user playlist info
  const [inputPlaylistValue, setInputPlaylistValue] = useState(""); // state to manage the input value of the new playlist create value
  const [visibleCount, setVisibleCount] = useState(15); // state to manage to display only 5 track at a time in a packs
  const [trackData, setTrackData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle login modal

  const cartItems = useSelector((state) => state.cart.items); // Access cart items from Redux store temp for buy now function
  const navigate = useNavigate(); // Hook to navigate programmatically // temp access for the buy now function

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loadingCart, setLoadingCart] = useState(false); // Add loading state for cart
  const [loadingBuyNow, setLoadingBuyNow] = useState(false); // Add loading state for buy now

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  // Determine restriction keys based on `ownerCode`
  const { restrictions, ownerMap } = restrictionUtils;
  const restrictionKeys =
    ownerCode && ownerMap[ownerCode] ? ownerMap[ownerCode] : ownerMap.default;

  // calling api the function when the packcode changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPack(packCode);
        if (response.length === 0) {
          navigate("/404"); // Navigate to the 404 page if data is empty
          return;
        }
        const data = response?.data || [];
        // console.log(data, "data");
        setPackData(data);
        // setTrackData(data.track); // Set the track object in the state
        // console.log(data, "trackData");
        setOwnerCode(data.pack?.ownerCode ?? null); // Update ownerCode, fallback to `null`

        // console.log(data?.name_slug);
        // Ensure the correct slug is set in the URL
        if (data?.name_slug !== packNameSlug) {
          // console.log("inside if");

          if (data && data?.markerType === "pack") {
            navigate(
              `/playlists/pack-${CleanSearchKey(data?.name)}/${packCode}`,
              { replace: true }
            );
          } else {
            navigate(`/playlists/${CleanSearchKey(data?.name)}/${packCode}`, {
              replace: true,
            });
          }
        }

        // fetchLikeStatus();
        // Extract and transform track data
        if (data.tracks?.length > 0) {
          const formattedTracks = data.tracks.map((track) => {
            const { costPrice, sellingPrice } = track.SKU?.[0] || {}; // Get values or undefined

            const discountPercent =
              costPrice && sellingPrice
                ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}`
                : null; // Calculate discount or fallback to ""

            // console.log(costPrice, sellingPrice, discountPercent);

            return {
              buttonText: "License",
              image: track.imageUrl,
              title: track.name,
              description: track.primaryArtists
                .map((artist) => artist.artistName)
                .join(", "),
              trackCode: track.trackCode,
              name: track.name_slug,
              sellingPrice: sellingPrice
                ? sellingPrice.toLocaleString("en-IN")
                : "",
              costPrice: costPrice ? costPrice.toLocaleString("en-IN") : "",
              discountPercent,
              waveJson: track.waveformLink,
              trackUrl:
                track.mp3Link ||
                getDirectLink(track.link) ||
                track.link ||
                track.waveformLink,
            };
          });

          setTrackData(formattedTracks); // Store the transformed track data
        }
      } catch (error) {
        console.error("Error fetching track data:", error);
      }
    };
    fetchData();
  }, [packCode]);

  // function to set the seo data
  const seoData = {
    title: `${packData?.name} | Music License | Hoopr Smash`,
    description: `License ${packData?.name} songs on Hoopr Smash. Access royalty-free ${packData?.name} music perfect for enhancing your projects, with easy licensing for any creative need.`,
    ogImage: `${config.s3imgUrl}web/playlists/${packCode}.webp`,
    canonical: window.location.href,
    // lang: "en",
  };
  setMetaTags(seoData);

  // Add this useEffect to call fetchLikeStatus when packData is ready
  useEffect(() => {
    if (packData) {
      fetchLikeStatus();
    }
  }, [packData]);

  // calc the discount price and display in ui
  const { costPrice, sellingPrice } = packData?.SKU?.[0] || {};
  const discountPercent =
    costPrice && sellingPrice
      ? `${(((costPrice - sellingPrice) / costPrice) * 100).toFixed(0)}% OFF`
      : ""; // If either value is missing, return an empty string

  const allowed = {
    Y: [
      // "Organic Short-form Video License Use Includes",
      "Creation of 1 unique video asset ONLY",
      "Can be published up to 3 times",
      "Can be used by a single brand only",
    ],
    N: [
      "Use in long-form videos",
      "Use in promoted ads",
      "Use in influencer marketing activities",
    ],
  };

  // function to handle the request quote of the track
  const handleRequestQuote = () => {
    const licenseData = {
      title: packData?.name,
      subTitle: "",
      img: config.s3imgUrl + "web/playlists/" + packCode + ".webp",
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
    trackUserEvent("requestquote-initiated");
  };

  // fucntion to add the playlist/pack in the cart
  const handleCartClick = async () => {
    setLoadingCart(true); // Set loadingCart to true

    if (!isAuthenticated) {
      setIsModalOpen(true);
      setLoadingCart(false);
      return;
    }
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: packData.name, // playlist/pack name
      secText: "",
      code: packData.playlistCode, // playlist/pack code
      sp: packData.SKU?.[0]?.sellingPrice.toLocaleString(), // selling price
      cp: packData.SKU?.[0]?.costPrice.toLocaleString(), // cost price
      skuID: packData.SKU?.[0]?.id,
    };
    // console.log(cartInfo);

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(cartInfo.code);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: cartInfo.skuID || "", qty: updatedQuantity, cartType: "C" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      setLoadingCart(false);
      showToast("Added to cart", "Your perfect soundpack awaits", "success"); //display toast msg
      // Track the add to cart event
      trackUserEvent("addtocart");
    } else {
      setLoadingCart(false);
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  const handleBuyNow = async () => {
    setLoadingBuyNow(true); // Set loadingBuyNow to true

    if (!isAuthenticated) {
      setIsModalOpen(true);
      setLoadingBuyNow(false);
      return;
    }
    // console.log(cartItems, "existing cart info");
    // Store existing cart data in localStorage
    // localStorage.setItem("prevcart", JSON.stringify(cartItems));

    dispatch(clearCart()); // Dispatch clearCart

    // calling the cart api now
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: packData.name, // playlist/pack name
      secText: "",
      code: packData.playlistCode, // playlist/pack code
      sp: packData.SKU?.[0]?.sellingPrice.toLocaleString(), // selling price
      cp: packData.SKU?.[0]?.costPrice.toLocaleString(), // cost price
      skuID: packData.SKU?.[0]?.id,
    };
    // console.log(cartInfo);

    // Get the current quantity for the pack
    const currentQuantity = getItemQuantity(cartInfo.code);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: cartInfo.skuID || "", qty: updatedQuantity, cartType: "B" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundpack awaits", "success"); //display toast msg
      setLoadingBuyNow(false);
      // Track the buy now event
      trackUserEvent("buy-now");

      // Navigate to checkout on success
      navigate("/checkout", { state: { type: "B" } });
    } else {
      setLoadingBuyNow(false);
      // Retrieve previous cart data from localStorage and restore it to Redux
      const prevCartData = JSON.parse(localStorage.getItem("prevcart")) || [];
      prevCartData.forEach((item) => dispatch(addToCart(item)));
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // fucntion to add the track to the user my likes
  const handleLike = async () => {
    try {
      // console.log(trackData.trackCode, "trackData");
      const payload = {
        typeCode: packData.playlistCode,
        type: packData?.markerType === "pack" ? "P" : "L",
        like: likeStatus === "Y" ? "N" : "Y",
      };
      const like = payload.like === "Y" ? "Y" : "N";
      setLikeStatus(like);
      const likeResponse = await createUserLike(payload);
      // console.log(likeResponse, "likeResponse");
      if (likeResponse?.error?.code === 0) {
        showToast("Like Added", "Likes Updated", "success"); //display toast msg
        // track liked event
        trackUserEvent(
          packData?.markerType === "pack" ? "pack-liked" : "playlist-liked"
        );
      } else {
        const likef = payload.like === "Y" ? "N" : "Y";
        setLikeStatus(likef);
        showToast("Error", "Likes Updated Fail", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShare = () => {
    handleShareClick();
    // Track the share event
    trackUserEvent(
      packData?.markerType === "pack" ? "pack-shared" : "playlist-shared"
    );
  };

  const fetchLikeStatus = async () => {
    try {
      // preparing payload
      const type = packData?.markerType === "pack" ? "P" : "L";

      const likeStatus = await getPlaylistLike(packCode, type);
      //   console.log(likeStatus);
      if (likeStatus?.error?.code === 0) {
        const liked = likeStatus.data.liked;
        // console.log(liked);
        setLikeStatus(liked);
      } else {
        // console.log("else");
        setLikeStatus("N");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   function to get the collection list
  const getCollections = async () => {
    try {
      const userPlaylist = await getUserCollections();

      if (userPlaylist?.error?.code === 0) {
        // console.log(userPlaylist, "user playlist");
        // setPlaylists(userPlaylist.data.collections);
        setPlaylists(userPlaylist?.data?.collections || []);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching user playlist:", error);
    }
    setCreatePlaylistPopup(true);
  };

  //   fucntion to create the collection
  const createNewCollection = async () => {
    // preparing the payload
    const payload = {
      name: inputPlaylistValue,
    };

    const createResponse = await createCollection(payload);

    if (createResponse?.error?.code === 0) {
      const playlist = {
        id: createResponse?.data?.id,
      };
      onPlaylistClick(playlist);
      // once playlist is created then add the track into the playlist and close the modal then
    } else {
      showToast("Like Added", "Failed to create collection", "error"); //display toast msg
    }
  };

  //   fucntion to handle the track addition in existing playlist
  const onPlaylistClick = async (playlist) => {
    // console.log(playlist);
    // preparign the payload
    const payload = {
      id: playlist.id,
      type: packData?.markerType === "pack" ? "P" : "L",
      code: [packCode],
    };

    // for adding track to playlists
    const addtrackResponse = await trackCollection(payload);
    // console.log(addtrackResponse, "506");
    if (addtrackResponse?.error?.code === 0) {
      //   console.log(addtrackResponse.data.name);

      // close the playlist popup modal
      handleClosePlaylistPopup();
      showToast(
        "Pack Added",
        `${packData.name} add to ${addtrackResponse?.data[0]?.name}`,
        "success"
      ); //display toast msg

      // track collection added event
      trackUserEvent(
        packData?.markerType === "pack"
          ? "pack-addtocollection"
          : "playlist-addtocollection"
      );
    } else {
      showToast("Pack Added", `Failed to add`, "error"); //display toast msg
    }
  };

  //   fucntion to handle the playlist popup modal close
  const handleClosePlaylistPopup = () => {
    setCreateInputPlaylist(false);
    setPlaylists([]);
    setInputPlaylistValue("");
    setCreatePlaylistPopup(false);
  };

  // common function for track user event
  const trackUserEvent = (eventName) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    trackEvent(eventName, {
      url: window.location.href,
      packName: packData.name,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      type: packData?.markerType === "pack" ? "packd" : "playlistd",
      code: packCode,
    });
  };

  const handleViewMore = () => {
    setVisibleCount((prev) => prev + 15); // Show 5 more items each time
  };

  const handleViewLess = () => {
    setVisibleCount(15); // Reset to initial state (show only first 5)
  };

  // carouselBtn
  // const buttons = [
  //   {
  //     id: 0,
  //     icon: <HiMusicalNote />,
  //     label: packData?.markerType === "pack" ? "Packs" : "Playlist",
  //   },
  //   // { id: "video", icon: <PiVideo />, label: "Know More" },
  //   { id: 1, icon: <TbHandClick />, label: "Usage Info" },
  //   // { id: 2, icon: <CgInfo />, label: "Track Details" },
  //   { id: 2, icon: <AiOutlineStop />, label: "Restricted" },
  // ];

  const buttons =
    packData?.markerType === "pack"
      ? [
          {
            id: 0,
            icon: <HiMusicalNote />,
            label: "Packs",
          },
          { id: 1, icon: <TbHandClick />, label: "Usage Info" },
          { id: 2, icon: <AiOutlineStop />, label: "Restricted" },
        ]
      : [
          // {
          //   id: 0,
          //   icon: <HiMusicalNote />,
          //   label: "Playlist",
          // },
        ];

  useEffect(() => {
    activeRef.current = active; // Persist active value across re-renders
    // console.log("🔴 Parent activeSlide before sending to Carousel:", active);
  }, [active]);

  const handleSetActive = useCallback((id) => {
    setActive(id);
  }, []);

  return (
    <div className={`${styles.container} ${styles.outerFull}`}>
      {/* rendering the create playlist ui popup */}
      {createPlaylistPopup && (
        <div className={styles.collectionContainer}>
          <div className={styles.collectionContent}>
            <div className={styles.popupHeading}>
              <p className={styles.pHText}>Add to Collection</p>
              <IoIosClose
                onClick={handleClosePlaylistPopup}
                size={30}
                cursor="pointer"
              />
            </div>
            <div className={styles.existingpsection}>
              {playlists.map((playlist) => (
                <div
                  key={playlist.id}
                  className={styles.existingCollection}
                  onClick={() => onPlaylistClick(playlist)} // Pass the clicked playlist info
                >
                  <div
                    style={{
                      backgroundImage: `url(${randomDefaultImage("pack")})`,
                    }}
                    className={styles.pimagecard}
                  ></div>
                  <div className={styles.playlistHeading}>
                    <p>
                      {playlist.name}
                      {""}
                    </p>
                    {/* <p>Tracks</p> */}
                  </div>
                </div>
              ))}
            </div>
            {createInputPlaylist && (
              <>
                <div>
                  <input
                    placeholder="Collection Name"
                    className={styles.cpinput}
                    value={inputPlaylistValue}
                    onChange={(e) => setInputPlaylistValue(e.target.value)}
                  />
                </div>
                <div>
                  <CustomButton
                    text="Create Collection"
                    backgroundColor="var(--button-primary)"
                    textColor="#fff"
                    fontWeight="600"
                    width="337px"
                    height="39px"
                    borderRadius="50px"
                    fontSize="14px"
                    onClick={createNewCollection}
                  />
                </div>
              </>
            )}
            {createInputPlaylist === false && (
              <div>
                <CustomButton
                  text="New Collection"
                  backgroundColor="var(--button-primary)"
                  textColor="#fff"
                  fontWeight="600"
                  width="337px"
                  height="39px"
                  borderRadius="50px"
                  fontSize="14px"
                  iconColor="#fff"
                  icon={<LuPlus />}
                  onClick={() => setCreateInputPlaylist(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.inner}>
        <div className={`${styles.innerBreadcrumb}`}>
          <Link to={"/"}>Home</Link> /&nbsp;
          {packData?.markerType === "playlist" ? (
            <>
              <Link to={"/playlists"}>Playlists</Link> /&nbsp;
            </>
          ) : (
            <>
              <Link to={"/packs"}>Packs</Link> /&nbsp;
            </>
          )}
          <span>{packData?.name}</span>
        </div>
        {/* packs head details section */}
        <div className={styles.trackContainer}>
          <div className={styles.carouselContainer}>
            {/* details section */}
            {/* <div className={styles.cardContainer}> */}
            {packData?.markerType === "playlist" ? (
              <>
                <div
                  className={`${styles.cardContainer} ${
                    packData?.markerType === "playlist"
                      ? styles.cardContainerp
                      : ""
                  }`}
                >
                  {/* details section */}
                  <div
                    className={`${styles.cardDetails} ${styles.pContainerHeight}`}
                  >
                    <div className={styles.playlist}>
                      <div className={styles.pContent}>
                        <div
                          className={styles.imageT}
                          style={{
                            backgroundImage: `url(${
                              config.s3imgUrl +
                                "web/playlists/" +
                                packCode +
                                ".webp" || randomDefaultImage("pack")
                            })`,
                          }}
                        >
                          <div className={styles.numberContainer}>
                            <p className={styles.totalNumber}>
                              {packData?.trackCount}
                            </p>
                            <span className={styles.ncTxt}>Tracks</span>
                          </div>
                        </div>
                        {/* <p className={styles.nametext}>{packData?.name}</p>
                        <div className={styles.iconContainer}>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={
                                likeStatus === "Y" ? (
                                  <FaHeart style={{ color: "red" }} />
                                ) : (
                                  <FaRegHeart />
                                )
                              }
                              // icon={<FaRegHeart />}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                              onClick={handleLike}
                            />
                          </div>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={<FaLink />}
                              onClick={handleShare}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                            />
                          </div>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={<BiAddToQueue />}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                              onClick={getCollections}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className={styles.pAbout}>
                        <p className={styles.nametext}>{packData?.name}</p>

                        {/* <div className={styles.aboutHeader}>
                          About the playlist
                        </div> */}
                        <p className={styles.abouttext}>
                          {packData?.description
                            // .split(". ")
                            .match(/.*?[?!.]/g)
                            .slice(0, 2)
                            .join(". ") + "." || ""}
                        </p>
                        <div className={styles.iconContainer}>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={
                                likeStatus === "Y" ? (
                                  <FaHeart style={{ color: "red" }} />
                                ) : (
                                  <FaRegHeart />
                                )
                              }
                              // icon={<FaRegHeart />}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                              onClick={handleLike}
                            />
                          </div>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={<FaLink />}
                              onClick={handleShare}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                            />
                          </div>
                          <div>
                            <CustomButton
                              isIconOnly={true}
                              backgroundColor="#fff"
                              borderRadius="50px"
                              iconColor="#000"
                              icon={<BiAddToQueue />}
                              width="34px"
                              height="34px"
                              iconSize="16px"
                              onClick={getCollections}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.cardContainer} ${
                    packData?.markerType === "playlist"
                      ? styles.cardContainerp
                      : ""
                  }`}
                >
                  <Carousel
                    gap={10}
                    autoScroll={false}
                    scrollInterval={4000}
                    forceSingleItem={true}
                    activeSlide={active} // Pass active slide to Carousel
                    hideArrow={true}
                    // onSlideChange={(index) => {
                    //   // console.log("Active Slide Index:", index);
                    //   setActive(index); // Update active state when slide changes
                    //   // setActiveIndex(index); // Update active state when slide changes
                    // }}
                  >
                    {/* details section */}
                    <div
                      className={`${styles.cardDetails} ${styles.tContainerHeight}`}
                    >
                      <div
                        className={styles.imageT}
                        style={{
                          backgroundImage: `url(${
                            config.s3imgUrl +
                              "web/playlists/" +
                              packCode +
                              ".webp" || randomDefaultImage("pack")
                          })`,
                        }}
                      >
                        <div className={styles.numberContainer}>
                          <p className={styles.totalNumber}>
                            {packData?.trackCount}
                          </p>
                          <span className={styles.ncTxt}>Tracks</span>
                        </div>
                      </div>
                      <p className={styles.nametext}>{packData?.name}</p>
                      <div className={styles.iconContainer}>
                        <div>
                          <CustomButton
                            isIconOnly={true}
                            backgroundColor="#fff"
                            borderRadius="50px"
                            iconColor="#000"
                            icon={
                              likeStatus === "Y" ? (
                                <FaHeart style={{ color: "red" }} />
                              ) : (
                                <FaRegHeart />
                              )
                            }
                            // icon={<FaRegHeart />}
                            width="34px"
                            height="34px"
                            iconSize="16px"
                            onClick={handleLike}
                          />
                        </div>
                        <div>
                          <CustomButton
                            isIconOnly={true}
                            backgroundColor="#fff"
                            borderRadius="50px"
                            iconColor="#000"
                            icon={<FaLink />}
                            onClick={handleShare}
                            width="34px"
                            height="34px"
                            iconSize="16px"
                          />
                        </div>
                        <div>
                          <CustomButton
                            isIconOnly={true}
                            backgroundColor="#fff"
                            borderRadius="50px"
                            iconColor="#000"
                            icon={<BiAddToQueue />}
                            width="34px"
                            height="34px"
                            iconSize="16px"
                            onClick={getCollections}
                          />
                        </div>
                      </div>
                      <p className={styles.abouttext}>
                        {packData?.description}
                      </p>
                    </div>
                    {/* know more video section */}
                    {/* <div className={styles.cardDetails}>
                <video
                  poster={`${config.s3imgUrl}static/about/top-placeholder.webp`}
                  playsInline
                  disablePictureInPicture
                  disableRemotePlayback
                  controls
                  className={styles.knowMoreVideo}
                >
                  <source
                    src={`${config.s3imgUrl}static/about/top-about.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div> */}
                    {/* do's and don't section */}
                    <div
                      className={`${styles.cardDetails} ${styles.cardInfo} ${styles.overflow}`}
                    >
                      <div className={styles.query}>
                        <p className={styles.question}>
                          What is organic short-form video pricing?
                        </p>
                        <p className={styles.answer}>
                          An attractive pricing plan devised for new-age,
                          digital-forward brands who wish to leverage popular
                          Bollywood trending tracks, regional hits and
                          independent gems in day-to-day short-form videos.
                          Licensing popular and trending music increases brand
                          engagement and provides 100% copyright safety.
                        </p>
                      </div>
                      <div>
                        <Link to={"/terms"} className={styles.tcBtn}>
                          <p> View detailed Terms & Conditions</p>
                          <FiArrowUpRight />
                        </Link>
                      </div>
                      <div className={styles.access}>
                        <div className={styles.accessCard}>
                          <p className={styles.accessHTxt}>What's Allowed</p>
                          {allowed.Y.map((item, index) => (
                            <div key={index} className={styles.accessItem}>
                              <div className={styles.allowedIcon}>
                                <IoIosCheckmark />
                              </div>
                              <p className={styles.accesstxt}>{item}</p>
                            </div>
                          ))}
                        </div>
                        <div className={styles.accessCard}>
                          <p className={styles.accessHTxt}>What's Restricted</p>
                          {allowed.N.map((item, index) => (
                            <div key={index} className={styles.accessItem}>
                              <div className={styles.notallowedIcon}>
                                <IoIosClose />
                              </div>
                              <p className={styles.accesstxt}>{item}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className={`${styles.query} ${styles.queryLong}`}>
                  <p className={styles.question}>What is Long-form Video ? </p>
                  <p className={styles.answer}>
                    An attractive pricing plan devised for new-age,
                    digital-forward brands who wish to leverage popular
                    Bollywood trending tracks, regional hits and independent
                    gems in day-to-day short-form videos. Licensing popular and
                    trending music increases brand engagement and provides 100%
                    copyright safety.
                  </p>
                </div> */}
                    </div>
                    {/* restricted section */}
                    <div
                      className={`${styles.cardDetails} ${styles.cardInfo} ${styles.overflow}`}
                    >
                      <div className={styles.query}>
                        <p className={styles.question}>Restricted Categories</p>
                        <p className={styles.answer}>
                          The restricted categories mentioned against each track
                          indicate the domains in which the use of this track is
                          strictly prohibited by the original owner of this
                          music. Restricted categories may wary from track to
                          track and you are advised to read these carefully
                          before making a purchase. Ignoring or disregarding
                          these restrictions may lead to a subsequent rejection
                          of clearances by the original owner of this track.
                        </p>
                      </div>
                      <div className={styles.rcontainer}>
                        {restrictionKeys.map((key) => {
                          const restriction = restrictions[key];

                          return (
                            <div key={key} className={styles.rcard}>
                              <div className={styles.rHeader}>
                                <div className={styles.rIcon}>
                                  <img
                                    src={restriction.icon}
                                    alt={restriction.name}
                                    className={styles.rImg}
                                  />
                                </div>
                                <p className={styles.rHtxt}>
                                  {restriction.name}
                                </p>
                              </div>
                              <p className={styles.rSubTxt}>
                                {restriction.desc}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Carousel>
                </div>
              </>
            )}
            {/* carousel btn */}
            <div className={`${styles.carouselBtn}`}>
              {buttons.map((btn) => (
                <div
                  key={btn.id}
                  className={`${styles.caroCard} ${
                    active === btn.id ? styles.expanded : ""
                  }`}
                  // onClick={() => {
                  //   setActive(Number(btn.id));
                  // }}
                  onClick={() => handleSetActive(btn.id)}
                >
                  <span
                    className={`${styles.icon} ${
                      active === btn.id ? styles.expandedIcon : ""
                    }`}
                  >
                    {btn.icon}
                  </span>
                  {active === btn.id && (
                    <span className={`${styles.text} ${styles.boldText}`}>
                      {btn.label}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* pricing section  */}
          {packData?.markerType === "pack" && (
            <div className={styles.pricing}>
              {/* Organic short form card */}
              <div className={styles.pContainer}>
                <div className={styles.pheader}>
                  <p className={styles.pheadertxt}>
                    Pricing for organic short-form video content
                  </p>
                  {/* <CiCircleInfo className={styles.infoIcon} /> */}
                </div>
                <div className={styles.pCard}>
                  {/* card content section heading and price text */}
                  <div className={styles.cardContent}>
                    <div>
                      <p className={styles.cardHTxt}>Short Form Video Use</p>
                      <p className={styles.cardSubTxt}>
                        For use in organic videos such as Instagram Reels or YT
                        Shorts only
                      </p>
                    </div>
                    {sellingPrice && (
                      <div>
                        <p className={styles.pprice}>
                          <span className={styles.pcrupee}>₹</span>
                          {sellingPrice
                            ? sellingPrice.toLocaleString("en-IN")
                            : ""}
                        </p>
                        <p className={styles.pdiscount}>
                          {discountPercent ? discountPercent : ""}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* card btn section */}
                  {sellingPrice && costPrice ? (
                    <>
                      <div className={styles.cardBtn}>
                        <div>
                          <CustomButton
                            // text="Add to Cart"
                            text={loadingCart ? "Please Wait" : "Add to Cart"}
                            backgroundColor="#F5F5F5"
                            textColor="#1B1B1B"
                            fontWeight="550"
                            width={windowWidth <= 768 ? "160px" : "190px"}
                            height="39px"
                            borderRadius="50px"
                            fontSize="14px"
                            onClick={handleCartClick}
                            loader={loadingCart ? true : false}
                            iconColor="#000"
                          />
                        </div>
                        <div>
                          <CustomButton
                            // text="License Now"
                            text={loadingBuyNow ? "Please Wait" : "License Now"}
                            backgroundColor="var(--button-primary)"
                            textColor="#fff"
                            fontWeight="550"
                            width={windowWidth <= 768 ? "160px" : "190px"}
                            height="39px"
                            borderRadius="50px"
                            fontSize="14px"
                            onClick={handleBuyNow}
                            loader={loadingBuyNow ? true : false}
                            iconColor="#fff"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.cardBtn}>
                        <div>
                          <CustomButton
                            text="Request a Quote"
                            backgroundColor="#F5F5F5"
                            textColor="#1B1B1B"
                            fontWeight="550"
                            width={windowWidth <= 768 ? "160px" : "190px"}
                            height="39px"
                            borderRadius="50px"
                            fontSize="14px"
                            onClick={handleRequestQuote}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* ott broadcast card */}
              <div className={styles.pContainer}>
                <div className={styles.pheader}>
                  <p className={styles.pheadertxt}>Other formats </p>
                  {/* <CiCircleInfo className={styles.infoIcon} /> */}
                </div>
                <div className={styles.pCard}>
                  {/* card content section heading and price text */}
                  <div className={styles.cardContent}>
                    <div>
                      <p className={styles.cardHTxt}>
                        TV, OTT or Broadcast License:
                      </p>
                      <p className={styles.cardSubTxt}>
                        Get custom quote for use in long-form video content for
                        TV, OTT or Broadcast platforms
                      </p>
                    </div>
                  </div>
                  {/* card btn section */}
                  <div className={styles.cardBtn}>
                    <div>
                      <CustomButton
                        text="Request a Quote"
                        backgroundColor="#F5F5F5"
                        textColor="#1B1B1B"
                        fontWeight="550"
                        width="190px"
                        height="39px"
                        borderRadius="50px"
                        fontSize="14px"
                        onClick={handleRequestQuote}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.catsTracks}>
          <div className={styles.theading}>
            <h2 className={styles.theadingTxt}>
              Tracks included in {packData?.name}
            </h2>
          </div>
          <div className={styles.ppTrackCards}>
            {packCode == 105 ? (
              <TrendingCard
                // trackType="trending"
                showPrice={packData?.markerType === "playlist" ? false : true}
                // trackCard={formattedTracks}
                trackCard={trackData?.slice(0, visibleCount)} // Display only 'visibleCount' tracks
                trackType={"trendingP"}
                btnWidth={
                  isMobile
                    ? windowWidth <= 320
                      ? "80px"
                      : windowWidth <= 375
                      ? "110px"
                      : "110px"
                    : isTablet
                    ? windowWidth <= 430
                      ? "110px"
                      : "168px"
                    : "186px"
                }
                // btnWidth={packData?.markerType === "playlist" ? "175px" : ""}
              />
            ) : (
              <TrackCard
                // trackType="trending"
                showPrice={packData?.markerType === "playlist" ? false : true}
                // trackCard={formattedTracks}
                trackCard={trackData?.slice(0, visibleCount)} // Display only 'visibleCount' tracks
                trackType={
                  packData?.markerType === "playlist" ? "playlist" : "packs"
                }
                btnWidth={packData?.markerType === "playlist" ? "175px" : ""}
              />
            )}
            {trackData?.length > 15 && (
              <div className={styles.buttonContainer}>
                {visibleCount < trackData?.length ? (
                  <div>
                    <CustomButton
                      text="View More"
                      fontSize="14px"
                      backgroundColor="var(--button-primary)"
                      textColor="#ffffff"
                      fontWeight="550"
                      width="120px"
                      height="30px"
                      borderRadius="4px"
                      onClick={handleViewMore}
                    />
                  </div>
                ) : (
                  <div>
                    <CustomButton
                      text="View Less"
                      fontSize="14px"
                      backgroundColor="var(--button-primary)"
                      textColor="#ffffff"
                      fontWeight="550"
                      width="120px"
                      height="30px"
                      borderRadius="4px"
                      onClick={handleViewLess}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
      {/* login modal popup */}
      <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default PackPage;
