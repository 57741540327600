import React, { useEffect, useRef, useState } from "react";
import { IoIosPlay, IoIosPause } from "react-icons/io";
import { TbRewindForward10, TbRewindBackward10 } from "react-icons/tb";
import WaveSurfer from "wavesurfer.js";
import HoverPlugin from "wavesurfer.js/dist/plugins/hover.js";
import "../styles/Waveform.css";

const Waveform = ({
  trackUrl,
  play,
  onPlayPauseChange,
  showControls = true,
  height,
  marginLeft,
  waveJson,
}) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null); // Reference to the WaveSurfer instance
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state for waveform
  const prevTrackUrlRef = useRef(trackUrl); // This will store the previous value of trackUrl
  const [jsonData, setJsonData] = useState(null); // json data of the track
  const playTimeoutRef = useRef(null); // Reference for the 30-second timeout
  const [playbackStart, setPlaybackStart] = useState(null); // Start time of the 30-sec window
  const [playbackEnd, setPlaybackEnd] = useState(null); // End time of the 30-sec window
  const [markerPosition, setMarkerPosition] = useState({
    left: "0%",
    width: "0%",
  }); // Marker position and width
  const [volume, setVolume] = useState(1); // State for volume control (range from 0 to 1)

  useEffect(() => {
    // console.log("29");

    const fetchWaveJson = async () => {
      try {
        const response = await fetch(waveJson);
        const json = await response.json();
        // console.log("Fetched WaveJson:", json);
        setJsonData(json); // waveJson is managed via state
      } catch (error) {
        console.error("Error fetching waveform JSON:", error);
      }
    };

    fetchWaveJson();
  }, [waveJson]);

  useEffect(() => {
    if (trackUrl !== prevTrackUrlRef.current) {
      setIsLoading(true);
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy(); // Destroy the previous instance
        waveSurferRef.current = null; // Set it to null after destroying
      }
      prevTrackUrlRef.current = trackUrl; // Update the previous trackUrl reference
    }

    // Initialize WaveSurfer instance only once (if not already initialized)
    if (!waveSurferRef.current) {
      //   console.log("Initializing WaveSurfer instance");
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#656565",
        progressColor: "#656565",
        // cursorColor: "#656565",
        height: height || 50,
        responsive: true,
        barWidth: 1,
        cursorWidth: 0,
        backend: "MediaElement",
        normalize: true,
        barHeight: 10,
        barGap: 0.0001,
        barRadius: 10,
        plugins: [
          HoverPlugin.create({
            lineColor: "#000", // Color of the hover line
            labelBackground: "#fff", // Background color for the hover time label
            labelColor: "#000", // Font color for the hover time label
            fontSize: 12, // Font size for the hover time label
          }),
        ],
      });

      if (
        jsonData &&
        Array.isArray(jsonData.data) &&
        jsonData.data.length > 0
      ) {
        // console.log("Using JSON peak data");
        waveSurferRef.current.load(trackUrl, jsonData.data);
      } else {
        // console.log("Using URL without peak data");
        waveSurferRef.current.load(trackUrl);
      }
      // waveSurferRef.current.load(trackUrl);

      waveSurferRef.current.on("loading", (progress) => {
        setIsLoading(progress < 100);
        // if (progress < 100 && !isLoading) {
        //   setIsLoading(true); // Only set to true if it's not already
        // }
        // if (progress === 100 && isLoading) {
        //   setIsLoading(false); // Only set to false when fully loaded
        // }
      });

      waveSurferRef.current.on("ready", (progress) => {
        setIsLoading(false);
        if (play) startPlaybackWithTimeout();
        // waveSurferRef.current.play(); // Automatically start playback
        // setIsPlaying(true); // Update state to reflect playback
        // onPlayPauseChange(true); // Notify parent about the playback state
        // setIsLoading(false);
      });

      // Handle errors during loading
      waveSurferRef.current.on("error", (e) => {
        console.error("WaveSurfer Error:", e);
      });

      // Volume control when slider changes
      waveSurferRef.current.on("volume", (newVolume) => {
        setVolume(newVolume); // Sync volume state with WaveSurfer
      });

      // for 30 sec logic
      waveformRef.current.addEventListener("click", (e) => {
        const waveformRect = waveformRef.current.getBoundingClientRect();
        const clickPosition =
          (e.clientX - waveformRect.left) / waveformRect.width; // Calculate progress based on the click position

        // console.log("Waveform clicked, position:", clickPosition);
        // Now pass the calculated position to the handleSeek function
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(clickPosition); // Seek to the clicked position
          handleSeek(clickPosition); // Pass the clickPosition to handleSeek
        }
      });
    }
  }, [trackUrl, jsonData]); // This effect depends on trackUrl

  useEffect(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current.setVolume(volume); // Update volume when slider value changes
    }
  }, [volume]);

  useEffect(() => {
    if (waveSurferRef.current) {
      if (play && !isLoading) {
        if (!isPlaying) {
          // waveSurferRef.current.play(); // Resume or start the track
          // setIsPlaying(true);
          // onPlayPauseChange(true); // Notify parent
          startPlaybackWithTimeout();
        }
      } else if (!play && isPlaying) {
        // waveSurferRef.current.pause(); // Pause the track
        // setIsPlaying(false);
        // onPlayPauseChange(false); // Notify parent
        waveSurferRef.current.pause();
        clearTimeout(playTimeoutRef.current);
        setIsPlaying(false);
        onPlayPauseChange(false); // Notify parent component
      }
    }
  }, [play, isLoading]);

  const calculateMarkerPosition = () => {
    if (waveSurferRef.current) {
      const duration = waveSurferRef.current.getDuration();
      if (playbackStart !== null && playbackEnd !== null) {
        const left = (playbackStart / duration) * 100; // Start position as a percentage
        const width = ((playbackEnd - playbackStart) / duration) * 100; // Width as a percentage
        setMarkerPosition({ left: `${left}%`, width: `${width}%` });
      }
    }
  };

  useEffect(() => {
    calculateMarkerPosition(); // Recalculate marker position when playbackStart or playbackEnd changes
  }, [playbackStart, playbackEnd]);

  // play for 30 sec logic
  const startPlaybackWithTimeout = () => {
    if (waveSurferRef.current) {
      const duration = waveSurferRef.current.getDuration();
      const currentTime = waveSurferRef.current.getCurrentTime();

      setPlaybackStart(currentTime); // Set the start time of the 30-sec window
      setPlaybackEnd(Math.min(currentTime + 30, duration)); // Set the end time (max 30 seconds or track end)

      waveSurferRef.current.play();
      setIsPlaying(true);
      onPlayPauseChange(true);

      clearTimeout(playTimeoutRef.current);
      playTimeoutRef.current = setTimeout(() => {
        waveSurferRef.current.pause();
        setIsPlaying(false);
        onPlayPauseChange(false);
      }, 30000);
    }
  };

  const handleSeek = (clickPosition) => {
    if (waveSurferRef.current) {
      const currentDuration = waveSurferRef.current.getDuration(); // Get track duration
      const seekTime = currentDuration * clickPosition; // Calculate the time based on the click position

      waveSurferRef.current.seekTo(seekTime / currentDuration); // Normalize the time to [0, 1]
      waveSurferRef.current.play(); // Resume playback immediately
      setIsPlaying(true);
      onPlayPauseChange(true);

      // Update playbackStart and playbackEnd based on the clicked position
      setPlaybackStart(seekTime);
      setPlaybackEnd(Math.min(seekTime + 30, currentDuration)); // Ensure it doesn't exceed track duration

      // Recalculate marker position
      const left = clickPosition * 100; // Convert click position to percentage
      const width =
        ((Math.min(seekTime + 30, currentDuration) - seekTime) /
          currentDuration) *
        100; // Calculate width as percentage
      setMarkerPosition({ left: `${left}%`, width: `${width}%` });

      // Reset timeout for automatic pause
      clearTimeout(playTimeoutRef.current);
      playTimeoutRef.current = setTimeout(() => {
        waveSurferRef.current.pause();
        setIsPlaying(false);
        onPlayPauseChange(false);
      }, Math.min(30000, (currentDuration - seekTime) * 1000)); // Adjust timeout for remaining duration
    } else {
      console.error("WaveSurfer instance is not available");
    }
  };

  const togglePlayPause = () => {
    if (waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.pause();
        setIsPlaying(false);
        onPlayPauseChange(false); // Notify parent
      } else {
        // waveSurferRef.current.play();
        // setIsPlaying(true);
        // onPlayPauseChange(true); // Notify parent
        startPlaybackWithTimeout();
      }
    }
  };

  const skipForward = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.skip(10);
    }
  };

  const skipBackward = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.skip(-10);
    }
  };

  const handleVolumeChange = (e) => {
    setVolume(e.target.value); // Update volume based on slider value
  };

  useEffect(() => {
    if (waveSurferRef.current) {
      // Update the background fill in real-time during playback
      const updateBackgroundFill = () => {
        const currentTime = waveSurferRef.current.getCurrentTime();
        const duration = waveSurferRef.current.getDuration();

        if (playbackStart !== null && playbackEnd !== null) {
          const progress = Math.min(
            Math.max(
              (currentTime - playbackStart) / (playbackEnd - playbackStart),
              0
            ),
            1
          ); // Clamp progress between 0 and 1

          // Dynamically update the marker position
          const markerElement = waveformRef.current.querySelector(
            ".custom-marker-fill"
          );
          if (markerElement) {
            markerElement.style.transform = `scaleX(${progress})`;
          }
        }
      };

      // Attach the audioprocess event listener
      waveSurferRef.current.on("audioprocess", updateBackgroundFill);

      // Cleanup the event listener on unmount
      return () => {
        waveSurferRef.current.un("audioprocess", updateBackgroundFill);
      };
    }
  }, [playbackStart, playbackEnd]);

  return (
    <div className="waveform-container">
      {/* player controls */}
      {showControls && (
        <div className="player-controls">
          <div className="playPause">
            <TbRewindBackward10
              cursor={"pointer"}
              size={22}
              color="#fff"
              onClick={skipBackward}
            />
            {isPlaying ? (
              <IoIosPause
                cursor={"pointer"}
                size={40}
                color="#fff"
                onClick={() => {
                  togglePlayPause();
                  setIsPlaying(false); // Ensure state updates correctly
                }}
              />
            ) : (
              <IoIosPlay
                cursor={"pointer"}
                size={40}
                color="#fff"
                onClick={() => {
                  togglePlayPause();
                  setIsPlaying(true); // Ensure state updates correctly
                }}
              />
            )}{" "}
            <TbRewindForward10
              cursor={"pointer"}
              size={22}
              color="#fff"
              onClick={skipForward}
            />
          </div>
          {/* Volume control slider */}
          <div>
            <div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                class="volume-slider"
              />
              {/* <span>{Math.round(volume * 100)}%</span> */}
            </div>
          </div>
        </div>
      )}

      {/* Waveform container */}
      <div style={{ marginLeft: marginLeft || "30px" }} className="waveform">
        {/* WaveSurfer container */}
        <div
          ref={waveformRef}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {/* Custom marker for the 30-second window */}
          {playbackStart !== null && playbackEnd !== null && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: markerPosition.left,
                width: markerPosition.width,
                height: "70px", // Full height of the waveform
                zIndex: 5, // Ensure it appears above the waveform
                pointerEvents: "none", // Prevent interaction with the marker
                overflow: "hidden", // Ensure the background stays within bounds
                border: "3px solid var(--button-primary)", // Use solid color for the border
                // borderImageSource:
                //   "linear-gradient(to right, #7e55d6, #dc6692, #e1c092, #ff004c)", // Gradient border (commented out)
                // borderImageSlice: 1, // Ensure the gradient is applied correctly
                margin: "-10px 0px 0px",
                borderRadius: "5px",
              }}
             >
              {/* Background fill */}
              <div
                className="custom-marker-fill"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100%",
                  width: "100%",
                  // background: "rgb(253, 200, 216, 0.6)", // light background color
                  background: "#FFFFFF66", // light background color
                  transformOrigin: "left", // Anchor the animation to the left
                  transform: "scaleX(0)", // Start with no fill
                  transition: "transform 0.1s linear", // Smooth animation
                }}
              />
            </div>
          )}
        </div>

        {/* Loading indicator */}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "16px",
              color: "#fff",
            }}
          >
            Loading...
          </div>
        )}
      </div>
      <div className="w-duration">30</div>
    </div>
  );
};

export default Waveform;
