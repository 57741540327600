// CustomDropdown.js
import React from "react";
import Select from "react-select";
import "./CustomDropdown.css"; // Importing the custom styles

// Custom styles for react-select
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#000",
    backgroundColor: state.isSelected
      ? "#EDEDED"
      : state.isFocused
      ? "#f5c4d0"
      : "#fff",
    padding: 10,
    // height: 25,
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#000",
    border: "none",
    // borderBottom: '2px solid #DDD',
    boxShadow: "0px 0px 4px #DEDEDE",
    "&:hover": {
      borderColor: "#DDD",
    },
    borderRadius: "10px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "50px",
    marginTop: 0,
    zIndex: 9999, // Ensuring dropdown appears above other elements
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "200px", // Set a max height for the dropdown list
    overflowY: "auto", // Enable scrolling if the list is too long
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#DDD",
    fontSize: "14px",
  }),
  container: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#444", // Change the arrow color
    "&:hover": {
      color: "#000", // Change the color on hover
    },
  }),
  indicatorSeparator: () => ({
    display: "none", // Hides the separator line
  }),
  multiValue: (provided) => ({
    ...provided,
    // backgroundColor: '#828282', // Background color for the selected pill
    backgroundColor: "#E6E6E6", // Background color for the selected pill
    borderRadius: "15px", // Rounded corners for the pill
    padding: "2px 5px", // Add padding for better appearance
    margin: "2px", // Space between pills
    // color: "#FFF", //  TEXT COLOR
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#000", // Text color for the label in the pill
    // fontWeight: 'bold', // Make the label bold
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#000", // Color of the remove 'x' icon
    // display: 'none',
    "&:hover": {
      backgroundColor: "#ff6b6b", // Background color on hover
      color: "#fff", // Change text color on hover
      borderRadius: "50%", // Circle effect around the icon
    },
  }),
};

// Example component for different use cases
const CustomDropdown = ({
  options,
  isMulti = false,
  isAsync = false,
  isDisabled = false,
  loadOptions = () => Promise.resolve([]),
  placeholder = "Select...",
  onChange,
  value,
  formatOptionLabel,
  ...props
}) => {
  return (
    <Select
      styles={customStyles}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      formatOptionLabel={formatOptionLabel}
      loadOptions={isAsync ? loadOptions : undefined}
      {...props}
    />
  );
};

export default CustomDropdown;
