
const MktCards = (currIdx, perLine, cardLine, cardsBeforeBanner) => {
  const bannerLine = cardLine * cardsBeforeBanner + cardLine;
  const currentLine = parseInt(currIdx / perLine) + 1;

  //  IF THE CURRENT LINE REACHES BANNER LINE
  if (currentLine % bannerLine === 0) {
    return 'b';
  }

  //  IF THE CURRENT LINE MATCHES THE CARD LINE REQUIREMENTS
  if (currentLine % cardLine === 0) {
    //  FIND THE ALERNATIVE LINE STRATEGY
    if ((currentLine / cardLine) % 2 === 0) {
      if (currIdx % perLine === 0) {
        return 'c';
      }
      else {
        return 'e';
      }
    }
    else {
      if (currIdx % perLine === (perLine - 1)) {
        return 'c';
      }
      else {
        return 'e';
      }
    }
  }
  //  THIS IS KINDA THE DEFAULT RETURN CONDITION
  //  RETURN ELEMENT IF NOTHING ELSE MATCHES
  return 'e';
};

export { MktCards };