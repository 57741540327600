import React, { useState, useContext } from "react";
import CustomButton from "../components/CustomButton/CustomButton";
import CustomInput from "../components/CustomInput/CustomInput";
import styles from "../styles/ContactSales.module.css";
import config from "../utils/config";
import { contactsales } from "../service/SupportService";
import { ToastContext } from "../utils/ToastContext";
import useMixpanel from "../service/MixpanelService";
import { Link } from "react-router-dom";

const ContactSales = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
    phoneNumber: "",
  });

  const [emailError, setEmailError] = useState(""); // State to store email error message
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loading, setLoading] = useState(false); // Add loading state

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber" && !/^\d*$/.test(value)) {
      return; // Allow only digits for phoneNumber
    }

    if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
      if (!emailRegex.test(value) && value !== "") {
        setEmailError("Invalid email format"); // Set error message
      } else {
        setEmailError(""); // Clear error message
      }
    }

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    // Check if any form field is empty or contains only whitespace
    // const isEmpty = Object.values(formData).some(
    //   (value) => !value || value.toString().trim() === ""
    // );
    // Check if any required form field (excluding message) is empty
    const isEmpty = Object.entries(formData).some(
      ([key, value]) =>
        key !== "message" && (!value || value.toString().trim() === "")
    );

    if (isEmpty) {
      showToast("Contact Sales", "All fields are mandatory.", "error");
      setLoading(false);
      return;
    }

    const payload = {
      form_id: 2,
      email: formData.email.trim(),
      name: formData.name.trim(),
      message: formData.message.trim(),
      mobile: formData.phoneNumber.trim(),
      companyName: formData.companyName.trim(),
    };

    try {
      const contact = await contactsales(payload);
      // console.log(contact);
      if (contact?.error?.code === 0) {
        showToast("Contact Sales", "Request Submitted Successfully", "success");
        // Track the contact sales event
        trackUserEvent("contactsales", formData);
        // Reset form fields to default state
        setFormData({
          name: "",
          email: "",
          companyName: "",
          message: "",
          phoneNumber: "",
        });
      } else {
        showToast("Contact Sales", "Failed to submit request", "error");
      }
    } catch (error) {
      // console.log(error, "Error in Submitting query");
      showToast("Contact Sales", "Error in Submitting query", "error");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const trackUserEvent = (eventName, formData) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const eventData = {
      url: window.location.href,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      name: formData.name,
      companyName: formData.companyName,
      message: formData.message,
      phoneNumber: formData.phoneNumber,
    };

    trackEvent(eventName, eventData);
  };

  return (
    <div className={`${styles.parentContainer} ${styles.outer}`}>
      <div className={`${styles.inner} ${styles.contentSection}`}>
        <div
          className={styles.imageSection}
          style={{
            backgroundImage: `url(${config.s3imgUrl}static/contact-sales/sales-n.webp)`,
          }}
        >
          <p className={styles.isHeadingtext}>Request a Demo</p>
          <p className={styles.isSubHeadingText}>
            Seamless music licensing awaits!
            <br />
            Schedule a FREE product demo now to know more!
          </p>
        </div>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={`${styles.scheduleDiv}`}>
            <p className={`${styles.scheduleFill}`}>Book an appointment as per your convenience</p>
            <a className={`${styles.scheduleButton}`} href="https://calendar.app.google/gTNFux384JB9r3ny5" target="_blank">
              Schedule Demo
            </a>
            <p className={`${styles.scheduleOr}`}><hr/></p>
            <p className={`${styles.scheduleFill}`}>Get in touch with our team</p>
          </div>
          <CustomInput
            placeholder="Name"
            height="50px"
            borderRadius="4px"
            border="1px solid #bfbfbf"
            marginTop="5px"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />

          <div className={styles.phoneInput}>
            <select className={styles.countryCode}>
              <option value="+91">+91</option>
            </select>
            <input
              type="text"
              placeholder="Enter Phone Number"
              className={styles.phoneNumber}
              value={formData.phoneNumber}
              onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
              maxLength={10}
            />
          </div>

          <CustomInput
            placeholder="Email"
            height="50px"
            borderRadius="4px"
            border="1px solid #bfbfbf"
            marginTop="5px"
            // type="text"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          {emailError && <p className={styles.errorMsg}>{emailError}</p>}

          <CustomInput
            placeholder="Company Name"
            height="50px"
            borderRadius="4px"
            border="1px solid #bfbfbf"
            marginTop="5px"
            value={formData.companyName}
            onChange={(e) => handleInputChange("companyName", e.target.value)}
          />

          {/* <div className={styles.textareaContainer}>
            <textarea
              placeholder="Message"
              className={styles.textarea}
              value={formData.message}
              onChange={(e) => handleInputChange("message", e.target.value)}
            />
          </div> */}
          <CustomButton
            // text="Submit Request"
            text={loading ? "Please Wait" : "Submit Request"}
            // backgroundColor="var(--button-primary)"
            backgroundColor="transparent"
            borderColor={"1px solid var(--button-primary)"}
            borderRadius="50px"
            width="397px"
            height="50px"
            textColor="var(--button-primary)"
            fontSize="18px"
            fontFamily="Raleway"
            fontWeight="550"
            loader={loading ? true : false}
            iconColor="#fff"
          />
        </form>
      </div>
      <div className={`${styles.inner} ${styles.detailsSection}`}>
        <div className={styles.dsHeader}>
          <p className={styles.dsheadingText}>Visit Us</p>
          <p className={styles.dssubHeading}>
            Hoopr, Office 10, Vicino Mall, New Link Rd, Oshiwara, Jogeshwari
            West, Mumbai, Maharashtra 400102
          </p>
        </div>
        <div className={styles.dsHeader}>
          <p className={styles.dsheadingText}>Chat with Us</p>
          <p className={styles.dssubHeading}>Mon - Fri from 11am to 6pm</p>
          <div className={styles.iconcontainer}>
            <img
              className={styles.icon}
              src={`${config.s3imgUrl}icons/whatsapp.png`}
            />
            <p className={styles.dssubHeading}>+91-7400226274</p>
          </div>{" "}
        </div>
        <div className={styles.dsHeader}>
          <p className={styles.dsheadingText}>Call Us</p>
          <p className={styles.dssubHeading}>Mon - Fri from 11am to 6pm</p>
          <div className={styles.iconcontainer}>
            <img
              className={styles.icon}
              src={`${config.s3imgUrl}icons/headset.png`}
            />
            <p className={styles.dssubHeading}>+91-7400226274</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSales;
