import React, { useState } from "react";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import { FaPlay, FaPause } from "react-icons/fa";
import styles from "./MiniTrack.module.css";
import { useGlobalPlayer } from "../../../utils/GlobalPlayerContext";
// import { useDispatch } from "react-redux";
import config from "../../../utils/config";

const MiniTrackCard = ({ trackInfo }) => {
  // console.log(trackInfo, "mini track card");

  const [activeTrackCode, setActiveTrackCode] = useState(null); // Track currently playing
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();
  // const dispatch = useDispatch(); // Hook to dispatch actions

  // Function to handle play button click
  const handlePlayClick = () => {
    // console.log("click");

    // Log the clicked track info
    // console.log("Clicked Track Info:", trackInfo);

    // Use the trackInfo passed as a prop
    const trackData = {
      trackImg: trackInfo.cardImg,
      trackName: trackInfo.preText,
      trackArtistName: trackInfo.secText,
      trackDuration: "03:25",
      trackCode: trackInfo.code,
      trackUrl: trackInfo.trackUrl,
      trackJson: trackInfo.trackJson,
    };

    // // Check if track is playing and then update the player
    if (activeTrackCode === trackInfo.code) {
      // If same track is clicked, toggle play/pause
      // console.log(
      //   "Same track clicked. Current isPlaying:",
      //   isPlaying,
      //   "Toggling state."
      // );
      setIsPlaying((prev) => !prev); //Correctly toggles play/pause
      // Check if player data is null and show player if needed
      if (!isPlaying) {
        const trackData = {
          trackImg: trackInfo.cardImg,
          trackName: trackInfo.preText,
          trackArtistName: trackInfo.secText,
          trackDuration: "03:25",
          trackCode: trackInfo.code,
          trackUrl: trackInfo.trackUrl,
          trackJson: trackInfo.trackJson,
        };
        showPlayer(trackData);
      }

      return;
    }

    // If a new track is clicked, stop the previous track and start the new one
    // console.log("New track clicked. Playing new track:", track.trackCode);
    setActiveTrackCode(trackInfo.code);
    setIsPlaying(true); // Always play new track
    showPlayer(trackData);
  };

  return (
    <div
      className={styles.trackCard}
      style={{
        backgroundImage: `url(${
          config.s3imgUrl + "web/tracks/" + trackInfo.code + ".webp" ||
          randomDefaultImage("track")
        })`,
      }}
    >
      <div className={styles.trackPlayBtn} onClick={handlePlayClick}>
        {activeTrackCode === trackInfo.code && isPlaying ? (
          <FaPause color="#000" size={18} />
        ) : (
          <FaPlay color="#000" size={18} />
        )}
      </div>
    </div>
  );
};

export default MiniTrackCard;
