import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";
import { getAuthHeaders } from "../utils/headers";

export const getCart = async (type = "C") => {
  const url = `${config.baseUrl}${urls.cart}/get?cartType=${type}`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error getting cart:", error);
    return { data: [] };
  }
};

export const putCart = async (payload) => {
  const url = `${config.baseUrl}${urls.cart}/update`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const createOrder = async (payload) => {
  const url = `${config.baseUrl}${urls.createPruchaseOrder}/create`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const updateOrder = async (payload) => {
  const url = `${config.baseUrl}${urls.createPruchaseOrder}/update`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const startTransaction = async (payload) => {
  const url = `${config.baseUrl}${urls.transaction}/init`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const commitTransaction = async (payload) => {
  const url = `${config.baseUrl}${urls.transaction}/commit`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating cart:", error);
    return { data: [] };
  }
};

export const addLicenseLink = async (payload) => {
  const url = `${config.baseUrl}${urls.transaction}/license`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};
