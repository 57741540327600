import axios from "axios";
import { urls } from "../utils/AppManager";
import config from "../utils/config";

export const otpSend = async (payload) => {
  const url = `${config.baseUrl}${urls.sendOtp}`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const otpVerify = async (payload) => {
  const url = `${config.baseUrl}${urls.verifyOtp}`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const userLogin = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/login`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

export const emailOtp = async (payload) => {
  const url = `${config.baseUrl}${urls.emailOtp}/send-otp`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};

// export const emailResend = async (payload) => {
//   const url = `${config.baseUrl}${urls.emailOtp}/resend-otp`;

//   const res = await axios
//     .post(url, payload)
//     .then((response) => response.data)
//     .catch((error) => {
//       console.log(error, "error");
//     });
//   return res;
// };

export const emailVerify = async (payload) => {
  const url = `${config.baseUrl}${urls.emailOtp}/verify-otp`;

  const res = await axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error, "error");
    });
  return res;
};
