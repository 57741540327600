import config from "../utils/config";
//  FOR MIXPANEL
import mixpanel from "mixpanel-browser";

//  INITIALISE MIXPANEL
mixpanel.init(config.mixpanelKey, {
  debug: false, // true if need to check in console what data is being send
  track_pageview: true,
  persistence: "localStorage",
});

const useMixpanel = () => {
  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const trackingParams = {};

    // Define the specific parameters you want to extract
    const allowedParams = [
      "utm_source",
      "utm_campaign",
      "utm_medium",
      "hs_ref",
    ];

    // url to be formated in &utm_source={source}&utm_campaign={campaign}&utm_medium={medium}&hs_ref={ref}

    // Iterate through the allowed parameters and add them to the object if present
    allowedParams.forEach((param) => {
      if (params.has(param)) {
        trackingParams[param] = params.get(param); // Add the parameter and its value
      }
    });

    return trackingParams;
  };

  const trackEvent = (eventName, eventProperties) => {
    // Extract URL parameters and add them to event properties
    const urlParams = getUrlParams();
    const finalEventProperties = {
      ...eventProperties,
      ...urlParams,
      url: window.location.href,
    };

    mixpanel.track(eventName, finalEventProperties);
  };

  return { trackEvent };
};

export default useMixpanel;
