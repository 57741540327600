const extractColors = (imageSrc, type = 'default') => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable CORS

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const topLeftPixel = ctx.getImageData(10, 10, 1, 1).data;

      let bottomRightPixel = "#FFF";
      if (type === 'genre') {
        bottomRightPixel = ctx.getImageData(840, 360, 1, 1).data;
      }
      else {
        bottomRightPixel = ctx.getImageData(
          img.width - 40,
          img.height - 40,
          1,
          1
        ).data;
      }

      let topLeftHex = rgbToHex(
        topLeftPixel[0],
        topLeftPixel[1],
        topLeftPixel[2]
      );
      let bottomRightHex = rgbToHex(
        bottomRightPixel[0],
        bottomRightPixel[1],
        bottomRightPixel[2]
      );

      if (type === 'lower') {
        topLeftHex = bottomRightHex
        bottomRightHex = '#FFFFFF';
      }

      if (type === "lang") {
        resolve(
          `linear-gradient(to bottom right, ${bottomRightHex}, ${topLeftHex})`
        );
      }
      else if (type === "lower") {
        resolve(
          `linear-gradient(to bottom, ${topLeftHex} 0%, ${bottomRightHex} 15%)`
        );
      }
      else {
        resolve(
          `linear-gradient(to bottom right, ${topLeftHex}, ${bottomRightHex})`
        );
      }
    };

    img.onerror = reject;
    img.src = imageSrc;
  });
};

const rgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export default extractColors;
