import React, { useContext, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { BiSolidMessageSquareError } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ToastContext } from "../../utils/ToastContext";
import styles from "./ToastComponent.module.css"; // Import the CSS module

const ToastComponent = () => {
  const { heading, message, isVisible, closeToast, type } =
    useContext(ToastContext);
  const [animationClass, setAnimationClass] = useState("");

  // console.log(heading, message, "13");

  useEffect(() => {
    if (isVisible) {
      setAnimationClass(styles.slideIn);
    } else {
      setAnimationClass(styles.slideOut);
    }
  }, [isVisible]);

  if (!isVisible && animationClass === styles.slideOut) return null;

  // Add a condition to check if heading, message, and type are not empty
  if (!heading || !message || !type) return null;

  const getIcon = () => {
    return type === "success" ? (
      <IoMdCheckmarkCircleOutline size={24} />
    ) : (
      <BiSolidMessageSquareError size={24} />
    );
  };

  const getBackgroundColor = () => {
    return type === "success" ? "#28a745" : "red";
  };

  return (
    <div className={`${styles.toast} ${animationClass}`}>
      <div
        className={styles.icon}
        style={{ backgroundColor: getBackgroundColor() }}
      >
        {getIcon()}
      </div>
      <div className={styles.messageContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontWeight: 550,
          }}
        >
          {type === "success" ? (
            <p className={styles.messageHeader} style={{ color: "#28a745" }}>
              {heading}
            </p>
          ) : (
            <p className={styles.messageHeader} style={{ color: "red" }}>
              Error
            </p>
          )}
          <IoClose
            size={24}
            className={styles.closeButton}
            onClick={closeToast}
          />
        </div>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default ToastComponent;
