import { configureStore } from "@reduxjs/toolkit";
import trackFiltersReducer from "./trackFiltersSlice";
import cartReducer from "./cartSlice";
import uiReducer from "./uiSlice";
import authReducer from "./authSlice";
import modalReducer from "./modalSlice";

const store = configureStore({
  reducer: {
    trackFilters: trackFiltersReducer,
    cart: cartReducer,
    ui: uiReducer, // for global player visible state
    auth: authReducer,
    modal: modalReducer, // for managing the modal which are being opened on right
  },
});

export default store;
