import config from "../config";

const testimonials = {
  basePath: `${config.s3imgUrl}static/about/testimonials`,
  list: [
    "mohit-ltn.webp",
    "shreya-riyers.webp",
    "rahul-html.webp",
    "srushti-newj.webp",
    "akhilesh-b24.webp",
    "paula-bhadipa.webp",
    "satish-riyers.webp",
    "aditya-brevistay.webp"
  ],
};

export default testimonials;
