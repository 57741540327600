import React, { useState, useEffect, useRef } from "react";
import styles from "./AllPlaylistCard.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import PlaylistCard from "./PlaylistCard";
import TrackFilter from "../TrackCard/TrackFilter";
import { getSearchResultFaceted } from "../../../service/SearchService";
import { getAllPlaylist } from "../../../service/PlaylistService";
import { FaFilter } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useSelector } from "react-redux";
import CleanSearchKey from "../../../utils/CleanSearchKey";
import { TbFilterCheck } from "react-icons/tb";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../../utils/config";
import priceFilter from "../../../utils/FilterBase64";

const AllPlaylistCards = ({ viewall = true }) => {
  const [page, setPage] = useState(1); // Dynamic page number
  const [results, setResults] = useState([]); // Store search results
  const [allplaylist, setAllPlaylist] = useState([]); // store all track list result
  const [isFetching, setIsFetching] = useState(false); // Loading state
  const [showViewMore, setShowViewMore] = useState(false); // Control View More button visibility
  const [noDataFound, setNoDataFound] = useState(false); // State for "No data found"
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [filters, setFilters] = useState({}); // state to manage the filters values
  const [sortBy, setSortBy] = useState({ type: "relevance", order: "" });
  const [priceFilters, setPriceFilters] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false); // to show and hide the filter btn
  const sectionId = "allSongs"; // ID of the "All Tracks" section
  const footerId = "footer-section"; // Footer Section

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  useEffect(() => {
    const checkVisibility = () => {
      const section = document.getElementById(sectionId);
      const footer = document.getElementById(footerId);
      if (!section || !footer) return;

      // const rect = section.getBoundingClientRect();
      const sectionRect = section.getBoundingClientRect();
      const footerRect = footer.getBoundingClientRect();

      // Ensure the section is within viewport but NOT before it starts
      const isInViewport =
        sectionRect.top < window.innerHeight && sectionRect.bottom > 0;

      // Prevent showing button too early
      const isAboveFold = sectionRect.top >= 0; // Ensures button appears only after section is reached

      // Check if at least 20% of the footer is visible
      const footerVisible = footerRect.top < window.innerHeight * 0.8;

      setIsVisible(isInViewport && !isAboveFold && !footerVisible);
    };

    const handleScroll = () => {
      requestAnimationFrame(checkVisibility);
    };

    window.addEventListener("scroll", handleScroll);
    checkVisibility(); // Run initially

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  // load the filters if there on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedFilters = searchParams.get("filters");
    const encodedPriceFilters = searchParams.get("priceFilters");
    const encodedSortBy = searchParams.get("sortBy");

    if (encodedFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedFilters)); // Decode Base64
        if (decodedFilters) {
          setFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedPriceFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedPriceFilters)); // Decode Base64
        if (decodedFilters) {
          setPriceFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedSortBy) {
      try {
        const decodedFilters = JSON.parse(atob(encodedSortBy)); // Decode Base64
        if (decodedFilters) {
          setSortBy(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
  }, [location.search]);

  // state for preventing the multiple api calls
  const isFetchingRef = useRef(false);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // Initial API Call main use effect to call the api on changes
  useEffect(() => {
    if (filtersLoaded && !isFetchingRef.current) {
      isFetchingRef.current = true;
      setPage(1);
      setResults([]);
      setNoDataFound(false);
      setShowViewMore(false);
      autoFetch4Pages(1).then(() => {
        isFetchingRef.current = false;
      });
    }
  }, [priceFilters, filters, sortBy, filtersLoaded]); // This effect runs whenever the searchKeyword and filters changes

  // Fetch all tracks (Default API Call)
  const fetchAllPlaylist = async (pageNumber) => {
    setResults([]);
    try {
      setIsFetching(true);
      setNoDataFound(false); // Reset "No data found" state before fetching

      const response = await getAllPlaylist(pageNumber);
      const data = response?.data?.playlists || [];
      if (data.length > 0) {
        setAllPlaylist((prevPackList) => [...prevPackList, ...data]);
        return true; // Continue fetching
      } else {
        setNoDataFound(true); // Set when no data found
        setShowViewMore(false); // Hide view more button
        return false;
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setNoDataFound(true); // Set when no data found
      setShowViewMore(false); // Hide view more button
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  // calling search api
  const fetchResults = async (pageNumber) => {
    setAllPlaylist([]); //setting all tracks array empty preventing duplication of search and all tracks
    // console.log("call");
    // console.log(filters, "filter val");

    try {
      setIsFetching(true);
      setNoDataFound(false); // Reset "No data found" state before fetching
      // Construct the payload with dynamic filters
      const payload = {
        asset_type: "playlist",
        artists: [], // If no artists, use empty array
        // bpm: { min: 1, max: 300 },
        // price: { min: priceFilters?.min || "", max: priceFilters?.max || "" },
        genres:
          filters?.genres?.map((item) => CleanSearchKey(item.label)) || [],
        languages:
          filters?.languages?.map((item) => CleanSearchKey(item.label)) || [],
        moods: filters?.moods?.map((item) => CleanSearchKey(item.label)) || [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: filters?.subgenres || [],
        usecases:
          filters?.useCases?.map((item) => CleanSearchKey(item.label)) || [],
        instruments:
          filters?.instruments?.map((item) => CleanSearchKey(item.label)) || [],
      };

      // Check and update sortby value if type is "relevance"
      let sortby = sortBy.type || "";

      if (sortby === "relevance") {
        sortby = "";
      }

      const order = sortBy.order || "";
      const searchKeyword = "";
      const data = await getSearchResultFaceted(
        searchKeyword,
        payload,
        pageNumber,
        sortby,
        order
      );

      const packData = data.filter((item) => item.marker_type === "playlist");
      // console.log(packData, "packData");

      if (packData.length > 0) {
        setResults((prev) => [...prev, ...packData]);
        return true; //continue fetching
      } else {
        setNoDataFound(true); // Set when no data found
        setShowViewMore(false); // Hide view more button
        return false;
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setNoDataFound(true); // Set when no data found
      setShowViewMore(false); // Hide view more button
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  // Function to auto-fetch 4 pages asynchronously
  const autoFetch4Pages = async (startPage) => {
    setIsFetching(true);

    let allPagesFetched = true;

    for (let i = startPage; i < startPage + 2; i++) {
      const hasData = hasActiveFilters
        ? await fetchResults(i)
        : await fetchAllPlaylist(i);
      // const hasData = hasActiveFilters
      //   ? await fetchAllPlaylist(i)
      //   : await fetchResults(i);
      if (!hasData) {
        allPagesFetched = false;
        break;
      }
    }

    setIsFetching(false);
    if (allPagesFetched) {
      setShowViewMore(true);
      setPage(startPage + 2);
    }
  };

  // function to load more track on click of view more btn
  const handleViewMoreClick = () => {
    autoFetch4Pages(page);
  };

  // formating the search track cards
  const searchPlaylist =
    results?.map((pack) => {
      // console.log(pack);
      // Ensure sku exists and is an array before accessing index 0
      const costPrice = pack?.sku?.[0]?.costPrice || "";
      const sellingPrice = pack?.sku?.[0]?.sellingPrice || "";
      const discountPercent = costPrice
        ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}% Off`
        : "0% Off";

      return {
        image:
          `${config.s3imgUrl}web/playlists/${pack.playlist_code}.webp` || "",
        title: pack.name,
        buttonText: "Explore",
        buttonLink: `#${pack.name_slug}`, // Link to the pack
        totalTracks: pack.track_count || 5,
        description: pack.description,
        packCode: pack.playlist_code, // No need for JSON.parse
        packNameSlug: pack.name_slug,
        type: pack.marker_type, // Fix the property name
        sellingPrice: sellingPrice || "",
        costPrice: costPrice || "",
        discountPercent, // Add calculated discount
      };
    }) || [];
  // console.log(searchPackList, "searchPackList");

  // fucntion to format the alltrack list
  const allPlaylist = allplaylist.map((pack) => {
    const costPrice = pack?.sku?.[0]?.costPrice || "";
    const sellingPrice = pack?.sku?.[0]?.sellingPrice || "";
    const discountPercent = costPrice
      ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}% Off`
      : "0% Off";

    return {
      image: `${config.s3imgUrl}web/playlists/${pack.playlistCode}.webp` || "",
      title: pack.name,
      buttonText: "Explore",
      buttonLink: `#${pack.name_slug}`, // Link to the pack
      totalTracks: pack.trackCount,
      description: pack.description,
      packCode: JSON.parse(pack.playlistCode), // Convert to string for statepack.playlistCode,
      packNameSlug: pack.name_slug,
      type: pack.markerType,
      sellingPrice: sellingPrice,
      costPrice: costPrice,
      discountPercent, // Add calculated discount
    };
  });

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  // for closing the filter modal
  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // default filters fucntion
  const handleFiltersSelected = (filters) => {
    // console.log("Updated Filters:", filters);
    setFilters(filters);
    const filtersString = JSON.stringify(filters);
    const encodedFilters = btoa(filtersString); // Encode to Base64

    // Update URL with filters
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", encodedFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // price filter fucntion
  const handlePriceFilterChange = (priceFilters) => {
    // console.log(priceFilters, "330");

    setPriceFilters(priceFilters);

    const encodedPriceFilters = priceFilter(priceFilters); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("priceFilters", encodedPriceFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // sort by filter fucntion
  const handleSortByChange = (e) => {
    const [type, order] = e.target.value.split("-");
    const newSortBy = { type, order: order || "" };

    setSortBy(newSortBy);

    const sortByString = JSON.stringify(newSortBy);
    const encodedSortBy = btoa(sortByString); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortBy", encodedSortBy);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // clear all filters function
  const handleClearFilter = () => {
    // Remove filter parameters from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("filters");
    searchParams.delete("priceFilters");
    searchParams.delete("sortBy");

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    setFilters({});
    setSortBy({ type: "relevance", order: "" });
    setPriceFilters({});
  };

  // console.log("Global Player Active : ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  const isPriceFilterActive = priceFilters.min || priceFilters.max;
  const hasActiveFilters =
    Object.values(filters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    ) ||
    sortBy.type !== "relevance" ||
    isPriceFilterActive;

  return (
    <>
      {/* Rotated Button */}
      {isVisible && (
        <div className={styles.rotateButton} onClick={toggleModal}>
          {hasActiveFilters ? (
            <div className={styles.filterActiveContainer}>
              <TbFilterCheck
                size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
                className={styles.filterActive}
              />
            </div>
          ) : (
            <FaFilter
              size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
              className={styles.filterIcon}
            />
          )}
          <span className={styles.sortText}>Sort & Filters</span>
        </div>
      )}
      {/* Modal */}
      {isModalOpen && (
        <div className={overlayClass} onClick={handleOverlayClick}>
          <div className={styles.modalContainer}>
            {/* header section */}
            <div className={styles.header}>
              <div className={styles.heading}>
                <p className={styles.headingtext}>Sort & Filters</p>
              </div>
              <IoIosClose
                onClick={closeModal}
                color="#000"
                cursor="pointer"
                size={34}
              />
            </div>
            <br></br>
            <div className={styles.filterWapper}>
              {/* Sort By Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Sort By</p>
                  {/* add radio or checkboxes for price name and owener if price is selected the display the two input fileds for
                max and min value and in a use effect the sort by value will be check on every change all the value of sort by will be in object. */}
                </div>
                <hr style={{ color: "#555" }} />
                <div className={styles.sortOptions}>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="relevance"
                      checked={sortBy.type === "relevance"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Relevance
                  </label>
                  {/* <label>
                    <input
                      type="radio"
                      name="type"
                      // value="price"
                      value="price-asc"
                      // checked={sortBy.type === "price"}
                      checked={
                        sortBy.type === "price" && sortBy.order === "asc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (low to high)
                  </label> */}
                  {/* <label>
                    <input
                      type="radio"
                      name="type"
                      // value="price"
                      value="price-desc"
                      // checked={sortBy.type === "price"}
                      checked={
                        sortBy.type === "price" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (high to low)
                  </label> */}
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-asc"
                      // checked={sortBy.type === "name"}
                      checked={sortBy.type === "name" && sortBy.order === "asc"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Asc)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-desc"
                      // checked={sortBy.type === "name"}
                      checked={
                        sortBy.type === "name" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Desc)
                  </label>
                </div>
              </div>
              {/* Filter Section */}
              <div style={{ marginTop: 20 }} className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Filter</p>
                  {/* <IoIosArrowDown /> */}
                </div>
                <hr style={{ color: "#555" }} />
                <div>
                  <TrackFilter
                    onPriceFilterChange={handlePriceFilterChange}
                    onFiltersSelected={handleFiltersSelected}
                    onClearFilters={handleClearFilter}
                    hideApply={true}
                    width="100%"
                    showPriceFilter={false}
                    savedFilters={filters}
                    priceFilters={priceFilters}
                    showLanguage={false}
                    showInstruments={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="allSongs" className={`${styles.container} ${styles.outerFull}`}>
        <div className={styles.inner}>
          <div className={styles.containerHeader}>
            <p className={styles.cheadingTxt}>All Playlist</p>
            <div className={styles.btnContainer}>
              {hasActiveFilters && (
                <p
                  onClick={handleClearFilter}
                  className={styles.clearFilterText}
                >
                  Clear Filters
                </p>
              )}
              {viewall && (
                <Link to={"/tracks"}>
                  <p className={styles.csubheadingTxt}>
                    View All{" "}
                    <span>
                      <IoIosArrowForward />
                    </span>
                  </p>
                </Link>
              )}
            </div>
          </div>
          <div className={styles.playlist}>
            <PlaylistCard
              packCards={allPlaylist.length > 0 ? allPlaylist : searchPlaylist}
              packType="allplaylists"
            />
          </div>
          {isFetching && (
            <p style={{ textAlign: "center", marginTop: "30px" }}>
              Loading more playlist...
            </p>
          )}
          {noDataFound && (
            <p style={{ textAlign: "center", marginTop: "30px" }}>
              No data found.
            </p>
          )}
          {showViewMore && !noDataFound && (
            <button
              className={styles.viewMoreBtn}
              onClick={handleViewMoreClick}
            >
              View More
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AllPlaylistCards;
