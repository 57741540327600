import config from "../config";

const restrictions = {
  ab: {
    name: "Alcoholic Beverages",
    desc: "Usage in content promoting or indulging with alcohol is strictly prohibited.",
    icon: `${config.s3imgUrl}icons/abred.png`,
  },
  dc: {
    name: "Defamatory Content",
    desc: "Cannot be used in content that harms or discredits individuals.",
    icon: `${config.s3imgUrl}icons/dcred.png`,
  },
  // ga: {
  //   name: "Gambling",
  //   desc: "Gambling",
  //   icon: `${config.s3imgUrl}icons/ga.png`,
  // },
  // ha: {
  //   name: "Hate Speech",
  //   desc: "Hate Speech",
  //   icon: `${config.s3imgUrl}icons/ha.png`,
  // },
  // il: {
  //   name: "Illegal Content",
  //   desc: "Illegal Content",
  //   icon: `${config.s3imgUrl}icons/il.png`,
  // },
  // ot: {
  //   name: "Other",
  //   desc: "Other",
  //   icon: `${config.s3imgUrl}icons/ot.png`,
  // },
  pc: {
    name: "Political Content",
    desc: "Restricted from use in political campaigns or partisan messaging.",
    icon: `${config.s3imgUrl}icons/pcred.png`,
  },
  se: {
    name: "Sexually Explicit",
    desc: "Not permitted in content featuring explicit sexual themes.",
    icon: `${config.s3imgUrl}icons/sered.png`,
  },
  sw: {
    name: "Sexual Wellness",
    desc: "Prohibited in advertisements or discussions of sexual wellness / products.",
    icon: `${config.s3imgUrl}icons/swred.png`,
  },
  to: {
    name: "Tobacco & Smoking",
    desc: "Cannot be used in content promoting or using tobacco or smoking products.",
    icon: `${config.s3imgUrl}icons/tored.png`,
  },
};

/*
  THE NUMBER INDICATES THE OWNER CODE
  IF OWNER CODE ARRAY IS NOT PRESENT, USE THE DEFAULT ARRAY TO SHOWCASE THE RESTRICTIONS
*/
const ownerMap = {
  1: ["ab", "dc", "pc", "se", "sw", "to"],
  default: [],
};

export default { restrictions, ownerMap };
