import config from "../config";

const brands = {
  basePath: `${config.s3imgUrl}static/about/brands`,
  list: [
    "bharat24.png",
    "cadbury.png",
    "dr-vaidya.png",
    "flipkart.png",
    "jagran.png",
    "kajaria.png",
    "marico.png",
    "myntra.png",
    "newj.png",
    "pocketfm.png",
    "sunsilk.png",
    "too-yumm.png",
    "universal.png",
    "zee.png",
    "zivame.png",
  ],
};

export default brands;
