import React, { useEffect, useRef, useState } from "react"; // Add useState
import "../styles/GlobalPlayer.css";
import { useGlobalPlayer } from "../utils/GlobalPlayerContext";
import { BiAddToQueue, BiX } from "react-icons/bi"; // Import close icon
import { FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import { useLocation, Link } from "react-router-dom";
import Waveform from "../utils/Waveform";
import {
  setGlobalPlayerActive,
  setShowOptionsState,
} from "../utils/redux/uiSlice"; // Import Redux action
import { useDispatch } from "react-redux";
import { useHandleShareClick } from "../utils/Share";
import config from "../utils/config";
import randomDefaultImage from "../utils/RandomDefaultImage";
import useMixpanel from "../service/MixpanelService";
import { IoEllipsisVerticalSharp } from "react-icons/io5";

const GlobalPlayer = () => {
  const { playerData, isPlaying, setIsPlaying, hidePlayer } = useGlobalPlayer(); // Use control from context
  const location = useLocation(); // Get current route for conditional rendering of pricing button
  const dispatch = useDispatch();
  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const prevTrackCode = useRef(null); // Ref to store previous track code
  const { trackEvent } = useMixpanel(); // Use Mixpanel service
  const [showOptions, setShowOptions] = useState(false); // State to toggle options

  // console.log(playerData, "24");

  // this use effect is for managaing the ui if other modal is open then for
  // auto height adjust the player status is being monitor.
  // this effect does not relate to track changes or pause/play of a track
  useEffect(() => {
    // Log the state change
    if (playerData) {
      // console.log("Setting Global Player Active to true",playerData);
      dispatch(setGlobalPlayerActive(true));

      // Track the track-streamed event
      // const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
      // trackEvent("track-streamed", {
      //   url: window.location.href,
      //   songName: playerData.trackName,
      //   userId: storedUserData.id,
      //   email: storedUserData.email,
      //   phone: storedUserData.mobile,
      //   type: "global-player",
      //   code: playerData.trackCode,
      // });
    }

    return () => {
      // console.log("Setting Global Player Active to false");
      dispatch(setGlobalPlayerActive(false));
    };
  }, [dispatch, playerData]);

  if (!playerData) return null;

  // Check if the current route matches `/tracks/:name/:trackCode`
  const isTrackPage = /^\/tracks\/[^/]+\/[^/]+$/.test(location.pathname);

  // console.log(playerData, "useGlobalPlayer");
  //   console.log(isPlaying, "isPlaying from global");

  const handlePlayPauseChange = (playing) => {
    // console.log(playing, "playing status from global player");
    // setIsPlaying(playing); // Update the global play state

    // console.log("Previous Track Code:", prevTrackCode.current);
    // console.log("Current Track Code:", playerData?.trackCode);

    if (!playerData || !playerData.trackCode) return; // Ensure playerData exists

    if (prevTrackCode.current === playerData.trackCode) {
      // Same track → Toggle play/pause
      setIsPlaying(playing);
    } else {
      // console.log("new track", playing);

      // Different track → Don't toggle, let it play
      setIsPlaying(true);
    }

    // Update ref with the current track
    prevTrackCode.current = playerData.trackCode;
  };

  const handleClosePlayer = () => {
    dispatch(setGlobalPlayerActive(false)); // Update Redux state on close
    hidePlayer();
  };

  const handlePricingClick = () => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    trackEvent("pricing-player", {
      url: window.location.href,
      songName: playerData.trackName,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
    });
  };

  const toggleOptions = () => {
    const newState = !showOptions;
    setShowOptions(newState); // Toggle the options visibility
    dispatch(setShowOptionsState(newState)); // Dispatch the state to Redux
  };

  const handleOptionClick = (callback) => {
    setShowOptions(false); // Hide the options
    dispatch(setShowOptionsState(false)); // Update Redux state
    if (callback) callback(); // Execute the provided callback
  };

  return (
    <div className="global-player-container outer-full">
      <div className="globalplayer">
        <div className="g-player-container gp-inner">
          {/* Close Icon */}
          <div className="g-player-close" onClick={handleClosePlayer}>
            <BiX size={24} color="#fff" />
          </div>
          <div className="g-player-mob-btn" onClick={toggleOptions}>
            <IoEllipsisVerticalSharp size={16} color="#000" />
          </div>
          {showOptions && (
            <div className="g-player-options-mob">
              <div onClick={() => handleOptionClick(handleShareClick)}>
                <FiShare2 size={22} color="#B2B2B2" />
              </div>
              <div onClick={() => handleOptionClick(null)}>
                <BiAddToQueue size={22} color="#B2B2B2" />
              </div>
              <div onClick={() => handleOptionClick(null)}>
                <FaRegHeart size={22} color="#B2B2B2" />
              </div>
              {!isTrackPage && (
                <Link
                  to={`/tracks/${encodeURIComponent(playerData.trackName)}/${
                    playerData.trackCode
                  }`}
                  onClick={() => handleOptionClick(handlePricingClick)}
                >
                  <div>Pricing</div>
                </Link>
              )}
            </div>
          )}
          {/* track info */}
          <div className="g-track-container">
            <div className="g-track-img-container">
              <Link
                to={`/tracks/${encodeURIComponent(playerData.trackName)}/${
                  playerData.trackCode
                }`}
              >
                <img
                  className="g-track-img"
                  src={
                    config.s3imgUrl +
                      "web/tracks/" +
                      playerData.trackCode +
                      ".webp" || randomDefaultImage("track")
                  }
                  alt="Track"
                />
              </Link>
            </div>
            <div className="g-track-info">
              <Link
                to={`/tracks/${encodeURIComponent(playerData.trackName)}/${
                  playerData.trackCode
                }`}
              >
                <h1>{playerData.trackName}</h1>
              </Link>
              <h2>{playerData.trackArtistName}</h2>
            </div>
          </div>
          {/* waveform with player controls */}
          <div className="g-waveform-container">
            <Waveform
              trackUrl={playerData?.trackUrl}
              waveJson={playerData?.trackJson}
              play={isPlaying} // Pass global isPlaying state
              onPlayPauseChange={handlePlayPauseChange} // Pass the callback
            />
          </div>
        </div>
        <div className="gp-actions-container">
          {/* player-action button */}
          <div className="g-player-actions">
            <FiShare2 onClick={handleShareClick} size={22} color="#B2B2B2" />
            <BiAddToQueue size={22} color="#B2B2B2" />
            <FaRegHeart size={22} color="#B2B2B2" />
            {/* Hide the custom button if the current route is the TrackPage */}
            <div className="g-pricing-btn">
              {!isTrackPage && (
                <Link
                  to={`/tracks/${encodeURIComponent(playerData.trackName)}/${
                    playerData.trackCode
                  }`}
                  onClick={handlePricingClick}
                >
                  <CustomButton
                    justifyContent="space-between"
                    text="Pricing"
                    backgroundColor="#fff"
                    textColor="#272727"
                    fontWeight="600"
                    width="147px"
                    height="30px"
                    borderRadius="50px"
                    fontSize="14px"
                    iconColor="#272727"
                    icon={<IoIosArrowForward />}
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalPlayer;
