import React, { useEffect, useState } from "react";
import styles from "../styles/GenreListing.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import { Link } from "react-router-dom";
// import CustomImg from "../components/CustomImg/CustomImg";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import setMetaTags from "../utils/SeoService";

const MoodsListing = () => {
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const { genres, status } = useSelector((state) => state.trackFilters);
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const seoData = {
      title: "20+ Different Genres Based Music Licensing | Hoopr Smash",
      description:
        "Browse 20+ genres for music licensing on Hoopr Smash. Access royalty-free tracks across styles, perfect for diverse creative projects, campaigns, and storytelling.",
      ogImage: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/genres/topbanner.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  const categories = {
    genres: genres.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };
  // console.log(categories, "genres");

  // Dynamically generate allgenres from categories
  const allGenres = categories.genres.map((genre, index) => ({
    id: genre.id,
    image: randomDefaultImage("genre"),
    heading: genre.title,
    slug: genre.slug,
  }));

  // console.log(allGenres, "allGenres");

  // Utility to pick 4 random genres from allGenres
  // const pickRandomGenres = (genres, count = 4) => {
  //   const shuffled = [...genres].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, count);
  // };

  // Utility to filter genres by title
  const pickGenresByTitle = (genres, titles) => {
    const titleSet = new Set(titles.map((title) => title.toLowerCase()));
    return genres.filter((genre) => titleSet.has(genre.heading.toLowerCase()));
  };

  // Randomly picked genres
  // const randomGenres = pickRandomGenres(allGenres);

  // Specifically picked genres by title
  const specificTitles = ["Devotional", "Indian Folk", "Cinematic"]; // Example titles
  const specificGenres = pickGenresByTitle(allGenres, specificTitles);

  const popularGenres = specificGenres; // Set popularMoods as randomly picked moods or specificMoods based on requirement

  // Function to find a genres by title for main banner
  // Function to find genres by title
  const findGenresByTitle = (genres, title) => {
    return genres.find(
      (genre) => genre.heading.toLowerCase() === title.toLowerCase()
    );
  };

  // Example: Find "Bihu" genres
  // const specificGenre = findGenresByTitle(allGenres, "Bihu");
  const specificGenre = "Bollywood Contemporary";

  return (
    <div className={styles.genresListingContainer}>
      {/* genres page description */}
      <div className={`${styles.genresListingDescription} ${styles.outer}`}>
        <div className={`${styles.innerBreadcrumb}`}>
          <Link to={"/"}>Home</Link> /&nbsp;
          <span>Genres</span>
        </div>
        <h1 className={styles.genresHeading}>Shop By Genres</h1>
        <h2 className={styles.genresSubHeading}>
          Find that distinctive vibe for your content! Explore popular tracks
          across 100+ genres to enhance relatability and elevate the emotional
          quotient.
        </h2>
      </div>
      {/* genres main banner */}
      <div
        style={{
          backgroundImage: `url(https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/genres/topbanner.webp)`,
          // backgroundImage: `url(https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/moods/topbanner.webp)`,
        }}
        className={`${styles.genresListingBanner} ${styles.inner}`}
      >
        <h1 className={styles.genresBannerHeading}>
          {/* {specificGenre ? specificGenre.heading : "Genre Name"} */}
          {/* {specificGenre} */}
          Bollywood
        </h1>
        {/* <h2 className={styles.genresBannerSubHeading}>
        Authentic and traditional music from various cultures around the world.
        </h2> */}
        <Link
          to={`/genres/${encodeURIComponent(
            specificGenre ? specificGenre.slug : ""
          )}/${specificGenre ? specificGenre.id : ""}`}
        >
          <CustomButton
            text="Explore Track"
            backgroundColor="#fff"
            borderRadius="50px"
            borderColor="none"
            // width="182px"
            width={
              isMobile
                ? windowWidth <= 320
                  ? "100px"
                  : windowWidth <= 375
                  ? "120px"
                  : "140px"
                : isTablet
                ? windowWidth <= 430
                  ? "140px"
                  : "168px"
                : "182px"
            }
            height={windowWidth <= 768 ? "30px" : "37px"}
            textColor="var(--button-primary)"
            fontSize={windowWidth <= 768 ? "10px" : "16px"}
            fontFamily="Raleway"
            fontWeight="bold"
            iconColor="var(--button-primary)"
            icon={<IoIosArrowForward />}
            justifyContent="space-between"
          />
        </Link>
      </div>
      {/* popular genres section */}
      <div className={`${styles.populargenresContainer} ${styles.outerFull}`}>
        <div className={`${styles.inner}`}>
          <h1 className={styles.populargenresHeading}>Popular Genres</h1>
          <div className={styles.populargenresCards}>
            {/* for handling more card css is added just update the slice value */}
            {popularGenres.slice(0, 3).map((genre) => (
              <Link
                // key={index}
                to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
              >
                <div key={genre.id} className={styles.populargenresCard}>
                  {/* <img
                  src={genre.image}
                  alt={genre.heading}
                  className={styles.populargenresCardImage}
                /> */}
                  <BgImageDiv
                    type="genre"
                    id={genre.id}
                    alt={genre.heading}
                    divClass={styles.populargenresCardImage}
                    src=""
                  ></BgImageDiv>
                  <h1 className={styles.populargenresCardHeading}>
                    {genre.heading}
                  </h1>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* all genres section */}
      <div className={`${styles.populargenresContainer} ${styles.outer}`}>
        <h1 className={styles.populargenresHeading}>All Genres</h1>
        <div className={styles.allgenresCards}>
          {/* for handling more card css is added just update the slice value */}
          {allGenres.map((genre) => (
            <Link
              // key={index}
              to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
            >
              <div key={genre.id} className={styles.allgenresCard}>
                {/* <CustomImg
                  type="genre"
                  id={genre.id}
                  alt={genre.heading}
                  imgClass={styles.populargenresCardImage}
                  src=""
                >
                </CustomImg> */}

                <BgImageDiv
                  type="genre"
                  id={genre.id}
                  alt={genre.heading}
                  divClass={styles.allgenresCardImage}
                  src=""
                ></BgImageDiv>
                <h1 className={styles.allgenresCardHeading}>{genre.heading}</h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodsListing;
