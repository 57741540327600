const genres = {
  "067044d8-9c45-49d8-9ece-2bb4857928d4": {
      "name": "Acoustic",
      "slug": "acoustic",
      "description": "Acoustic music is a versatile and engaging choice for background music in branded and entertaining content. It can enhance the emotional connection between the brand and the audience. This makes an acoustic track particularly effective in fostering authenticity and relatability; whether it is a soft guitar melody or a soothing piano tune, it helps content feel more human and accessible. Its warm, organic sound creates a relaxed and intimate atmosphere that complements a wide range of visuals, from a travel commercial to a lifestyle product showcase. We provide quick music licensing for commercial and branded use. An acoustic tune, perfectly matching your requirements, helps enhance the brand image; its simplicity allows for a clear focus on the message or story being told while still providing a rich auditory experience."
  },
  "4b2a7fca-d34b-4baa-972b-03086b86a0ff": {
      "name": "Afro",
      "slug": "afro",
      "description": "Afro-music is a powerful fusion of rhythm, melody, and cultural vibrancy, making it a perfect choice for engaging and energetic content. Its infectious beats, deep percussion, and dynamic instrumentation create a lively and immersive experience, ideal for travel videos, fashion campaigns, and upbeat brand storytelling. Whether it’s the pulsating rhythms of Afrobeats or the soulful melodies of Highlife, Afro-music fosters an emotional connection with audiences. We offer a curated selection of copyright-safe Afro tracks, ensuring authentic sound without legal headaches. Explore a diverse range of styles, from traditional African grooves to contemporary Afro-fusion, and find the perfect sonic complement for your project. Need high-energy beats? Or perhaps something more mellow and rhythmic? Afro-music adds a unique global flair to any visual, elevating your content and connecting with viewers on an emotional level."
  },
  "6612f3b1-8745-4305-afe9-acd973f7cecf": {
      "name": "Ambient",
      "slug": "ambient",
      "description": "Ambient music is the ultimate backdrop for creating an immersive, meditative, and calming experience. Characterized by soft textures, evolving soundscapes, and deep atmospheric layers, it is ideal for relaxation content, tech presentations, and cinematic storytelling. Whether used in a luxury brand campaign or a wellness video, ambient tracks set the mood without overpowering the main message. They seamlessly blend into the background while adding emotional depth and sophistication. We offer an extensive collection of copyright-safe music that caters to various ambient styles, from ethereal electronic pads to nature-inspired compositions. These tracks ensure a tranquil and engaging experience for your audience while keeping your content legally compliant. With ambient music, you can transform any project into a captivating and emotionally resonant piece."
  },
  // "8be0c3e5-1f2a-4f1e-98e8-494abe02406f": {
  //     "name": "Blues",
  //     "slug": "blues",
  //     "description": "Soulful guitar riffs and emotional melodies that bring depth to character-driven stories and vintage narratives."
  // },
  "3e3cb3f5-fcf0-4e4d-a5b9-ea1d6c2cf4cc": {
      "name": "Bollywood",
      "slug": "bollywood",
      "description": "Bollywood music is known for its grand orchestration, dramatic highs, and deeply emotional melodies. With a mix of classical Indian instruments and modern production, it creates a cinematic experience that captivates audiences. Whether it’s a wedding celebration, an intense action sequence, or a heartwarming love story, Bollywood music enhances emotions and storytelling like no other. From soul-stirring ballads to high-energy dance numbers, its versatility makes it an ideal choice for dynamic and expressive content. We provide copyright protection for trendy Bollywood tracks, ensuring that you get these hits without any licensing issues. Whether you’re looking for traditional Bollywood tracks or contemporary fusion, our diverse collection brings authenticity and excitement to your content, making it more engaging and visually rich."
  },
  "478cf343-37bc-4458-b596-5de8c0edcddb": {
      "name": "Cinematic",
      "slug": "cinematic",
      "description": "Cinematic music adds grandeur, emotion, and depth to storytelling, making it perfect for trailers, documentaries, and high-impact brand campaigns. With sweeping orchestral arrangements, dramatic crescendos, and powerful instrumentation, it creates an immersive experience that captivates viewers. Whether it’s an inspiring sports ad, a suspenseful thriller, or an emotional brand story, cinematic tracks enhance the narrative with their compelling soundscapes. We offer copyright protection for trendy Bollywood music that brings the magic of cinematic grandeur to your content, ensuring professional-quality audio without legal complications. From epic orchestras to delicate piano compositions, our collection helps in crafting a visually and sonically stunning experience, elevating your content with a soundtrack that resonates with audiences."
  },
  // "fa082a08-94f8-48f1-ae33-35c1194baa77": {
  //     "name": "Corporate",
  //     "slug": "corporate",
  //     "description": "Upbeat and motivational tracks with polished tones, ideal for presentations, explainer videos, and success stories."
  // },
  // "ea5d8e0a-c5d9-41b9-a71d-dfab7087ed76": {
  //     "name": "Country",
  //     "slug": "country",
  //     "description": "Rustic melodies with acoustic charm, great for nature-inspired content, travelogues, and lifestyle projects."
  // },
  "ea7468e8-b5ef-46d9-ace6-9d10e5fc5760": {
      "name": "Devotional",
      "slug": "devotional",
      "description": "Devotional music brings a sense of peace, spirituality, and emotional depth, making it ideal for religious content, yoga sessions, and meditation videos. Rooted in traditional hymns, chants, and bhajans, it fosters a connection to the divine while providing a soothing atmosphere. Whether it’s a calming mantra or a soulful kirtan, devotional tracks add authenticity and serenity to storytelling. We provide copyright safety for devotional tracks that are perfect for spiritual projects, ensuring a respectful and immersive listening experience without any legal concerns. From classical Indian devotional music to modern interpretations, our collection helps create a deeply moving and meditative ambiance for content focused on mindfulness and faith."
  },
  "ef5b24a7-702b-462f-bf80-29cae8a6dbc7": {
      "name": "Electronic",
      "slug": "electronic",
      "description": "Electronic music is energetic, futuristic, and dynamic, making it a great fit for tech brands, fitness videos, and high-energy commercials. From deep house grooves to pulsating EDM drops, electronic tracks bring excitement and movement to any content. With their modern production and layered synth sounds, they create an engaging atmosphere that keeps audiences hooked. Our collection of copyright-safe music includes both underground gems and mainstream electronic popular hits, giving you access to popular music for any creative project. Whether you're aiming for a fast-paced, adrenaline-filled sound or a more ambient, chilled-out vibe, electronic music adds a powerful and captivating touch to your visuals."
  },
  "62a33aa8-4133-4084-9eac-05ecd702eb44": {
      "name": "Festival",
      "slug": "festival",
      "description": "Festival music captures the joy, excitement, and celebratory spirit of grand occasions, making it ideal for event promotions, party videos, and cultural storytelling. Whether it's the energetic beats of carnival drums, the vibrant sound of folk dances, or the electrifying drop of an EDM festival track, this genre fills the air with an infectious sense of fun and togetherness. We offer copyright-safe festival music tracks that allow you to create lively and engaging content without worrying about restrictions. From traditional festival sounds to modern electronic anthems, our diverse collection ensures your content is vibrant, festive, and filled with musical energy that enhances every visual moment."
  },
  "33c990ad-4298-4a07-a19b-93b148772e28": {
      "name": "Funk",
      "slug": "funk",
      "description": "Funk music is all about groove, rhythm, and energetic instrumentation. With its punchy basslines, syncopated beats, and vibrant brass sections, funk is perfect for lifestyle branding, fashion content, and upbeat commercial projects. It brings an irresistible charm and retro appeal, making any video stand out with its lively sound. License our collection of popular Bollywood music that includes funky classics and modern grooves, ensuring your content has the perfect rhythm-driven soundtrack. Whether you need a cool, laid-back funk vibe or a high-energy danceable track, funk music adds a stylish and engaging dimension to any creative project."
  },
  "6a79250a-2be5-43e9-a6ba-e094dfc46120": {
      "name": "Hip Hop / Rap",
      "slug": "hip-hop-rap",
      "description": "Hip-hop and Rap music bring raw energy, rhythm, and urban storytelling to content, making them ideal for sports highlights, streetwear branding, and edgy commercials. With bold beats, lyrical flows, and dynamic instrumentation, hip-hop adds authenticity and attitude to visuals. From old-school boom-bap to modern trap beats, this genre is all about making a statement. We offer copyright protection for hip-hop tracks that ensure your content maintains a professional and polished feel without legal concerns. Whether you’re looking for smooth R&B-infused rap or hard-hitting beats, hip-hop music enhances storytelling with its unmistakable presence and rhythmic intensity."
  },
  "82e9113f-c1a4-492b-adf9-8e292c7f4a9a": {
      "name": "Indian Classical",
      "slug": "indian-classical",
      "description": "Indian Classical music is deeply rooted in tradition and artistry, making it an exceptional choice for cultural projects, meditative content, and refined branding. With its intricate ragas, soulful sitar, and mesmerizing tabla rhythms, it evokes a sense of elegance and depth, perfect for storytelling that demands authenticity and sophistication. Our collection of licensing tracks includes both traditional and fusion Indian classical compositions, ensuring that you have access to trending sounds for your creative projects. Whether it’s a serene background for meditation or an elegant addition to a cultural documentary, Indian Classical music brings grace and refinement to any visual experience."
  },
  "55c32e7e-3214-405f-9196-356cbdcbfa64": {
      "name": "Indian Contemporary",
      "slug": "indian-contemporary",
      "description": "Indian Contemporary music blends traditional melodies with modern arrangements, creating a sound that is fresh, dynamic, and versatile. Whether it’s indie fusion, Bollywood-inspired pop, or experimental sounds, this genre brings an exciting mix of cultures and influences. It’s perfect for lifestyle branding, travel vlogs, and artistic storytelling, adding an emotional and relatable touch. Our copyright-safe Indian Contemporary tracks ensure your content has an authentic and professional sound without any legal issues. From soulful ballads to energetic beats, this genre seamlessly enhances any visual narrative, making it a great choice for diverse and engaging multimedia projects."
  },
  "0c29a125-b83e-4b4b-8208-ac8b7e810e2b": {
      "name": "Indian Folk",
      "slug": "indian-folk",
      "description": "Indian Folk music is deeply rooted in regional traditions, capturing the essence of storytelling through rhythmic beats, raw melodies, and rustic instrumentation. Whether it’s Punjabi Bhangra, Rajasthani folk, or Bengali Baul music, this genre brings warmth and authenticity to content. It is an excellent choice for documentaries, cultural showcases, and heritage-based storytelling. We help you license popular tracks that feature a variety of Indian Folk compositions, from traditional to contemporary adaptations, ensuring your content remains genuine and engaging. With rich sounds of the dhol, flute, and folk vocals, these tracks elevate the storytelling experience, making them perfect for showcasing cultural depth and heritage."
  },
  // "b34273af-0f15-468c-befd-2f4dbff21f00": {
  //     "name": "Indian Folk Fusion",
  //     "slug": "indian-folk-fusion",
  //     "description": "A blend of traditional folk and modern beats, perfect for festive videos and vibrant cultural showcases."
  // },
  "63d98bbb-3754-4366-970b-f7cb1b230b59": {
      "name": "Indie",
      "slug": "indie",
      "description": "Indie music is all about creativity, raw emotion, and an authentic storytelling experience. With its unpolished charm, organic instrumentation, and heartfelt lyrics, Indie music is perfect for personal branding, heartfelt commercials, and lifestyle content. Whether it’s mellow acoustic tunes or vibrant alternative rock, this genre helps content feel more personal and relatable. We offer copyright safety for Indie tracks that allow content creators to use unique, popular hits without restrictions. Whether you're looking for a relaxed folk-inspired melody or an upbeat indie-pop tune, this genre adds artistic flair and authenticity to your visuals."
  },
  "8462b1d8-6210-4a61-9174-76a3f1a1af99": {
      "name": "Jazz",
      "slug": "jazz",
      "description": "Jazz music brings elegance, sophistication, and a touch of improvisation to content. With smooth saxophones, deep double bass, and expressive piano melodies, it is perfect for luxury branding, cafés, and artistic storytelling. Whether it’s upbeat swing, cool jazz, or soft lounge music, this genre enhances ambiance and adds a timeless charm to visuals. Our collection includes classic jazz standards and modern interpretations, giving you access to copyright-safe tracks for your content. Whether setting a stylish mood for a product launch or adding warmth to a brand video, jazz music effortlessly elevates the experience."
  },
  "31026e90-b30c-4b15-ae87-be7fbd924b1a": {
      "name": "Kids",
      "slug": "kids",
      "description": "Kids’ music is playful, bright, and engaging, making it ideal for educational videos, animated content, and children’s entertainment. With cheerful melodies, fun rhythms, and catchy tunes, it captures young audiences effortlessly. From sing-along tracks to upbeat instrumentals, kids' music adds joy and energy to content designed for younger viewers. We provide copyright-safe Kids' tracks, ensuring content remains fun and engaging while adhering to legal requirements. Whether it's a learning app, a cartoon series, or an interactive game, kids' music creates an enjoyable and memorable experience for little listeners."
  },
  "20129102-87b0-42b0-932d-5120cc2e5ace": {
      "name": "Latin",
      "slug": "latin",
      "description": "Latin music is filled with passion, rhythm, and energy, making it perfect for dance videos, travel content, and vibrant commercial campaigns. From the sultry beats of salsa and bachata to the high-energy vibes of reggaeton and samba, Latin music brings an irresistible liveliness to any project. We help you license a popular Bollywood collection that features both traditional and modern Latin sounds, ensuring your content has a rich, culturally immersive feel. Whether you want a romantic Latin ballad or an upbeat fiesta track, Latin music provides a rhythmic and engaging experience that resonates with audiences worldwide."
  },
  "109d19cd-02d1-4f8d-b5c7-81d073db6a62": {
      "name": "Pop",
      "slug": "pop",
      "description": "Pop music is universal, catchy, and dynamic, making it an ideal choice for branding, social media content, and commercials. With its energetic beats, singable melodies, and polished production, pop music keeps audiences engaged and entertained. Whether it’s an uplifting anthem or a heartfelt ballad, pop tracks bring versatility to any project. We offer a wide selection of copyright-safe Pop tracks, ensuring that your content has popular and hit music without legal concerns. From trending radio hits to fresh indie pop sounds, this genre adds a contemporary and engaging feel to any visual narrative."
  },
  "2d67bd5d-353b-47dc-8373-0e60d3ce7970": {
      "name": "Retro",
      "slug": "retro",
      "description": "Retro music transports audiences back in time, offering a nostalgic and timeless feel. Whether it’s rock ‘n’ roll from the ‘50s, disco anthems from the ‘70s, or synth-pop from the ‘80s, retro tracks bring vintage vibes that enhance storytelling and branding. This genre is perfect for throwback campaigns, vintage fashion promos, and classic-themed videos. Our copyright-safe track collection includes iconic retro sounds and fresh takes on classic styles, ensuring you have the perfect blend of old-school charm and modern quality. Whether it's a groovy disco beat or a rockabilly jam, retro music adds a fun and stylish element to any project."
  },
  "13d50cdd-6fcc-4537-b415-fd4dc28423be": {
      "name": "RnB",
      "slug": "rnb",
      "description": "R&B (Rhythm and Blues) music is smooth, soulful, and deeply expressive, making it perfect for fashion campaigns, romantic storytelling, and luxury branding. With silky vocals, groove-driven basslines, and emotional depth, R&B creates an intimate and stylish atmosphere that resonates with audiences. We provide copyright-safe R&B tracks, giving you access to popular music for your content without restrictions. Whether it's a sensual slow jam or an upbeat, funk-inspired groove, R&B adds warmth, elegance, and authenticity to any creative project."
  },
  "747af598-6978-440e-a1c7-932a9d185d11": {
      "name": "Rock",
      "slug": "rock",
      "description": "Rock music delivers intensity, passion, and raw energy, making it an excellent choice for sports highlights, action-packed commercials, and rebellious branding. From classic rock anthems to modern alternative and indie rock, this genre amplifies emotions and brings a bold presence to visuals. License our popular track collection featuring legendary rock sounds and fresh new bands, ensuring your content has the perfect balance of grit and melody. Whether you need a high-energy guitar solo or a steady, driving beat, rock music helps your project stand out with power and attitude."
  },
  "063115a1-b198-4095-9054-1c4d4359cd73": {
      "name": "Western Classical",
      "slug": "western-classical",
      "description": "Western Classical music offers elegance, grandeur, and timeless beauty, making it perfect for luxury branding, cinematic storytelling, and artistic content. With rich orchestral arrangements, intricate piano compositions, and powerful symphonies, it enhances storytelling with sophistication and emotional depth. We provide copyright-safe Classical tracks, ensuring legally compliant music for your projects. Whether it's a majestic symphony or a delicate solo piece, classical music elevates the storytelling experience and adds a refined touch to any visual production."
  },
  "403989c0-f71f-4b00-b534-d2fe4f0af1b9": {
      "name": "World",
      "slug": "world",
      "description": "World music brings together sounds from different cultures, making it perfect for travel documentaries, multicultural branding, and globally inspired storytelling. Whether it’s African drumming, Latin guitars, Asian flutes, or Middle Eastern melodies, world music creates an immersive and authentic experience that celebrates diversity. Our copyright-safe track collection includes traditional and contemporary world music, ensuring a rich and varied selection for your projects. Whether you're looking for rhythmic tribal beats or soothing ethnic harmonies, world music adds depth, cultural richness, and a unique flavor to your content."
  }
}

export default genres;