import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/MyAccountCss/Likes.module.css";
// import randomDefaultImage from "../../utils/RandomDefaultImage";
// import { FaPlay, FaHeart } from "react-icons/fa";
// import config from "../../utils/config";
import PackCard from "../../components/CardsComponent/PackCard/PackCard";
import PlaylistCard from "../../components/CardsComponent/PlaylistCard/PlaylistCard";
import { getLikes } from "../../service/UserService";
import getDirectLink from "../../utils/AudioUrlFormated";
// import MiniTrackCard from "../../components/CardsComponent/TrackCard/MiniTrackCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { createUserLike } from "../../service/UserService";
import { ToastContext } from "../../utils/ToastContext";
import TrackCard from "../../components/CardsComponent/TrackCard/TrackCard";

const MyLikes = () => {
  const [selectedTab, setSelectedTab] = useState("Tracks"); // state to manage the pill/button selected
  const [packCards, setPackCards] = useState([]); // packs cards data
  const [playlistCards, setPlaylistCards] = useState([]); // playlist cards data
  const [tracks, setTracks] = useState([]); // track cards data
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage

  // fetching the user likes
  useEffect(() => {
    const fetchLikes = async () => {
      const likeResponse = await getLikes();

      // console.log(likeResponse, "likeResponse");

      // Separate tracks
      // const { likedTracks } = likeResponse;
      const { likedTracks = [] } = likeResponse.data;
      const mappedTracks = likedTracks.map((track) => {
        // if (!track) return {}; // Skip if track data is invalid
        // console.log(track);

        const { costPrice, sellingPrice, id } = track?.SKU?.[0] || {};
        const discountPercent = (
          ((costPrice - sellingPrice) / costPrice) *
          100
        ).toFixed(0);

        const artistNames = track.primaryArtists?.length
          ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
          : "";

        return {
          title:
            track.name.length > 18
              ? track.name.substring(0, 17) + "..."
              : track.name,
          description:
            artistNames.length > 30
              ? artistNames.substring(0, 29) + "..."
              : artistNames,
          buttonText: "License",
          buttonLink: "#",
          discountPercent: discountPercent || "",
          costPrice: costPrice ? costPrice.toLocaleString("en-IN") : "",
          sellingPrice: sellingPrice
            ? sellingPrice.toLocaleString("en-IN")
            : "",
          name: track.name_slug,
          trackCode: track.trackCode,
          trackUrl: track.mp3Link || getDirectLink(track.link),
          waveJson: track.waveformLink,
          skuId: id || "",
          vocal: track.vocal,
        };
      });

      setTracks(mappedTracks);

      // Separate packs and playlists
      // const { likedPlaylists } = likeResponse;
      const { likedPlaylists = [] } = likeResponse.data;

      const mappedPacks = likedPlaylists
        .filter((item) => item.markerType === "pack")
        .map((pack) => {
          // console.log(pack, "liked pack");

          const { costPrice, sellingPrice, id } = pack?.SKU?.[0] || {};
          const discountPercent = costPrice
            ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}% Off`
            : ""; // Calculate discount or fallback to "0% OFF"

          return {
            title: pack.name,
            buttonText: "License",
            buttonLink: `/playlists/${pack.name_slug}/${pack.playlistCode}`,
            totalTracks: pack.tracksCount,
            packCode: pack.playlistCode,
            packNameSlug: pack.name_slug,
            sellingPrice: sellingPrice || "",
            costPrice: costPrice || "",
            discountPercent: discountPercent || "",
            skuId: id || "",
          };
        });
      // console.log(mappedPacks, "mappedPacks");

      const mappedPlaylists = likedPlaylists
        .filter((item) => item.markerType === "playlist")
        .map((playlist) => ({
          // image: `url(${
          //   config.s3imgUrl + "web/playlists/" + playlist.playlistCode + ".webp"
          // })`,
          title: playlist.name,
          buttonText: "License",
          totalTracks: playlist.tracksCount,
          packCode: playlist.playlistCode,
          packNameSlug: playlist.name_slug,
          // costPrice: 479999,
          // sellingPrice: 299999,
        }));

      // console.log(mappedPlaylists, "mappedPlaylists");
      setPackCards(mappedPacks);
      setPlaylistCards(mappedPlaylists);
    };
    fetchLikes();
  }, []);

  useEffect(() => {
    if (tracks?.length > 0) {
      setSelectedTab("Tracks");
      return;
    } else if (playlistCards?.length > 0) {
      setSelectedTab("Playlists");
      return;
    } else if (packCards?.length > 0) {
      setSelectedTab("Packs");
      return;
    } else {
      setSelectedTab("Tracks"); // Default to Tracks if no data is available
      return;
    }
  }, [packCards, playlistCards, tracks]);

  // function to handle tab swtich
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // const handleRemoveTrackLike = async (trackCode) => {
  //   // creating payload
  //   const payload = {
  //     typeCode: trackCode,
  //     type: "T",
  //     like: "N",
  //   };
  //   const dislikeTrack = await handleDislike(payload);
  //   // console.log(dislikeTrack, "track remove from likes");
  //   if (dislikeTrack.error.code === 0) {
  //     showToast("Track Likes", "Track removed", "success"); //display toast msg
  //     // Filter out the track with the given trackCode
  //     const updatedTracks = tracks.filter(
  //       (track) => track.trackCode !== trackCode
  //     );
  //     setTracks(updatedTracks);
  //   } else {
  //     showToast("Error", "Failed to update the likes", "error"); // Show error toast if API fails
  //   }
  // };

  const removePlaylist = async (packCode) => {
    // creating payload
    const payload = {
      typeCode: packCode,
      type: "L",
      like: "N",
    };
    const dislikePack = await handleDislike(payload);
    // console.log(dislikePack, "pack removed from like");
    if (dislikePack.error.code === 0) {
      showToast("Playlist Likes", "Playlist removed", "success"); //display toast msg
      setPlaylistCards((prev) =>
        prev.filter((playlist) => playlist.packCode !== packCode)
      );
    } else {
      showToast("Error", "Failed to update the likes", "error"); // Show error toast if API fails
    }
  };

  const removePack = async (packCode) => {
    // creating payload
    const payload = {
      typeCode: packCode,
      type: "P",
      like: "N",
    };
    const dislikePack = await handleDislike(payload);
    // console.log(dislikePack, "pack removed from like");
    if (dislikePack.error.code === 0) {
      showToast("Pack Likes", "Pack removed", "success"); //display toast msg
      setPackCards((prev) =>
        prev.filter((playlist) => playlist.packCode !== packCode)
      );
    } else {
      showToast("Error", "Failed to update the likes", "error"); // Show error toast if API fails
    }
  };

  const handleDislike = async (payload) => {
    const fetchData = await createUserLike(payload);
    // console.log(fetchData);
    return fetchData;
  };

  return (
    <div className={styles.likesContainer}>
      {tracks.length || playlistCards.length > 0 ? (
        <>
          <div className={styles.likesHeaderSection}>
            <h1 className={styles.containerHeading}>My Likes</h1>
            <div className={styles.containerPills}>
              {/* {["Tracks", "Playlists", "Packs"].map((tab) => ( */}
              {["Tracks", "Playlists", "Packs"].map((tab) => (
                <div
                  key={tab}
                  className={`${styles.pills} ${
                    selectedTab === tab ? styles.selectedPill : ""
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>
          {/* Content Section Based on Selected Tab */}
          <div className={styles.likesDataSection}>
            {selectedTab === "Tracks" &&
              (tracks.length > 0 ? (
                <>
                  <TrackCard trackCard={tracks} />
                </>
              ) : (
                // tracks.map((track, index) => renderTrackCard(track, index))
                <p className={styles.noDataMessage}>No tracks found.</p>
              ))}
            {selectedTab === "Playlists" &&
              (playlistCards.length > 0 ? (
                <div>
                  <PlaylistCard
                    packCards={playlistCards}
                    packType="likesplaylist"
                    showLikeIcon={true}
                    likedStatus={true}
                    handlePlaylistRemoveLike={removePlaylist}
                  />
                </div>
              ) : (
                <p className={styles.noDataMessage}>No playlists found.</p>
              ))}
            {selectedTab === "Packs" &&
              (packCards.length > 0 ? (
                <div>
                  <PackCard
                    showLikeIcon={true}
                    packCards={packCards}
                    packType="likespacks"
                    likedStatus={true}
                    handlePackRemoveLike={removePack}
                  />
                </div>
              ) : (
                <p className={styles.noDataMessage}>No packs found.</p>
              ))}
          </div>
        </>
      ) : (
        <>
          <div className={styles.likesHeaderSection}>
            <h1 className={styles.containerHeading}>My Likes</h1>
            <img
              src="https://cdn.hooprsmash.com/emptystates/no-likes.webp"
              className={styles.emptyImage}
            />
            <h3 className={styles.emptyh3}>No favourites found!</h3>
            <p className={styles.emptyHeading}>
              <br />
              You don't have any likes yet! Browse the website and start liking
              your favorite tracks & packs
            </p>
            <span className={styles.browseBtn}>
              <Link to={"/"}>
                <CustomButton
                  text="Browse"
                  backgroundColor="var(--button-primary)"
                  borderRadius="50px"
                  width="270px"
                  height="52px"
                  textColor="#fff"
                  fontSize="18px"
                  fontFamily="Raleway"
                  fontWeight="550"
                />
              </Link>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default MyLikes;
