const sections = {
  "eafd60a8-c0ab-4504-a3af-96ed441d267a": {
    name: "Advertising",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8": {
    name: "Automobile",
    sections: [
      {
        id: "lc",
        name: "Luxury Cars",
        pillName: "Luxury Cars",
      },
      {
        id: "mv",
        name: "Motovlog",
        pillName: "Motovlog",
      },
      {
        id: "cr",
        name: "Car Review",
        pillName: "Car Review",
      }
    ]
  },
  "c63cbc85-930c-40bd-8e8c-84f252c4f7f6": {
    name: "Background Scores",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "5a1e36b9-614b-4540-a81c-6ad79db245b0": {
    name: "Beauty & Skincare",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "ed70513b-a743-42ec-95a5-248c60e13800": {
    name: "Bumpers",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "64538398-f88b-431b-a63c-b79971fbed6a": {
    name: "Comedy",
    sections: [
      {
        id: "sm",
        name: "Social Media & Meme",
        pillName: "Social Media & Meme",
      },
      {
        id: "sk",
        name: "Sketch",
        pillName: "Sketch",
      },
      {
        id: "su",
        name: "Stand Up",
        pillName: "Stand Up",
      },
      {
        id: "ps",
        name: "Parody & Spoof",
        pillName: "Parody & Spoof",
      }
    ],
  },
  "1b708e79-bf38-476f-abbe-cf18fddd43cc": {
    name: "Cooking",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "0e01f907-a8cd-4e71-9d73-81097fe21789": {
    name: "Corporate",
    sections: [
      {
        id: "br",
        name: "Branding",
        pillName: "Branding",
      },
      {
        id: "ad",
        name: "Advertising",
        pillName: "Advertising",
      },
      {
        id: "pl",
        name: "Product Launches",
        pillName: "Product Launches",
      },
      {
        id: "an",
        name: "Announcements",
        pillName: "Announcements",
      }
    ],
  },
  "3c48a7fb-291e-4db9-bee0-526d36472585": {
    name: "Devotional",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "e20706f1-c89e-4500-9662-c68fdfa5a532": {
    name: "Ed-Tech",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "4d12fd79-69b5-4482-9a7d-61cfe521439c": {
    name: "Fashion",
    sections: [
      {
        id: "tr",
        name: "Trending Tracks",
        pillName: "Trending",
      },
      {
        id: "gr",
        name: "GRWM Tracks",
        pillName: "GRWM",
      },
      {
        id: "lx",
        name: "Luxury/Designer Tracks",
        pillName: "Luxury/Designer",
      },
      {
        id: "et",
        name: "Ethnic/Traditional Tracks",
        pillName: "Ethnic/Traditional",
      },
    ],
  },
  "8c1b166d-087c-40ac-bd92-6bce898d3488": {
    name: "Festive",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "9f01440b-5e9a-4252-ba8e-b2b63f6fc185": {
    name: "Film & OTT Content",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "d096a310-adf6-4974-8ff6-3ca8e5d0e55c": {
    name: "Food",
    sections: [
      {
        id: "st",
        name: "Street Cuisine",
        pillName: "Street Cuisine",
      },
      {
        id: "cf",
        name: "Cultural and Festive Cuisine",
        pillName: "Cultural and Festive Cuisine"
      },
      {
        id: "ct",
        name: "Cooking Tutorials",
        pillName: "Cooking Tutorials"
      },
      {
        id: "hc",
        name: "Home Cooked",
        pillName: "Home Cooked"
      },
      {
        id: "fd",
        name: "Fine Dining",
        pillName: "Fine Dining"
      }
    ]
  },
  "0ea970e0-a57e-40c9-b46e-2d04a74c20ab": {
    name: "Gaming",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "26f05bb3-c7a1-4409-a28e-6f9003c976d5": {
    name: "Global Occasions",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "4f4624b6-9f8a-458d-82d1-77a844691856": {
    name: "Health & Fitness",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "b8e9fb8f-69fc-40c8-b1d0-09f6f81e92fa": {
    name: "Indian Festivals",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "11334cec-2010-4885-bc35-f0f995d42701": {
    name: "Intros & Outros",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "1d785d09-f059-4952-98eb-9c21d3864890": {
    name: "Jingles",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "f872e350-5430-46a1-aca0-af292a9c13b9": {
    name: "Kids",
    sections: [
      {
        id: "fs",
        name: "Fashion",
        pillName: "Fashion",
      },
      {
        id: "fn",
        name: "Fun",
        pillName: "Fun",
      },
      {
        id: "ex",
        name: "Experience",
        pillName: "Experience",
      }
    ],
  },
  "3446ca31-c19c-4eab-8c97-a0b6bcf8369b": {
    name: "Learning",
    sections: [
      {
        id: "er",
        name: "Events Recap",
        pillName: "Events Recap",
      },
      {
        id: "mr",
        name: "Memes & Relatable",
        pillName: "Memes & Relatable",
      },
      {
        id: "mt",
        name: "Motivational",
        pillName: "Motivational",
      },
      {
        id: "fc",
        name: "Funny Content",
        pillName: "Funny Content",
      },
      {
        id: "ig",
        name: "Inspiring Groovy",
        pillName: "Inspiring Groovy",
      }
    ],
  },
  "f909fd19-7d11-4bb8-9ef0-33a1852b2278": {
    name: "Meditation",
    sections: [
      {
        id: "gm",
        name: "Guided",
        pillName: "Guided",
      },
      {
        id: "mr",
        name: "Mindfulness and Relaxation",
        pillName: "Mindfulness and Relaxation",
      },
      {
        id: "yb",
        name: "Yoga & Breathwork",
        pillName: "Yoga & Breathwork",
      },
      {
        id: "ch",
        name: "Chakra Healing",
        pillName: "Chakra Healing",
      }
    ],
  },
  "953971e2-fd76-416d-b570-c0ab566302c1": {
    name: "Movies",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "3f0ff233-984f-4ebb-a2f2-982e4b7472f1": {
    name: "NGO & Non-Profits",
    sections: [
      {
        id: "ph",
        name: "Philanthropy",
        pillName: "Philanthropy",
      },
      {
        id: "ev",
        name: "Events",
        pillName: "Events",
      },
    ]
  },
  "ba2205b8-c398-4e70-b65c-0f5df5a75adc": {
    name: "Patriotic",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "84a88317-f88c-4432-85df-5ab81e8a1588": {
    name: "Podcasts & Audiobooks",
    sections: [
      {
        id: "tc",
        name: "True Crime",
        pillName: "True Crime",
      },
      {
        id: "cm",
        name: "Comedy",
        pillName: "Comedy",
      },
      {
        id: "np",
        name: "News & Politics",
        pillName: "News & Politics",
      },
      {
        id: "sw",
        name: "Self-Improvement & Wellness",
        pillName: "Self-Improvement & Wellness",
      },
      {
        id: "be",
        name: "Business & Entrepreneurship",
        pillName: "Business & Entrepreneurship",
      }
    ],
  },
  "264c7ea7-fd2b-4485-9957-58a57f09e54d": {
    name: "Promos & Trailers",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "c5cbe26e-074b-447e-bf9f-256055854732": {
    name: "Shorts & Reels",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "538f6a59-7a05-46f9-8abf-d78a915549a1": {
    name: "Shows",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "21b41ad8-b66c-4a79-bd24-4485db9c7dbc": {
    name: "Sports",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "33b2bf23-f5b5-4398-aa51-7046a2922691": {
    name: "Travel",
    sections: [
      {
        id: "wl",
        name: "Wildlife",
        pillName: "Wildlife",
      },
      {
        id: "cr",
        name: "Cultural / Regional",
        pillName: "Cultural / Regional",
      },
      {
        id: "ad",
        name: "Adventure",
        pillName: "Adventure",
      },
      {
        id: "lx",
        name: "Luxury",
        pillName: "Luxury",
      },
      {
        id: "sl",
        name: "Solo",
        pillName: "Solo",
      },
      {
        id: "ar",
        name: "Accommodation Reviews",
        pillName: "Accommodation Reviews",
      },
      {
        id: "ct",
        name: "Couple Travel",
        pillName: "Couple Travel",
      }
    ],
  },
  "4f95ba81-8a7d-453d-8a5d-325ca9b57feb": {
    name: "Unboxing & Reviews",
    sections: [
      {
        id: "tr",
        name: "Trending & Popular",
      },
      {
        id: "lx",
        name: "Luxe",
      },
      {
        id: "et",
        name: "Ethnic",
      },
    ],
  },
  "7f0b6aac-1d3e-4262-8063-52eb57e3430c": {
    name: "Wedding",
    sections: [
      {
        id: "fs",
        name: "Fun Songs",
        pillName: "Fun Songs",
      },
      {
        id: "cr",
        name: "Clips/Reels",
        pillName: "Clips/Reels",
      },
      {
        id: "br",
        name: "Baaraat",
        pillName: "Baaraat",
      },
      {
        id: "oa",
        name: "Outfits & Accessories",
        pillName: "Outfits & Accessories",
      },
    ],
  },
};

const secContent = {
  "4d12fd79-69b5-4482-9a7d-61cfe521439c": {
    tr: [
      {
        type: "T",
        codes: ["56", "74", "103", "60", "69"],
      }
    ],
    gr: [
      {
        type: "T",
        codes: ["55", "253", "98", "197", "659"],
      },
    ],
    lx: [
      {
        type: "T",
        codes: ["109", "96", "255", "5321", "61"],
      }
    ],
    et: [
      {
        type: "T",
        codes: ["66", "472", "258", "2345", "2952"],
      }
    ],
  },
  "d096a310-adf6-4974-8ff6-3ca8e5d0e55c": {
    st: [
      {
        type: "T",
        codes: ["2613", "2973", "691", "2372", "6234"],
      }
    ],
    cf: [
      {
        type: "T",
        codes: ["95", "2561", "307", "70", "2175"],
      },
    ],
    ct: [
      {
        type: "T",
        codes: ["2461", "3057", "1172", "2623", "1488"],
      }
    ],
    hc: [
      {
        type: "T",
        codes: ["53", "66", "656", "87", "2209"],
      }
    ],
    fd: [
      {
        type: "T",
        codes: ["3193", "2766", "1049", "3033", "2214"],
      }
    ],
  },
  "33b2bf23-f5b5-4398-aa51-7046a2922691": {
    wl: [
      {
        type: "T",
        codes: ["2857",	"2565",	"1098",	"2360",	"1827"],
      }
    ],
    cr: [
      {
        type: "T",
        codes: ["2345",	"2585",	"172",	"2922",	"584"],
      },
    ],
    ad: [
      {
        type: "T",
        codes: ["2465",	"140",	"1207",	"3184",	"1725"],
      }
    ],
    lx: [
      {
        type: "T",
        codes: ["2508",	"59",	"229",	"50",	"1384"],
      }
    ],
    sl: [
      {
        type: "T",
        codes: ["97",	"117",	"188",	"81",	"5164"],
      }
    ],
    ar: [
      {
        type: "T",
        codes: ["95",	"84",	"716",	"86",	"1487"],
      }
    ],
    ct: [
      {
        type: "T",
        codes: ["55",	"98",	"159",	"107",	"5630"],
      }
    ],
  },
  "7f0b6aac-1d3e-4262-8063-52eb57e3430c": {
    fs: [
      {
        type: "T",
        codes: ["2530", "92", "2428", "3095", "5681"],
      }
    ],
    cr: [
      {
        type: "T",
        codes: ["2892", "2671", "387", "3170", "509"],
      },
    ],
    br: [
      {
        type: "T",
        codes: ["2386", "2355", "3465", "2397", "847"],
      }
    ],
    oa: [
      {
        type: "T",
        codes: ["121", "3031", "612", "2380", "607"],
      }
    ],
  },
  "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8": {
    lc: [
      {
        type: "T",
        codes: ["2644", "827", "2340", "1852", "2508"],
      }
    ],
    mv: [
      {
        type: "T",
        codes: ["140", "261", "481", "3268", "2907"],
      },
    ],
    cr: [
      {
        type: "T",
        codes: ["2500", "2999", "5142", "3238", "1563"],
      }
    ]
  },
  "64538398-f88b-431b-a63c-b79971fbed6a": {
    sm: [
      {
        type: "T",
        codes: ["2452", "137", "547", "2367", "668"],
      }
    ],
    sk: [
      {
        type: "T",
        codes: ["2790", "669", "2817", "2767", "1124"],
      },
    ],
    su: [
      {
        type: "T",
        codes: ["120", "1116", "5146", "124", "91"],
      }
    ],
    ps: [
      {
        type: "T",
        codes: ["2350", "69", "1413", "95", "136"],
      }
    ]
  },
  "0e01f907-a8cd-4e71-9d73-81097fe21789": {
    br: [
      {
        type: "T",
        codes: ["71", "5654", "129", "1564", "122"],
      }
    ],
    ad: [
      {
        type: "T",
        codes: ["2923", "2786", "1565", "2347", "986"],
      },
    ],
    pl: [
      {
        type: "T",
        codes: ["2698", "2004", "963", "3325", "132"],
      }
    ],
    an: [
      {
        type: "T",
        codes: ["2852", "5358", "2360", "949", "2855"],
      }
    ]
  },
  "f872e350-5430-46a1-aca0-af292a9c13b9": {
    fs: [
      {
        type: "T",
        codes: ["110", "2143", "2451", "2758", "5527"],
      }
    ],
    fn: [
      {
        type: "T",
        codes: ["3178", "3028", "1662", "142", "1309"],
      },
    ],
    ex: [
      {
        type: "T",
        codes: ["2545", "2145", "2943", "2142", "2589"],
      }
    ]
  },
  "3446ca31-c19c-4eab-8c97-a0b6bcf8369b": {
    er: [
      {
        type: "T",
        codes: ["2527", "5654", "3325", "5630", "2680"],
      }
    ],
    mr: [
      {
        type: "T",
        codes: ["2813", "1918", "2452", "3088", "2107"],
      },
    ],
    mt: [
      {
        type: "T",
        codes: ["143", "647", "2827", "5663", "2360"],
      }
    ],
    fc: [
      {
        type: "T",
        codes: ["2367", "1912", "124", "1911", "120"],
      }
    ],
    ig: [
      {
        type: "T",
        codes: ["2851", "1169", "1018", "3184", "2852"],
      }
    ]
  },
  "f909fd19-7d11-4bb8-9ef0-33a1852b2278": {
    gm: [
      {
        type: "T",
        codes: ["2936", "3251", "643", "2461", "2480"],
      }
    ],
    mr: [
      {
        type: "T",
        codes: ["2623", "1120", "3033", "685", "3262"],
      },
    ],
    yb: [
      {
        type: "T",
        codes: ["2659", "3004", "1157", "5417", "2578"],
      }
    ],
    ch: [
      {
        type: "T",
        codes: ["2345", "3252", "1186", "3119", "5421"],
      }
    ]
  },
  "3f0ff233-984f-4ebb-a2f2-982e4b7472f1": {
    ph: [
      {
        type: "T",
        codes: ["3099", "2545", "5627", "3079", "5302"],
      }
    ],
    ev: [
      {
        type: "T",
        codes: ["3004", "2807", "5659", "5607", "2692"],
      },
    ]
  },
  "84a88317-f88c-4432-85df-5ab81e8a1588": {
    tc: [
      {
        type: "T",
        codes: ["2842", "2879", "672", "2476", "936"],
      }
    ],
    cm: [
      {
        type: "T",
        codes: ["131", "1282", "441", "2367", "2637"],
      },
    ],
    np: [
      {
        type: "T",
        codes: ["3203", "5563", "3093", "2093", "3232"],
      }
    ],
    sw: [
      {
        type: "T",
        codes: ["132", "1623", "2680", "2827", "1314"],
      }
    ],
    be: [
      {
        type: "T",
        codes: ["2527", "5659", "2855", "3093", "2531"],
      }
    ]
  }
};

export { sections, secContent };