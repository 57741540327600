import React, { useState, useEffect } from "react";
import "../styles/PackListing.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import AllPackCard from "../components/CardsComponent/PackCard/AllPackCard";
// import RandomDefaultImage from "../utils/RandomDefaultImage";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";
import setMetaTags from "../utils/SeoService";
import Carousel from "../components/Carousel/Carousel";

const PackListing = () => {
  useEffect(() => {
    const seoData = {
      title: "Pack Based Music Licensing | Hoopr Smash",
      description:
        "Browse Hoopr Smash's collection of premium music packs, carefully curated and categorized by mood, genre, and budget, making it easy to find the right sound for your creative work.",
      ogImage: `${config.s3imgUrl}web/promotional/packs/banner1.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  const offerCards = [
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner1.webp`,
      title: "Trendsetters",
      description:
        "Fresh tracks, trending vibes! Explore the latest hits from Bollywood's finest. License premium tracks for your next project.",
      buttonText: "License Pack",
      packCode: 12,
      packNameSlug: "trendsetters",
      titleImg: `${config.s3imgUrl}web/promotional/packs/trends.png`,
    },
    {
      image: `${config.s3imgUrl}web/promotional/packs/banner2.webp`,
      title: "Where’s the Party?",
      description:
        "Make your year-end celebrations unforgettable. License the perfect track for every occasion.",
      buttonText: "License Pack",
      packCode: 7,
      packNameSlug: "wheres-the-party",
      titleImg: `${config.s3imgUrl}web/promotional/packs/party.png`,
    },
  ];

  // 72, 17, 18, 59, 62, 37

  const packCards = [
    {
      image: `${config.s3imgUrl}web/playlists/72.webp`,
      title: "Travel - Incredible India",
      buttonText: "License",
      buttonLink: "/playlists/pack-travel-incredible-india/72",
      totalTracks: 5,
      sellingPrice: "61999",
      costPrice: "69995",
      discountPercent: `${(((69995 - 61999) / 69995) * 100).toFixed(0)}% Off`,
      packCode: 72,
      packNameSlug: "travel-incredible-india",
      skuId: "cn612nNRwyQGTILwdvs",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/17.webp`,
      title: "Pulse Racers",
      buttonText: "License",
      buttonLink: "/playlists/pack-pulse-racers/17",
      totalTracks: 5,
      sellingPrice: "89999",
      costPrice: "109994",
      discountPercent: `${(((109994 - 89999) / 109994) * 100).toFixed(0)}% Off`,
      packCode: 17,
      packNameSlug: "pulse-racers",
      skuId: "YmevKisChpssmEe3Anvq",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/18.webp`,
      title: "Boss Brands",
      buttonText: "License",
      buttonLink: "/playlists/pack-boss-brands/18",
      totalTracks: 5,
      sellingPrice: "68999",
      costPrice: "76995",
      discountPercent: `${(((76995 - 68999) / 76995) * 100).toFixed(0)}% Off`,
      packCode: 18,
      packNameSlug: "boss-brands",
      skuId: "3h4LE096NunLPLwi9249",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/59.webp`,
      title: "Cooking - Ethnic",
      buttonText: "License",
      buttonLink: "/playlists/pack-cooking-ethnic/59",
      totalTracks: 5,
      sellingPrice: "2649",
      costPrice: "2995",
      discountPercent: `${(((2995 - 2649) / 2995) * 100).toFixed(0)}% Off`,
      packCode: 59,
      packNameSlug: "cooking-ethnic",
      skuId: "etedlgyCpmFr5ecrVV3",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/62.webp`,
      title: "Fashion - Western Wear",
      buttonText: "License",
      buttonLink: "/playlists/pack-fashion-western-wear/62",
      totalTracks: 6,
      sellingPrice: "74999",
      costPrice: "91994",
      discountPercent: `${(((91994 - 74999) / 91994) * 100).toFixed(0)}% Off`,
      packCode: 62,
      packNameSlug: "fashion-western-wear",
      skuId: "Hy5WzAxm9p8qAWfFrkU2",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/37.webp`,
      title: "Wedding - Bollywood",
      buttonText: "License",
      buttonLink: "/playlists/pack-wedding-bollywood/37",
      totalTracks: 5,
      sellingPrice: "62896",
      costPrice: "73995",
      discountPercent: `${(((73995 - 62896) / 73995) * 100).toFixed(0)}% Off`,
      packCode: 37,
      packNameSlug: "wedding-bollywood",
      skuId: "oAq0PTZKWD5YAhgDmjm",
      type: "pack",
      description:
        "A romantic playlist filled with sweet, soulful tracks. Perfect for love stories, engagement vlogs, wedding videos, anniversary content, or any project that captures the magic, warmth, and beauty of love.",
    },
  ];

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <div className="pl-innerBreadcrumb">
          <Link to={"/"}>Home</Link> /&nbsp;
          <span>Packs</span>
        </div>
        <h1>Shop by Packs</h1>
        <h2>
          Discover a collection of the perfect tracks for your project. Browse
          our extensive library of music packs, categorized by occasion and use
          cases. Whether you're creating a commercial, a social media post, or a
          full-length film, seamlessly license the music you need for your
          content.
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${offer.image})`,
            }}
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              <h1>
                <CustomImg
                  type="playlist"
                  id="party_title"
                  alt="Where's the Party?"
                  imgClass=""
                  src={offer.titleImg}
                />
              </h1>
            </div>
            {/* {/* <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div> */}
            <Link
              to={`/playlists/pack-${encodeURIComponent(offer.packNameSlug)}/${
                offer.packCode
              }`}
            >
              <div>
                <CustomButton
                  text={offer.buttonText}
                  backgroundColor="#fff"
                  borderRadius="30px"
                  borderColor="none"
                  width="182px"
                  height="37px"
                  textColor="var(--button-primary)"
                  iconColor="var(--button-primary)"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="bold"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <div className="packs-container outer-full">
        <div className="inner inner-pl">
          <h1 className="packs-container-heading">Explore Popular Packs</h1>
          <div className="packs-description-view">
            <h2>Multi-track packs for all occasions at great savings!</h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner"></div>
          <div className="inner">
            {windowWidth <= 481 ? (
              <Carousel gap={20} autoScroll={false} scrollInterval={4000}>
                {packCards.map((pack, index) => (
                  <PackCard key={index} packCards={[pack]} packType="pop" />
                ))}{" "}
              </Carousel>
            ) : (
              <PackCard packType="pop" packCards={packCards} />
            )}
          </div>
        </div>
      </div>
      {/* all packs section */}
      <div style={{ marginTop: "10px" }}>
        <AllPackCard viewall={false} />
      </div>
    </div>
  );
};

export default PackListing;
