import React from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/MyAccountCss/MyAccount.module.css";

const MyAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Tabs array with their corresponding routes
  const tabs = [
    { name: "Profile", path: "profile" },
    { name: "Purchases", path: "purchases" },
    { name: "Likes", path: "likes" },
    { name: "Collections", path: "collection" },
  ];

  // Get the active tab based on the current route
  const activeTab = tabs.find((tab) =>
    location.pathname.includes(tab.path)
  )?.path;

  return (
    <div className={styles.myAccountContainer}>
      {/* Left Navigation Bar */}
      <div className={styles.leftNav}>
        <h2 className={styles.navHeading}>My Account</h2>
        <hr className={styles.breakLine}></hr>
        <ul className={styles.tabsList}>
          {tabs.map((tab) => (
            <li
              key={tab.path}
              className={`${styles.tabItem} ${
                activeTab === tab.path ? styles.activeTab : ""
              }`}
              onClick={() => navigate(`/user/${tab.path}`)}
            >
              {tab.name}
            </li>
          ))}
          <a href="/faqs" target="_blank">
            <li key="faqs" className={`${styles.tabItem}`}>FAQs</li>
          </a>
        </ul>
      </div>

      {/* Right Content Area - Render Child Routes with Outlet */}
      <div className={styles.rightContent}>
        <Outlet /> {/* This renders the child routes */}
      </div>
    </div>
  );
};

export default MyAccount;
