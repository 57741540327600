import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "../styles/CategoryPage.module.css";
import categoryInfo from "../utils/data/categories";
import { useParams, Link } from "react-router-dom";
import { FaLink } from "react-icons/fa6";
import CustomButton from "../components/CustomButton/CustomButton";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { getSearchResultFaceted } from "../service/SearchService";
import getDirectLink from "../utils/AudioUrlFormated";
import setMetaTags from "../utils/SeoService";
import config from "../utils/config";
import { useSelector } from "react-redux";
import { TbFilterCheck } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import TrackFilter from "../components/CardsComponent/TrackCard/TrackFilter";
import CleanSearchKey from "../utils/CleanSearchKey";
import { useNavigate, useLocation } from "react-router-dom";
import priceFilter from "../utils/FilterBase64";
import extractColors from "../utils/ExtractColors";
import { useHandleShareClick } from "../utils/Share";
import { sections, secContent } from "../utils/data/progCat";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import { getMultiTrack } from "../service/TrackService";
import TrendingCard from "../components/CardsComponent/TrackCard/TrendingCard";

const CatPage = () => {
  const [page, setPage] = useState(1); // Dynamic page number
  const [results, setResults] = useState(null); // Store fetched results
  const [isFetching, setIsFetching] = useState(false); // Loading state
  const [showViewMore, setShowViewMore] = useState(false); // Control View More button visibility
  const [noDataFound, setNoDataFound] = useState(false); // State for "No data found"
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [filters, setFilters] = useState({}); // state to manage the filters values
  const [sortBy, setSortBy] = useState({ type: "relevance", order: "" });
  const [priceFilters, setPriceFilters] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [backgroundGradient, setBackgroundGradient] = useState("");
  const [categorySections, setCategorySections] = useState([]); // state to manage the category sections which has the id and names to be displayed
  const [categoryContent, setCategoryContent] = useState([]); // data to be displayed for each category section
  const [categoryTracks, setCategoryTracks] = useState([]); // tracks to be displayed for each category section
  const { name, categoryCode } = useParams(); // accesing value from the url

  const navigate = useNavigate();
  const location = useLocation();

  const { handleShareClick } = useHandleShareClick(); // Use the custom hook for the share icon

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  const categoryData = categoryInfo[categoryCode];

  // console.log(categoryData, "categoryData");

  useEffect(() => {
    if (!categoryData || categoryData === undefined || categoryData === null) {
      navigate("/404");
      return;
    }
    if (name !== categoryData?.name) {
      // console.log(true);
      navigate(
        `/use-cases/${CleanSearchKey(categoryData?.name)}/${categoryCode}`,
        { replace: true }
      );
    }
  }, [name, categoryData]);

  // load the filters if there on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedFilters = searchParams.get("filters");
    const encodedPriceFilters = searchParams.get("priceFilters");
    const encodedSortBy = searchParams.get("sortBy") || "owner";

    if (encodedFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedFilters)); // Decode Base64
        if (decodedFilters) {
          setFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedPriceFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedPriceFilters)); // Decode Base64
        if (decodedFilters) {
          setPriceFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedSortBy) {
      try {
        const decodedFilters = JSON.parse(atob(encodedSortBy)); // Decode Base64
        if (decodedFilters) {
          setSortBy(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
  }, [location.search]);

  useEffect(() => {
    const seoData = {
      title: `${categoryData?.name} Songs | Music Licensing | Hoopr Smash`,
      description: `License ${categoryData?.name} songs with Hoopr Smash. Royalty-free ${categoryData?.name} music to enhance creativity and add depth to diverse projects.`,
      ogImage: `${config.s3imgUrl}web/cats/${categoryCode}.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, [categoryCode, categoryData?.name]);

  // state for preventing the multiple api calls
  const isFetchingRef = useRef(false);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  const fetchResults = useCallback(
    async (pageNumber) => {
      // console.log("call");
      // console.log(filters, "filter val");

      try {
        setIsFetching(true);
        setNoDataFound(false); // Reset "No data found" state before fetching
        // Construct the payload with dynamic filters
        const payload = {
          asset_type: "music",
          artists: [], // If no artists, use empty array
          bpm: { min: 1, max: 300 },
          price: { min: priceFilters?.min || "", max: priceFilters?.max || "" },
          genres:
            filters?.genres?.map((item) => CleanSearchKey(item.label)) || [],
          languages:
            filters?.languages?.map((item) => CleanSearchKey(item.label)) || [],
          moods:
            filters?.moods?.map((item) => CleanSearchKey(item.label)) || [],
          playlists: [],
          sfxcategories: [],
          sfxsubcategories: [],
          subgenres: filters?.subgenres || [],
          usecases:
            [CleanSearchKey(categoryData?.name)] ||
            filters?.useCases?.map((item) => CleanSearchKey(item.label)) ||
            [],
          instruments:
            filters?.instruments?.map((item) => CleanSearchKey(item.label)) ||
            [],
          ...(filters?.vocals ? { vocal: [filters.vocals] } : {}), // Conditionally add 'vocal' only if it exists
        };

        // Check and update sortby value if type is "relevance"
        let sortby = sortBy.type || "";

        if (sortby === "relevance") {
          sortby = "owner";
        }

        const order = sortBy.order || "";
        const searchKeyword = "";

        const data = await getSearchResultFaceted(
          searchKeyword,
          payload,
          pageNumber,
          sortby,
          order
        );
        const trackData = data.filter((item) => item.type === "music");
        // console.log(trackData, "trackData");

        if (trackData.length === 0) {
          if (pageNumber === 1) {
            setNoDataFound(true);
          }
          setShowViewMore(false); // Hide view more button
          return false; // Stop further API calls
        } else if (trackData.length > 0) {
          // Filter out duplicates by track_code
          setResults((prevResults) => {
            const existingTrackCodes = new Set(
              prevResults?.map((track) => track.track_code) || []
            );
            const uniqueTracks = trackData.filter(
              (track) => !existingTrackCodes.has(track.track_code)
            );
            return prevResults
              ? [...prevResults, ...uniqueTracks]
              : uniqueTracks;
          });
          return true; // Continue fetching
        } else {
          setNoDataFound(true); // Set when no data found
          setShowViewMore(false); // Hide view more button
          return false;
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setNoDataFound(true); // Set when no data found
        setShowViewMore(false); // Hide view more button
        return false;
      } finally {
        setIsFetching(false);
      }
    },
    [filters, priceFilters, sortBy, categoryData?.name]
  );

  // Function to auto-fetch 4 pages asynchronously
  const autoFetch4Pages = useCallback(
    async (startPage) => {
      setIsFetching(true);

      let allPagesFetched = true;

      for (let i = startPage; i < startPage + 4; i++) {
        const hasData = await fetchResults(i);
        if (!hasData) {
          allPagesFetched = false;
          break;
        }
      }

      setIsFetching(false);
      if (allPagesFetched) {
        setShowViewMore(true);
        setPage(startPage + 4);
      }
    },
    [fetchResults]
  );

  // main use effect to call the api on changes
  useEffect(() => {
    if (filtersLoaded && !isFetchingRef.current) {
      isFetchingRef.current = true;
      setPage(1);
      setResults([]);
      setNoDataFound(false);
      setShowViewMore(false);
      autoFetch4Pages(1).then(() => {
        isFetchingRef.current = false;
      });
    }
  }, [
    categoryCode,
    priceFilters,
    filters,
    sortBy,
    filtersLoaded,
    autoFetch4Pages,
  ]); // This effect runs whenever the searchKeyword and filters changes

  // function to fetch the category data according to the code
  const fetchCategoryData = (categoryCode) => {
    // console.log("fetchCategoryData called with:", { categoryCode });

    if (sections[categoryCode]) {
      // console.log("Category found:", sections[categoryCode]);
      const categorySections = sections[categoryCode].sections;
      // console.log("categorySections:", categorySections);

      if (secContent[categoryCode]) {
        const allSecContent = secContent[categoryCode];
        // console.log("allSecContent:", allSecContent);

        // Create a new object with only the first item of each array
        const firstItemsContent = {};
        for (const key in allSecContent) {
          if (allSecContent[key].length > 0) {
            firstItemsContent[key] = [allSecContent[key][0]];
          }
        }

        // console.log("firstItemsContent:", firstItemsContent);
        // Check if any of the first items have a codes array
        const hasCodes = Object.values(firstItemsContent).some(
          (item) => item[0].codes && item[0].codes.length > 0
        );

        if (hasCodes) {
          // Set the category sections and content
          setCategorySections(categorySections);
          setCategoryContent(firstItemsContent);
        } else {
          setCategoryContent([]);
          setCategorySections([]);
          setCategoryTracks([]);
        }
      } else {
        setCategoryContent([]);
        setCategorySections([]);
        setCategoryTracks([]);
        // console.log("No content found for categoryCode:", categoryCode);
      }
    } else {
      // console.log("No category found for code:", categoryCode);
      setCategoryContent([]);
      setCategorySections([]);
      setCategoryTracks([]);
    }
  };
  // Load category section data on component mount and when categoryCode changes
  useEffect(() => {
    // const type = "T"; // type you want to use (T, P, or L)
    fetchCategoryData(categoryCode);
  }, [categoryCode]);

  // function to load more track on click of view more btn
  const handleViewMoreClick = () => {
    autoFetch4Pages(page);
  };

  // formatting the track cards
  const allTrackList =
    results?.map((track) => {
      const trackSKu = track.sku[0];
      // console.log(trackSKu);
      const parsedSKU = JSON.parse(trackSKu);

      // const SKU = [
      //   {
      //     costPrice: 69999,
      //     sellingPrice: 49999,
      //     gstPercent: 18,
      //     maxUsage: 3,
      //   },
      // ];

      const SKU = [
        {
          costPrice: parsedSKU.cost_price,
          sellingPrice: parsedSKU.selling_price,
          gstPercent: parsedSKU.gst_percent,
          maxUsage: parsedSKU.max_usage,
          id: parsedSKU.id,
        },
      ];

      // Extract costPrice and sellingPrice
      const { costPrice, sellingPrice, id } = SKU[0];

      // Calculate discount percentage
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", ");

      return {
        image:
          track.image_url !== "" && track.image_url != null
            ? track.image_url
            : randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "License",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString() || "",
        sellingPrice: sellingPrice.toLocaleString() || "",
        name: track.name_slug,
        trackCode: track.track_code,
        waveJson: track.waveform_link,
        trackUrl:
          track.mp3_link || getDirectLink(track.link) || track.waveform_link,
        skuId: id || "",
        vocal: track.vocal,
      };
    }) || [];

  // console.log(allTrackList, "allTrackList");

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  // function to close the filter modal
  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // default filters fucntion
  const handleFiltersSelected = (filters) => {
    // console.log("Updated Filters:", filters);
    setFilters(filters);
    const filtersString = JSON.stringify(filters);
    const encodedFilters = btoa(filtersString); // Encode to Base64

    // Update URL with filters
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", encodedFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // price filter fucntion
  const handlePriceFilterChange = (priceFilters) => {
    setPriceFilters(priceFilters);

    const encodedPriceFilters = priceFilter(priceFilters); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("priceFilters", encodedPriceFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // sort by filter fucntion
  const handleSortByChange = (e) => {
    const [type, order] = e.target.value.split("-");
    const newSortBy = { type, order: order || "" };

    setSortBy(newSortBy);

    const sortByString = JSON.stringify(newSortBy);
    const encodedSortBy = btoa(sortByString); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortBy", encodedSortBy);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // clear all filters function
  const handleClearFilter = () => {
    // Remove filter parameters from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("filters");
    searchParams.delete("priceFilters");
    searchParams.delete("sortBy");

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    setFilters({});
    setSortBy({ type: "relevance", order: "" });
    setPriceFilters({});
  };

  // console.log("Global Player Active : ", isGlobalPlayerActive); // Log the value to main the modal popup above the player
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  // Check if any filters are active for mainting active and non active icon on filters
  const hasActiveFilters =
    Object.values(filters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    ) || sortBy.type !== "relevance";

  // function to fetch the gradient color
  useEffect(() => {
    const fetchGradient = async () => {
      try {
        const gradient = await extractColors(
          `${config.s3imgUrl}web/cats/${categoryCode}.webp`
        );
        setBackgroundGradient(gradient);
      } catch (error) {
        console.error("Error fetching gradient:", error);
      }
    };

    fetchGradient();
  }, [categoryCode]);

  // calling api the function when the trackCode changes to get the tracks based on categories
  useEffect(() => {
    // console.log(categoryContent, "categoryContent");

    const fetchData = async () => {
      try {
        const promises = [];

        for (const key in categoryContent) {
          const section = categoryContent[key];
          if (section.length > 0 && section[0].type === "T") {
            const codes = section[0].codes;
            promises.push(
              getMultiTrack(codes).then((response) => ({
                key,
                data: response.data,
              }))
            );
          }
        }

        const results = await Promise.all(promises);

        const newResults = {};
        results.forEach(({ key, data }) => {
          if (data.tracks && data.tracks.length > 0) {
            newResults[key] = data.tracks.map((track) => {
              const { costPrice, sellingPrice, id } = track.SKU[0];
              const discountPercent = (
                ((costPrice - sellingPrice) / costPrice) *
                100
              ).toFixed(0);

              const artistNames = track.primaryArtists?.length
                ? track.primaryArtists
                    .map((artist) => artist.artistName)
                    .join(", ")
                : "";

              return {
                title:
                  track.name.length > 18
                    ? track.name.substring(0, 17) + "..."
                    : track.name,
                description:
                  artistNames.length > 30
                    ? artistNames.substring(0, 29) + "..."
                    : artistNames,
                buttonText: "License",
                buttonLink: "#",
                discountPercent: discountPercent || "50% OFF",
                costPrice: costPrice.toLocaleString("en-IN") || "",
                sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
                name: track.name_slug,
                trackCode: track.trackCode,
                trackUrl: track.mp3Link || getDirectLink(track.link),
                waveJson: track.waveformLink,
                skuId: id || "",
                vocal: track.vocal,
              };
            });
          }
        });

        // console.log(newResults, "newResults");

        setCategoryTracks(newResults);
      } catch (error) {
        console.error("Error fetching track data:", error);
      }
    };

    fetchData();
  }, [categoryContent, categoryCode]);

  // console.log(categoryTracks, "categoryTracks");

  return (
    <div className={styles.container}>
      {/* Rotated Button */}
      <div className={styles.rotateButton} onClick={toggleModal}>
        {hasActiveFilters ? (
          <div className={styles.filterActiveContainer}>
            <TbFilterCheck
              size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
              className={styles.filterActive}
            />
          </div>
        ) : (
          <FaFilter
            size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
            className={styles.filterIcon}
          />
        )}
        <span>Sort & Filters</span>
      </div>
      {/* fiter Modal */}
      {isModalOpen && (
        <div className={overlayClass} onClick={handleOverlayClick}>
          <div
            className={styles.modalContainer}
            style={{
              margin: "0 auto", // Center the modal horizontally
              left: "0", // Align to the left if needed
              right: "unset", // Ensure no conflicting right alignment
            }}
          >
            {/* header section */}
            <div className={styles.header}>
              <div className={styles.heading}>
                <p className={styles.headingtext}>Sort & Filters</p>
              </div>
              <IoIosClose
                onClick={closeModal}
                color="#000"
                cursor="pointer"
                size={34}
              />
            </div>
            <br></br>
            <div className={styles.filterWapper}>
              {/* Sort By Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Sort By</p>
                </div>
                <hr style={{ color: "#555" }} />
                <div className={styles.sortOptions}>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="relevance"
                      checked={sortBy.type === "relevance"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Relevance
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-asc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "asc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (low to high)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-desc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (high to low)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-asc"
                      checked={sortBy.type === "name" && sortBy.order === "asc"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Asc)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-desc"
                      checked={
                        sortBy.type === "name" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Desc)
                  </label>
                </div>
              </div>
              {/* Filter Section */}
              <div style={{ marginTop: 20 }} className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Filter</p>
                  {/* <IoIosArrowDown /> */}
                </div>
                <hr style={{ color: "#555" }} />
                <div>
                  <TrackFilter
                    onPriceFilterChange={handlePriceFilterChange}
                    onFiltersSelected={handleFiltersSelected}
                    onClearFilters={handleClearFilter}
                    hideApply={true}
                    width="100%"
                    showPriceFilter={true}
                    savedFilters={filters}
                    priceFilters={priceFilters}
                    showUseCase={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*  details section */}
      <div
        className={`${styles.dContainer} ${styles.outerFull}`}
        style={{ background: backgroundGradient }}
      >
        <div className={`${styles.inner} ${styles.innerMp}`}>
          <div className={styles.dContent}>
            <div className={styles.dSection}>
              {/* <BgImageDiv
              type="cat"
              id={categoryCode}
              divClass={styles.dImage}
            ></BgImageDiv> */}
              {/* mood details */}
              <div className={styles.details}>
                <span className={`${styles.innerBreadcrumb}`}>
                  <Link to={"/"}>Home</Link> /&nbsp;
                  <Link to={"/use-cases"}>Use Cases</Link> /&nbsp;
                  <span>{categoryData?.name}</span>
                </span>
                <div className={styles.dHeader}>
                  <div className={styles.nameShare}>
                    <h1 className={styles.dHtxt}>
                      {categoryData?.name || "catName"}
                    </h1>
                    {/* like share button */}
                    <CustomButton
                      isIconOnly={true}
                      backgroundColor="#F3F3F3"
                      borderRadius="50px"
                      iconColor="#000"
                      icon={<FaLink />}
                      onClick={handleShareClick}
                      width={windowWidth <= 481 ? "24px" : "34px"}
                      height={windowWidth <= 481 ? "24px" : "34px"}
                      iconSize={windowWidth <= 481 ? "12px" : "16px"}
                    />
                  </div>
                  {!categorySections.length > 0 && (
                    <h2 className={styles.dStxt}>
                      {/* {categoryData?.description || ""} */}
                      {/* {categoryData?.description
                        .split(/\s+/)
                        .slice(0, 20)
                        .join(" ") + "..." || ""} */}
                      {categoryData?.description
                        .split(". ")
                        .slice(0, 2)
                        .join(". ") + "." || ""}
                    </h2>
                  )}
                </div>
              </div>
              {/* <div className={styles.pills}>hh</div> */}
            </div>
            {/* BG IMAGE */}
            {categorySections.length > 0 && (
              <>
                <BgImageDiv
                  type="cat"
                  id={categoryCode}
                  divClass={styles.bgImage}
                  bg={true}
                ></BgImageDiv>
                {/* categories pills section */}
                <div className={`${styles.pillsContainer} ${styles.outerFull}`}>
                  {/* <p className={styles.pillHtxt}>{categoryData?.name} Categories:</p> */}
                  <p className={styles.pillHtxt}>
                    {categoryData?.name} Categories
                  </p>
                  <div className={styles.pills}>
                    {categorySections.map((section) => (
                      <a
                        key={section.id}
                        href={`#${section.id}`}
                        className={styles.pill}
                      >
                        {section.pillName}
                      </a>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* tracks section */}
      <div className={`${styles.outerFull}`}>
        <div className={styles.inner}>
          <div className={`${styles.tracksContainer}`}>
            {/* categories based tracks section */}
            {categorySections.length > 0 && (
              <div className={styles.tracks}>
                {categorySections.map((section) => (
                  <div key={section.id} id={section.id}>
                    {categoryTracks[section.id] &&
                      categoryTracks[section.id].length > 0 && (
                        <>
                          <h2 className={styles.tracksHtxt}>
                            {categoryData?.name} - {section.name}
                          </h2>
                          {section.id === "tr" ? (
                            <TrendingCard
                              trackType="trending"
                              trackCard={
                                windowWidth <= 481
                                  ? categoryTracks[section.id].slice(0, 4)
                                  : categoryTracks[section.id]
                              }
                              btnWidth={
                                isMobile
                                  ? windowWidth <= 320
                                    ? "65px"
                                    : windowWidth <= 375
                                    ? "95px"
                                    : "100px"
                                  : isTablet
                                  ? windowWidth <= 430
                                    ? "100px"
                                    : "158px"
                                  : "186px"
                              }
                            />
                          ) : (
                            <TrackCard
                              trackType="searchTrack"
                              trackCard={
                                windowWidth <= 481
                                  ? categoryTracks[section.id].slice(0, 4)
                                  : categoryTracks[section.id]
                              }
                              btnWidth={
                                isMobile
                                  ? windowWidth <= 320
                                    ? "65px"
                                    : windowWidth <= 375
                                    ? "95px"
                                    : "100px"
                                  : isTablet
                                  ? windowWidth <= 430
                                    ? "100px"
                                    : "158px"
                                  : "186px"
                              }
                            />
                          )}
                        </>
                      )}
                  </div>
                ))}
              </div>
            )}
            {/* all tracks */}
            <div className={styles.tracks}>
              <>
                <h2 className={styles.tracksHtxt}>
                  All {categoryData?.name} Tracks
                </h2>
                <TrackCard
                  trackType="searchTrack"
                  trackCard={allTrackList}
                  btnWidth={
                    isMobile
                      ? windowWidth <= 320
                        ? "65px"
                        : windowWidth <= 375
                        ? "95px"
                        : "100px"
                      : isTablet
                      ? windowWidth <= 430
                        ? "100px"
                        : "158px"
                      : "186px"
                  }
                />
              </>
              {isFetching && (
                <p className={styles.msgTxt}>Loading more tracks...</p>
              )}
              {noDataFound && results.length === 0 && (
                <p
                  className={styles.msgTxt}
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  No data found.
                </p>
              )}
            </div>
          </div>
          {showViewMore && !noDataFound && (
            <button
              className={styles.viewMoreBtn}
              onClick={handleViewMoreClick}
            >
              View More
            </button>
          )}
          {!isFetching && (
            <div className={`${styles.desDiv}`}>
              <p>License {categoryData?.name} Music</p>
              {/* <br /> */}
              <span className={styles.desBottom}>
                {categoryData?.description || ""}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatPage;
