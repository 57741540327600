import React from "react";
import "./CustomInput.css";

const CustomInput = ({
  type = "nl",
  placeholder = "Enter Text",
  borderRadius = "4px",
  onChange,
  onFocus,
  onBlur,
  width = "100%",
  height,
  border,
  fontSize,
  fontFamily,
  paddingLeft,
  marginTop,
  value,
  showRequired, // using in the case of wl whether to show the star
  error, // Error message to display
  spanText,
}) => {
  // type = wl i.e. render with label ui
  // type = nl will be default i.e. with no label
  return (
    <>
      {type === "nl" && (
        <div className="input-container">
          <input
            type="text"
            placeholder=" " // Use an empty placeholder to ensure the label moves up
            className="input-box"
            style={{
              border: border || "1px solid #000",
              borderRadius: borderRadius,
              width: width || "",
              height: height || "40px",
              fontSize: fontSize || "16px",
              fontFamily: fontFamily || "Inter",
              paddingLeft: paddingLeft || "",
            }}
            // onChange={onChange}
            onChange={(e) => onChange && onChange(e)}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value || ""}
            autoComplete="off"
          />
          <label
            style={{
              marginTop: marginTop || "",
            }}
            className="input-label"
          >
            {" "}
            {placeholder}{" "}
            {showRequired && <span style={{ color: "red" }}> *</span>}
          </label>
          <div
            style={{
              width: width || "",
            }}
          >
            {error && <p className="error-text">{error}</p>}
            {spanText && !error && <span className="infoSpan">{spanText}</span>}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomInput;
