import React, { useState, useEffect } from "react";
import "../styles/Playlist.css";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import PlaylistCard from "../components/CardsComponent/PlaylistCard/PlaylistCard";
import AllPlaylistCard from "../components/CardsComponent/PlaylistCard/AllPlaylistCard";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";
import config from "../utils/config";
import Carousel from "../components/Carousel/Carousel";
// import RandomDefaultImage from "../utils/RandomDefaultImage";

const Playlist = () => {
  const offerCards = [
    {
      image: `${config.s3imgUrl}web/promotional/playlists/banner-1.webp`,
      title: "Trendsetters",
      description:
        "Fresh tracks, trending vibes! Explore the latest hits from Bollywood's finest. License premium tracks for your next project.",
      buttonText: "Explore",
      packCode: 99,
      packNameSlug: "trendsetters",
      titleImg: `${config.s3imgUrl}web/promotional/playlists/title-1.webp`,
    },
    {
      image: `${config.s3imgUrl}web/promotional/playlists/banner-2.webp`,
      title: "Where’s the Party?",
      description:
        "Make your year-end celebrations unforgettable. License the perfect track for every occasion.",
      buttonText: "Explore",
      packCode: 28,
      packNameSlug: "wheres-the-party",
      titleImg: `${config.s3imgUrl}web/promotional/playlists/title-2.webp`,
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}web/playlists/157.webp`,
      title: "Composed by Pritam",
      buttonText: "Explore",
      totalTracks: 30,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 157,
      packNameSlug: "composed-by-pritam",
    },
    {
      image: `${config.s3imgUrl}web/playlists/179.webp`,
      title: "Top 20 Valentine Hits",
      buttonText: "Explore",
      totalTracks: 20,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 179,
      packNameSlug: "top-20-valentine-hits",
    },
    {
      image: `${config.s3imgUrl}web/playlists/141.webp`,
      title: "Best of KK",
      buttonText: "Explore",
      totalTracks: 11,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 141,
      packNameSlug: "best-of-kk",
    },
    {
      image: `${config.s3imgUrl}web/playlists/117.webp`,
      title: "Best of Satinder Sartaaj",
      buttonText: "Explore",
      totalTracks: 10,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 117,
      packNameSlug: "best-of-satinder-sartaaj",
    },
    {
      image: `${config.s3imgUrl}web/playlists/150.webp`,
      title: "Best of Vishal Dadlani",
      buttonText: "Explore",
      totalTracks: 17,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 150,
      packNameSlug: "best-of-vishal-dadlani",
    },
    {
      image: `${config.s3imgUrl}web/playlists/174.webp`,
      title: "Smash Hits ft. Ranveer Singh",
      buttonText: "Explore",
      totalTracks: 5,
      costPrice: 479999,
      sellingPrice: 299999,
      packCode: 174,
      packNameSlug: "smash-hits-ft.-ranveer-singh",
    },
  ];

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="pack-listing-container">
      {/* packs page description */}
      <div className="pack-listing-description outer">
        <div className="ll-innerBreadcrumb">
          <Link to={"/"}>Home</Link> /&nbsp;
          <span>Playlists</span>
        </div>
        <h1>Explore Playlists</h1>
        <h2>
          Discover a collection of the perfect tracks for your project. Browse
          our extensive library of music playlists, categorized by occasion and
          use cases.
          {/* Whether you're creating a commercial, a social media post, or a full-length film, seamlessly license the music you need for your content. */}
        </h2>
      </div>
      {/* top packs card section */}
      <div className="toppack-container outer">
        {offerCards.map((offer, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${offer.image})`,
            }}
            className="toppack-card-img"
          >
            <div className="toppack-card-title">
              <h1>
                <CustomImg
                  type="pack"
                  id="party_title"
                  alt="Where's the Party?"
                  imgClass="pack-banner-title-image"
                  src={offer.titleImg}
                />
              </h1>
            </div>
            {/* {/* <div className="toppack-card-description">
              <h2>{offer.description}</h2>
            </div> */}
            <Link
              to={`/playlists/${encodeURIComponent(offer.packNameSlug)}/${
                offer.packCode
              }`}
            >
              <div>
                <CustomButton
                  text={offer.buttonText}
                  backgroundColor="#fff"
                  borderRadius="30px"
                  borderColor="none"
                  width="150px"
                  height="37px"
                  textColor="var(--button-primary)"
                  iconColor="var(--button-primary)"
                  fontSize="16px"
                  fontFamily="Raleway"
                  fontWeight="bold"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* popular packs */}
      <div className="packs-container outer-full">
        <div className="inner inner-pl">
          <h1 className="packs-container-heading">Explore Popular Playlists</h1>
          <div className="packs-description-view">
            <h2>
              Collection of tracks for different occasions, moods and use cases!
            </h2>
            {/* <div className="packs-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#373737" />
              </p>
            </div> */}
          </div>
          <div className="inner">
            {windowWidth <= 481 ? (
              <Carousel gap={20} autoScroll={false} scrollInterval={4000}>
                {packCards.map((pack, index) => (
                  <PlaylistCard
                    key={index}
                    packCards={[pack]}
                    packType="toppackhome"
                  />
                ))}{" "}
              </Carousel>
            ) : (
              <PlaylistCard packType="pop" packCards={packCards} />
            )}
          </div>
        </div>
      </div>
      {/* all packs section */}
      <div style={{ marginTop: "10px" }}>
        <AllPlaylistCard viewall={false} />
      </div>
    </div>
  );
};

export default Playlist;
