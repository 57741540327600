import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "../styles/Login.module.css";
import CustomInput from "../components/CustomInput/CustomInput";
import { IoClose } from "react-icons/io5";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
// import CustomImg from "../components/CustomImg/CustomImg";
import {
  otpSend,
  otpVerify,
  userLogin,
  emailOtp,
  emailVerify,
} from "../service/Auth";
import { ToastContext } from "../utils/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../utils/redux/authSlice";
import { updateProfile } from "../service/UserService";
import { IoMdCheckmark } from "react-icons/io";
import useMixpanel from "../service/MixpanelService";

const Login = ({
  isOpen,
  onClose,
  profileMandatory = false,
  onProfileUpdate,
}) => {
  const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
  const [isModalOpen, setIsModalOpen] = useState(isOpen); // Sync with parent initially
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtpM, setVerifyOtpM] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [gmailLogin, setGmailLogin] = useState(false);
  const [profileVerify, setProfileVerify] = useState(false);
  const [loginMode, setLoginMode] = useState(false);
  const [profile, setProfile] = useState(false);
  const [email, setEmail] = useState(""); // Store email input
  const [emailError, setEmailError] = useState(false); // Store email validation error
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [name, setName] = useState(""); // State for name input for profile completion
  const [companyName, setCompanyName] = useState(""); // State for company name profile completion
  const [companyType, setCompanyType] = useState(""); // State for company type profile completion
  const [emailVerified, setEmailVerified] = useState(false); // state to manage to check whether the email for profile completion login via mobile is done or not
  const [profileBtn, setProfileBtn] = useState(true); // state to maintain while completing profile until and unless the email is verified the button will disabled
  const [previouslyVerifiedEmail, setPreviouslyVerifiedEmail] = useState(""); // Add a new state variable to store the previously verified email
  const [resendTimer, setResendTimer] = useState(0); // State for resend timer
  const [attempts, setAttempts] = useState(0); // State for attempt count temp maintain in fe later on be msg to be displayed
  const [resendTimerProfile, setResendTimerProfile] = useState(0); // Timer for profile OTP
  const [attemptsProfile, setAttemptsProfile] = useState(0); // Profile OTP attempts

  const dispatch = useDispatch(); // dispatching action to the redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { trackEvent } = useMixpanel();

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // effect to check the user stored data in local
  useEffect(() => {
    if (storedUserData) {
      setName(storedUserData.name || "");
      setEmail(storedUserData.email || "");
      setCompanyName(storedUserData.companyName || "");
      setCompanyType(storedUserData.companyType || "");
      setPhoneNumber(storedUserData.mobile || "");
    }
  }, []);

  // to monitor the email changes if already email is present and still want to change then verified state to be false to let otp sent and verify the new email
  useEffect(() => {
    if (!email) {
      setEmailVerified(false);
      return;
    } else if (
      storedUserData.email === email ||
      previouslyVerifiedEmail === email
    ) {
      setEmailVerified(true);
      // console.log("setting email verify true");
    } else {
      setEmailVerified(false);
      // console.log("setting email verify false");
    }
  }, [email, previouslyVerifiedEmail]);

  // Sync local state when parent changes `isOpen`
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  // Sync local state when parent changes `isAuthenticated`
  useEffect(() => {
    // console.log("auth", isAuthenticated);
    // console.log(profile, "profile");
    if (isAuthenticated) {
      // console.log("auth 57", isAuthenticated);
      setMobileLogin(true); // Set login state
      setLoginMode(false); // Disable login UI after login
      setProfile(true); // Show profile completion UI
    } else {
      setMobileLogin(false); // Set login state
      setLoginMode(true); // Show login UI when not logged in
      setProfile(false); // Hide profile UI
    }
  }, [isOpen]);

  // useEffect to check the conditions and update profileBtn
  useEffect(() => {
    if (
      emailVerified &&
      email.trim() !== "" &&
      name.trim() !== "" &&
      companyName.trim() !== "" &&
      companyType.trim() !== ""
    ) {
      setProfileBtn(false); // Enable the button
    } else {
      setProfileBtn(true); // Disable the button
    }
  }, [emailVerified, email, name, companyName, companyType]);

  // Countdown Timer Logic
  useEffect(() => {
    if (resendTimer > 0) {
      const interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    if (resendTimerProfile > 0) {
      const interval = setInterval(() => {
        setResendTimerProfile((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [resendTimer, resendTimerProfile]);

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close modal locally
    resetState(); // Reset state when modal is closed
    onClose(); // Notify the parent to close the modal
  };

  // fucntion to handle the change in phone number
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // fucntion to handle the login via  mobile otp method
  const handleGetOtp = async (e) => {
    e.preventDefault();

    if (!phoneNumber && phoneNumber.length < 10) {
      showToast(
        "OTP Sent",
        "Please enter a valid 10-digit mobile number.",
        "error"
      ); //display toast msg
      return;
    }

    handleLoginService();
  };

  // function to get the otp again while login with mobile
  const handleResendGetOtp = async () => {
    // console.log(attempts, "attempts");
    const otpLimitKey = `otpResendLimit_${phoneNumber}`; // Unique key per phone number
    const otpLimit = getCookie(otpLimitKey);

    if (otpLimit) {
      setAttempts(3);
      return;
    }

    if (attempts >= 3) {
      return;
    }

    setOtp(new Array(6).fill("")); // Clear the OTP inputs
    setResendTimer(30); // Start countdown from 30 seconds

    // Ensure API call happens before restriction
    handleLoginService();

    setAttempts((prev) => {
      const newAttempts = prev + 1;
      if (newAttempts === 3) {
        // Delay setting the cookie until after the timer completes
        setTimeout(() => {
          setCookie(otpLimitKey, "true", 5, true);
        }, 30000); // Delay by 30 seconds (timer duration)
      }
      return newAttempts;
    });
  };

  // common api function to send the otp while login with mobile
  const handleLoginService = async () => {
    // setVerifyOtpM(true); // un comment this line for debug purpose

    // Log or use the phone number value
    // console.log("Phone Number:", phoneNumber);
    // preparing the payload
    const payload = {
      mobile: phoneNumber,
    };

    try {
      const sendOtp = await otpSend(payload);
      // console.log(sendOtp, "send otp resp");

      if (sendOtp.message === "OTP sent successfully!") {
        showToast("OTP Sent", sendOtp.message, "success"); //display toast msg
        setVerifyOtpM(true);
      } else {
        showToast("OTP Sent", "Failed to send OTP", "error"); //display toast msg
        return;
      }
    } catch (error) {
      showToast("OTP Sent", "Failed to send OTP", "error"); //display toast msg
      return;
    }
  };

  // function to handle the otp change
  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus(); // Move to the next input box
    }
  };

  // function to handle the verify otp via mobile otp
  const verifyOtp = async (e) => {
    e.preventDefault();

    if (attempts >= 3) {
      showToast("OTP Verified", "Limit Exceeded", "error"); //display toast msg
      return;
    }

    // getting the otp input boxes values
    // console.log(otp.join(""));
    // preparing payload for verifying the otp
    const payload = {
      mobile: phoneNumber,
      otp: otp.join(""),
    };
    try {
      const verifyOtp = await otpVerify(payload);
      // console.log(verifyOtp, "verify otp resp");
      if (verifyOtp.message === "OTP verified successfully!") {
        handleLogin();
      } else {
        showToast("OTP Verified", "Failed to Verify OTP", "error"); //display toast msg
        return;
      }
    } catch (error) {
      showToast("OTP Verified", "Failed to Verify OTP", "error"); //display toast msg
      return;
    }
  };

  // function to handle to call the login api after verification
  const handleLogin = async () => {
    deleteCookie("skipProfile"); // just to test the case  for debug not to uncomment // temp on 28 mar as skip profile is removed - later to be removed
    // Prepare the payload
    const payload = {
      mobile: phoneNumber,
      // email: email, // using later on when login with email will be a option or google login
      method: "direct",
    };

    try {
      const loginResp = await userLogin(payload);
      // console.log(loginResp, "login resp");

      if (loginResp?.error?.code === 0) {
        const { token, userData } = loginResp.data;

        // Dispatch login with token and userData
        dispatch(
          login({
            token,
            userData,
          })
        );

        // Store userData in localStorage
        localStorage.setItem("userData", JSON.stringify(userData));

        // Check if user has skipped profile completion
        // const hasSkippedProfile = getCookie("skipProfile") === "true";

        // Check if specific fields are null or empty
        const { name, email, companyType, companyName } = userData;
        const isDataIncomplete =
          !name || !email || !companyType || !companyName;

        if (isDataIncomplete) {
          setMobileLogin(true); // Set login state
          setProfile(true); // Set profile UI
          setLoginMode(false);
        } else {
          setLoginMode(true);
          closeModal(); // If profile is complete or skipped, close modal
        }
        showToast("Login Successful", "Welcome!", "success");
        // setLoginMode(false);

        // Track the sign-up event
        trackEvent("sign-up", {
          name: userData.name,
          email: userData.email,
          companyType: userData.companyType,
          companyName: userData.companyName,
          // skipProfile: hasSkippedProfile,
          url: window.location.href,
        });
      } else {
        showToast("Login Failed", "Failed to login. Please try again", "error");
        dispatch(logout());
      }
    } catch (error) {
      console.error(error);
      showToast("Login Failed", "Something Went Wrong!!", "error");
      dispatch(logout());
    }
  };

  // Utility to set a cookie for profile complete
  // const setCookie = (name, value, days) => {
  //   const date = new Date();
  //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //   document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  // };
  // Utility to set a cookie with optional minute-based expiry
  const setCookie = (name, value, time, isMinutes = false) => {
    const date = new Date();
    const expiryTime = isMinutes
      ? time * 60 * 1000
      : time * 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + expiryTime);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    // console.log("cook set");
  };

  // Utility to get a cookie profile complete
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };

  // Utility to delete a cookie (optional, for clearing test cases) profile complete for later use if manunally need to do it
  const deleteCookie = (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  };

  // function to handle the skip profile
  // const handleSkipProfile = () => {
  //   setCookie("skipProfile", "true", 30); // Set cookie to indicate profile was skipped for 1 month
  //   setLoginMode(false);
  //   closeModal(); // Close the modal
  // };

  // function to handle the complete profile
  const handleCompleteProfile = async () => {
    // create payload
    const payload = {
      name: name,
      email: email,
      companyName: companyName,
      companyType: companyType,
      // mobile: phoneNumber, // to enable later on when google sign is there else this payload is not needed currently
    };
    // console.log(payload);

    // Check if any value in the payload is null, undefined, or an empty string
    const isPayloadValid = Object.values(payload).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    // console.log(emailVerified,'email verified');

    if (!isPayloadValid) {
      showToast("User Profile", "Incomplete Profile Info", "error");
      return;
    } else if (!emailVerified) {
      // console.log(emailVerified);
      showToast("User Profile", "Please Verify the Email", "error");
      return;
    }

    const profileUpdate = await updateProfile(payload);
    // console.log(profileUpdate);
    if (profileUpdate?.error?.code === 0) {
      // updating the userdata in the local storage
      // Retrieve existing user data from localStorage
      let userData = JSON.parse(localStorage.getItem("userData")) || {};

      // update to the new values
      userData.companyName = payload.companyName;
      userData.companyType = payload.companyType;
      userData.email = payload.email;
      userData.name = payload.name;

      // Save updated data back to localStorage
      localStorage.setItem("userData", JSON.stringify(userData));

      showToast("User Profile", "Profile Updated Successfully", "success");
      setLoginMode(true);

      // Call the callback function to indicate profile update
      if (profileMandatory && onProfileUpdate) {
        onProfileUpdate(true);
      }

      closeModal();
    } else {
      showToast("User Profile", "Error Updating Profile", "error");
    }
  };

  // email otp function
  const handleEmailOtp = async () => {
    setOtp(new Array(6).fill(""));
    if (emailError || !email) {
      showToast("Email Error", "Incorrect Email Format", "error");
      return;
    }
    setProfileVerify(true);
    if (mobileLogin) {
      // create payload
      const payload = {
        email: email,
        id: storedUserData.id,
      };
      // console.log(payload, "email to be verify");
      const getEmailOtp = await emailOtp(payload);
      // console.log(getEmailOtp, "email otp");
      if (getEmailOtp?.error?.code === 0) {
        showToast("Email OTP", "Otp Sent successfully", "success");
      } else {
        showToast("Email Error", "Fail to send otp", "error");
      }
    }
  };

  // email verify function
  const handleEmailVerify = async (e) => {
    e.preventDefault();

    if (attemptsProfile >= 3) {
      showToast("OTP Verified", "Limit Exceeded", "error"); //display toast msg
      return;
    }

    // create payload
    const payload = {
      email: email,
      otp: otp.join(""),
      id: storedUserData.id,
    };

    const verifyemail = await emailVerify(payload);
    // console.log(verifyemail, "email verify");
    if (verifyemail.error.code === 0) {
      showToast("Email Verify", "Otp Verified successfully", "success");
      setEmailVerified(true);
      setPreviouslyVerifiedEmail(email); // Store the verified email
      setProfileVerify(false);
      setOtp(new Array(6).fill(""));
    } else {
      showToast("Email Error", "Fail to verify otp", "error");
    }
  };

  // resend otp fucntion while completing profile
  const handleResendOtp = async () => {
    const otpLimitKey = `otpProfileResendLimit_${phoneNumber}`;
    const otpLimit = getCookie(otpLimitKey);

    if (otpLimit) {
      setAttemptsProfile(3);
      return;
    }

    if (attempts >= 3) {
      return;
    }

    setOtp(new Array(6).fill(""));
    setResendTimerProfile(30); // Start 30-sec timer

    // resend otp will based on mobilelogin or gmaillogin condition
    if (mobileLogin) {
      // resend otp for email
      // create payload
      const payload = {
        email: email,
        id: storedUserData.id,
      };
      const resendOtp = await emailOtp(payload);
      if (resendOtp?.error?.code === 0) {
        showToast("Email OTP", "Otp Sent successfully", "success");
      } else {
        showToast("Email Error", "Fail to send otp", "error");
      }
    }
    setAttemptsProfile((prev) => {
      if (prev === 2) {
        setCookie(otpLimitKey, "true", 5, true); // Block after 3rd attempt
        return 3;
      }
      return prev + 1;
    });
  };

  // fucntion to handle the backspace for otp input boxes
  const handleBackspace = (index) => {
    if (index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus(); // Move to the previous input box
    }
  };

  // fucntion to reset the all login state to default
  const resetState = () => {
    setPhoneNumber("");
    setVerifyOtpM(false);
    setOtp(new Array(6).fill(""));
    setMobileLogin(false);
    setGmailLogin(false);
    setProfileVerify(false);
    setLoginMode(false);
    setProfile(false);
    setEmail(""); // Clear email state
    setEmailError(""); // Clear email error
  };

  // email input handler regex check
  const handleEmailChange = (e) => {
    const value = e.target.value;

    setEmail(value);

    // Validate email with regex
    if (!emailRegex.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false); // Clear error if email is valid
    }
  };

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // dropdown values
  const options = [
    { value: "Agency", label: "Agency" },
    { value: "Brand", label: "Brand" },
    { value: "Content Studio", label: "Content Studio" },
    { value: "Independent Filmmaker", label: "Independent Filmmaker" },
    {
      value: "Individual Creator / Influencer",
      label: "Individual Creator / Influencer",
    },
    { value: "OTT Platform", label: "OTT Platform" },
    { value: "Production House", label: "Production House" },
    { value: "Radio Station", label: "Radio Station" },
    { value: "TV Channel", label: "TV Channel" },
    { value: "Other", label: "Other" },
  ];

  if (!isModalOpen) return null;

  // console.log(emailVerified, "email verify");

  return (
    <div className={styles.modalOverlay}>
      <div
        className={styles.modalContainer}
        // style={{
        //   // backgroundImage: `url(
        //   //       ${config.s3imgUrl}web/onboarding/login.webp
        //   //     )`,
        //   backgroundImage: `url(
        //     ${config.s3imgUrl}web/onboarding/login-n2.png
        //   )`,
        // }}
      >
        {/* login with otp and gmail section */}
        {loginMode && (
          <div className={styles.innerContent}>
            {/* <div onClick={closeModal} className={styles.closeIconMain}>
              <IoClose
                cursor="pointer"
                onClick={() => !isOpen}
                size={24}
                color="#000"
              />
            </div> */}
            {process.env.REACT_APP_ENV === "production" && (
              <div onClick={closeModal} className={styles.closeIconMain}>
                <IoClose
                  cursor="pointer"
                  onClick={() => !isOpen}
                  size={24}
                  color="#000"
                />
              </div>
            )}
            {verifyOtpM === false ? (
              <form className={styles.login} onSubmit={handleGetOtp}>
                {/* <div className={styles.login}> */}
                <p className={styles.loginText}>Log In</p>
                <div className={styles.phoneInput}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <button
                  className={styles.getOtpButton}
                  // onClick={handleGetOtp}
                >
                  Get OTP
                </button>
                {/* <div className={styles.separator}>
                  <span>OR</span>
                </div> */}
                {/* <button
                  // onClick={() => {
                  //   setProfile(true);
                  //   setLoginMode(false);
                  //   setGmailLogin(true);
                  // }}
                  className={styles.googleButton}
                >
                  <img
                    src="https://www.gstatic.com/images/branding/product/1x/gsa_512dp.png"
                    alt="Google"
                    className={styles.googleIcon}
                  />
                  Sign In With Google
                </button> */}
                {/* <CustomImg
                  src={`${config.s3imgUrl}web/onboarding/google-light-si-sq.png`}
                  id="google-sign-in"
                  alt="sign-in-with-google"
                  imgClass={styles.googleSignIn}
                  type="genre"
                /> */}
                {/* </div> */}
              </form>
            ) : (
              // login with otp verify section
              <form onSubmit={verifyOtp} className={styles.login}>
                {/* <div className={styles.login}> */}
                <p className={styles.loginText}>Verify OTP</p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.otpNumber}>
                    <p className={styles.otp}>OTP sent to +91-{phoneNumber}</p>
                    <p
                      onClick={() => {
                        setVerifyOtpM(false);
                        setOtp(new Array(6).fill("")); // Clear the OTP inputs
                        setAttempts(0);
                        setResendTimer(0);
                      }}
                      className={styles.edit}
                    >
                      Edit
                    </p>
                  </div>
                  <div className={styles.otpContainer}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInput} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    {attempts < 3 || resendTimer > 0 ? (
                      resendTimer > 0 ? (
                        <span className={styles.resendSpan}>
                          Resend in {resendTimer} sec
                        </span>
                      ) : (
                        <span
                          onClick={() => handleResendGetOtp()}
                          className={styles.resendSpan}
                        >
                          Click here to resend
                        </span>
                      )
                    ) : (
                      <span className={styles.error}>
                        Sending OTP limit exceeded
                      </span>
                    )}
                  </p>
                  <button className={styles.getOtpButton}>Verify</button>
                </div>
                {/* </div> */}
              </form>
            )}
            <div className={styles.privacy}>
              By continuing, you agree to Hoopr Smash{" "}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </div>
          </div>
        )}
        {profile && (
          // profile info section
          <div className={styles.profileContent}>
            {/* {profileMandatory == false ? (
              <div onClick={handleSkipProfile} className={styles.closeIcon}>
                <IoClose
                  cursor="pointer"
                  onClick={() => !isOpen}
                  size={24}
                  color="#000"
                />
              </div>
            ) : null} */}
            <p
              style={{ margin: profileVerify ? "0px" : "" }}
              className={styles.profileText}
            >
              Enhance Experience by Completing Profile
            </p>
            <div className={styles.inputContainer}>
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Name"
                borderRadius="8px"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/* profile mobile and email verify based on login mode */}
              <div className={styles.verfiyProfile}>
                {gmailLogin && (
                  <div className={styles.phoneInputProfile}>
                    <select className={styles.countryCode}>
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className={styles.phoneNumber}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={10}
                    />
                  </div>
                )}
                {mobileLogin && (
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    width={windowWidth <= 481 ? "190px" : "288px"}
                    placeholder="Enter Email and Verify"
                    borderRadius="8px"
                    containerWidth="auto"
                    onChange={handleEmailChange}
                    value={email}
                  />
                )}
                {emailVerified ? (
                  <div className={styles.emailVerified}>
                    <IoMdCheckmark size={22} color="#fff" />
                  </div>
                ) : (
                  <>
                    {profileVerify ? (
                      <button
                        onClick={handleEmailVerify}
                        className={styles.profileVerifyBtn}
                      >
                        Verify
                      </button>
                    ) : (
                      <button
                        onClick={handleEmailOtp}
                        className={styles.profileVerifyBtn}
                      >
                        Get OTP
                      </button>
                    )}
                  </>
                )}
              </div>
              {profileVerify && (
                <div>
                  <div className={styles.otpContainerProfile}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInputProfile} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    {attemptsProfile < 3 || resendTimerProfile > 0 ? ( // Check attempts and timer
                      resendTimerProfile > 0 ? (
                        <span className={styles.resendSpan}>
                          Resend in {resendTimerProfile} sec
                        </span>
                      ) : (
                        <span
                          style={{ cursor: "pointer" }}
                          className={styles.resendSpan}
                          onClick={handleResendOtp}
                        >
                          {""}Click here to resend
                        </span>
                      )
                    ) : (
                      <span className={styles.error}>
                        Sending OTP limit exceeded
                      </span>
                    )}
                    {/* Didn't get a code? {""}
                    <span
                      style={{ cursor: "pointer" }}
                      className={styles.resendSpan}
                      onClick={handleResendOtp}
                    >
                      {""}Click here to resend
                    </span> */}
                  </p>
                </div>
              )}
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Company Name"
                borderRadius="8px"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <CustomDropdown
                options={options}
                placeholder="Type of company"
                value={options.find((option) => option.value === companyType)} // Ensures the dropdown shows the correct selected value
                onChange={(selectedOption) =>
                  setCompanyType(selectedOption ? selectedOption.value : "")
                }
              />
            </div>
            <div className={styles.buttonContainercp}>
              <CustomButton
                text="Complete Profile"
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="100%"
                height="48px"
                textColor="#fff"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="550"
                onClick={handleCompleteProfile}
                disabled={profileBtn}
              />
            </div>
            {/* {profileMandatory == false ? (
              <span onClick={handleSkipProfile} className={styles.skip}>
                Skip for Now
              </span>
            ) : null} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
