import React, { useEffect, useState } from "react";
import styles from "../styles/MoodsListing.module.css";
import CustomButton from "../components/CustomButton/CustomButton";
import { IoIosArrowForward } from "react-icons/io";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import { Link } from "react-router-dom";
import CustomImg from "../components/CustomImg/CustomImg";
import setMetaTags from "../utils/SeoService";

const MoodsListing = () => {
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const { moods, status } = useSelector((state) => state.trackFilters);
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const seoData = {
      title: "Mood Based Music Licensing | Hoopr Smash",
      description:
        "Browse mood based music licensing on Hoopr Smash. Find royalty-free tracks tailored to every emotion and vibe, perfect for enhancing your creative projects effortlessly.",
      ogImage: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/moods/topbanner.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  const categories = {
    moods: moods.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };
  // console.log(categories, "moods");

  // Dynamically generate allMoods from categories
  const allMoods = categories.moods.map((mood, index) => ({
    id: mood.id,
    image: randomDefaultImage("square"),
    heading: mood.title,
    slug: mood.slug,
  }));

  // console.log(allMoods, "allMoods");

  // Utility to pick 4 random moods from allMoods
  // const pickRandomMoods = (moods, count = 4) => {
  //   const shuffled = [...moods].sort(() => 0.5 - Math.random());
  //   return shuffled.slice(0, count);
  // };

  // Utility to filter moods by title
  const pickMoodsByTitle = (moods, titles) => {
    const titleSet = new Set(titles.map((title) => title.toLowerCase()));
    return moods.filter((mood) => titleSet.has(mood.heading.toLowerCase()));
  };

  // Randomly picked moods
  // const randomMoods = pickRandomMoods(allMoods);

  // Specifically picked moods by title
  const specificTitles = ["Groovy", "Celebratory", "Energetic", "Spiritual"]; // Example titles
  const specificMoods = pickMoodsByTitle(allMoods, specificTitles);

  const popularMoods = specificMoods; // Set popularMoods as randomly picked moods or specificMoods based on requirement

  // Function to find a mood by title for main banner
  // Function to find mood by title
  const findMoodByTitle = (moods, title) => {
    return moods.find(
      (mood) => mood.heading.toLowerCase() === title.toLowerCase()
    );
  };

  // Example: Find "Badass" mood
  const specificMood = findMoodByTitle(allMoods, "Romantic");
  // console.log(specificMood.slug, "specificMood");

  return (
    <div className={styles.moodsListingContainer}>
      {/* moods page description */}
      <div className={`${styles.moodsListingDescription} ${styles.outer}`}>
        <div className={`${styles.innerBreadcrumb}`}>
          <Link to={"/"}>Home</Link> /&nbsp;
          <span>Moods</span>
        </div>
        <h1 className={styles.moodsHeading}>Shop By Moods</h1>
        <h2 className={styles.moodsSubHeading}>
          Don't miss out on this trend-setting opportunity! For a limited time,
          you can...
        </h2>
      </div>
      {/* mood main banner */}
      <div
        style={{
          // backgroundImage: `url(${randomDefaultImage("track")})`,
          backgroundImage: `url(https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/moods/topbanner.webp)`,
        }}
        className={`${styles.moodsListingBanner} ${styles.inner}`}
      >
        <h1 className={styles.moodsBannerHeading}>
          {specificMood ? specificMood.heading : "Moods Name"}
        </h1>
        <h2 className={styles.moodsBannerSubHeading}>
          {specificMood ? specificMood.moodsSubHeadingheading : "Moods Name"}
        </h2>
        <Link
          to={`/moods/${encodeURIComponent(
            specificMood ? specificMood.slug : ""
          )}/${specificMood ? specificMood.id : ""}`}
        >
          <CustomButton
            text="Explore Track"
            backgroundColor="#fff"
            borderRadius="50px"
            borderColor="none"
            // width="182px"
            width={
              isMobile
                ? windowWidth <= 320
                  ? "100px"
                  : windowWidth <= 375
                  ? "120px"
                  : "140px"
                : isTablet
                ? windowWidth <= 430
                  ? "140px"
                  : "168px"
                : "182px"
            }
            height={windowWidth <= 768 ? "30px" : "37px"}
            textColor="var(--button-primary)"
            fontSize={windowWidth <= 768 ? "10px" : "16px"}
            fontFamily="Raleway"
            fontWeight="bold"
            iconColor="var(--button-primary)"
            icon={<IoIosArrowForward />}
            justifyContent="space-between"
          />
        </Link>
      </div>
      {/* popular moods section */}
      <div className={`${styles.popularMoodsContainer} ${styles.outerFull}`}>
        <div className={styles.inner}>
          <h1 className={styles.popularMoodsHeading}>Popular Moods</h1>
          <div className={styles.popularMoodsCards}>
            {/* for handling more card css is added just update the slice value */}
            {popularMoods.slice(0, 4).map((mood) => (
              <Link
                // key={index}
                to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}
              >
                <div key={mood.id} className={styles.popularMoodsCard}>
                  {/* <img
                  src={mood.image}
                  alt={mood.heading}
                  className={styles.popularMoodsCardImage}
                /> */}
                  <CustomImg
                    type="mood"
                    id={mood.id}
                    alt={mood.heading}
                    imgClass={styles.popularMoodsCardImage}
                    src=""
                  ></CustomImg>
                  <h1 className={styles.popularMoodsCardHeading}>
                    {mood.heading}
                  </h1>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* all moods section */}
      <div className={`${styles.popularMoodsContainer} ${styles.outer}`}>
        <h1 className={styles.popularMoodsHeading}>All Moods</h1>
        <div className={styles.allMoodsCards}>
          {/* for handling more card css is added just update the slice value */}
          {allMoods.map((mood) => (
            <Link
              // key={index}
              to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}
            >
              <div key={mood.id} className={styles.allMoodsCard}>
                <CustomImg
                  type="mood"
                  id={mood.id}
                  alt={mood.heading}
                  imgClass={styles.allMoodsCardImage}
                  src=""
                ></CustomImg>
                {/* <img
                  src={mood.image}
                  alt={mood.heading}
                  className={styles.allMoodsCardImage}
                /> */}
                <h1 className={styles.allMoodsCardHeading}>{mood.heading}</h1>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodsListing;
