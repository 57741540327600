import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";
import { getAuthHeaders } from "../utils/headers";

export const createUserLike = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/like`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getTrackLike = async (trackCode) => {
  const url = `${config.baseUrl}${urls.user}/likes/T/${trackCode}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getPlaylistLike = async (packCode, type = "P") => {
  const url = `${config.baseUrl}${urls.user}/likes/${type}/${packCode}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const getLikes = async () => {
  const url = `${config.baseUrl}${urls.user}/likes`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const createCollection = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/collections/create`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error in creating collection", error);
    return { data: [] };
  }
};

export const removeCollection = async (id) => {
  const url = `${config.baseUrl}${urls.user}/collections/remove/${id}`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.delete(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error in creating collection", error);
    return { data: [] };
  }
};

export const trackCollection = async (payload) => {
  // api to add a track,pack and playlist in particular collection
  const url = `${config.baseUrl}${urls.user}/collections/addTrack`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error adding in collection:", error);
    return { data: [] };
  }
};

export const delCollection = async (payload) => {
  // api to remove a track,pack and playlist from particular collection
  const url = `${config.baseUrl}${urls.user}/collections/deleteTrack`;
  const headers = getAuthHeaders();

  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error adding in collection:", error);
    return { data: [] };
  }
};

export const getUserCollections = async (id) => {
  const url = id
    ? `${config.baseUrl}${urls.user}/collections/${id}`
    : `${config.baseUrl}${urls.user}/collections`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const updateCollection = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/collections/update`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const updateProfile = async (payload) => {
  const url = `${config.baseUrl}${urls.user}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const getUserProfile = async () => {
  const url = `${config.baseUrl}${urls.user}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const userAllAddress = async () => {
  const url = `${config.baseUrl}${urls.user}/addresses`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const createAddress = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/addresses`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error adding new profile:", error);
    return { data: [] };
  }
};

export const addressUpdate = async (addressId, payload) => {
  const url = `${config.baseUrl}${urls.user}/addresses/${addressId}`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.put(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const getUserPurchases = async () => {
  const url = `${config.baseUrl}${urls.user}/purchases`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const userDownload = async (payload) => {
  const url = `${config.baseUrl}${urls.user}/download`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    return { data: [] };
  }
};

export const getCity = async (stateId, countryId) => {
  const url = `${config.baseUrl}cities?stateId=${stateId}&countryId=${countryId}`;
  // const headers = getAuthHeaders();
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.error("Error getting city id:", error);
    return { data: [] };
  }
};

export const getState = async (countryId) => {
  const url = `${config.baseUrl}states?countryId=${countryId}`;
  // const headers = getAuthHeaders();
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.error("Error getting state id:", error);
    return { data: [] };
  }
};

export const getCountry = async () => {
  const url = `${config.baseUrl}countries?page=1&limit=300`;
  // const headers = getAuthHeaders();
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.error("Error getting country id:", error);
    return { data: [] };
  }
};
