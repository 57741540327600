const moods = {
    // "f11b4beb-c2de-41a1-81d3-1ca0dd870110": {
    //     "name": "Abstract",
    //     "description": "Ethereal and experimental soundscapes, perfect for creative and avant-garde projects.",
    // },
    "9adc4829-f074-40cc-a9d6-12263b78bcb0": {
        "name": "Action",
        "description": "Whether you're looking for cinematic battle themes, heart-racing chase sequences, or explosive fight scene soundtracks, our copyright-safe action music selection brings the intensity you need to keep viewers on the edge of their seats. With aggressive drums, electrifying guitar riffs, and pulse-pounding orchestral elements, this genre is perfect for action-packed trailers, extreme sports videos, and adrenaline-fueled campaigns. Action music is intense, fast-paced, and designed to heighten excitement and suspense. The music’s high energy engages audiences, making every moment feel bigger and more impactful. License popular tracks that ensure your campaigns remain trendy while complying with legal requirements.",
    },
    "89f693a7-6b5c-40a9-94e9-719caf72a963": {
        "name": "Adventurous",
        "description": "Adventurous music captures the thrill of exploration and discovery, making it the perfect backdrop for travel documentaries, outdoor brand campaigns, or epic storytelling videos. With uplifting orchestral swells, rhythmic percussion, and dynamic melodies, this genre inspires curiosity and excitement, encouraging audiences to embark on new journeys. We offer trendy copyright-safe adventurous tracks that allow you to create immersive, relatable content. Whether it’s a soaring cinematic theme or a rhythmic tribal beat, license our collection that helps enhance the spirit of adventure and adds a sense of wonder to your visuals.",
    },
    // "e29621c0-09f0-44d1-af32-aa8c7ba52d0e": {
    //     "name": "Aggressive",
    //     "description": "",
    // },
    "de78fff8-b2cd-4758-92c6-7c7b182ff2f1": {
        "name": "Angry",
        "description": "Angry music is raw, intense, and charged with emotion. With heavy guitar riffs, pounding drums, and aggressive synths, this genre is ideal for high-energy sports montages, rebellious branding, and intense cinematic scenes. It fuels powerful emotions, making it perfect for expressing frustration, determination, or defiance. Our collection includes copyright-safe popular hits, allowing you to add high-impact soundtracks to your campaigns. Whether it's a heavy rock anthem or an intense hip-hop beat, these tracks give your project an edge and make a strong statement.",
    },
    "469a2a08-704b-4339-8b77-cc6314f2d8ac": {
        "name": "Anthemic",
        "description": "Anthemic music is bold, uplifting, and designed to evoke a sense of unity, pride, and triumph. This type of music is perfect for a celebratory campaign, motivational promotions, sports commercials, or an athleisure brand. The music builds momentum, lifting the spirits of the audience and amplifying the impact of key moments, whether it’s a victory, a call to action, or a moment of achievement. Often characterized by grand orchestral arrangements, powerful choirs, and soaring melodies, it creates a feeling of collective strength and inspiration. We provide copyright protection for anthemic tracks that have the power to rally people together, reinforcing themes of hope, determination, and unity, making it an effective tool for stirring emotions and leaving a lasting impression.",
    },
    // "afabbc54-6e4a-4e77-ab10-ca63a543b4c8": {
    //     "name": "Badass",
    //     "description": "Powerful and edgy music for rebellious and edgy content.",
    //     "img": ""
    // },
    // "3f674d09-a211-4f0d-8647-a8909e077d8b": {
    //     "name": "Busy",
    //     "description": "Energetic and fast-paced music to match the hustle and bustle of modern life.",
    // },
    "fc6d31ac-ee6e-489c-a7dc-79da156c3247": {
        "name": "Calm",
        "description": "Calm music is soothing, tranquil, and designed to create a peaceful atmosphere. With soft piano, gentle acoustic guitars, and ambient textures, this genre is ideal for meditation videos, wellness branding, and emotional storytelling. It allows the audience to relax, unwind, and feel at ease. We provide a license for popular tracks, ensuring that your campaigns have a legally compliant and trending soundscape. Whether you’re crafting spa brand content, a mindfulness app soundtrack for campaigns, or any wellness industry brand, these peaceful melodies enhance serenity and emotional connection.",
    },
    "3fd90f5f-f872-466e-8f97-075ea0635ee4": {
        "name": "Celebratory",
        "description": "Celebratory music is vibrant, joyful, and full of energy, making it ideal for festive campaigns, wedding videos, and victory moments. With upbeat rhythms, cheerful melodies, and dynamic instrumentation, it captures the essence of happiness and success. Whether it’s a grand event or a personal milestone, this genre adds excitement to every scene. We offer copyright-safe celebratory tracks that allow you to create feel-good campaigns without any legal concerns. From lively brass sections to uplifting pop beats, these tracks amplify the joy and make your moments feel even more special.",
    },
    // "b9adc674-d482-4900-bb9c-65c57690ba3e": {
    //     "name": "Chill",
    //     "description": "Laid-back and mellow music for relaxation and chill-out sessions.",
    // },
    "51ad375b-d2c4-437e-af1d-c171f6054119": {
        "name": "Dark",
        "description": "Dark music is moody, mysterious, and intense, often used to build suspense and tension. With deep basslines, eerie synths, and haunting melodies, it is ideal for psychological thrillers, horror, and dystopian narratives. The unsettling tones keep audiences engaged and heighten the emotional impact. License our copyright-safe tracks to ensure your dark-themed campaigns maintain a trending sound while meeting legal requirements. Whether it’s a cinematic horror score or a brooding industrial beat, these tracks bring depth and intrigue to your storytelling.",
    },
    // "b617ce60-ced1-4f75-853c-76545f8cb163": {
    //     "name": "Depressed",
    //     "description": "Sad and melancholic music, perfect for dramatic scenes, heartbreaks, and emotional content.",
    // },
    "92a2b8ff-5b82-4f0b-98ad-80ab2c012a9f": {
        "name": "Dramatic",
        "description": "Dramatic music is bold, emotionally charged, and impactful, making it perfect for cinematic storytelling, emotional campaigns, and high-stakes moments. With sweeping orchestral crescendos, powerful vocal choirs, and dynamic instrumentation, this genre enhances the gravity of any scene. Our collection features copyright-safe and popular tunes, ensuring that your campaigns have an emotionally gripping soundtrack. Whether it’s a heart-wrenching score or a thrilling orchestral piece, dramatic music makes every moment unforgettable.",
    },
    "6a011e8c-5048-4448-bce2-5458dadf1421": {
        "name": "Dreamy",
        "description": "Dreamy music is ethereal, atmospheric, and evocative, creating a sense of wonder and introspection. With soft synth pads, reverb-drenched guitars, and gentle melodies, it’s perfect for romantic scenes, fantasy storytelling, and artistic branding. The floating soundscapes transport audiences into a surreal, calming world. We offer copyright-safe, dreamy tracks that enhance your campaigns with mesmerizing and tranquil sounds. Whether you need music for an abstract visual piece or a slow-motion montage, this genre adds a touch of magic and elegance.",
    },
    "9e6482af-cca0-42c8-a9a7-7cc4a634d5a3": {
        "name": "Elegant",
        "description": "Elegant music exudes sophistication, grace, and refinement. With delicate piano melodies, soft string arrangements, and smooth jazz influences, it is perfect for luxury branding, high-end fashion campaigns, and upscale event promotions. This genre enhances the feeling of exclusivity and class, making any campaign more polished and distinguished. We provide a license for trending tracks, ensuring that your campaigns have a touch of popular music while remaining legally compliant. Whether it's a classical waltz or a modern lounge tune, our collection helps you set the perfect refined tone.",
    },
    // "bed6f8ce-d5b3-4d09-acd3-a4629ed69592": {
    //     "name": "Emotional",
    //     "description": "Evocative and poignant music, perfect for dramas, emotional scenes, and sentimental ads.",
    // },
    "f39282cf-64be-455d-b17e-6efa6dc0fa01": {
        "name": "Energetic",
        "description": "Energetic music is fast-paced, dynamic, and full of life, making it ideal for sports videos, fitness campaigns, and action-packed branding. With pulsating beats, powerful synths, and driving guitar riffs, this genre injects excitement and motivation into any project, keeping audiences engaged. We provide copyright protection for our energetic tracks, giving your campaigns an electrifying boost without any legal concerns. Whether you're creating a workout video or a fast-paced promotional ad, these tracks ensure your visuals are paired with trending soundscapes.",
    },
    // "6a026083-a5ae-4a1b-8fff-1075cf069373": {
    //     "name": "Epic",
    //     "description": "Grand and sweeping music for epic scenes and trailers.",
    // },
    "7e98f210-b995-46f0-973c-0da8e6713c17": {
        "name": "Ethnic",
        "description": "Ethnic music is rich in cultural influences, blending traditional instruments and melodies from around the world. It’s perfect for travel documentaries, multicultural branding, and heritage-inspired campaigns. Whether it’s the sound of African drums, Indian sitars, or Middle Eastern tunes, ethnic music creates an authentic and immersive atmosphere. We offer licenses for popular tracks that bring the world’s diverse musical traditions to your campaigns, providing trending and popular Bollywood soundtracks. Whether you need an authentic folk melody or a modern fusion, these tracks add a regional essence to your visuals.",
    },
    // "4ca08b7e-0c98-4922-a776-576e8a36e331": {
    //     "name": "Exciting",
    //     "description": "Upbeat and energetic music to excite and energize your audience. Suitable for revelation, unboxing and curtain raisers.",
    // },
    // "3c75595e-fb29-4efa-b281-73b64e44abb2": {
    //     "name": "Fantasy",
    //     "description": "Magical and mystical music to transport your audience to otherworldly realms.",
    // },
    "50d100e3-5353-491c-ad52-7f2758bb3dda": {
        "name": "Feel Good",
        "description": "Feel-good music is uplifting, cheerful, and designed to spread positivity. With lively beats, catchy hooks, and bright instrumentation, this genre is perfect for lifestyle vlogs, fun campaigns, and motivational storytelling. It instantly enhances the mood, leaving the audience with a sense of joy and excitement. Our copyright-free collection of tracks includes trendy hits, ensuring that your campaigns resonate with audiences through energetic and heartwarming melodies. Whether it's an upbeat pop anthem or an acoustic sing-along, these tracks create an atmosphere of happiness and optimism.",
    },
    // "623df712-99f1-4fa9-8636-3cf3a15e335f": {
    //     "name": "Festive",
    //     "description": "Cheerful and celebratory music for festivals and holidays.",
    // },
    "fdb3543d-af3b-4712-8fb8-a38a38f257ae": {
        "name": "Flirty",
        "description": "Flirty music is playful, charming, and full of personality, making it ideal for romantic comedies, beauty product campaigns, and stylish branding. With jazzy undertones, groovy basslines, and cheeky melodies, it sets a fun and seductive tone that keeps audiences engaged. We provide copyright-safe flirty tracks, ensuring that your campaigns remain lighthearted and engaging without legal concerns. Whether it's a smooth lounge track or a bubbly pop tune, this genre adds charm and allure to any project.",
    },
    "43d114d0-ae93-48a2-a079-bab015a9be89": {
        "name": "Fun",
        "description": "Fun music is lively, energetic, and bursting with joy, making it perfect for children’s campaigns, party scenes, and vibrant branding. With fast tempos, playful melodies, and infectious rhythms, it keeps audiences entertained and engaged. We license a collection of trendy tracks featuring exciting and upbeat compositions, ensuring that your campaigns maintain a trending sound without copyright worries. Whether it's a quirky jingle or a high-energy pop track, fun music amplifies the excitement of any visual.",
    },
    // "5888d8b9-503a-4034-a8a2-0a7c3072ac55": {
    //     "name": "Funny",
    //     "description": "Humorous and quirky music, perfect for comedy sketches, sitcoms, and funny commercials.",
    // },
    "8da09f93-eaa1-423b-adb7-93749d161e59": {
        "name": "Glamorous",
        "description": "Glamorous music is luxurious, stylish, and dazzling, ideal for high-fashion campaigns, red-carpet events, and upscale branding. With lush orchestral swells, shimmering synths, and seductive rhythms, this genre exudes sophistication and exclusivity. We provide copyright safety for popular tracks in the glamorous music category, ensuring your content shines with elegance and modern flair. Whether it's a sultry R&B groove or a chic electronic beat, these tracks elevate your visuals with confidence and class.",
    },
    // "e15b4625-c45b-4692-ba86-311bb2aa2c16": {
    //     "name": "Grand",
    //     "description": "Majestic and powerful music for grand and epic occasions.",
    // },
    "f1b0d86f-ffaf-4387-b7e5-914eeada7383": {
        "name": "Groovy",
        "description": "Groovy music is all about rhythm, funk, and movement. With punchy basslines, syncopated beats, and vibrant brass sections, it’s perfect for dance videos, retro branding, and stylish campaigns. The infectious energy makes it impossible to sit still. We offer copyright-safe groovy tracks that add a lively and rhythmic essence to your content. Whether you're looking for classic funk or modern disco-inspired beats, these tracks ensure a fun and engaging experience.",
    },
    "0ca27d5b-d78a-4ac5-875c-1008756ef158": {
        "name": "Happy",
        "description": "Happy music is bright, cheerful, and uplifting, ideal for family-friendly campaigns, positive messaging, and feel-good branding. With catchy melodies, upbeat tempos, and playful instrumentation, this genre spreads joy and positivity effortlessly. Our copyright-safe collection of Bollywood tracks features a wide range of happy music, ensuring your campaigns maintain a trending sound without any copyright concerns. Whether it's an acoustic folk tune or a bouncy pop melody, these tracks add warmth and enthusiasm to any project.",
    },
    "375c4386-a819-4aa4-bdd1-3c66b0c3ed5c": {
        "name": "Heartwarming",
        "description": "Heartwarming music is emotional, tender, and deeply moving, making it perfect for heartfelt campaigns, touching storytelling, and meaningful branding. With gentle piano, sweeping strings, and warm melodies, it enhances sentimental moments and evokes strong emotions. We offer copyright safety for popular hits, ensuring your campaigns connect with audiences on a personal level. Whether it's an uplifting orchestral piece or a soothing acoustic song, these tracks create a profound emotional impact.",
    },
    // "5747c295-e345-4265-8921-7833577e391f": {
    //     "name": "Hopeful",
    //     "description": "Optimistic and uplifting music, perfect for inspirational content, motivational videos, and feel-good ads.",
    // },
    // "70fc6a0c-a6e6-4da8-95a8-9782bdea9900": {
    //     "name": "Horror",
    //     "description": "Dark and eerie music to create suspense and fear.",
    // },
    "d0d2b2dc-6791-47a1-902e-1b14788757f1": {
        "name": "Inspiring",
        "description": "Inspiring music is uplifting, motivational, and full of hope, ideal for success stories, corporate campaigns, and transformative branding. With grand orchestral builds, empowering melodies, and dynamic rhythms, it pushes audiences toward positive action. Our copyright-safe, inspiring tracks help reinforce messages of determination, growth, and resilience without legal worries. Whether it’s a cinematic anthem or an acoustic ballad, these tracks uplift and empower viewers.",
    },
  //   "9200766a-1c9e-4c61-9d84-1402310790c6": {
  //       "name": "Intense",
  //       "description": "Intense and dramatic music to build tension and excitement.",
  // },
    "ed7d43f9-918f-4e20-80dc-e3113c556803": {
        "name": "Intimate",
        "description": "",
    },
  //   "1e0e85bc-19a2-422a-8553-0939f08f78aa": {
  //       "name": "Joyful",
  //       "description": "Upbeat and energetic music, perfect for party scenes, comedies, and upbeat commercials.",
  //   },
    "115a3e41-d2ee-47ae-9fa1-c96c21c20178": {
        "name": "Lazy",
        "description": "Lazy music is laid-back, chill, and effortless, making it perfect for relaxation campaigns, cozy branding, and casual storytelling. With smooth jazz, lo-fi beats, and mellow acoustic tones, it creates a carefree atmosphere. We provide a license for popular Bollywood tracks in the lazy music category, ensuring that your campaigns maintain a trending and soothing sound. Whether it’s a beachside tune or a relaxed evening groove, these tracks set a calm and easygoing mood.",
    },
    // "66585de3-83a6-44d4-a651-4f8c0dcffec8": {
    //     "name": "Melancholic",
    //     "description": "Sad and reflective music, perfect for dramas, heartbreaks, and emotional scenes.",
    // },
    "7e9caf70-1cd5-4e82-8ee0-8f4590f2f8bb": {
        "name": "Motivational",
        "description": "Motivational music is dynamic, powerful, and inspiring, making it perfect for fitness campaigns, personal growth stories, and success-driven branding. With driving rhythms, uplifting melodies, and intense builds, it encourages audiences to push forward. Our copyright-safe popular hits collection features impactful motivational tracks, ensuring your campaigns are paired with the perfect soundtrack to boost determination and confidence. Whether it’s a heroic orchestral piece or an energetic rock anthem, these tracks drive action and ambition.",
    },
    "3bbdfc0a-78a8-4efc-8f51-7b30162c755b": {
        "name": "Mysterious",
        "description": "Mysterious music is suspenseful, intriguing, and atmospheric, making it ideal for thrillers, crime documentaries, and cinematic campaigns. With haunting strings, eerie synths, and deep basslines, it creates tension and curiosity. We offer copyright protection for our mysterious tracks that enhance campaigns with dark and enigmatic soundscapes. Whether it’s a detective drama or a supernatural mystery, these tracks add depth and suspense.",
    },
    "ad8e3c56-3a40-409d-b1c5-01e9a35a6e23": {
        "name": "Nostalgic",
        "description": "Take a trip down memory lane! Nostalgic music blends warm melodies, classic sounds, and heartfelt emotions to bring back cherished moments. Whether it’s a retro vibe or a sentimental story, this music wraps listeners in familiarity. Ideal for vintage product campaigns, storytelling content, brand legacy videos, and family-focused commercials. Whether you’re celebrating the past, honoring tradition, or adding a retro touch, this soundtrack brings that old-school charm. Looking for a sound that feels like home? License our collection of copyright-safe popular Bollywood hits, so your short-form promotional videos can stir up beautiful memories without worry.",
    },
    // "ad055f48-c801-46a3-9836-616bbc43095e": {
    //     "name": "Peppy",
    //     "description": "Energetic and upbeat music to get you moving.",
    // },
    // "18bcd8ec-4d62-48fb-a95e-66123d3d2f39": {
    //     "name": "Rebellious",
    //     "description": "Edgy and rebellious music, perfect for action movies, sports commercials, and rebellious brands.",
    // },
    "550d8185-9e23-45a9-90bb-e8993b3c9759": {
        "name": "Relaxing",
        "description": "Deep breaths, calm mind, and pure tranquility—relaxing music is designed to melt stress away. With soft synths and soothing rhythms, it’s a great fit for spa promotions, meditation content, wellness brands, and mindful living campaigns. Whether your brand is about self-care, sleep enhancement, or stress relief, this soundtrack sets the perfect tone. Want a music selection that feels like a deep breath? We provide copyright safety for our easy-to-license playlist of trending Bollywood tunes, so your short-form promotional videos can create a peaceful escape for your audience.",
    },
    "ed820c8e-03f5-43d1-bbd8-c96c51b31f88": {
        "name": "Romantic",
        "description": "Love is in the air! Romantic music sets the mood with soft melodies, heartfelt lyrics, and dreamy instrumentals that make every moment feel special. Ideal for wedding videos, dating apps, jewelry brands, romantic getaway promotions, and Valentine’s Day campaigns. Whether you’re capturing a love story, launching a couple-centric brand, or setting a dreamy aesthetic, this music makes hearts flutter. Want a soundtrack that speaks the language of love? Our collection of popular Bollywood anthems is 100% copyright-safe and easy to license, so your short-form promotional videos can capture romance without a hitch.",
    },
    "91365ba2-a58c-4152-a5b3-780ea9f62216": {
        "name": "Sad",
        "description": "Sometimes, emotions run deep. Sad music brings out raw, heartfelt feelings with melancholic melodies, soft strings, and soulful piano compositions. It’s a good fit for emotional storytelling, awareness campaigns, heartfelt brand messages, and cinematic trailers. Whether you're sharing a moving story, bringing attention to a cause, or setting a dramatic tone, this music enhances every tear-jerking moment. Need a soundtrack that resonates emotionally? License our collection of copyright-safe popular Bollywood hits, so your short-form promotional videos can connect on a deeper level.",
    },
    // "d40a1f69-6d17-4f38-bc90-fcab1d780296": {
    //     "name": "Sensual",
    //     "description": "Sultry and seductive music. Ideal for sensual scenes in movies, music videos, and adult content.",
    // },
    // "22a59a52-5591-4d8b-9fc0-a5282e2a642e": {
    //     "name": "Serious",
    //     "description": "Serious and solemn music for formal and professional occasions.",
    // },
    "eaf38e4e-3afc-40bf-9670-3a3e95c2ebd7": {
        "name": "Sexy",
        "description": "Smooth, sultry, and irresistibly cool—sexy music adds a touch of allure and confidence to any scene. With deep basslines, jazzy instrumentals, and seductive melodies, it’s ideal for luxury brands, high-end fashion, perfume commercials, and sleek product promotions. Whether you're setting the mood for a stylish campaign or adding a little intrigue, this music turns up the heat. Want to add some undeniable charm to your content? We provide copyright safety for our easy-to-license playlist of popular Bollywood tracks, so your short-form promotional videos can stay smooth and sophisticated.",
    },
    // "398ee4b5-ba5a-4904-b76b-34e0a912030d": {
    //     "name": "Soft",
    //     "description": "Gentle and soothing music for delicate and sensitive moments.",
    // },
    "dec28c89-f48a-4c39-9582-3eb3e822a683": {
        "name": "Soulful",
        "description": "Rich, emotional, and deeply expressive—soulful music is all about heart and depth. With smooth vocals, groovy rhythms, and warm melodies, it’s a great fit for lifestyle brands, storytelling content, luxury experiences, and heartfelt commercials. Whether you’re highlighting human connections, capturing deep emotions, or adding a touch of class, this music brings richness to every moment. Want a soundtrack that resonates with pure soul? Our collection of trendy Bollywood melodies is 100% copyright-safe and easy to license, so your short-form promotional videos can be as heartfelt as your message.",
    },
    "7d58aafd-8d01-4625-b513-4b291ff4495a": {
        "name": "Spiritual",
        "description": "Sacred, uplifting, and full of devotion—spiritual music brings peace, faith, and transcendence. Featuring soft chants, ethereal vocals, and meditative instrumentals, it’s best suited for devotional content, yoga and meditation apps, mindful living brands, and faith-based campaigns. Whether you're sharing messages of hope, promoting inner peace, or connecting with deeper beliefs, this music elevates the experience. Looking for a sound that touches the soul? License our collection of copyright-safe popular Bollywood hits so your short-form promotional videos can spread light and harmony without a second thought.",
    },
    // "03ce178f-e648-4c58-a840-af199ab13b13": {
    //     "name": "Suspense",
    //     "description": "Tense and mysterious music to build suspense and anticipation.",
    // },
    // "6c29befb-abce-4b79-8132-d23345a7bee3": {
    //     "name": "Sweet",
    //     "description": "Soft, romantic, and sentimental music. Perfect for love songs, romantic comedies, and heartwarming ads.",
    // },
    // "eaf56e56-4e35-4db9-8bf1-a34be195bbf1": {
    //     "name": "Thoughtful",
    //     "description": "Reflective and contemplative music for introspective moments.",
    // },
    "dc0ba229-e598-495f-bf6e-9fb209ebe097": {
        "name": "Thriller",
        "description": "Edge-of-your-seat tension, eerie undertones, and heart-racing suspense—thriller music keeps audiences hooked. With dark synths, intense beats, and a cinematic build-up, this music is a must-have for horror trailers, suspenseful ads, crime documentaries, and dramatic brand storytelling. Whether you’re creating a mystery, teasing an upcoming project, or diving into high-stakes storytelling, this soundtrack keeps the tension alive. Want a score that keeps viewers on edge? We provide copyright safety for our easy-to-license playlist of Bollywood so your short-form promotional videos can stay thrilling without legal worries. Let’s set the scene for suspense!",
    },
    // "e5f21bae-1943-4a6e-b36f-e15a6e2df5d4": {
    //     "name": "Upbeat",
    //     "description": "Energetic and positive music to uplift and inspire.",
    // }
};

export default moods;