// import React, { useState, useEffect, useContext } from "react";
import React, { useState, useEffect } from "react";
import "../styles/Header.css";
import SearchBar from "../components/SearchBar/SearchBar";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import { MdMenu } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
import Cart from "./Cart";
import { selectItemCount } from "../utils/redux/cartSlice";

import { logout } from "../utils/redux/authSlice";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import RequestSong from "./RequestSong";

import {
  FaRegFaceLaughBeam,
  FaQuestion,
  FaInfo,
  FaRegNewspaper,
} from "react-icons/fa6";
import { IoIosArrowForward, IoIosArrowRoundForward } from "react-icons/io";
import { LuMousePointerClick } from "react-icons/lu";
import { MdHeadsetMic } from "react-icons/md";
import { PiWaveformBold } from "react-icons/pi";
import { RiMusicAiFill, RiLogoutCircleRLine  } from "react-icons/ri";

// import CustomImg from "../components/CustomImg/CustomImg";
// import { FaRegCircleUser } from "react-icons/fa6";
// import { FaInfo } from "react-icons/fa";
// import { ToastContext } from "../utils/ToastContext";
// import { useAuth } from "../utils/AuthContext";
// import { BiSupport } from "react-icons/bi";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // STATE TO CHECK THE MOBILE VIEW ACTIVE STATUS
  const [isSearchActive, setIsSearchActive] = useState(false); //  STATE TO CHECK THE SEARCH BAR ACTIVE STATUS
  const [activeTab, setActiveTab] = useState("usecase"); // Default tab is "mood"
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle login modal
  const [isCartOpen, setIsCartOpen] = useState(false); // toggle cart modal
  const totalItemCount = useSelector(selectItemCount); // Get the item count from Redux store
  const [activeMenu, setActiveMenu] = useState(null); // menu item for mobile
  const [subMenu, setSubMenu] = useState(null); // sub menu items for mobile
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  // const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [selectedOccasionCategory, setSelectedOccasionCategory] =
    useState(null); // state to track in mobile menu

  const [selectedSmashHits, setSelectedSmashHits] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState(null);

  const { genres, useCases, moods, status } = useSelector(
    (state) => state.trackFilters
  );
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // toggle menu for mobile headers
  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
    setSubMenu(null);
  };

  // toggle menu sub header for mobile
  const openSubMenu = (menu) => {
    // console.log(menu, "menu");

    setSubMenu(menu);
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      if (!isAuthenticated) {
        setIsModalOpen(true);
      }
    }
  }, [isAuthenticated]);
  // useEffect(() => {
  //   if (process.env.REACT_APP_ENV === "production" && !isAuthenticated) {
  //     setIsModalOpen(true);
  //   }
  // }, [isAuthenticated]);

  const navigate = useNavigate();

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  const categories = {
    moods: moods.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
    genre: genres.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
    usecase: useCases.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };

  // FUNCTION TO TOGGLE THE MOBILE VIEW
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // FUNCTION TO TOGGLE THE SEARCH BAR
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleLogout = () => {
    localStorage.clear(); // temp purpose
    dispatch(logout()); // This will change the auth state to unauthenticated
    navigate("/"); // Redirect to the home page
  };

  // mobile menu options
  const categoriesMobile = ["Use Cases", "Moods", "Genres"];
  const supportMobile = [
    { name: "How to Use", link: "/how-to-use" },
    { name: "About Us", link: "/about" },
    { name: "Blog", link: "https://blog.hooprsmash.com" },
    { name: "FAQs", link: "/faqs" },
    { name: "Contact Us", link: "/contact-us" },
    { name: "Sales Enquiry", link: "/contact-sales" },
    { name: "Claims Support", link: "#" },
    // { name: "Request A Song", link: "/request-a-song" },
  ];
  const exploreMobile = [
    { name: "Tracks", link: "/tracks" },
    { name: "Packs", link: "/packs" },
    { name: "Playlists", link: "/playlists" },
    { name: "Languages", link: "/languages" },
  ];

  const smashHitsMobile = {
    upcomingEvents: [
      {
        title: "Ram Navami",
        link: `/search?searchKeyword=${encodeURIComponent(
          "Ram Navami"
        )}&hs_ref=searchbar_header_mob`,
      },
      {
        title: "Vaisakhi",
        link: `/search?searchKeyword=${encodeURIComponent(
          "Vaisakhi"
        )}&hs_ref=searchbar_header_mob`,
      },
      {
        title: "Mother's Day",
        link: `/search/?searchKeyword=${encodeURIComponent(
          "Mother's Day"
        )}&hs_ref=searchbar_header_mob`,
      },
      {
        title: "Buddha Purnima",
        link: `/search/?searchKeyword=${encodeURIComponent(
          "Buddha Purnima"
        )}&hs_ref=searchbar_header_mob`,
      },
      {
        title: "Father's Day",
        link: `/search/?searchKeyword=${encodeURIComponent(
          "Father's Day"
        )}&hs_ref=searchbar_header_mob`,
      },
      {
        title: "Guru Purnima",
        link: `/search?searchKeyword=${encodeURIComponent(
          "Guru Purnima"
        )}&hs_ref=searchbar_header_mob`,
      },
    ],
    trendingPlaylists: [
      { title: "New on Smash", link: "/playlists/new-on-smash/106" },
      {
        title: "Trending on Social Media",
        link: "/playlists/trending-on-social-media/105",
      },
      { title: "Popular Bollywood", link: "/playlists/popular-bollywood/14" },
      { title: "Independant hits", link: "/playlists/independent-hits/104" },
    ],
  };

  const myAccountMobile = [
    { name: "Profile", link: "user/profile" },
    { name: "Purchases", link: "user/purchases" },
    { name: "Likes", link: "user/likes" },
    { name: "Collections", link: "user/collection" },
  ];

  // mobile menu handle navigation fucntion
  const handleMenuClick = (link, external = false) => {
    // console.log(link, "link");
    // console.log(external, "external");

    if (external) {
      window.open(link, "_blank"); // Opens external link in a new tab
    } else {
      setActiveMenu(null);
      setSubMenu(null);
      setSelectedOccasionCategory(null);
      navigate(link); // React Router navigation for internal links
    }
    toggleMobileMenu(); // Close the menu
  };

  const upcomingEvents = {
    eventsList: [
      {
        title: "Ram Navami",
        month: "apr",
        date: "06",
        className: "calendar-event-month apr",
      },
      {
        title: "Vaisakhi",
        month: "apr",
        date: "13",
        className: "calendar-event-month apr",
      },
      {
        title: "Mother's Day",
        month: "may",
        date: "11",
        className: "calendar-event-month may",
      },
      {
        title: "Buddha Purnima",
        month: "may",
        date: "12",
        className: "calendar-event-month may",
      },
      {
        title: "Father's Day",
        month: "jun",
        date: "15",
        className: "calendar-event-month jun",
      },
      {
        title: "Guru Purnima",
        month: "jul",
        date: "10",
        className: "calendar-event-month jul",
      },

      // DO NOT DELETE THIS
      // THIS IS FOR BEING READY IN THE FUTURE

      // {
      //   title: "Friendship Day",
      //   month: "aug",
      //   date: "03",
      //   className: "calendar-event-month aug",
      // },
      // {
      //   title: "Raksha Bandhan",
      //   month: "aug",
      //   date: "09",
      //   className: "calendar-event-month aug",
      // },
      // {
      //   title: "Janmashtami",
      //   month: "aug",
      //   date: "16",
      //   className: "calendar-event-month aug",
      // },
      // {
      //   title: "Ganesh Puja",
      //   month: "aug",
      //   date: "27",
      //   className: "calendar-event-month aug",
      // },
    ],
    bannerList: [
      {
        title: "New on Smash",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/new.webp`,
        url: "/playlists/new-on-smash/106",
      },
      {
        title: "Trending on Social Media",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/trending.webp`,
        url: "/playlists/trending-on-social-media/105",
      },
      {
        title: "Popular Bollywood",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/popular.webp`,
        url: "/playlists/popular-bollywood/14",
      },
      {
        title: "Independent Hits",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/indie.webp`,
        url: "/playlists/independent-hits/104",
      },
    ],
  };

  // const packsMenuItems = {
  //   s1: [
  //     {
  //       title: "Big Bang Chartbusters",
  //       link: "/playlists/pack-big-bang-chartbusters/15",
  //     },
  //     {
  //       title: "Boss Brands",
  //       link: "/playlists/pack-boss-brands/18",
  //     },
  //     {
  //       title: "Capture the Moment",
  //       link: "playlists/pack-capture-the-moment/19",
  //     },
  //     {
  //       title: "Childhood Innocence",
  //       link: "/playlists/pack-childhood-innocence/20",
  //     },
  //     {
  //       title: "Classical Creations",
  //       link: "playlists/pack-classical-creations/23",
  //     },
  //     {
  //       title: "Desi Devotion",
  //       link: "/playlists/pack-desi-devotion/16",
  //     },
  //     {
  //       title: "Festivals of India (Jan - Mar)",
  //       link: "/playlists/pack-festivals-of-india-(jan-mar)/27",
  //     },
  //     {
  //       title: "Festivals of India (Apr - Jun)",
  //       link: "/playlists/pack-festivals-of-india-(apr-jun)/110",
  //     },
  //   ],
  //   s2: [
  //     {
  //       title: "Festivals of India (Jul - Sep)",
  //       link: "/playlists/pack-festivals-of-india-(jul-sept)/111",
  //     },
  //     {
  //       title: "Festivals of India (Oct- Dec)",
  //       link: "/playlists/pack-festivals-of-india-(oct-dec)/112",
  //     },
  //     {
  //       title: "Fusion Mantra",
  //       link: "/playlists/pack-fusion-mantra/24",
  //     },
  //     {
  //       title: "Happily Ever After",
  //       link: "/playlists/pack-happily-ever-after/13",
  //     },
  //     {
  //       title: "Heroes We Love",
  //       link: "/playlists/pack-heroes-we-love/21",
  //     },
  //     {
  //       title: "I Love My India",
  //       link: "/playlists/pack-i-love-my-india/10",
  //     },
  //     {
  //       title: "Love is in the Air",
  //       link: "/playlists/pack-love-is-in-the-air/11",
  //     },
  //     {
  //       title: "Pulse Racers",
  //       link: "/playlists/pack-pulse-racers/17",
  //     },
  //   ],
  //   s3: [
  //     {
  //       title: "Reels Mania",
  //       link: "/playlists/pack-reels-mania/6",
  //     },
  //     {
  //       title: "The Yogi's Playlist",
  //       link: "/playlists/pack-the-yogi's-playlist/26",
  //     },
  //     {
  //       title: "Traveller's Tunes",
  //       link: "/playlists/pack-traveller's-tunes/25",
  //     },
  //     {
  //       title: "Trendsetters",
  //       link: "/playlists/pack-trendsetters/12",
  //     },
  //     {
  //       title: "Where's The Party?",
  //       link: "/playlists/pack-where's-the-party/7",
  //     },
  //     {
  //       title: "Women Who Rule",
  //       link: "/playlists/pack-women-who-rule/22",
  //     },
  //     {
  //       title: "Year End Celebrations",
  //       link: "/playlists/pack-year-end-celebrations/8",
  //     },
  //   ],
  // };

  // const filterTracksItems = {
  //   budget: [
  //     {
  //       title: "Tracks Under ₹4,999",
  //       link: "",
  //     },
  //     {
  //       title: "Tracks Between ₹5,000 - ₹24,999",
  //       link: "",
  //     },
  //     {
  //       title: "Tracks Between ₹25,000 - ₹44,999",
  //       link: "",
  //     },
  //     {
  //       title: "Tracks Between ₹45,000 - ₹59,999",
  //       link: "",
  //     },
  //     {
  //       title: "Tracks Above  ₹60,000",
  //       link: "",
  //     },
  //   ],
  //   byType: [
  //     {
  //       title: "Vocal",
  //       link: "",
  //       img: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/home/vocal.png",
  //     },
  //     {
  //       title: "Instrumental",
  //       link: "",
  //       img: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/home/instrumental.png",
  //     },
  //   ],
  //   byLanguage: {
  //     s1: [
  //       {
  //         title: "Assamese",
  //         link: "",
  //       },
  //       {
  //         title: "Bengali",
  //         link: "",
  //       },
  //       {
  //         title: "Bhojpuri",
  //         link: "",
  //       },
  //       {
  //         title: "English",
  //         link: "",
  //       },
  //       {
  //         title: "Gujarati",
  //         link: "",
  //       },
  //       {
  //         title: "Haryanvi",
  //         link: "",
  //       },
  //       {
  //         title: "Hindi",
  //         link: "",
  //       },
  //     ],
  //     s2: [
  //       {
  //         title: "Kannada",
  //         link: "",
  //       },
  //       {
  //         title: "Malayalam",
  //         link: "",
  //       },
  //       {
  //         title: "Marathi",
  //         link: "",
  //       },
  //       {
  //         title: "Punjabi",
  //         link: "",
  //       },
  //       {
  //         title: "Rajasthani",
  //         link: "",
  //       },
  //       {
  //         title: "Tamil",
  //         link: "",
  //       },
  //       {
  //         title: "Telugu",
  //         link: "",
  //       },
  //     ],
  //   },
  // };

  const occasionsItems = {
    celebrations: [
      {
        title: "Anniversary",
        link: "/search?q=anniversary",
      },
      // {
      //   title: 'Baby Shower',
      //   link: '/search?q=baby%20shower'
      // },
      {
        title: "Birthday",
        link: "/search?q=birthday",
      },
      {
        title: "Engagement",
        link: "/search?q=engagement",
      },
      // {
      //   title: "House Warming",
      //   link: "/search?q=house%20warming",
      // },
      {
        title: "Party",
        link: "/search?q=party",
      },
      {
        title: "Sangeet",
        link: "/search?q=sangeet",
      },
      {
        title: "Wedding",
        link: "/search?q=wedding",
      },
    ],
    sports: [
      {
        title: "Cricket",
        link: "/search?q=cricket",
      },
      // {
      //   title: "Women's T20 World Cup",
      //   link: '/search?q=birthday'
      // }
    ],
    january: [
      {
        title: "Lohri",
        link: "/search?q=lohri",
      },
      {
        title: "Pongal",
        link: "/search?q=pongal",
      },
      // {
      //   title: "Makar Sankranti",
      //   link: "/search?q=makar sankranti",
      // },
      {
        title: "Republic Day",
        link: "/search?q=republic day",
      },
    ],
    february: [
      // {
      //   title: "Vasant Panchami",
      //   link: "/search?q=vasant panchami",
      // },
      {
        title: "Valentine's Day",
        link: "/search?q=valentines day",
      },
      {
        title: "Maha Shivratri",
        link: "/search?q=maha shivratri",
      },
    ],
    march: [
      {
        title: "Women's Day",
        link: "/search?q=womens day",
      },
      {
        title: "Holi",
        link: "/search?q=holi",
      },
      {
        title: "Gudi Padwa",
        link: "/search?q=gudi padwa",
      },
      {
        title: "Ramadan",
        link: "/search?q=eid",
      },
      // {
      //   title: "Ugadi",
      //   link: "/search?q=ugadi",
      // },
      // {
      //   title: "Eid-ul-Fitr",
      //   link: "/search?q=eid-ul-fitr",
      // },
    ],
    april: [
      {
        title: "Ram Navami",
        link: "/search?q=ram navami",
      },
      // {
      //   title: "Mahavir Jayanti",
      //   link: "/search?q=mahavir jayanti",
      // },
      {
        title: "Vaisakhi",
        link: "/search?q=vaisakhi",
      },
      // {
      //   title: "Ambedkar Jayanti",
      //   link: "/search?q=ambedkar jayanti",
      // },
      // {
      //   title: "Bahag Bihu",
      //   link: "/search?q=bahag bihu",
      // },
      // {
      //   title: "Good Friday",
      //   link: "/search?q=good friday",
      // },
      // {
      //   title: "Easter",
      //   link: "/search?q=easter",
      // },
    ],
    may: [
      // {
      //   title: "International Labour Day",
      //   link: "/search?q=international labour day",
      // },
      {
        title: "Mother's Day",
        link: "/search?q=mothers day",
      },
      // {
      //   title: "Buddha Purnima",
      //   link: "/search?q=rambuddha purnimaazan",
      // },
    ],
    june: [
      {
        title: "Bakri Eid",
        link: "/search?q=eid",
      },
      {
        title: "Father's Day",
        link: "/search?q=fathers day",
      },
    ],
    july: [
      {
        title: "Guru Purnima",
        link: "/search?q=guru purnima",
      },
    ],
    august: [
      {
        title: "Friendship Day",
        link: "/search?q=friendship day",
      },
      {
        title: "Raksha Bandhan",
        link: "/search?q=raksha bandhan",
      },
      {
        title: "Independence Day",
        link: "/search?q=independence day",
      },
      {
        title: "Janmashtami",
        link: "/search?q=janmashtami",
      },
      // {
      //   title: "Parsi New Year",
      //   link: "/search?q=parsi new year",
      // },
      {
        title: "Ganesh Puja",
        link: "/search?q=ganesh puja",
      },
    ],
    september: [
      {
        title: "Teacher's Day",
        link: "/search?q=teachers day",
      },
      // {
      //   title: "Eid-e-Milad",
      //   link: "/search?q=eid-e-milad",
      // },
      // {
      //   title: "Onam",
      //   link: "/search?q=onam",
      // },
      {
        title: "Durga Puja",
        link: "/search?q=durga puja",
      },
      {
        title: "Navaratri",
        link: "/search?q=navaratri",
      },
    ],
    october: [
      // {
      //   title: "World Smile Day",
      //   link: "/search?q=world smile day",
      // },
      // {
      //   title: "World Elderly Day",
      //   link: "/search?q=world elderly day",
      // },
      {
        title: "Gandhi Jayanti",
        link: "/search?q=gandhi jayanti",
      },
      {
        title: "Dussehra",
        link: "/search?q=dussehra",
      },
      {
        title: "Karva Chauth",
        link: "/search?q=karva chauth",
      },
      // {
      //   title: "Teachers' Day",
      //   link: "/search?q=world teachers day",
      // },
      {
        title: "Mental Health Day",
        link: "/search?q=world mental health day",
      },
      // {
      //   title: "International Day of the Girl Child",
      //   link: "/search?q=international day of the girl child",
      // },
      // {
      //   title: "World Food Day",
      //   link: "/search?q=world food day",
      // },
      {
        title: "Diwali",
        link: "/search?q=diwali",
      },
      // {
      //   title: "Halloween",
      //   link: "/search?q=halloween",
      // },
    ],
    november: [
      // {
      //   title: "Govardhan Puja",
      //   link: "/search?q=govardhan puja",
      // },
      // {
      //   title: "Bhai Dooj",
      //   link: "/search?q=bhai dooj",
      // },
      // {
      //   title: "Chhath Puja",
      //   link: "/search?q=chhath puja",
      // },
      {
        title: "Children's Day",
        link: "/search?q=childrens day",
      },
      // {
      //   title: "International Students Day",
      //   link: "/search?q=international students day",
      // },
      // {
      //   title: "International Men's Day",
      //   link: "/search?q=international mens day",
      // },
      {
        title: "Thanksgiving",
        link: "/search?q=thanksgiving day",
      },
    ],
    december: [
      // {
      //   title: "AIDS Awareness Day",
      //   link: "/search?q=world aids awareness day",
      // },
      // {
      //   title: "Civil Aviation Day",
      //   link: "/search?q=civil aviation day",
      // },
      // {
      //   title: "Human Rights Day",
      //   link: "/search?q=human rights day",
      // },
      {
        title: "Christmas",
        link: "/search?q=christmas",
      },
      {
        title: "New Year's Eve",
        link: "/search?q=new years eve",
      },
    ],
  };

  // fucntion handle the overlay div on hover of header tabs
  const handleMouseEnter = () => {
    const bgsElement = document.getElementById("bgs");
    if (bgsElement) {
      bgsElement.style.display = "block";
    }
  };

  // fucntion handle the overlay div on hover of header tabs
  const handleMouseLeave = () => {
    const bgsElement = document.getElementById("bgs");
    if (bgsElement) {
      bgsElement.style.display = "none";
    }
    setActiveTab("usecase");
  };

  // fucntion handle the overlay div on hover of header tabs
  useEffect(() => {
    const hoverElements = document.querySelectorAll(".header-tab-span");

    hoverElements.forEach((element) => {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      hoverElements.forEach((element) => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const allTabs = document.querySelectorAll(".header-tab-span");
      const clickedTab = event.target.closest(".header-tab-span");

      // Remove 'no-hover' class from all tabs
      allTabs.forEach((tab) => tab.classList.remove("no-hover"));

      if (clickedTab) {
        // Add 'no-hover' class to clicked tab to disable hover
        clickedTab.classList.add("no-hover");

        // Remove 'no-hover' class when mouse leaves
        clickedTab.addEventListener(
          "mouseleave",
          () => clickedTab.classList.remove("no-hover"),
          { once: true }
        );
      }
    };

    // Attach event listener to document (event delegation)
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // console.log(subMenu, "submenu", selectedCategories, "selectedCategories");

  return (
    <div className="header-container">
      {/* main header section */}
      <div className="header-main-section">
        {!isMobileMenuOpen && (
          <div className="header-logo-tabs">
            {/* Mobile menu icon and logo */}
            <div className="mobile-menu-logo">
              <MdMenu
                color="#434343"
                size={28}
                className="menu-icon"
                onClick={toggleMobileMenu}
              />
              <Link to="/">
                <img
                  src={config.s3imgUrl + "web/logos/smash-bright.png"}
                  // src={config.s3imgUrl + "web/logos/smash.png"}
                  // src={config.s3imgUrl + "web/logos/smash-xmas.png"}
                  alt="logo"
                  className="header-logo"
                />
              </Link>
            </div>

            {/* Mobile cart and search icons */}
            <div className="mobile-icons">
              {/* <img
                src={config.s3imgUrl + "icons/carticon.png"}
                alt="cart"
                className="cart-icon"
                onClick={() => setIsCartOpen(true)}
              /> */}
              <div
                onClick={() => setIsCartOpen(true)}
                className="cart-icon-container"
              >
                <img
                  src={config.s3imgUrl + "icons/carticon.png"}
                  alt="logo"
                  className="cart-icon"
                />
                <div className="cartCountHeader">{totalItemCount}</div>
              </div>
              <LuSearch
                size={24}
                color={isSearchActive ? "var(--button-primary)" : "#434343"} // Change color if search is active
                onClick={toggleSearch}
                className="search-icon"
              />
            </div>

            {/* Header tabs for desktop */}
            <div className="header-tabs">
              {/* smashits */}
              <div
                className="header-tab-span"
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <span>SmashHits</span>
                {/* hover div of SmashHits */}
                <div className="hover-content">
                  {/* eventsList */}
                  <div className="smashits-upcoming">
                    <p>Upcoming Events</p>
                    {/* <p>Trending Songs</p> */}
                    <div>
                      {upcomingEvents.eventsList.map((event, index) => (
                        <Link
                          to={`/search?searchKeyword=${encodeURIComponent(
                            event.title
                          )}&hs_ref=smashits_header`}
                        >
                          <div
                            key={index}
                            className="smashits-upcoming-content"
                          >
                            {/* <img
                            className="smashits-upcoming-image"
                            src={event.imgUrl}
                            alt={event.title}
                          /> */}
                            <div className="calendar-event">
                              <div className={event.className}>
                                {event.month}
                              </div>
                              <div className="calendar-event-date">
                                {event.date}
                              </div>
                            </div>
                            {/* <CustomImg
                              type="square"
                              id={event.title}
                              alt={event.title}
                              imgClass="smashits-upcoming-image"
                              src={event.imgUrl}
                            /> */}
                            <p>{event.title}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  {/* bannerList */}
                  <div className="smashits-banners-container">
                    {upcomingEvents.bannerList.map((banner, index) => (
                      <Link to={banner.url}>
                        <BgImageDiv
                          type="cat"
                          id={index}
                          divClass="smashits-banners"
                          src={banner.imgUrl}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              {/* categories */}
              <div className="header-tab-span">
                <span>Categories</span>
                <div className="hover-content cate-width">
                  <div className="categories-subheader-container">
                    {/* Header Tabs */}
                    <div className="categories-subheader-heading">
                      <Link to={"/use-cases"}>
                        <span
                          onMouseOver={() => setActiveTab("usecase")}
                          style={{
                            color:
                              activeTab === "usecase"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Use Cases
                        </span>
                      </Link>
                      <Link to={"/moods"}>
                        <span
                          onMouseOver={() => setActiveTab("mood")}
                          style={{
                            color:
                              activeTab === "mood"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Moods
                        </span>
                      </Link>

                      <Link to={"/genres"}>
                        <span
                          onMouseOver={() => setActiveTab("genre")}
                          style={{
                            color:
                              activeTab === "genre"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Genres
                        </span>
                      </Link>
                    </div>

                    {/* Content Sections */}
                    <div className="categories-subheader-items">
                      {/* Moods Section */}
                      {/* Mapping categories.moods to dynamically display content */}

                      {categories.moods.map(
                        (mood, index) =>
                          activeTab === "mood" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/moods/${encodeURIComponent(mood.slug)}/${
                                  mood.id
                                }`}
                              >
                                <p>{mood.title}</p>
                              </Link>
                            </div>
                          )
                      )}

                      {/* Genre Section */}

                      {categories.genre.map(
                        (genre, index) =>
                          activeTab === "genre" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/genres/${encodeURIComponent(
                                  genre.slug
                                )}/${genre.id}`}
                              >
                                <p>{genre.title}</p>
                              </Link>
                            </div>
                          )
                      )}
                      {/* Use Case Section */}
                      {categories.usecase.map(
                        (usecase, index) =>
                          activeTab === "usecase" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/use-cases/${encodeURIComponent(
                                  usecase.slug
                                )}/${usecase.id}`}
                              >
                                <p>{usecase.title}</p>
                              </Link>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* occasions */}
              <div className="header-tab-span">
                <span>Occasions</span>
                <div className="hover-content occasion-width">
                  <div className="occasion-subheader-container">
                    {Object.entries(occasionsItems).map(
                      ([month, occasions]) => (
                        <div key={month} className="occasion-list">
                          {month === "may" ? (
                            <p className="may-t">
                              {month.charAt(0).toUpperCase() + month.slice(1)}
                            </p>
                          ) : (
                            <p className={month}>
                              {month.charAt(0).toUpperCase() + month.slice(1)}
                            </p>
                          )}
                          {occasions.map((occasion, index) => (
                            <Link
                              style={{ margin: 0, padding: 0 }}
                              to={`/search?searchKeyword=${encodeURIComponent(
                                occasion.title
                              )}&hs_ref=occasion_header`}
                            >
                              <span key={index}>{occasion.title}</span>
                            </Link>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* packs */}
              {/* <div className="header-tab-span">
              <Link to="/packs">
                <span>Packs</span>
              </Link>
              <div className="hover-content hc-packs">
                <div>
                  <p className="smashits-subheader-p">A-Z</p>
                  <div className="pack-sub-header-container">
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s1 */}
              {/* {packsMenuItems.s1.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s2 */}
              {/* {packsMenuItems.s2.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s3 */}
              {/* {packsMenuItems.s3.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {/* filter tracks */}
              {/* <div className="header-tab-span">
              <span>Filter Tracks</span>
              <div className="hover-content ft-track-width">
                <div className="ft-subheader"> */}
              {/* track by budget */}
              {/* <div className="ft-by-budget">
                    <p>By Budget</p>
                    <div className="ft-by-budget-list">
                      {filterTracksItems.budget.map((item, index) => (
                        <div key={index} className="ft-by-budget-list-item">
                          <span>
                            <a href={item.link}>{item.title}</a>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div> */}
              {/* track by type */}
              {/* <div className="ft-by-type">
                    <p>By Type</p>
                    <div className="ft-by-type-list">
                      {filterTracksItems.byType.map((item, index) => (
                        <div className="ft-type-list-wrapper">
                          <div key={index} className="ft-by-type-list-item">
                            <img src={item.img} alt={item.title} />
                          </div>
                          <p style={{ margin: 0 }}>{item.title}</p>
                        </div>
                      ))}
                    </div>
                  </div> */}
              {/* track by language */}
              {/* <div className="ft-by-language">
                    <p>By Language</p>
                    <div className="ft-by-language-list-container">
                      <div className="ft-by-language-list">
                        {filterTracksItems.byLanguage.s1.map((item, index) => (
                          <Link
                            style={{ margin: 0, padding: 0 }}
                            to={`/search?searchKeyword=${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <div key={index}>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                      <div className="ft-by-language-list">
                        {filterTracksItems.byLanguage.s2.map((item, index) => (
                          <Link
                            style={{ margin: 0, padding: 0 }}
                            to={`/search?searchKeyword=${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <div key={`s2-${index}`}>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div> */}
              {/* </div>
              </div>
            </div> */}
              {/* Explore More */}
              <div className="header-tab-span">
                <span>License</span>
                <div className="hover-content explore-width">
                  <div className="ft-subheader">
                    <div>
                      <div className="explore-container">
                        <div className="explore-list">
                          <div className="explore-wrapper">
                            <div className="explore-section">
                              <Link to={"/tracks"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/license/tracks.webp`}
                                    alt="Explore Tracks"
                                  />
                                <span>Tracks</span>
                                </div>
                              </Link>
                            </div>
                            <div className="explore-section">
                              <Link to={"/packs"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/license/packs.webp`}
                                    alt="Explore Packs"
                                  />
                                <span>Combos & Packs</span>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="explore-wrapper">
                            <div className="explore-section">
                              <Link to={"/playlists"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/license/playlists.webp`}
                                    alt="Explore Playlists"
                                  />
                                <span>Playlists</span>
                                </div>
                              </Link>
                            </div>
                            <div className="explore-section">
                              <Link to={"/languages"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/license/languages.webp`}
                                    alt="Explore Languages"
                                  />
                                <span>Languages</span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to={`/playlists/dance-numbers/31`}> */}
              {/* <Link to={`/foryou`}>
              <div className="header-tab-span">
                <span>For You</span>
              </div>
            </Link> */}
              {/* search bar */}
              <div style={{ marginLeft: 25 }}>
                <SearchBar
                  placeholder="Search on smash"
                  iconPosition="end"
                  width="280px"
                  height="44px"
                />
              </div>
            </div>
          </div>
        )}

        {/* login and cart section */}
        <div className="header-login-cart">
          <Link to={`/contact-sales`}>
            <div className="demo-r">Request a Demo</div>
          </Link>

          {/* <div>
            <a href="https://forms.gle/Trec9poXtsNpHnJJ8" target="_blank">
              <CustomButton
                text="Call for Artists"
                borderRadius="30px"
                height="39px"
                borderColor="none"
                backgroundColor="#F7F7F7"
                width="172px"
                fontSize="16px"
                fontWeight="550"
                fontFamily="Raleway"
                textColor="var(--button-primary)"
              />
            </a>
          </div> */}
          {isAuthenticated ? (
            <div className="user-dropdown-container">
              <FaRegFaceLaughBeam
                className="user-icon"
                size={25}
                // onClick={() => setIsModalOpen(true)}
                // style={{ backgroundColor: "#FFEBF1" }}
              />
              <div className="user-dropdown">
                <ul>
                  <Link to={`/user`}>
                    <li>Profile</li>
                  </Link>
                  <Link to={`/user/Purchases`}>
                    <li>Purchases</li>
                  </Link>
                  <Link to={`/user/likes`}>
                    <li>Likes</li>
                  </Link>
                  <Link to={`/user/collection`}>
                    <li>Collections</li>
                  </Link>
                  <li className="logout" onClick={handleLogout}>
                    <RiLogoutCircleRLine /> Logout
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div>
              <CustomButton
                text="Log In"
                borderRadius="30px"
                height="39px"
                borderColor="none"
                backgroundColor="var(--button-primary)"
                width="172px"
                fontSize="14px"
                fontWeight="550"
                fontFamily="inter"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          )}

          {/* SUPPORT SECTION */}
          <div className="header-tabs">
            <div className="header-tab-span">
              {/* <span><BiSupport size={24} /></span> */}
              <span>Support</span>
              <div className="hover-content support-width">
                <div className="ft-subheader">
                  <div>
                    <div className="explore-container">
                      <div className="support-list">
                        <div className="support-wrapper">
                          <Link to={"/how-to-use"}>
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <LuMousePointerClick />
                              </span>
                              Using Hoopr Smash
                            </p>
                          </Link>
                          <Link to={"/about"}>
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <FaInfo />
                              </span>
                              About Hoopr Smash
                            </p>
                          </Link>
                          <a href="https://blog.hooprsmash.com" target="_blank">
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <FaRegNewspaper />
                              </span>
                              Hoopr Smash Blog
                            </p>
                          </a>
                          <p>
                            <hr />
                          </p>
                          <Link to={"/faqs"}>
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <FaQuestion />
                              </span>
                              FAQs
                            </p>
                          </Link>
                          <Link to={"/contact-us"}>
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <MdHeadsetMic />
                              </span>
                              Contact Support
                            </p>
                          </Link>
                          <a
                            href="https://forms.gle/Trec9poXtsNpHnJJ8"
                            target="_blank"
                          >
                            <p className="support-list-p">
                              <span className="support-icon-holder">
                                <RiMusicAiFill />
                              </span>
                              Call for Artists
                            </p>
                          </a>
                          <p
                            className="support-list-p"
                            onClick={() => setIsRequestOpen(true)}
                          >
                            <span className="support-icon-holder">
                              <PiWaveformBold />
                            </span>
                            Request a Song
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CART SECTION */}
          <div onClick={() => setIsCartOpen(true)} className="header-cart">
            <div className="cart-icon-container">
              <img
                src={config.s3imgUrl + "icons/carticon.png"}
                alt="logo"
                className="cart-icon"
              />
              <div className="cartCountHeader">{totalItemCount}</div>
            </div>
          </div>
        </div>

        <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
      </div>

      {/* Conditional SearchBar for mobile view */}
      {isSearchActive && (
        <div className="mobile-search-bar">
          <SearchBar placeholder="Search on smash" iconPosition="start" />
        </div>
      )}

      {/* Mobile sidebar menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu-content">
            <div className="close-icon">
              <IoCloseCircleOutline
                size={24}
                color="#434343"
                onClick={toggleMobileMenu}
              />
            </div>
            {!subMenu ? (
              <>
                {/* smashhits options */}
                <div
                  onClick={() => toggleMenu("smashhits")}
                  className="mobilemenucontainer"
                >
                  <span>SmashHits</span>
                  {activeMenu !== "smashhits" && (
                    <IoIosArrowForward size={18} />
                  )}
                </div>
                {activeMenu === "smashhits" && (
                  <div className="mobilehl2">
                    {Object.keys(smashHitsMobile).map((category, index) => {
                      const formattedCategory = category
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase());
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            openSubMenu("smashhits");
                            setSelectedSmashHits(category);
                          }}
                          className="msubmenutxt"
                        >
                          <p>{formattedCategory}</p>
                          <IoIosArrowRoundForward size={18} />
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* categories options */}
                <div
                  onClick={() => toggleMenu("categories")}
                  className="mobilemenucontainer"
                >
                  <span>Categories</span>
                  {activeMenu !== "categories" && (
                    <IoIosArrowForward size={18} />
                  )}
                </div>
                {activeMenu === "categories" && (
                  <div className="mobilehl2">
                    {categoriesMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          // console.log(item, "item");

                          if (item === "Moods") {
                            // console.log("Opening subMenu: Moods");
                            openSubMenu("Moods");
                          }
                          if (item === "Use Cases") openSubMenu("Use Cases");
                          if (item === "Genres") openSubMenu("Genres");
                          setSelectedCategories(item);
                          // console.log(item, "item");
                        }}
                        className="msubmenutxt"
                      >
                        <p>{item}</p>
                        <IoIosArrowRoundForward size={18} />
                      </div>
                    ))}
                  </div>
                )}
                {/* license options */}
                <div
                  onClick={() => toggleMenu("explore")}
                  className="mobilemenucontainer"
                >
                  <span>License</span>
                  {activeMenu !== "explore" && <IoIosArrowForward size={18} />}
                </div>
                {activeMenu === "explore" && (
                  <div className="mobilehl2">
                    {exploreMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            item.link,
                            item.link.startsWith("http")
                          )
                        }
                        className="msubmenutxt"
                      >
                        {item.link ? (
                          item.link.startsWith("http") ? (
                            <p>{item.name}</p> // External links (e.g., Blog)
                          ) : (
                            <Link to={item.link}>
                              <p>{item.name}</p>
                            </Link>
                          )
                        ) : (
                          <p>{item.name}</p>
                        )}
                        {/* <IoIosArrowRoundForward size={18} /> */}
                      </div>
                    ))}
                  </div>
                )}

                {/* Occasions */}
                <div
                  onClick={() => toggleMenu("occasions")}
                  className="mobilemenucontainer"
                >
                  <span>Occasions</span>
                  {activeMenu !== "occasions" && (
                    <IoIosArrowForward size={18} />
                  )}
                </div>
                {activeMenu === "occasions" && (
                  <div className="mobilehl2">
                    {Object.keys(occasionsItems).map((category, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          openSubMenu("occasions");
                          setSelectedOccasionCategory(category);
                        }}
                        className="msubmenutxt"
                      >
                        <p>
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </p>
                        <IoIosArrowRoundForward size={18} />
                      </div>
                    ))}
                  </div>
                )}

                {/* support options */}
                <div
                  onClick={() => toggleMenu("support")}
                  className="mobilemenucontainer"
                >
                  <span>Support</span>
                  {activeMenu !== "support" && <IoIosArrowForward size={18} />}
                </div>
                {activeMenu === "support" && (
                  <div className="mobilehl2">
                    {supportMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            item.link,
                            item.link.startsWith("http")
                          )
                        }
                        className="msubmenutxt"
                      >
                        {item.link ? (
                          item.link.startsWith("http") ? (
                            <p>{item.name}</p> // External links (e.g., Blog)
                          ) : (
                            <Link to={item.link}>
                              <p>{item.name}</p>
                            </Link>
                          )
                        ) : (
                          <p>{item.name}</p>
                        )}
                        {/* <IoIosArrowRoundForward size={18} /> */}
                      </div>
                    ))}
                  </div>
                )}

                {/* smashhits options */}
                <div
                  onClick={() => toggleMenu("myaccount")}
                  className="mobilemenucontainer"
                >
                  <span>My Account</span>
                  {activeMenu !== "myaccount" && (
                    <IoIosArrowForward size={18} />
                  )}
                </div>
                {activeMenu === "myaccount" && (
                  <div className="mobilehl2">
                    {myAccountMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            item.link,
                            item.link.startsWith("http")
                          )
                        }
                        className="msubmenutxt"
                      >
                        {item.link ? (
                          item.link.startsWith("http") ? (
                            <p>{item.name}</p> // External links (e.g., Blog)
                          ) : (
                            <Link to={item.link}>
                              <p>{item.name}</p>
                            </Link>
                          )
                        ) : (
                          <p>{item.name}</p>
                        )}
                        {/* <IoIosArrowRoundForward size={18} /> */}
                      </div>
                    ))}
                  </div>
                )}

                {/* login/logout button */}
                {isAuthenticated ? (
                  <div className="custom-button-container">
                    <CustomButton
                      text="Logout"
                      borderRadius="30px"
                      height="39px"
                      borderColor="none"
                      backgroundColor="var(--button-primary)"
                      width="100%"
                      fontSize="14px"
                      fontWeight="550"
                      fontFamily="inter"
                      className="custom-button"
                      onClick={handleLogout}
                    />
                  </div>
                ) : (
                  <div className="custom-button-container">
                    <CustomButton
                      text="Log In"
                      borderRadius="30px"
                      height="39px"
                      borderColor="none"
                      backgroundColor="var(--button-primary)"
                      width="100%"
                      fontSize="14px"
                      fontWeight="550"
                      fontFamily="inter"
                      className="custom-button"
                      onClick={() => setIsModalOpen(true)}
                    />
                  </div>
                )}
              </>
            ) : (
              // subMenu ----------------------------------------
              <div className="mobile-submenu">
                <span
                  style={{ borderBottom: "none" }}
                  className="spanBack"
                  onClick={() => setSubMenu(null)}
                >
                  ← Back
                </span>
                {subMenu === "Moods" && selectedCategories && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/moods")}>View All</p>
                    {categories.moods.map((mood, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/moods/${encodeURIComponent(mood.slug)}/${mood.id}`
                          )
                        }
                      >
                        <p>{mood.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {subMenu === "Genres" && selectedCategories && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/genres")}>View All</p>
                    {categories.genre.map((genre, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/genres/${encodeURIComponent(genre.slug)}/${
                              genre.id
                            }`
                          )
                        }
                      >
                        <p>{genre.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {subMenu === "Use Cases" && selectedCategories && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/use-cases")}>
                      View All
                    </p>
                    {categories.usecase.map((usecase, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/use-cases/${encodeURIComponent(usecase.slug)}/${
                              usecase.id
                            }`
                          )
                        }
                      >
                        <p>{usecase.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {/* Show Occasions for Selected Category */}
                {subMenu === "occasions" && selectedOccasionCategory && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    {occasionsItems[selectedOccasionCategory]?.map(
                      (occasion, index) => (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(occasion.link)}
                        >
                          <p>{occasion.title}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
                {/* Show selected smash hits list */}
                {subMenu === "smashhits" && selectedSmashHits && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    {smashHitsMobile[selectedSmashHits]?.map(
                      (occasion, index) => (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(occasion.link)}
                        >
                          <p>{occasion.title}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <RequestSong
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
      />
    </div>
  );
};

export default Header;
