import React, { useContext, useState } from "react";
import "./PackCard.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { RiShoppingCart2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
// import randomDefaultImage from "../../../utils/RandomDefaultImage";
import { addToCart } from "../../../utils/redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../../utils/ToastContext";
import { putCart } from "../../../service/TransactionService";
import { FaHeart } from "react-icons/fa";
import RequestLicense from "../../../screens/RequestLicense";
import config from "../../../utils/config";
import useMixpanel from "../../../service/MixpanelService";
import Login from "../../../screens/Login";
import { AiOutlineDelete } from "react-icons/ai";
import CleanSearchKey from "../../../utils/CleanSearchKey";

const CardComponent = ({
  packCards,
  packType,
  showPrice = true,
  showLikeIcon = false,
  likedStatus = false,
  handlePackRemoveLike,
  showDeleteIcon = false,
  handlePackDelete,
}) => {
  const dispatch = useDispatch(); // Hook to dispatch actions
  const items = useSelector((state) => state.cart.items); // Redux selector
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle login modal

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  // console.log(packCards);

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  const handleCartClick = async (pack) => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
      return;
    }
    // console.log(pack, "pack info for cart");
    const cartInfo = {
      cardType: "pack", // card type to display
      preText: pack.title, // pack name
      secText: "", // pack description
      code: pack.packCode, // pack code
      sp: pack.sellingPrice, // selling price
      cp: pack.costPrice, // cost price
      cardImg: pack.image, // card img based on pack
      skuID: pack.skuId || "",
    };

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(pack.packCode);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [{ skuId: pack.skuId || "", qty: updatedQuantity, cartType: "C" }],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      // Track the add to cart event
      trackUserEvent("addtocart", pack);
    } else {
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // fucntion to open request quote screen
  const handleRequestQuote = (pack) => {
    const licenseData = {
      title: pack.title,
      subTitle: "",
      img: config.s3imgUrl + "web/playlists/" + pack.packCode + ".webp",
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
    trackUserEvent("requestquote-initiated", pack);
  };

  // common function for track user event
  const trackUserEvent = (eventName, pack) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    trackEvent(eventName, {
      url: window.location.href,
      packName: pack.title,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      type: "pack-card",
      code: pack.packCode,
    });
  };

  return (
    <div className="packs-cards-container" data-type={packType}>
      {packCards.map((pack, index) => (
        <div key={index} className="pack-card">
          <div className="pack-title">
            <Link
              to={`/playlists/pack-${CleanSearchKey(pack.title)}/${
                pack.packCode
              }`}
            >
              <h1 className="pack-heading">
                {pack.title.length > 35
                  ? pack.title.substring(0, 32) + "..."
                  : pack.title}
              </h1>
            </Link>
            {/* <span>Pack of {pack.totalTracks} tracks</span> */}
            <span>{pack.totalTracks} tracks</span>
          </div>

          <Link
            to={`/playlists/pack-${CleanSearchKey(pack.title)}/${
              pack.packCode
            }`}
          >
            <div
              className="pack-card-img"
              style={{
                backgroundImage: `url(${
                  config.s3imgUrl + "web/playlists/" + pack.packCode + ".webp"
                })`,
              }}
            >
              {showLikeIcon && (
                <div
                  className="card-like-info-pack"
                  style={{ opacity: likedStatus ? 100 : 50 }}
                >
                  {likedStatus ? (
                    <FaHeart
                      onClick={() => handlePackRemoveLike(pack.packCode)}
                      color="#E90B2F"
                      size={24}
                      cursor="pointer"
                    />
                  ) : (
                    <FaRegHeart cursor="pointer" size={24} color="#fff" />
                  )}
                </div>
              )}
              {showDeleteIcon && (
                <div className="card-del-info">
                  <AiOutlineDelete
                    cursor="pointer"
                    size={24}
                    color="var(--button-primary)"
                    onClick={() => handlePackDelete(pack.packCode)}
                  />
                </div>
              )}
            </div>
          </Link>

          <div className="pack-price-button">
            {/* price section */}
            <div className="pack-price-container">
              <div className="pack-discount-price">
                <div className="pack-discount-amount">
                  {/* <span>₹</span>
                  <p>
                    {pack.sellingPrice
                      ? Number(pack.sellingPrice).toLocaleString("en-IN")
                      : ""}
                  </p> */}
                  {pack.sellingPrice && pack.costPrice ? (
                    <>
                      <span>₹</span>
                      <p>{Number(pack.sellingPrice).toLocaleString("en-IN")}</p>
                    </>
                  ) : (
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "normal",
                        letterSpacing: 0.1,
                        color: "#717070",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleRequestQuote(pack);
                      }}
                    >
                      Request Quote
                    </p>
                  )}
                </div>
                {showPrice &&
                  pack.costPrice > 0 &&
                  pack.sellingPrice !== pack.costPrice && (
                    <div className="pack-discount-percentage">
                      {pack.discountPercent}
                    </div>
                  )}
              </div>
              {showPrice &&
                pack.sellingPrice &&
                pack.costPrice &&
                pack.sellingPrice !== pack.costPrice && (
                  <div className="pack-cost-price">
                    <span>
                      ₹{Number(pack.costPrice).toLocaleString("en-IN")}
                    </span>
                  </div>
                )}
            </div>
            {/* buttons section */}
            {showPrice && (
              <div className="explore-track-button">
                <Link
                  to={`/playlists/pack-${CleanSearchKey(pack.title)}/${
                    pack.packCode
                  }`}
                  className="explore-pack-btn"
                >
                  {pack.buttonText}
                  <IoIosArrowForward color="var(--button-primary)" size={20} />
                </Link>

                {pack.sellingPrice !== pack.costPrice && (
                  <button
                    onClick={() => {
                      handleCartClick(pack);
                    }}
                    className="explore-pack-cart-btn"
                  >
                    {<RiShoppingCart2Line color="#fff" size={20} />}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
      {/* login modal popup */}
      <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default CardComponent;
