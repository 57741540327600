import store from "./redux/store";

export const getAuthHeaders = () => {
  const state = store.getState();

  if (state.auth.token === null) {
    return {};
  }
  const accessToken = state.auth.token;
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
