import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import styles from "../styles/Home.module.css";
// import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward, IoMdCloseCircle } from "react-icons/io";
// import { RiShoppingCart2Line } from "react-icons/ri";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";
// import PackCard from "../components/CardsComponent/PackCard/PackCard";
import PlaylistCard from "../components/CardsComponent/PlaylistCard/PlaylistCard";
import { Link } from "react-router-dom";
// import randomDefaultImage from "../utils/RandomDefaultImage";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import { getMultiTrack } from "../service/TrackService";
import getDirectLink from "../utils/AudioUrlFormated";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import CustomImg from "../components/CustomImg/CustomImg";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import setMetaTags from "../utils/SeoService";
import Carousel from "../components/Carousel/Carousel";
import priceFilter from "../utils/FilterBase64";
import useRestoreCart from "../utils/RestoreCartTemp"; // temp access for manage the direct buy now function
import TrendingCard from "../components/CardsComponent/TrackCard/TrendingCard";
import PackCard from "../components/CardsComponent/PackCard/PackCard";

const Home = () => {
  const [instaTracks, setInstaTracks] = useState([]); // instaTracks
  const [t999, setT999] = useState([]); // track under 999
  const [t20, setT20] = useState([]); // track under t20
  const [indieTracks, setIndieTracks] = useState([]); // indie tracks

  const [videoModal, setVideoModal] = useState(false); // show and hide the video modal

  // const [bannerPopup, setBannerPopup] = useState(false); // to display know more banner

  // const [featureTracks, setFeatureTracks] = useState([]); // feature tracks data
  // const [contemporaryTracks, setContemporaryTracks] = useState([]); // contemporary track data
  // const [allTimeTracks, setAllTimeTracks] = useState([]); // all time tracks
  // const [t2999, setT2999] = useState([]); // track under 2999
  // const [t4999, setT4999] = useState([]); // track under 4999

  // ***************************************** temp access for manage the direct buy now function
  const restoreCart = useRestoreCart(); // Hook to dispatch actions //temp access for manage the direct buy now function

  useEffect(() => {
    restoreCart(); // Call the function
  }, []); // Runs only once when the component mounts
  // ***************************************** temp access for manage the direct buy now function

  // home page top main banners
  const mainBanner = [
    {
      image: `${config.s3imgUrl}web/promotional/home/license.png`,
      buttonText: "Watch Video",
      buttonLink: "/about",
    },
    {
      image: `${config.s3imgUrl}web/promotional/home/ram-navami.webp`,
      buttonText: "Explore Tracks",
      buttonLink: `/search?searchKeyword=ram navami&hs_ref=home-top-banner`,
      // IMPORTANT: EXPIRY DATE SHOULD BE 1 DAY AFTER THE EVENT
      expiry: "2025-04-07",
    },
    {
      image: `${config.s3imgUrl}web/promotional/home/cricket.webp`,
      buttonText: "Explore Tracks",
      buttonLink: `/search?searchKeyword=cricket&hs_ref=home-top-banner`,
      // IMPORTANT: EXPIRY DATE SHOULD BE 1 DAY AFTER THE EVENT
      expiry: "2025-05-26",
    },
    {
      image: `${config.s3imgUrl}web/promotional/home/summer.webp`,
      buttonText: "View Playlist",
      buttonLink: `/playlists/summer-smashes/284?hs_ref=home-top-banner`,
      // IMPORTANT: EXPIRY DATE SHOULD BE 1 DAY AFTER THE EVENT
      expiry: "2025-06-01",
    },
    {
      image: `${config.s3imgUrl}web/promotional/home/bolly.webp`,
      buttonText: "View Playlist",
      buttonLink: "/playlists/popular-bollywood/14?hs_ref=home-top-banner",
    },
    {
      image: `${config.s3imgUrl}web/promotional/home/bohemia.webp`,
      buttonText: "Explore Tracks",
      buttonLink: `/search?searchKeyword=bohemia&priceFilters=${priceFilter({
        min: 1999,
        max: "",
      })}&hs_ref=home-banner`,
    },
  ];

  // Get current date for home page top main banners
  const today = new Date();

  // Filter banners based on expiry date
  const validBanners = mainBanner.filter((banner) => {
    if (!banner.expiry) return true; // No expiry means it's always valid
    const expiryDate = new Date(banner.expiry); // Directly create Date object from YYYY-MM-DD
    return expiryDate > today; // Show only if expiry is in the future
  });

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // setting the seo meta on page renders
  useEffect(() => {
    const seoData = {
      title: "Music Licensing for Video, Film & Advertising | Hoopr Smash",
      description:
        "License songs for video, film, and advertising on Hoopr Smash. Access a wide range of music tracks to enhance your projects, with easy and flexible licensing options.",
      ogImage: `${config.s3imgUrl}web/logos/smash-bright.png`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  // fetching the remote config file from the firebase
  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        // Fetch and activate remote config values
        await fetchAndActivate(remoteConfig); // This waits for the config to be fetched

        // Retrieve the JSON string from Remote Config
        const jsonString = getValue(remoteConfig, "Home_Config").asString();

        // Parse the JSON string into a JavaScript object
        const parsedConfig = JSON.parse(jsonString);
        // console.log("Parsed Config:", parsedConfig);
        // console.log("Tracks Data:", parsedConfig.tracks);
        processTracks(parsedConfig.tracks);
      } catch (error) {
        console.error("Error fetching remote config:", error);
      }
    };

    fetchRemoteConfig();
  }, []);

  // Process the tracks and filter by type
  // const processTracks = (tracks) => {
  //   // Filter and convert allTimeTracks to string codes
  //   const allinstaTrackCodes = tracks
  //     .filter((track) => track.type === "instaTracks")
  //     .map((track) => String(track.trackCode)); // Convert to string
  //   // Filter and convert featureTracks to string codes
  //   // const featureTrackCodes = tracks
  //   //   .filter((track) => track.type === "featureTracks")
  //   //   .map((track) => String(track.trackCode)); // Convert to string

  //   // Filter and convert contemporaryTracks to string codes
  //   // const contemporaryTrackCodes = tracks
  //   //   .filter((track) => track.type === "contemporaryTracks")
  //   //   .map((track) => String(track.trackCode)); // Convert to string

  //   // Filter and convert indieTracks to string codes
  //   const indieTrackCodes = tracks
  //     .filter((track) => track.type === "indieTracks")
  //     .map((track) => String(track.trackCode)); // Convert to string

  //   // Filter and convert allTimeTracks to string codes
  //   // const allTimeTrackCodes = tracks
  //   //   .filter((track) => track.type === "allTimeTracks")
  //   //   .map((track) => String(track.trackCode)); // Convert to string

  //   const t999Codes = tracks
  //     .filter((track) => track.type === "tu")
  //     .map((track) => String(track.trackCode)); // Convert to string

  //   const t2999Codes = tracks
  //     .filter((track) => track.type === "t2999")
  //     .map((track) => String(track.trackCode)); // Convert to string

  //   const t4999Codes = tracks
  //     .filter((track) => track.type === "t4999")
  //     .map((track) => String(track.trackCode)); // Convert to string

  //   const t20Codes = tracks
  //     .filter((track) => track.type === "t20")
  //     .map((track) => String(track.trackCode)); // Convert to string

  //   // Fetch data for feature tracks and update state incrementally
  //   fetchTrackDataIncremental(allinstaTrackCodes, "instaTracks");

  //   // Fetch data for feature tracks and update state incrementally
  //   // fetchTrackDataIncremental(featureTrackCodes, "featureTracks");

  //   // Fetch data for contemporary tracks and update state incrementally
  //   // fetchTrackDataIncremental(contemporaryTrackCodes, "contemporaryTracks");

  //   // Fetch data for indie tracks and update state incrementally
  //   fetchTrackDataIncremental(indieTrackCodes, "indieTracks");

  //   // Fetch data for all time tracks and update state incrementally
  //   // fetchTrackDataIncremental(allTimeTrackCodes, "allTimeTracks");

  //   // Fetch data for feature tracks and update state incrementally
  //   fetchTrackDataIncremental(t999Codes, "tu");

  //   // fetchTrackDataIncremental(t2999Codes, "t2999");

  //   // fetchTrackDataIncremental(t4999Codes, "t4999");

  //   fetchTrackDataIncremental(t20Codes, "t20");
  // };

  // Fetch track data incrementally based on track codes with order
  // const fetchTrackDataIncremental = async (trackCodes, trackType) => {
  //   const fetchPromises = trackCodes.map(async (trackCode) => {
  //     const trackData = await getMultiTrack([trackCode]); // Fetch data for a single track

  //     const track = trackData?.data?.tracks?.[0];
  //     if (!track) return null; // Skip if track data is invalid

  //     const { costPrice, sellingPrice, id } = track.SKU[0];
  //     const discountPercent = (
  //       ((costPrice - sellingPrice) / costPrice) *
  //       100
  //     ).toFixed(0);

  //     const artistNames = track.primaryArtists?.length
  //       ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
  //       : "";

  //     // Process and return the track with the order field
  //     return {
  //       order: track.order, // Ensure `order` exists in the track object
  //       // image: track.imageUrl || randomDefaultImage("track"),
  //       title:
  //         track.name.length > 18
  //           ? track.name.substring(0, 17) + "..."
  //           : track.name,
  //       description:
  //         artistNames.length > 30
  //           ? artistNames.substring(0, 29) + "..."
  //           : artistNames,
  //       buttonText: "License",
  //       buttonLink: "#",
  //       discountPercent: discountPercent || "",
  //       costPrice: costPrice.toLocaleString("en-IN") || "",
  //       sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
  //       name: track.name_slug,
  //       trackCode: track.trackCode,
  //       trackUrl: track.mp3Link || getDirectLink(track.link),
  //       waveJson: track.waveformLink,
  //       skuId: id || "",
  //     };
  //   });

  //   // Process tracks incrementally as they resolve
  //   for await (const processedTrack of fetchPromises) {
  //     if (processedTrack) {
  //       const updateState = (prev) =>
  //         Array.from(
  //           new Map(
  //             [...prev, processedTrack].map((track) => [track.trackCode, track])
  //           )
  //         )
  //           .map(([, value]) => value)
  //           .sort((a, b) => a.order - b.order); // Sort based on `order`

  //       if (trackType === "instaTracks") {
  //         setInstaTracks(updateState);
  //       } else if (trackType === "indieTracks") {
  //         setIndieTracks(updateState);
  //       } else if (trackType === "tu") {
  //         setT999(updateState);
  //       } else if (trackType === "t2999") {
  //         setT2999(updateState);
  //       } else if (trackType === "t4999") {
  //         setT4999(updateState);
  //       } else if (trackType === "t20") {
  //         setT20(updateState);
  //       }
  //     }
  //   }
  // };

  const processTracks = async (tracks) => {
    const trackTypes = [
      { key: "instaTracks", codes: [] },
      { key: "indieTracks", codes: [] },
      { key: "tu", codes: [] },
      { key: "t20", codes: [] },
    ];

    // Populate track types with respective codes
    tracks.forEach((track) => {
      const trackType = trackTypes.find((t) => t.key === track.type);
      if (trackType) {
        trackType.codes.push(String(track.trackCode));
      }
    });

    // Function to fetch data for each track type
    const fetchNextTrackType = async (index) => {
      if (index >= trackTypes.length) return;

      const { key, codes } = trackTypes[index];
      if (codes.length > 0) {
        await fetchTrackDataIncremental(codes, key);
      }
      // Trigger the next track type after the current one completes
      fetchNextTrackType(index + 1);
    };

    // Start fetching sequentially
    fetchNextTrackType(0);
  };

  const fetchTrackDataIncremental = async (trackCodes, trackType) => {
    if (trackCodes.length === 0) return;

    try {
      // Fetch data for all track codes in one API call
      const trackData = await getMultiTrack(trackCodes);

      if (!trackData?.data?.tracks?.length) return;

      const processedTracks = trackData.data.tracks
        .map((track) => {
          if (!track || !track.SKU?.length) return null;

          const { costPrice, sellingPrice, id } = track.SKU[0];
          const discountPercent = (
            ((costPrice - sellingPrice) / costPrice) *
            100
          ).toFixed(0);

          const artistNames = track.primaryArtists?.length
            ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
            : "";

          return {
            order: track.order,
            title:
              track.name.length > 18
                ? track.name.substring(0, 17) + "..."
                : track.name,
            description:
              artistNames.length > 30
                ? artistNames.substring(0, 29) + "..."
                : artistNames,
            buttonText: "License",
            buttonLink: "#",
            discountPercent: discountPercent || "",
            costPrice: costPrice.toLocaleString("en-IN") || "",
            sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
            name: track.name_slug,
            trackCode: track.trackCode,
            trackUrl: track.mp3Link || getDirectLink(track.link),
            waveJson: track.waveformLink,
            skuId: id || "",
            vocal: track.vocal,
          };
        })
        .filter(Boolean); // Remove any null values

      if (processedTracks.length > 0) {
        const updateState = (prev) =>
          Array.from(
            new Map(
              [...prev, ...processedTracks].map((track) => [
                track.trackCode,
                track,
              ])
            )
          )
            .map(([, value]) => value)
            .sort((a, b) => a.order - b.order); // Sort based on `order`

        // Update the corresponding state
        if (trackType === "instaTracks") {
          setInstaTracks(updateState);
        } else if (trackType === "indieTracks") {
          setIndieTracks(updateState);
        } else if (trackType === "tu") {
          setT999(updateState);
          // } else if (trackType === "t2999") {
          //   setT2999(updateState);
          // } else if (trackType === "t4999") {
          //   setT4999(updateState);
        } else if (trackType === "t20") {
          setT20(updateState);
        }
      }
    } catch (error) {
      console.error("Error fetching track data:", error);
    }
  };

  // for know more image banners
  // const imagePoints = ["Point1.png", "Point2.png", "Point3.png"];

  const catEssentials = [
    {
      image: `${config.s3imgUrl}web/playlists/59.webp`,
      title: "Cooking - Ethnic",
      buttonText: "License",
      buttonLink: "/playlists/pack-cooking-ethnic/59",
      totalTracks: 4,
      packCode: 59,
      packNameSlug: "cooking-ethnic",
      sellingPrice: "2649",
      costPrice: "2995",
      discountPercent: `${(((2995 - 2649) / 2995) * 100).toFixed(0)}% Off`,
      skuId: "etedlgyCpmFr5ecrVV3",
      type: "pack",
      description:
        "Add flavor to your ethnic culinary adventures with authentic, culturally inspired melodies. Perfect for traditional cooking content.",
    },
    {
      image: `${config.s3imgUrl}web/playlists/113.webp`,
      title: "Fashion Ethnic Wear",
      buttonText: "License",
      buttonLink: "/playlists/pack-fashion-ethnic-wear/113",
      totalTracks: 5,
      packCode: 113,
      packNameSlug: "fashion-ethnic-wear",
      sellingPrice: "66999",
      costPrice: "74995",
      discountPercent: `${(((74995 - 66999) / 74995) * 100).toFixed(0)}% Off`,
      skuId: "EJ2TYWZ6H8u9U5682OOx",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/72.webp`,
      title: "Incredible India",
      buttonText: "License",
      buttonLink: "/playlists/pack-travel-incredible-india/72",
      totalTracks: 5,
      packCode: 72,
      packNameSlug: "travel-incredible-india",
      sellingPrice: "61999",
      costPrice: "69995",
      discountPercent: `${(((69995 - 61999) / 69995) * 100).toFixed(0)}% Off`,
      skuId: "cn612nNRwyQGTILwdvs",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/80.webp`,
      title: "Art & Craft",
      buttonText: "License",
      buttonLink: "/playlists/pack-kids-art-and-craft/80",
      totalTracks: 5,
      packCode: 80,
      packNameSlug: "kids-art-and-craft",
      sellingPrice: "43999",
      costPrice: "49995",
      discountPercent: `${(((49995 - 43999) / 49995) * 100).toFixed(0)}% Off`,
      skuId: "LbrlPSZwcw5rpnweBrML",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/82.webp`,
      title: "EVs Exclusive",
      buttonText: "License",
      buttonLink: "/playlists/pack-automobile-evs-exclusive/82",
      totalTracks: 5,
      packCode: 82,
      packNameSlug: "automobile-evs-exclusive",
      sellingPrice: "2649",
      costPrice: "2995",
      discountPercent: `${(((2995 - 2649) / 2995) * 100).toFixed(0)}% Off`,
      skuId: "zDAVMCuRJriyWPK99jVF",
      type: "pack",
      description: "",
    },
    {
      image: `${config.s3imgUrl}web/playlists/96.webp`,
      title: "Active Wear",
      buttonText: "License",
      buttonLink: "/playlists/pack-fashion-active-wear/96",
      totalTracks: 5,
      packCode: 96,
      packNameSlug: "fashion-active-wear",
      sellingPrice: "49999",
      costPrice: "58995",
      discountPercent: `${(((58995 - 49999) / 58995) * 100).toFixed(0)}% Off`,
      skuId: "3PVd2Po1zPi4sVOoJ6sT",
      type: "pack",
      description: "",
    },
  ];

  // const packCards = [
  //   {
  //     image: `${config.s3imgUrl}web/playlists/6.webp`,
  //     title: "Reels Mania",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-reels-mania/6",
  //     totalTracks: 5,
  //     packCode: 6,
  //     packNameSlug: "reels-mania",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  //   {
  //     image: `${config.s3imgUrl}web/playlists/12.webp`,
  //     title: "Trendsetters",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-trendsetters/12",
  //     totalTracks: 5,
  //     packCode: 12,
  //     packNameSlug: "trendsetters",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  //   {
  //     image: `${config.s3imgUrl}web/playlists/13.webp`,
  //     title: "Happily Ever After",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-happily-ever-after/13",
  //     totalTracks: 5,
  //     packCode: 13,
  //     packNameSlug: "happily-ever-after",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  //   {
  //     image: `${config.s3imgUrl}web/playlists/8.webp`,
  //     title: "Year End Celebrations",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-year-end-celebrations/8",
  //     totalTracks: 3,
  //     packCode: 8,
  //     packNameSlug: "year-end-celebrations",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  //   {
  //     image: `${config.s3imgUrl}web/playlists/11.webp`,
  //     title: "Love Is In The Air",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-love-is-in-the-air/11",
  //     totalTracks: 5,
  //     packCode: 11,
  //     packNameSlug: "love-is-in-the-air",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  //   {
  //     image: `${config.s3imgUrl}web/playlists/7.webp`,
  //     title: "Where's The Party?",
  //     buttonText: "License",
  //     buttonLink: "/playlists/pack-wheres-the-party/7",
  //     totalTracks: 5,
  //     packCode: 7,
  //     packNameSlug: "wheres-the-party",
  //     sellingPrice: "99999",
  //     costPrice: "129999",
  //     discountPercent: "23% Off",
  //   },
  // ];

  const categoryCards = [
    {
      name: "Fashion",
      slug: "fashion",
      popular: "true",
      id: "4d12fd79-69b5-4482-9a7d-61cfe521439c",
    },
    {
      name: "Food",
      slug: "food",
      popular: "false",
      id: "d096a310-adf6-4974-8ff6-3ca8e5d0e55c",
    },
    {
      name: "Travel",
      slug: "travel",
      popular: "true",
      id: "33b2bf23-f5b5-4398-aa51-7046a2922691",
    },
    {
      name: "Health & Fitness",
      slug: "health-and-fitness",
      popular: "false",
      id: "4f4624b6-9f8a-458d-82d1-77a844691856",
    },
    {
      name: "Automobile",
      slug: "autombile",
      popular: "false",
      id: "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8",
    },
  ];

  const featuredMoods = [
    {
      name: "Romantic",
      slug: "romantic",
      popular: "false",
      id: "ed820c8e-03f5-43d1-bbd8-c96c51b31f88",
    },
    {
      name: "Motivational",
      slug: "motivational",
      popular: "false",
      id: "7e9caf70-1cd5-4e82-8ee0-8f4590f2f8bb",
    },
    {
      name: "Ethnic",
      slug: "ethnic",
      popular: "false",
      id: "7e98f210-b995-46f0-973c-0da8e6713c17",
    },
    {
      name: "Dreamy",
      slug: "dreamy",
      popular: "false",
      id: "6a011e8c-5048-4448-bce2-5458dadf1421",
    },
    {
      name: "Action",
      slug: "action",
      popular: "false",
      id: "9adc4829-f074-40cc-a9d6-12263b78bcb0",
    },
    {
      name: "Nostalgic",
      slug: "nostalgic",
      popular: "false",
      id: "ad8e3c56-3a40-409d-b1c5-01e9a35a6e23",
    },
  ];

  const featuredGenres = [
    {
      name: "Bollywood",
      slug: "bollywood",
      popular: "false",
      id: "3e3cb3f5-fcf0-4e4d-a5b9-ea1d6c2cf4cc",
    },
    {
      name: "Indian Folk",
      slug: "indian-folk",
      popular: "false",
      id: "0c29a125-b83e-4b4b-8208-ac8b7e810e2b",
    },
    {
      name: "Kids",
      slug: "kids",
      popular: "false",
      id: "31026e90-b30c-4b15-ae87-be7fbd924b1a",
    },
  ];

  // const offerCards = [
  //   {
  //     image: `${config.s3imgUrl}trackimages/sampleyrf.png`,
  //     title: "Offers & Discounts",
  //     description:
  //       "Don't miss out on this trend-setting opportunity! For a limited time...",
  //     buttonText: "Explore Tracks",
  //   },
  //   {
  //     image: `${config.s3imgUrl}trackimages/samplepopularsong.png`,
  //     title: "Extra 10% OFF on Popular Songs",
  //     description:
  //       "Don't miss out on this trend-setting opportunity! For a limited time, you can...",
  //     buttonText: "Explore Tracks",
  //   },
  // ];

  // const getTracks = async (trackCodes, type) => {
  //   const trackData = await getMultiTrack(trackCodes);

  //   const processedTracks = trackData?.data?.tracks?.map((track) => {
  //     const { costPrice, sellingPrice } = track.SKU[0];
  //     const discountPercent = (
  //       ((costPrice - sellingPrice) / costPrice) *
  //       100
  //     ).toFixed(0);

  //     const artistNames = track.primaryArtists?.length
  //       ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
  //       : "";

  //     return {
  //       image: track.imageUrl || randomDefaultImage("track"),
  //       title:
  //         track.name.length > 18
  //           ? track.name.substring(0, 17) + "..."
  //           : track.name,
  //       description:
  //         artistNames.length > 30
  //           ? artistNames.substring(0, 29) + "..."
  //           : artistNames,
  //       buttonText: "Explore",
  //       buttonLink: "#",
  //       discountPercent: discountPercent || "50% OFF",
  //       costPrice: costPrice.toLocaleString("en-IN") || "19,999",
  //       sellingPrice: sellingPrice.toLocaleString("en-IN") || "79,999",
  //       name: track.name_slug,
  //       trackCode: track.trackCode,
  //       trackUrl:
  //         track.mp3Link || getDirectLink(track.link) || track.waveformLink,
  //     };
  //   });

  //   if (type === "featureTracks") {
  //     setFeatureTracks(processedTracks);
  //   }
  //   // else if (type === "trendingTracks") {
  //   //   // setTrendingTracks(processedTracks);
  //   // }
  // };

  // const DemoIG = () => {
  //   return (
  //     <div
  //       className="IG-embed"
  //       dangerouslySetInnerHTML={{ __html: ig_embed }}
  //     ></div>
  //   );
  // };

  //fucntion to display discount banner modal
  // const handleBannerModal = () => {
  //   setBannerPopup(!bannerPopup);
  // };

  // watch video modal show and hide fucntion
  const handleBannerClick = (banner) => (event) => {
    if (banner.buttonText === "Watch Video") {
      event.preventDefault(); // Prevent navigation
      setVideoModal(true); // Open modal
      // const [videoModal, setVideoModal] = useState(false);
    }
  };

  // closing modal on outside click of video modal
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setVideoModal(false);
    }
  };

  // ui for video modal
  const renderWatchVideo = () => {
    return (
      <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div style={{ cursor: "pointer" }} onClick={() => setVideoModal(false)}>
          <IoMdCloseCircle className={styles.modalClose} />
        </div>
        <div className={styles.modalContainer}>
          <div>
            <video
              poster={`${config.s3imgUrl}static/about/top-about.webp`}
              // poster={`${config.s3imgUrl}static/about/top-58.webp`}
              playsInline
              disablePictureInPicture
              disableRemotePlayback
              controls
              autoPlay
              className={styles.introVideo}
            >
              <source
                src={`${config.s3imgUrl}static/about/top-about.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.parentContainer}>
      {/* video modal */}
      {videoModal && renderWatchVideo()}
      {/* banner section */}
      <div className={`${styles.outer} ${styles.bannerContainer}`}>
        <Carousel gap={10} autoScroll={false} scrollInterval={4000}>
          {validBanners.map((banner, index) => (
            <Link
              to={banner.buttonLink}
              onClick={handleBannerClick(banner)}
              key={index}
            >
              <div
                key={index}
                style={{
                  backgroundImage: `url(${banner.image})`,
                }}
                className={styles.bannerCardImg}
              >
                {/* {index !== 0 && (
                  <CustomButton
                    text={banner.buttonText}
                    backgroundColor={
                      index == 0 ? "var(--button-primary)" : "#FFF"
                    }
                    borderRadius="50px"
                    borderColor={index == 0 ? "none" : "var(--button-primary)"}
                    width="190px"
                    height="44px"
                    textColor={index == 0 ? "#FFF" : "var(--button-primary)"}
                    iconColor={index == 0 ? "#FFF" : "var(--button-primary)"}
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="bold"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                  />
                )} */}

                <CustomButton
                  text={banner.buttonText}
                  // backgroundColor={
                  //   index === 0 ? "var(--button-primary)" : "#FFF"
                  // }
                  // backgroundColor="none"
                  borderRadius="50px"
                  // borderColor={index === 0 ? "1px solid var(--button-primary)" : "var(--button-primary)"}
                  // borderColor="1px solid var(--button-primary)"
                  borderColor={
                    index === 0
                      ? "1px solid var(--button-primary)"
                      : "2px solid #FFFFFF55"
                  }
                  width="190px"
                  height="44px"
                  textColor={index === 0 ? "var(--button-primary)" : "#FFF"}
                  iconColor={index === 0 ? "var(--button-primary)" : "#FFF"}
                  fontSize="16px"
                  fontFamily="Inter"
                  fontWeight="bold"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
      {/* MARKETING BANNER 1 */}
      <div className={`${styles.mktBanner2Container} ${styles.outer}`}>
        <Link to={"/about"}>
          {/* <CustomImg
          type="genre"
          id="discountBanner"
          alt="Know more about licensing"
          imgClass=""
          // src={`${config.s3imgUrl}web/promotional/tracks/b-ott.webp`}
          src={`${config.s3imgUrl}web/home/mkt-90.webp`}
        /> */}
          <video
            autoPlay
            muted
            loop
            playsInline
            disablePictureInPicture
            disableRemotePlayback
            className={styles.abtSearchImgL}
          >
            <source
              src={`${config.s3imgUrl}web/home/mkt-top-banner.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Link>
      </div>
      {/* know more banner */}
      {/* <div
        className="discountBanner-container outer"
        onClick={handleBannerModal}
      >

        <CustomImg
          type="genre"
          id="discountBanner"
          alt="Know more about licensing"
          imgClass=""
          src={`${config.s3imgUrl}web/home/know-more.png`}
        />

        {bannerPopup && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="modal-close-btn" onClick={handleBannerModal}>
                &times;
              </button>
              <h2>
                License Popular Bollywood & Trending Tracks in 3 Simple Steps
              </h2>
              <div className="modal-banner-img-container">
                {imagePoints.map((imageName, index) => (
                  <div key={index} className="model-banner-img">
                    <img
                      src={`${config.s3imgUrl}web/home/knowmore/${imageName}`}
                      alt={`Banner ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div> */}
      {/* instagram trending track */}
      <div
        className={`${styles.trackParentContainer} ${styles.outerFull} ${styles.instaContainer} ${styles.mobileTrend}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={`${styles.tpHeadertext} ${styles.textWhite}`}>
              Instagram Trending Tracks
              {/* <span className={`${styles.discountR} ${styles.textWhite}`}>
                Upto 55% off
              </span> */}
            </h1>
            <img
              className={styles.fireIcon}
              src={config.s3imgUrl + "icons/fire.png"}
              alt="trending fire icon"
            />
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={`${styles.tpSubHeaderText}`}>
              License the season's hottest tracks for explosive engagement
            </h2>
            <Link to={"/playlists/trending-on-social-media/105"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltextwhite}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#fff" />
                </p>
              </div>
            </Link>
          </div>
          <div className={`${styles.trackCards}`}>
            {/* <TrackCard
              trackCard={instaTracks}
              btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
            /> */}
            <TrendingCard
              trackCard={
                windowWidth <= 481 ? instaTracks.slice(0, 4) : instaTracks
              }
              // trackCard={contemporaryTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "130px" : isTablet ? "168px" : "186px"}
              trackType="trending"
            />
          </div>
        </div>
      </div>

      {/* BUDGET FRIENDLY MARKETING BANNERS */}
      <div className={`${styles.budgetContainer} ${styles.inner}`}>
        <div>
          <Link
              to={`/search?searchKeyword=&priceFilters=${priceFilter({
                min: 2,
                max: 1000,
              })}&hs_ref=home-mkt-999-banner`}
            >
            <img alt="Tracks under 999" className={`${styles.budgetImage}`} src={`${config.s3imgUrl}web/promotional/home/999-b.webp`} />
          </Link>
        </div>
        <div>
          <Link
              to={`/search?searchKeyword=&priceFilters=${priceFilter({
                min: 1000,
                max: 2000,
              })}&hs_ref=home-mkt-1999-banner`}
            >
            <img alt="Tracks under 1999" className={`${styles.budgetImage}`} src={`${config.s3imgUrl}web/promotional/home/1999-b.webp`} />
          </Link>
        </div>
        <div>
          <Link
            to={`/search?searchKeyword=&priceFilters=${priceFilter({
              min: 2000,
              max: 5000,
            })}&hs_ref=home-mkt-4999-banner`}
          >
            <img alt="Tracks under 4999" className={`${styles.budgetImage}`} src={`${config.s3imgUrl}web/promotional/home/4999-b.webp`} />
          </Link>

        </div>
      </div>


      {/* track under 999 Container */}
      {/* <div
        className={`${styles.trackParentContainer} ${styles.outer} ${styles.containerHeight}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              Tracks Under &nbsp; ₹999{" "}
              <span className={styles.discountR}>Upto 55% off</span>
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2
              className={`${styles.tpSubHeaderText} ${styles.tpSubHeaderTextB}`}
            >
              Exclusive tracks at budget-friendly prices
            </h2>
            <Link
              to={`/search?searchKeyword=&priceFilters=${priceFilter({
                min: 2,
                max: 1000,
              })}&hs_ref=home-under-999-section`}
            >
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={windowWidth <= 481 ? t999.slice(0, 4) : t999}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              trackType="contemporary"
            />
          </div>
        </div>
      </div> */}
      <div
        className={`${styles.trackParentContainer} ${styles.outerFull} ${styles.containerHeight} ${styles.cricketContainer}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              Cricket Fever{" "}
              {/* <span className={styles.discountR}>Upto 55% off</span> */}
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2
              className={`${styles.tpSubHeaderText} ${styles.tpSubHeaderTextB}`}
            >
              License cricket tracks that capture every boundary and wicket!
            </h2>
            <Link to={"/search?searchKeyword=Cricket"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={windowWidth <= 481 ? t20.slice(0, 4) : t20}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              trackType="contemporary"
            />
          </div>
        </div>
      </div>
      {/* SUPER SAVER COMBO PACKS */}
      <div className={`${styles.packsContainer} ${styles.outer}`}>
        <div style={{ margin: 0 }} className={styles.inner}>
          <h1 className={styles.packsHeading}>
            Super Saver Combo Packs
            <span className={styles.discount}>Upto 55% off</span>
          </h1>
          <div className={styles.packHeaderContainer}>
            <h2 className={styles.packSubHeading}>
              Enjoy great savings on thematic multi-track packs
            </h2>
            <a href="/packs">
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All Packs</p>
                <p>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </a>
          </div>
          {/* Apply Carousel only for Mobile & Tablet (≤781px) */}
          {windowWidth <= 781 ? (
            <Carousel gap={15} autoScroll={false} scrollInterval={4000}>
              {catEssentials.map((pack, index) => (
                <PackCard key={index} packCards={[pack]} packType="pop" />
              ))}
            </Carousel>
          ) : (
            <PackCard packCards={catEssentials} packType="toppackhome" />
          )}
          {/* <PlaylistCard packCards={catEssentials} packType="toppackhome" /> */}
        </div>
      </div>
      {/* INDIE CONTAINER */}
      <div
        style={{ backgroundColor: "#fff" }}
        className={`${styles.trackParentContainer} ${styles.outerFull} ${styles.containerHeight} ${styles.bgBlue} ${styles.indiContainer} ${styles.mobile}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={`${styles.tpHeadertext} ${styles.indieHeading}`}>
              Indie Hits <span className={styles.discountR}>Upto 55% off</span>
              {/* <span>
                <img
                  className="trending-icon-header"
                  src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                  alt="trending"
                />
              </span> */}
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              License popular smash hits by independent artists
            </h2>
            <Link to={"/playlists/independent-hits/104"}>
              <div className={styles.viewall}>
                <p className={`${styles.viewalltext} ${styles.viewWhitep}`}>
                  View All
                </p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#FFF" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? indieTracks.slice(0, 4) : indieTracks
              }
              // trackCard={indieTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
              cartBgColor="#000"
              cartIgColor="#FFF"
              borderColor="1px solid rgb(204, 204, 204)"
            />
          </div>
        </div>
      </div>
      {/* all time hits section */}
      {/* <div
        className={`${styles.trackParentContainer} ${styles.outer} ${styles.containerHeight}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              All Time Hits{" "}
              <span className={styles.discountR}>Upto 55% off</span>
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              License the most iconic Bollywood hits of all time!
            </h2>
            <Link to={"/playlists/nostalgic-2000s/99"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? allTimeTracks.slice(0, 4) : allTimeTracks
              }
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              trackType="contemporary"
            />
          </div>
        </div>
      </div> */}
      {/* top packs container */}
      {/* <div className={`${styles.packsContainer} ${styles.outer}`}>
        <div style={{ margin: 0 }} className={styles.inner}>
          <h1 className={styles.packsHeading}>
            Hottest Packs This Season
            <span className={styles.discount}>Upto 70% off</span>
          </h1>
          <div className={styles.packHeaderContainer}>
            <h2 className={styles.packSubHeading}>
              Buy specially curated thematic, multi-track packs and enjoy great
              savings
            </h2>
            <a href="/packs">
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All Packs</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </a>
          </div> */}

      {/* Apply Carousel only for Mobile & Tablet (≤781px) */}
      {/* {windowWidth <= 481 ? (
            <Carousel gap={20} autoScroll={false} scrollInterval={4000}>
              {packCards.map((pack, index) => (
                <PlaylistCard
                  key={index}
                  packCards={[pack]}
                  packType="toppackhome"
                />
              ))}
            </Carousel>
          ) : (
            <PlaylistCard packCards={packCards} packType="toppackhome" />
          )}
        </div>
      </div> */}
      {/* explore by brand category i.e. categories */}
      <div className={`${styles.brandContainer} ${styles.outerFull}`}>
        <div className={styles.inner}>
          <div className={styles.bcHeader}>
            <h1 className={styles.bcHeaderText}>Top Searched Categories</h1>
            <Link to="/use-cases">
              <div className={styles.bcViewAll}>
                View All <IoIosArrowForward size={18} color="#373737" />
              </div>
            </Link>
            {/* <div className="brand-viewall">
              <Link to={"/categories"}>
                <p>
                  View All <IoIosArrowForward color="#373737" />
                </p>
              </Link>
            </div> */}
            {/* /use-cases/travel/33b2bf23-f5b5-4398-aa51-7046a2922691 */}
          </div>
          <div className={styles.bcCards}>
            {categoryCards
              .slice(0, windowWidth <= 481 ? 4 : 5)
              .map((cat, index) => (
                <Link
                  key={index}
                  to={`/use-cases/${encodeURIComponent(cat.slug)}/${cat.id}`}
                >
                  <CustomImg
                    type="cat"
                    id={cat.id}
                    alt={cat.name}
                    imgClass={styles.brandCardImg}
                    src=""
                  />
                </Link>
              ))}
          </div>
        </div>
      </div>
      {/* feature moods and genre section */}
      <div className={`${styles.mgContainer} ${styles.outer}`}>
        <div className={`${styles.inner} ${styles.innerMgContainer}`}>
          <div className={styles.genreContainer}>
            <div className={styles.mgHeaderContainer}>
              <h1 className={styles.mgHeaderText}>Top Searched Genres</h1>
              <Link to="/genres">
                <div className={styles.bcViewAll}>
                  View All <IoIosArrowForward size={18} color="#373737" />
                </div>
              </Link>
            </div>
            <div className={styles.genreCardContainer}>
              {featuredGenres.map((genre) => (
                <Link
                  to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
                >
                  <div key={genre.id} className={styles.mgCards}>
                    <CustomImg
                      type="genre"
                      id={genre.id}
                      alt={genre.name}
                      imgClass={styles.genreCardImg}
                      src=""
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.moodContainer}>
            <div className={styles.mgHeaderContainer}>
              <h1 className={styles.mgHeaderText}>Top Searched Moods</h1>
              <Link to="/moods">
                <div className={styles.bcViewAll}>
                  View All <IoIosArrowForward size={18} color="#373737" />
                </div>
              </Link>
            </div>
            <div className={styles.moodCardContainer}>
              {featuredMoods.map((mood) => (
                <Link to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}>
                  <div key={mood.id} className={styles.mgCards}>
                    <BgImageDiv
                      type="mood"
                      id={mood.id}
                      divClass={styles.moodCardImg}
                    ></BgImageDiv>
                    <p className={styles.moodCardHeading}>{mood.name}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* feature tracks section */}
      {/* <div
        className={`${styles.ftContainer} ${styles.outerFull} ${styles.ftmobile}`}
      >
        <div className={styles.inner}>
          <h1 className={`${styles.ftHeading}`}>
            Featured Tracks
            <span className={styles.discountR}>Upto 55% off</span>
          </h1>
          <div className={styles.tpSubHeader}></div>
          <div style={{ paddingBottom: 20 }} className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? featureTracks.slice(0, 4) : featureTracks
              }
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              trackType="feature"
              cartBgColor="#fff"
              cartIgColor="#000"
            />
          </div>
        </div>
      </div> */}
      {/* marketing banner 2 section */}
      {/* <div className={`${styles.mktBanner2Container} ${styles.outer}`}>
        <Link to={"/tracks"}>
          <CustomImg
            type="genre"
            id="discountBanner"
            alt="Know more about licensing"
            imgClass={styles.mkt2image}
            src={`${config.s3imgUrl}web/promotional/tracks/b-ott.webp`}
          />
        </Link>
      </div> */}
      {/* all songs container */}
      <div className={styles.allSongs}>
        <AllTrackCards viewall="true" />
      </div>
    </div>
  );
};

export default Home;
