import React from "react";
import "./PlaylistCard.css";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
// import { RiShoppingCart2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import config from "../../../utils/config";
import { AiOutlineDelete } from "react-icons/ai";
import CleanSearchKey from "../../../utils/CleanSearchKey";

const CardComponent = ({
  packCards,
  packType,
  showPrice = true,
  showLikeIcon = false,
  likedStatus = false,
  handlePlaylistRemoveLike,
  showDeleteIcon = false,
  handlePlaylistDelete,
}) => {
  // console.log(packCards);

  // const handlePlaylistRemoveLike = () => {};

  return (
    <div className="playlist-cards-container" data-type={packType}>
      {packCards.map((pack, index) => (
        <div key={index} className="pack-card">
          <div className="pack-title">
            <Link
              to={`/playlists/${CleanSearchKey(pack.title)}/${pack.packCode}`}
            >
              <h1 className="pack-heading">
                {pack.title.length > 35
                  ? pack.title.substring(0, 32) + "..."
                  : pack.title}
              </h1>
            </Link>
            {/* <span>Pack of {pack.totalTracks} tracks</span> */}
            <span>{pack.totalTracks} tracks</span>
          </div>

          <Link
            to={`/playlists/${CleanSearchKey(pack.title)}/${pack.packCode}`}
          >
            <div
              className="pack-card-img"
              style={{
                backgroundImage: `url(${
                  config.s3imgUrl + "web/playlists/" + pack.packCode + ".webp"
                })`,
              }}
            >
              {showLikeIcon && (
                <div
                  className="card-like-info-playlist"
                  style={{ opacity: likedStatus ? 100 : 50 }}
                >
                  {likedStatus ? (
                    <FaHeart
                      onClick={() => handlePlaylistRemoveLike(pack.packCode)}
                      color="#E90B2F"
                      size={24}
                      cursor="pointer"
                    />
                  ) : (
                    <FaRegHeart cursor="pointer" size={24} color="#fff" />
                  )}
                </div>
              )}
              {showDeleteIcon && (
                <div className="card-del-info">
                  <AiOutlineDelete
                    cursor="pointer"
                    size={24}
                    color="var(--button-primary)"
                    onClick={() => handlePlaylistDelete(pack.packCode)}
                  />
                </div>
              )}
            </div>
          </Link>

          <div className="pack-price-button">
            {/* <div className="pack-price-container">
              <div className="pack-discount-price">
                <div className="pack-discount-amount">
                  <span>₹</span>
                  <p>99,999</p>
                </div>
                {showPrice && (
                  <div className="pack-discount-percentage">23% Off</div>
                )}
              </div>
              {showPrice && (
                <div className="pack-cost-price">
                  <p>Cost:</p>
                  <span>₹1,29,999</span>
                </div>
              )}
            </div> */}
            {showPrice && (
              <div className="explore-track-button">
                <Link
                  to={`/playlists/${CleanSearchKey(pack.title)}/${
                    pack.packCode
                  }`}
                  className="explore-pack-btn"
                >
                  {pack.buttonText}
                  <IoIosArrowForward color="var(--button-primary)" size={20} />
                </Link>

                {/* <button className="explore-pack-cart-btn">
                  {<RiShoppingCart2Line color="#fff" size={20} />}
                </button> */}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
