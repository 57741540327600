const categories = {
  // "eafd60a8-c0ab-4504-a3af-96ed441d267a": {
  //     "name": "Advertising",
  //     "description": "High-energy tracks and jingles to elevate your brand's visibility and create memorable campaigns. Use cases: TV commercials, radio ads, digital ads.",
  // },
  "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8": {
      "name": "Automobile",
      "description": "Music drives the energy of your content, making it suitably more dynamic and engaging. Whether showcasing speed, luxury, or adventure, the right tracks can help enhance the thrill and excitement. Our collection of popular and trending tracks includes powerful beats, adrenaline-pumping rock, and sleek electronic music, assisting brands in creating memorable experiences. Upbeat and intense sounds amplify the rush of an SUV on the road, while smooth, modern rhythms complement premium car promotions, customer reviews, and car demos. By aligning music with the brand's personality and message, campaigns feel more immersive and emotionally compelling. License our copyright-safe Bollywood hits to enhance storytelling, making your content stand out while keeping viewers engaged.",
  },
  "c63cbc85-930c-40bd-8e8c-84f252c4f7f6": {
      "name": "Background Scores",
      "description": "Background scores set the mood, subtly shaping the emotional impact of content without overshadowing the message. Whether creating tension, warmth, or excitement, the right music complements visuals seamlessly. We provide copyright-safe background scores that cover a range of moods, from cinematic orchestral arrangements for launch events to gentle acoustic melodies for product montages, ensuring the perfect backdrop for any campaign. By carefully selecting the right sound, brands can enhance storytelling, making content more immersive and engaging. With popular and trending tracks, brands can create memorable experiences that capture attention and elevate the emotional depth of the campaigns.",
  },
  // "5a1e36b9-614b-4540-a81c-6ad79db245b0": {
  //     "name": "Beauty & Skincare",
  //     "description": "Elegant and sophisticated music for beauty and skincare content. Use Cases: Beauty vlogs, makeup tutorials, skincare reviews, hair tutorials, product reviews",
  // },
  "ff81cbd0-c571-4ffd-9349-136fd04f64c0": {
      "name": "Beauty & Wellness",
      "description": "Beauty and wellness content thrives on soothing, elegant, uplifting music that resonates with the audience. Whether promoting skincare, wellness routines, or luxury beauty brands, the right sound helps to enhance relaxation and sophistication. License our copyright-safe tracks that range from ambient soundscapes for makeup routine videos to soft R&B and dreamy electronic beats for DIY skincare and beauty hacks, aligning with the essence of wellness and self-care. Music helps reinforce feelings of calm, confidence, and rejuvenation, making campaigns more compelling and aspirational. With our collection of trending and popular Bollywood hits, brands can create a luxurious and inviting atmosphere, elevating their messaging while ensuring an immersive experience for their audience.",
  },
  "ed70513b-a743-42ec-95a5-248c60e13800": {
      "name": "Bumpers ",
      "description": "Bumpers are short but impactful, requiring music that grabs attention instantly. Whether energetic, quirky, or dramatic for intros & outros, the right sound creates a lasting impression in just a few seconds. Our copyright-safe tracks feature catchy beats for call-to-actions, bold transitions perfect for countdowns, and vibrant melodies ideal for logo animations designed to enhance brand recognition. Playful jingles, electronic bursts, or cinematic stingers ensure a polished and professional touch, keeping audiences engaged. By licensing the perfect sound, brands can create seamless transitions that maintain energy and excitement. With our popular Bollywood and trending tracks, brands can make even the shortest content feel dynamic, polished, and memorable.",
  },
  "64538398-f88b-431b-a63c-b79971fbed6a": {
      "name": "Comedy ",
      "description": "Comedy content thrives on music that enhances timing and humor, creating a fun and engaging experience. Quirky tunes for memes, lighthearted and playful tracks ideal for parodies and humorous scripts add character and keep audiences entertained. We provide 100% copyright protection on trending tracks that range from whimsical orchestral pieces to funky beats, perfect for amplifying comedic moments. The right background music enhances punchlines, elevating the overall humor and energy of the campaign. Whether for witty brand storytelling or playful content, license our popular Bollywood hits to create a seamless connection with the audience, leaving them entertained and engaged.",
  },
  "0e01f907-a8cd-4e71-9d73-81097fe21789": {
      "name": "Corporate ",
      "description": "Corporate campaigns require music that feels professional, inspiring, and engaging. Whether for motivational brand messages or innovative product showcases, the right sound can enhance credibility and impact. License our copyright-safe tracks that range from uplifting orchestral pieces in content showcasing the company’s culture to sleek electronic beats for CEO & employee messages, ensuring a polished and modern feel. Music adds warmth, confidence, and energy, helping brands create an emotional connection with their audiences. With our popular Bollywood and trending tracks, brands can reinforce their message while maintaining a refined and contemporary aesthetic.",
  },
  "3c48a7fb-291e-4db9-bee0-526d36472585": {
      "name": "Devotional ",
      "description": "Devotional content requires music that evokes spirituality, peace, and deep emotional connection. Soulful chants, serene instrumentals, and traditional melodies help create a sense of calm and devotion. We provide copyright-safe tracks, including a range of meditative sounds, Indian classical influences for Ethnic brands, and soothing choir harmonies in Yoga & wellness content that enhance spiritual storytelling. Whether for faith-based brands, wellness initiatives, or cultural campaigns for festive seasons, our vast music catalog helps deepen the emotional impact of content. By licensing our popular hits, brands can create an uplifting and immersive experience, ensuring an authentic and meaningful connection with their audience.",
  },
  // "07f3896a-efc7-4d24-8a78-8b6a72d775ed": {
  //     "name": "E-Commerce",
  //     "description": "Upbeat tunes to enhance product demos, ads, and shopping experiences.",
  // },
  "4d12fd79-69b5-4482-9a7d-61cfe521439c": {
      "name": "Fashion",
      "description": "Fashion campaigns need music that is bold, stylish, and trend-setting. Whether for high-end couture or streetwear brands, the right sound enhances the aesthetic and attitude. Our copyright-safe tracks feature massy Bollywood hits in OOTD videos, sleek electronic beats in try-on hauls, smooth jazz, and modern pop, setting the perfect mood for content ranging from runway-inspired visuals and fashion trends to quirky GRWM videos. Music helps convey confidence, glamour, and individuality, making content more aspirational and immersive. License our popular trending tracks to create a sense of exclusivity and sophistication, ensuring that your campaigns resonate with the fashion-forward audience.",
  },
  "8c1b166d-087c-40ac-bd92-6bce898d3488": {
      "name": "Festive Content",
      "description": "Festive campaigns require celebratory, energetic, and culturally rich music. Whether for seasonal promotions or traditional celebrations, the right tracks enhance the joyful spirit. License our copyright-safe tracks, which include upbeat folk for festive discount announcements, vibrant orchestral arrangements in ethnic apparel showcases, and festive percussion beats in sweets & dessert brand videos that capture the essence of celebration. Music creates an emotional connection, making content more engaging and memorable. With our popular hits, brands can elevate their festive campaigns, ensuring an immersive and cheerful experience that resonates with audiences.",
  },
  // "9f01440b-5e9a-4252-ba8e-b2b63f6fc185": {
  //     "name": "Film & OTT Content",
  //     "description": "Dramatic, cinematic, and emotive music for films and OTT content. Use Cases: Movie trailers, web series, documentaries, short films, TV commercials",
  // },
  "e1b380a3-8fc2-4c18-82c4-ca9afe281fdf": {
      "name": "Financial Services",
      "description": "Financial campaigns benefit from music that feels trustworthy, reassuring, and sophisticated. Subtle orchestral compositions, smooth electronic sounds, and modern corporate beats help establish credibility. Our copyright-safe tracks provide a professional and composed backdrop, reinforcing confidence in the messaging. Whether for banking, insurance, or investment brands, the right music ensures clarity and impact. With our popular Bollywood and trending tracks, brands can make financial content feel approachable, engaging, and trustworthy.",
  },
  "d096a310-adf6-4974-8ff6-3ca8e5d0e55c": {
      "name": "Food",
      "description": "Food content comes alive with playful, warm, and inviting music. Whether showcasing gourmet delights or everyday meals, the right sound enhances the sensory experience. Our copyright-safe tracks range from upbeat acoustic tunes in food vlogs to jazzy and tropical beats for recipe videos to add energy and flavor to the visuals. Music helps create a sense of indulgence, comfort, and excitement, making food brands more appealing. With our popular hits, campaigns can feel fresh, vibrant, and irresistibly engaging, leaving audiences craving more.",
  },
  "0ea970e0-a57e-40c9-b46e-2d04a74c20ab": {
      "name": "Gaming ",
      "description": "Gaming campaigns demand high-energy, dynamic, and immersive music. From pulse-pounding electronic beats to heroic orchestral scores, the right sound enhances action and excitement. Our copyright-safe tracks include powerful soundscapes for gaming montages, glitchy synths for gaming set-up reveals, and intense rhythms for a gameplay video and competitive and adventure-driven content. Music drives engagement, making gameplay experiences more thrilling and immersive. With our popular Bollywood and trending tracks, gaming brands can create electrifying campaigns that keep audiences on the edge of their seats.",
  },
  // "26f05bb3-c7a1-4409-a28e-6f9003c976d5": {
  //     "name": "Global Occasions",
  //     "description": "International music tracks to celebrate global events and cultures. Use cases: Travel vlogs, cultural documentaries, language learning videos.",
  // },
  "4f4624b6-9f8a-458d-82d1-77a844691856": {
      "name": "Health & Fitness",
      "description": "Health and fitness content thrives on music that is energetic, motivating, and uplifting. Whether for workout brands or wellness initiatives, the right beats set the tone for movement and mindfulness. We provide copyright-safe popular Bollywood tracks that include high-energy electronic beats for workout routines, upbeat pop for fitness challenges, and soothing ambient melodies for nutrition & meal planning tips, catering to different fitness moods. Music enhances motivation, focus, and endurance, making campaigns more dynamic and engaging. With our popular hits, brands can create an inspiring and empowering experience that resonates with their audience.",
  },
  // "b8e9fb8f-69fc-40c8-b1d0-09f6f81e92fa": {
  //     "name": "Indian Festivals",
  //     "description": "Traditional and contemporary Indian music for celebrating Indian festivals. Use cases: Festival videos, cultural documentaries, devotional content.",
  // },
  "11334cec-2010-4885-bc35-f0f995d42701": {
      "name": "Intros & Outros",
      "description": "Intros and outros define the first and last impression of a campaign, requiring music that is memorable and impactful. Whether punchy and bold or subtle and smooth, the right sound sets the tone instantly. We provide copyright-safe music licensing for trending tracks that include modern electronic beats great for a fashion brand, orchestral stingers ideal for a FMCG brand, and playful melodies designed for strong brand recall. Music adds professionalism, energy, and cohesion, making the content feel polished. With our popular Bollywood and trending tracks, brands can establish a distinct identity, ensuring their campaigns leave a lasting impact.",
  },
  "1d785d09-f059-4952-98eb-9c21d3864890": {
      "name": "Jingles",
      "description": "Jingles are essential for brand recognition, requiring music that is catchy, memorable, and engaging. Whether upbeat and fun or sophisticated and smooth, the right melody can leave a lasting impression. License our copyright-safe Bollywood tracks that include bright acoustic tunes, playful pop, and rhythmic beats designed to enhance brand recall. Trending music helps make content instantly recognizable, reinforcing brand messaging with a unique and engaging sound. With our popular hits, brands can create impactful jingles that keep audiences hooked and engaged.",
  },
  "f872e350-5430-46a1-aca0-af292a9c13b9": {
      "name": "Kids",
      "description": "Kids’ content thrives on music that is playful, colorful, and engaging. Whether for educational brands or entertainment, the right sound keeps young audiences captivated. License our copyright-safe Bollywood and trending tracks catalog that includes cheerful melodies, bouncy beats for kids fashion content, and whimsical instrumentals, adding excitement and fun to the visuals. Music enhances learning, creativity, and storytelling, making campaigns more interactive and engaging. With our popular trending tracks, brands can create immersive experiences that keep children entertained while reinforcing positive messaging.",
  },
  "3446ca31-c19c-4eab-8c97-a0b6bcf8369b": {
      "name": "Learning & Ed-Tech",
      "description": "Educational content benefits from music that is inspiring, engaging, and supportive of learning. Whether for online courses, explainer videos, or academic brands, the right sound enhances retention and focus. We make it easy to license copyright-safe popular tracks that range from soothing ambient pieces to upbeat motivational tunes, making education more enjoyable and interactive. Music adds clarity, energy, and emotional depth, ensuring that learners stay engaged. With our popular hits, brands can create impactful campaigns that make knowledge-sharing both effective and memorable.",
  },
  "f909fd19-7d11-4bb8-9ef0-33a1852b2278": {
      "name": "Meditation",
      "description": "Meditation content requires music that is soothing, tranquil, and immersive. Whether for mindfulness brands or wellness campaign promotions, the right sound creates a peaceful and calming atmosphere. License our copyright-safe tracks that include ambient soundscapes, soft instrumental pieces, and nature-inspired melodies, helping to promote deep relaxation. Music plays a crucial role in guiding meditation practices, reducing stress, and enhancing emotional well-being. With our popular trending tracks, brands can license, create, and elevate their campaigns, ensuring a deeply soothing and immersive experience.",
  },
  // "953971e2-fd76-416d-b570-c0ab566302c1": {
  //     "name": "Movies",
  //     "description": "Dramatic and cinematic music to elevate your film projects. Use cases: Short films, feature films, movie trailers.",
  // },
  "3f0ff233-984f-4ebb-a2f2-982e4b7472f1": {
    "name": "Non Profits & NGO",
    "description": "Non-profit campaigns need music that is emotional, uplifting, and inspiring. Whether raising awareness or driving action, the right sound helps create a heartfelt connection with audiences. We provide a license for the copyright safety of popular and trending tracks that include moving orchestral pieces, soft acoustic melodies, and motivational rhythms that enhance storytelling. Music helps convey urgency, compassion, and hope, making content more impactful and engaging. With our popular hits, brands can ensure their message resonates, leaving a lasting impression on viewers.",
  },
  // "1e615e83-0a74-40b0-b65f-b4af467705e3": {
  //     "name": "OTT / Webshows",
  //     "description": "Cinematic sounds to elevate trailers, intros, and episodic storytelling.",
  // },
  "ba2205b8-c398-4e70-b65c-0f5df5a75adc": {
      "name": "Patriotic ",
      "description": "Patriotic content requires music that is powerful, uplifting, and emotionally resonant. Whether celebrating national pride or honoring heritage, the right sound enhances the impact of the message. License our copyright-safe tracks that include grand orchestral scores, traditional folk melodies, and anthemic compositions that evoke a sense of unity and strength. Music amplifies emotions, making campaigns more compelling and inspiring. With our popular Bollywood and trending tracks, brands can create patriotic content that leaves a lasting and meaningful impression.",
  },
  "84a88317-f88c-4432-85df-5ab81e8a1588": {
      "name": "Podcasts & Audiobooks",
      "description": "Podcasts and audiobooks need music that enhances storytelling, setting the mood without overpowering the voice. Whether for engaging discussions, suspenseful narratives, or motivational storytelling, the right sound adds depth and professionalism. We provide copyright safety for music that ranges from subtle background scores to rhythmic transitions, ensuring smooth and engaging listening experiences. Music helps establish tone and structure, making content more polished and immersive. With popular Bollywood and trending hits, brands can create high-quality audio campaigns that captivate listeners and maintain engagement.",
  },
  // "c3461e1c-aa33-4c75-949a-bf84a80744db": {
  //     "name": "Product Launches",
  //     "description": "Dynamic tracks to amplify unveilings, promos, and brand showcases.",
  // },
  "264c7ea7-fd2b-4485-9957-58a57f09e54d": {
      "name": "Promos & Trailers",
      "description": "Promos and trailers need music that builds anticipation, excitement, and intrigue. Whether for brand reveals or new product launches, the right sound enhances impact. Our copyright-safe tracks include cinematic orchestral swells, energetic beats, and dramatic transitions designed to captivate audiences. Music amplifies emotions, making content feel bold, engaging, and unforgettable. With our popular Bollywood and trending tracks, brands can create promos and trailers that leave a strong and lasting impression.",
  },
  "c5cbe26e-074b-447e-bf9f-256055854732": {
      "name": "Shorts & Reels",
      "description": "Short-form content thrives on music that is energetic, fun, and instantly engaging. Whether for social media brands or viral storytelling, the right track keeps viewers hooked. License trending Bollywood tracks from our playlist featuring catchy hooks for your GRWM or OOTD Reels, vibrant beats, and dynamic transitions for makeup routines, making the content feel fresh and modern. Music plays a key role in making campaigns stand out, adding personality and energy to visuals. With our popular hits, brands can create share-worthy Shorts & Reels that captivate audiences within seconds.",
  },
  // "538f6a59-7a05-46f9-8abf-d78a915549a1": {
  //     "name": "Shows",
  //     "description": "Versatile music tracks to enhance the mood and atmosphere of your TV shows and web series. Use cases: TV shows, web series, reality shows.",
  // },
  "21b41ad8-b66c-4a79-bd24-4485db9c7dbc": {
      "name": "Sports ",
      "description": "Sports campaigns require music that is adrenaline-fueled, powerful, and inspiring. Whether celebrating victories or showcasing athleticism, the right sound amplifies intensity. License our copyright-safe tracks, which include epic orchestral scores in motivational content tournament promotions, high-energy rock for sports announcements, and pulsating beats in tournament promotions that elevate excitement. Music enhances movement, drive, and competition, making content feel more thrilling and dynamic. With our popular trending tracks, brands can create sports-driven campaigns that energize and inspire audiences.",
  },
  "5799c0ea-7399-46ad-a047-aeeaa80e9d57": {
      "name": "Technology ",
      "description": "Technology content thrives on music that feels futuristic, sleek, and innovative. Whether showcasing cutting-edge brands or breakthrough ideas, the right sound enhances the modern aesthetic. License our copyright-safe tracks featuring ambient electronic beats for a device launch promotional video, sci-fi-inspired soundscapes for a software launch, and glitchy textures, adding sophistication and intrigue. Music helps create a sense of innovation and progress, making campaigns more engaging and forward-thinking. With our popular Bollywood and trending hits, brands can craft tech-driven content that feels cutting-edge and impactful.",
  },
  "33b2bf23-f5b5-4398-aa51-7046a2922691": {
      "name": "Travel ",
      "description": "Travel campaigns need music that evokes wanderlust, adventure, and excitement. Whether showcasing serene landscapes or urban escapes, the right sound enhances storytelling. License our copyright-safe tracks featuring breezy acoustic guitar for your creations filled with wanderlust, rhythmic world beats for tourism promotional videos, and cinematic orchestral swells, bringing destinations to life. Music helps immerse audiences in the experience, making content more memorable and captivating. With our popular Bollywood and trending tracks, brands can create travel-inspired campaigns that inspire exploration and discovery.",
  },
  "4f95ba81-8a7d-453d-8a5d-325ca9b57feb": {
      "name": "Unboxing & Reviews",
      "description": "Unboxing and reviews thrive on music that is engaging, upbeat, and modern. Whether showcasing tech gadgets, beauty products, or lifestyle essentials, the right sound enhances anticipation and excitement. Our featured tracks include sleek electronic beats, trendy pop rhythms, and dynamic transitions that keep viewers hooked. Music adds polish and professionalism, making campaigns feel fresh and engaging. License our copyright-safe, popular Bollywood hits to create unboxing and review experiences that feel immersive, stylish, and entertaining, ensuring audiences stay engaged and excited about the products being showcased.",
  },
  "7f0b6aac-1d3e-4262-8063-52eb57e3430c": {
      "name": "Wedding & Reception",
      "description": "Wedding and reception campaigns require music that is romantic, elegant, and celebratory. Whether capturing intimate moments or grand festivities, the right sound enhances the emotional depth of the visuals. Our copyright-safe, popular bangers range from orchestral love ballads for jewellery brand content to Bollywood-pumped-up numbers for ethnic fashion videos and upbeat festive beats in promoting Indian sweets, perfectly complementing every stage of the celebration. Music adds warmth, nostalgia, and joy, making wedding content feel magical and heartfelt. License our popular trending tracks and create wedding experiences that feel timeless, luxurious, and emotionally captivating.",
  }
};

export default categories;
