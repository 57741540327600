import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/MoodsPage.module.css";
import moodsInfo from "../utils/data/moods";
import { useParams, Link } from "react-router-dom";
// import { FiShare2 } from "react-icons/fi";
import { FaLink } from "react-icons/fa6";
import CustomButton from "../components/CustomButton/CustomButton";
// import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { getSearchResultFaceted } from "../service/SearchService";
import getDirectLink from "../utils/AudioUrlFormated";
import setMetaTags from "../utils/SeoService";
import config from "../utils/config";
import { useSelector } from "react-redux";
import { TbFilterCheck } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import TrackFilter from "../components/CardsComponent/TrackCard/TrackFilter";
import CleanSearchKey from "../utils/CleanSearchKey";
import { useNavigate, useLocation } from "react-router-dom";
import priceFilter from "../utils/FilterBase64";
import extractColors from "../utils/ExtractColors";
import { useHandleShareClick } from "../utils/Share";

const MoodsPage = () => {
  const [page, setPage] = useState(1); // Dynamic page number
  const [results, setResults] = useState([]); // Store fetched results
  const [isFetching, setIsFetching] = useState(false); // Loading state
  const [showViewMore, setShowViewMore] = useState(false); // Control View More button visibility
  const [noDataFound, setNoDataFound] = useState(false); // State for "No data found"
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [filters, setFilters] = useState({}); // state to manage the filters values
  const [sortBy, setSortBy] = useState({ type: "relevance", order: "" });
  const [priceFilters, setPriceFilters] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [backgroundGradient, setBackgroundGradient] = useState("");

  const { handleShareClick } = useHandleShareClick(); // Use the custom hook

  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  const { name, moodsCode } = useParams(); // Extract the id from the URL

  useEffect(() => {
    const fetchGradient = async () => {
      try {
        const gradient = await extractColors(
          `${config.s3imgUrl}web/moods/${moodsCode}.webp`
        );
        setBackgroundGradient(gradient);
      } catch (error) {
        console.error("Error fetching gradient:", error);
      }
    };

    fetchGradient();
  }, [moodsCode]);

  const moodDataString = moodsInfo[moodsCode]
    ? JSON.stringify(moodsInfo[moodsCode])
    : null; // get the mood data based on code from json file

  const moodData = moodDataString ? JSON.parse(moodDataString) : {}; // parse the fetch info from json file
  // console.log(moodData,'148');

  useEffect(() => {
    if (!moodData || moodData === undefined || moodData === null) {
      navigate("/404");
      return;
    }
    if (name !== moodData?.name) {
      // console.log(true);
      navigate(`/moods/${CleanSearchKey(moodData?.name)}/${moodsCode}`, {
        replace: true,
      });
    }
  }, [name, moodData]);

  // load the filters if there on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedFilters = searchParams.get("filters");
    const encodedPriceFilters = searchParams.get("priceFilters");
    const encodedSortBy = searchParams.get("sortBy");

    if (encodedFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedFilters)); // Decode Base64
        if (decodedFilters) {
          setFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedPriceFilters) {
      try {
        const decodedFilters = JSON.parse(atob(encodedPriceFilters)); // Decode Base64
        if (decodedFilters) {
          setPriceFilters(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
    if (encodedSortBy) {
      try {
        const decodedFilters = JSON.parse(atob(encodedSortBy)); // Decode Base64
        if (decodedFilters) {
          setSortBy(decodedFilters); // Update filters state
          setFiltersLoaded(true); // Mark filters as loaded
        }
      } catch (error) {
        console.error("Error decoding filters:", error);
        setFiltersLoaded(true); // Even if decoding fails, allow API calls
      }
    } else {
      setFiltersLoaded(true); // No filters in URL, allow API calls
    }
  }, [location.search]);

  // seo use effect
  useEffect(() => {
    const seoData = {
      title: `${moodData.name} Songs | Music Licensing | Hoopr Smash`,
      description: `License ${moodData.name} songs with Hoopr Smash. Royalty-free ${moodData.name} music to enhance creativity and add depth to diverse projects.`,
      ogImage: `${config.s3imgUrl}web/moods/${moodsCode}.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, [moodData]);

  // state for preventing the multiple api calls
  const isFetchingRef = useRef(false);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // main use effect to call the api on changes
  useEffect(() => {
    if (filtersLoaded && !isFetchingRef.current) {
      isFetchingRef.current = true;
      setPage(1);
      setResults([]);
      setNoDataFound(false);
      setShowViewMore(false);
      autoFetch4Pages(1).then(() => {
        isFetchingRef.current = false;
      });
    }
  }, [moodsCode, priceFilters, filters, sortBy, filtersLoaded]); // This effect runs whenever the searchKeyword and filters changes

  const fetchResults = async (pageNumber) => {
    // console.log("call");
    // console.log(filters, "filter val");

    try {
      setIsFetching(true);
      setNoDataFound(false); // Reset "No data found" state before fetching
      // Construct the payload with dynamic filters
      const payload = {
        asset_type: "music",
        artists: [], // If no artists, use empty array
        bpm: { min: 1, max: 300 },
        price: { min: priceFilters?.min || "", max: priceFilters?.max || "" },
        genres:
          filters?.genres?.map((item) => CleanSearchKey(item.label)) || [],
        languages:
          filters?.languages?.map((item) => CleanSearchKey(item.label)) || [],
        moods:
          [CleanSearchKey(moodData.name)] ||
          filters?.moods?.map((item) => CleanSearchKey(item.label)) ||
          [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: filters?.subgenres || [],
        usecases:
          filters?.useCases?.map((item) => CleanSearchKey(item.label)) || [],
        instruments:
          filters?.instruments?.map((item) => CleanSearchKey(item.label)) || [],
        ...(filters?.vocals ? { vocal: [filters.vocals] } : {}), // Conditionally add 'vocal' only if it exists
      };

      // Check and update sortby value if type is "relevance"
      let sortby = sortBy.type || "";

      if (sortby === "relevance") {
        sortby = "owner";
      }

      const order = sortBy.order || "";
      const searchKeyword = "";

      const data = await getSearchResultFaceted(
        searchKeyword,
        payload,
        pageNumber,
        sortby,
        order
      );
      const trackData = data.filter((item) => item.type === "music");
      // console.log(trackData, "trackData");

      if (trackData.length === 0) {
        if (pageNumber === 1) {
          setNoDataFound(true);
        }
        setShowViewMore(false); // Hide view more button
        return false; // Stop further API calls
      } else if (trackData.length > 0) {
        // Filter out duplicates by track_code
        setResults((prevResults) => {
          const existingTrackCodes = new Set(
            prevResults?.map((track) => track.track_code) || []
          );
          const uniqueTracks = trackData.filter(
            (track) => !existingTrackCodes.has(track.track_code)
          );
          return prevResults ? [...prevResults, ...uniqueTracks] : uniqueTracks;
        });
        return true; // Continue fetching
      } else {
        setNoDataFound(true); // Set when no data found
        setShowViewMore(false); // Hide view more button
        return false;
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setNoDataFound(true); // Set when no data found
      setShowViewMore(false); // Hide view more button
      return false;
    } finally {
      setIsFetching(false);
    }
  };

  // Function to auto-fetch 4 pages asynchronously
  const autoFetch4Pages = async (startPage) => {
    setIsFetching(true);

    let allPagesFetched = true;

    for (let i = startPage; i < startPage + 4; i++) {
      const hasData = await fetchResults(i);
      if (!hasData) {
        allPagesFetched = false;
        break;
      }
    }

    setIsFetching(false);
    if (allPagesFetched) {
      setShowViewMore(true);
      setPage(startPage + 4);
    }
  };

  // function to load more track on click of view more btn
  const handleViewMoreClick = () => {
    autoFetch4Pages(page);
  };

  // formatting the track cards
  const allTrackList =
    results?.map((track) => {
      const trackSKu = track.sku[0];
      // console.log(trackSKu);
      const parsedSKU = JSON.parse(trackSKu);

      // const SKU = [
      //   {
      //     costPrice: 69999,
      //     sellingPrice: 49999,
      //     gstPercent: 18,
      //     maxUsage: 3,
      //   },
      // ];

      const SKU = [
        {
          costPrice: parsedSKU.cost_price,
          sellingPrice: parsedSKU.selling_price,
          gstPercent: parsedSKU.gst_percent,
          maxUsage: parsedSKU.max_usage,
          id: parsedSKU.id,
        },
      ];

      // Extract costPrice and sellingPrice
      const { costPrice, sellingPrice, id } = SKU[0];

      // Calculate discount percentage
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = JSON.parse(track.tracks_artist_role || "[]") // Ensure valid JSON
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", ");

      return {
        image:
          track.image_url !== "" && track.image_url != null
            ? track.image_url
            : randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "License",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString() || "",
        sellingPrice: sellingPrice.toLocaleString() || "",
        name: track.name_slug,
        trackCode: track.track_code,
        waveJson: track.waveform_link,
        trackUrl:
          track.mp3_link || getDirectLink(track.link) || track.waveform_link,
        skuId: id || "",
        vocal: track.vocal,
      };
    }) || [];

  // console.log(allTrackList, "allTrackList");

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Close the modal if the click happens outside the modalContainer
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // default filters fucntion
  const handleFiltersSelected = (filters) => {
    // console.log("Updated Filters:", filters);
    setFilters(filters);
    const filtersString = JSON.stringify(filters);
    const encodedFilters = btoa(filtersString); // Encode to Base64

    // Update URL with filters
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", encodedFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // price filter fucntion
  const handlePriceFilterChange = (priceFilters) => {
    setPriceFilters(priceFilters);

    const encodedPriceFilters = priceFilter(priceFilters); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("priceFilters", encodedPriceFilters);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // sort by filter fucntion
  const handleSortByChange = (e) => {
    const [type, order] = e.target.value.split("-");
    const newSortBy = { type, order: order || "" };

    setSortBy(newSortBy);

    const sortByString = JSON.stringify(newSortBy);
    const encodedSortBy = btoa(sortByString); // Encode to Base64

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortBy", encodedSortBy);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // clear all filters function
  const handleClearFilter = () => {
    // Remove filter parameters from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("filters");
    searchParams.delete("priceFilters");
    searchParams.delete("sortBy");

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    setFilters({});
    setSortBy({ type: "relevance", order: "" });
    setPriceFilters({});
  };

  // console.log("Global Player Active : ", isGlobalPlayerActive); // Log the value
  const overlayClass = isGlobalPlayerActive
    ? styles.modalOverlayWithPlayer
    : styles.modalOverlay;

  const hasActiveFilters =
    Object.values(filters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    ) || sortBy.type !== "relevance";

  return (
    <div className={styles.moodContainer}>
      {/* Rotated Button */}
      <div className={styles.rotateButton} onClick={toggleModal}>
        {hasActiveFilters ? (
          <div className={styles.filterActiveContainer}>
            <TbFilterCheck
              size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
              className={styles.filterActive}
            />
          </div>
        ) : (
          <FaFilter
            size={windowWidth < 481 ? 14 : windowWidth < 768 ? 16 : 22}
            className={styles.filterIcon}
          />
        )}
        <span>Sort & Filters</span>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div className={overlayClass} onClick={handleOverlayClick}>
          <div className={styles.modalContainer}>
            {/* header section */}
            <div className={styles.header}>
              <div className={styles.heading}>
                <p className={styles.headingtext}>Sort & Filters</p>
              </div>
              <IoIosClose
                onClick={closeModal}
                color="#000"
                cursor="pointer"
                size={34}
              />
            </div>
            <br></br>
            <div className={styles.filterWapper}>
              {/* Sort By Section */}
              <div className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Sort By</p>
                </div>
                <hr style={{ color: "#555" }} />
                <div className={styles.sortOptions}>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="relevance"
                      checked={sortBy.type === "relevance"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Relevance
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-asc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "asc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (low to high)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="price-desc"
                      checked={
                        sortBy.type === "price" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Price (high to low)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-asc"
                      checked={sortBy.type === "name" && sortBy.order === "asc"}
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Asc)
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="name-desc"
                      checked={
                        sortBy.type === "name" && sortBy.order === "desc"
                      }
                      onChange={handleSortByChange}
                      className={styles.radioInput}
                    />
                    Name (Desc)
                  </label>
                </div>
              </div>
              {/* Filter Section */}
              <div style={{ marginTop: 20 }} className={styles.filtersSection}>
                <div className={styles.filterHeading}>
                  <p>Filter</p>
                  {/* <IoIosArrowDown /> */}
                </div>
                <hr style={{ color: "#555" }} />
                <div>
                  <TrackFilter
                    onPriceFilterChange={handlePriceFilterChange}
                    onFiltersSelected={handleFiltersSelected}
                    onClearFilters={handleClearFilter}
                    hideApply={true}
                    width="100%"
                    showPriceFilter={true}
                    savedFilters={filters}
                    priceFilters={priceFilters}
                    showMood={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* mood details */}
      <div
        className={`${styles.mpContainer} ${styles.outerFull}`}
        style={{ background: backgroundGradient }}
      >
        <div className={`${styles.inner} ${styles.innerMp}`}>
          <div className={styles.mpDetailsSection}>
            {/* <BgImageDiv
              type="mood"
              id={moodsCode}
              divClass={styles.mpImage}
            ></BgImageDiv> */}
            {/* mood details */}
            <div className={styles.mpDetails}>
              <span className={`${styles.innerBreadcrumb}`}>
                <Link to={"/"}>Home</Link> /&nbsp;
                <Link to={"/moods"}>Moods</Link> /&nbsp;
                <span>{moodData.name}</span>
              </span>
              <div className={styles.mpNames}>
                <h1 className={styles.moodName}>
                  {moodData.name || "moodName"}
                </h1>
                <h2 className={styles.moodDes}>
                  {moodData.description
                    ? moodData.description.split(". ").slice(0, 2).join(". ") +
                      "."
                    : ""}
                </h2>
              </div>
            </div>
          </div>
          {/* like share button */}
          <div className={styles.mpActionButton}>
            <div>
              <CustomButton
                isIconOnly={true}
                backgroundColor="#F3F3F3"
                borderRadius="50px"
                iconColor="#000"
                icon={<FaLink />}
                onClick={handleShareClick}
                width={windowWidth <= 481 ? "24px" : "34px"}
                height={windowWidth <= 481 ? "24px" : "34px"}
                iconSize={windowWidth <= 481 ? "12px" : "16px"}
              />
            </div>
          </div>
        </div>
      </div>
      {/* mood based tracks section */}
      <div className={`${styles.outerFull}`}>
        <div className={`${styles.moodTracks} ${styles.inner}`}>
          {/* <div className={styles.mtHeading}>
          <h1 className={styles.mtHeadingText}>{moodData.name} Songs</h1>
          {hasActiveFilters && (
            <p onClick={handleClearFilter} className={styles.clearFilterText}>
              Clear Filters
            </p>
          )}
        </div> */}
          <div className={styles.mtTrack}>
            <TrackCard
              trackType="searchTrack"
              trackCard={allTrackList}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "65px"
                    : windowWidth <= 375
                    ? "95px"
                    : "100px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "100px"
                    : "158px"
                  : "186px"
              }
            />
            {isFetching && (
              <p style={{ textAlign: "center", marginTop: "30px" }}>
                Loading more tracks...
              </p>
            )}
            {noDataFound && results.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "30px" }}>
                No data found.
              </p>
            )}
          </div>
        </div>
        {showViewMore && !noDataFound && (
          <button className={styles.viewMoreBtn} onClick={handleViewMoreClick}>
            View More
          </button>
        )}
        <div className={`${styles.inner}`}>
          <div className={`${styles.desDiv}`}>
            <p>License {moodData.name} Music</p>
            <span className={styles.desBottom}>
              {moodData.description || ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodsPage;
