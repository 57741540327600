import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false, // Initial state (modal inactive)
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    activateModal: (state) => {
      state.isActive = true;
    },
    deactivateModal: (state) => {
      state.isActive = false;
    },
  },
});

export const { activateModal, deactivateModal } = modalSlice.actions;
export default modalSlice.reducer;
