import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import styles from "../../styles/MyAccountCss/Collection.module.css";
import { getUserCollections } from "../../service/UserService";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import randomDefaultImage from "../../utils/RandomDefaultImage";
import { ToastContext } from "../../utils/ToastContext";
import TrackCard from "../../components/CardsComponent/TrackCard/TrackCard";
import PackCard from "../../components/CardsComponent/PackCard/PackCard";
import PlaylistCard from "../../components/CardsComponent/PlaylistCard/PlaylistCard";
import { IoChevronBackOutline } from "react-icons/io5";
import {
  removeCollection,
  delCollection,
  updateCollection,
} from "../../service/UserService";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import config from "../../utils/config";

const MyCollection = () => {
  const [collections, setCollections] = useState([]); // State for storing collections from API
  const [editCollectionId, setEditCollectionId] = useState(null); // Stores collection ID being edited
  const [editCollectionName, setEditCollectionName] = useState(""); // Stores new collection name
  const [menuOpen, setMenuOpen] = useState(null); // Stores the open menu ID
  const [selectedCollection, setSelectedCollection] = useState(null); // State for selected collection ID
  // const [packs, setPacks] = useState([]); // packs cards data
  // const [playlists, setPlaylists] = useState([]); // playlist cards data
  // const [tracks, setTracks] = useState([]); // track cards data
  const [selectedTab, setSelectedTab] = useState("Tracks"); // state to manage the pill/button selected

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage

  // fetch user all collections
  useEffect(() => {
    const fetchUserPlaylist = async () => {
      try {
        const userPlaylist = await getUserCollections();
        // console.log(userPlaylist, "34");
        if (userPlaylist?.error?.code === 0) {
          setCollections(userPlaylist?.data?.collections || []);
        } else {
          showToast("Error", "Something Went Wrong!!!", "error"); // Show error toast if API fails
          return;
          // console.log("No playlists found in the response.");
        }
      } catch (error) {
        console.error("Error fetching user playlist:", error);
      }
    };
    fetchUserPlaylist();
  }, [showToast]);

  useEffect(() => {
    if (selectedCollection) {
      if (selectedCollection.tracks?.length > 0) {
        setSelectedTab("Tracks");
      } else if (selectedCollection.playlists?.length > 0) {
        setSelectedTab("Playlists");
      } else if (selectedCollection.packs?.length > 0) {
        setSelectedTab("Packs");
      } else {
        setSelectedTab("Tracks"); // Default to Tracks if no data is available
      }
    }
  }, [selectedCollection]);

  // Helper function to get the image URL
  const getImageUrl = (collection) => {
    if (collection.tracks?.length > 0) {
      return `${config.s3imgUrl}web/tracks/${collection.tracks[0].trackCode}.webp`;
    } else if (collection.packs?.length > 0) {
      return `${config.s3imgUrl}web/playlists/${collection.packs[0].playlistCode}.webp`;
    } else if (collection.playlists?.length > 0) {
      return `${config.s3imgUrl}web/playlists/${collection.playlists[0].playlistCode}.webp`;
    } else {
      return randomDefaultImage("track");
    }
  };

  // function to fetch the single collection data
  const handleCardClick = useCallback(async (id) => {
    try {
      const response = await getUserCollections(id);
      const { collections } = response.data;
      if (collections.length > 0) {
        setSelectedCollection(collections[0]);
      }
    } catch (error) {
      console.error("Error fetching collection details:", error);
    }
  }, []);

  // Derived state using useMemo
  const formattedTracks = useMemo(() => {
    if (!selectedCollection) return [];
    return selectedCollection.tracks.map((track) => {
      const { costPrice, sellingPrice, id } = track?.SKU?.[0] || {};
      return {
        title:
          track.name.length > 18
            ? `${track.name.substring(0, 17)}...`
            : track.name,
        description:
          track.primaryArtists?.map((artist) => artist.name).join(", ") || "",
        buttonText: "License",
        discountPercent: costPrice
          ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}`
          : "",
        costPrice: costPrice ? costPrice.toLocaleString("en-IN") : "",
        sellingPrice: sellingPrice ? sellingPrice.toLocaleString("en-IN") : "",
        name: track.name_slug,
        trackCode: track.trackCode,
        trackUrl: track.mp3Link,
        waveJson: track.waveformLink,
        skuId: id || "",
        vocal: track.vocal,
      };
    });
  }, [selectedCollection]);

  const formattedPacks = useMemo(() => {
    if (!selectedCollection) return [];
    return selectedCollection.packs.map((pack) => {
      const { costPrice, sellingPrice, id } = pack?.SKU?.[0] || {};
      return {
        title: pack.name,
        buttonText: "License",
        buttonLink: `/playlists/${pack.name_slug}/${pack.playlistCode}`,
        totalTracks: pack.trackCount,
        packCode: pack.playlistCode,
        packNameSlug: pack.name_slug,
        sellingPrice: sellingPrice || "",
        costPrice: costPrice || "",
        discountPercent: costPrice
          ? `${((1 - sellingPrice / costPrice) * 100).toFixed(0)}% Off`
          : "",
        skuId: id || "",
      };
    });
  }, [selectedCollection]);

  const formattedPlaylists = useMemo(() => {
    if (!selectedCollection) return [];
    return selectedCollection.playlists.map((playlist) => ({
      title: playlist.name,
      buttonText: "License",
      totalTracks: playlist.trackCount,
      packCode: playlist.playlistCode,
      packNameSlug: playlist.name_slug,
    }));
  }, [selectedCollection]);

  // function to handle tab swtich
  const handleTabClick = useCallback((tab) => setSelectedTab(tab), []);

  // function to delete the collection
  const handleDeleteClick = useCallback(
    async (id) => {
      try {
        const removeColl = await removeCollection(id);
        if (removeColl.error.code === 0) {
          setCollections((prev) =>
            prev.filter((collection) => collection.id !== id)
          );
          showToast(
            "Collection",
            "Your collection has been deleted",
            "success"
          );
        } else {
          showToast("Error", "Failed to delete the collection", "error");
        }
      } catch (error) {
        console.error("Error deleting collection:", error);
        showToast("Error", "Failed to delete the collection", "error");
      }
    },
    [showToast]
  );

  const removePack = async (packCode) => {
    // creating payload
    const payload = {
      id: selectedCollection.id,
      type: "P",
      code: [packCode],
    };

    // console.log(payload, "pack delete from collection payload");
    // for remove pack from the collection
    const removeResponse = await delCollection(payload);
    // console.log(removeResponse, "172");
    if (removeResponse?.error?.code === 0) {
      // console.log(removeResponse.data.updatedCollection.name);
      // Update the packs state by removing the deleted pack
      // setPacks((prevPacks) =>
      //   prevPacks.filter((pack) => pack.packCode !== packCode)
      // );
      setSelectedCollection((prev) => ({
        ...prev,
        packs: prev.packs.filter((pack) => pack.packCode !== packCode),
      }));

      showToast("Collection Update", `Pack removed`, "success"); //display toast msg
    } else {
      showToast("Collection Update", `Failed to remove`, "error"); //display toast msg
    }
  };

  const removePlaylist = async (packCode) => {
    // creating payload
    const payload = {
      id: selectedCollection.id,
      type: "L",
      code: [packCode],
    };

    // console.log(payload, "pack delete from collection payload");
    // for remove pack from the collection
    const removeResponse = await delCollection(payload);
    // console.log(removeResponse, "172");
    if (removeResponse?.error?.code === 0) {
      // console.log(removeResponse.data.updatedCollection.name);
      // Update the packs state by removing the deleted pack
      // setPlaylists((prevPlaylists) =>
      //   prevPlaylists.filter((playlist) => playlist.packCode !== packCode)
      // );

      setSelectedCollection((prev) => ({
        ...prev,
        playlists: prev.playlists.filter(
          (playlist) => playlist.packCode !== packCode
        ),
      }));

      showToast("Collection Update", `Playlist removed`, "success"); //display toast msg
    } else {
      showToast("Collection Update", `Failed to remove`, "error"); //display toast msg
    }
  };

  // Handle menu toggle
  const toggleMenu = useCallback(
    (id) => setMenuOpen((prev) => (prev === id ? null : id)),
    []
  );

  // Handle rename initiation
  const handleRenameClick = (collection) => {
    setEditCollectionId(collection.id);
    setEditCollectionName(collection.name);
    setMenuOpen(null);
  };

  // Handle name change input
  const handleNameChange = (e) => {
    setEditCollectionName(e.target.value);
  };

  // Save the new name
  const handleSaveClick = async (id) => {
    const payload = {
      id: id,
      name: editCollectionName,
    };
    try {
      const response = await updateCollection(payload);
      if (response?.error?.code === 0) {
        setCollections((prevCollections) =>
          prevCollections.map((collection) =>
            collection.id === id
              ? { ...collection, name: editCollectionName }
              : collection
          )
        );
        showToast("Collection", "Name Updated", "success");
        setEditCollectionId(null);
      } else {
        showToast("Error", "Failed to update name", "error");
      }
    } catch (error) {
      // console.error("Error updating name:", error);
      showToast("Error", "Failed to update", "error");
    }
  };

  return (
    <div>
      <h1 className={styles.collectionHeading}>My Collections</h1>
      {/* no collections found Section */}
      {collections.length === 0 && (
        <div className={styles.collContentEmpty}>
          <img
            src="https://cdn.hooprsmash.com/emptystates/no-playlists.webp"
            className={styles.emptyImage}
            alt="no-playlist"
          />
          <h3 className={styles.emptyh3}>No collections found!</h3>
          <p className={styles.emptyHeading}>
            <br />
            You haven't created a collection yet
          </p>
          <span className={styles.browseBtn}>
            <Link to={"/"}>
              <CustomButton
                text="Create Your First Collection"
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="350px"
                height="52px"
                textColor="#fff"
                fontSize="18px"
                fontFamily="Raleway"
                fontWeight="550"
              />
            </Link>
          </span>
        </div>
      )}
      {/* collections section */}
      {collections.length > 0 && !selectedCollection && (
        <div className={styles.collSection}>
          {collections.map((collection) => {
            // console.log("Image URL for collection:", getImageUrl(collection));
            return (
              <div key={collection.id} className={styles.collCard}>
                <div
                  style={{
                    backgroundImage: `url(${getImageUrl(collection)})`,
                  }}
                  className={styles.card}
                  onClick={() => handleCardClick(collection.id)}
                ></div>
                {/* <p className={styles.collName}>{collection.name}</p> */}
                {editCollectionId === collection.id ? (
                  <div className={styles.renameContainer}>
                    <input
                      type="text"
                      value={editCollectionName}
                      onChange={handleNameChange}
                      className={styles.renameInput}
                    />
                    <button
                      onClick={() => handleSaveClick(collection.id)}
                      className={styles.saveButton}
                    >
                      Save
                    </button>
                    <IoIosCloseCircle
                      cursor="pointer"
                      onClick={() => setEditCollectionId(null)}
                      size={22}
                      // color="var(--button-primary)"
                    />
                  </div>
                ) : (
                  <div className={styles.collNameContainer}>
                    <div className={styles.collNameWrapper}>
                      <p
                        // onClick={() => handleCardClick(collection.id)}
                        className={styles.collName}
                      >
                        {collection.name}
                      </p>
                      {/* <span className={styles.itemCount}>Items: 3</span> */}
                      <span className={styles.itemCount}>
                        {collection.totalCount > 0
                          ? `Items: ${collection.totalCount}`
                          : "Empty Collection"}
                      </span>
                    </div>
                    <div
                      className={styles.collMenuContainer}
                      onClick={() => toggleMenu(collection.id)}
                    >
                      <IoEllipsisVerticalSharp
                        size={20}
                        color="var(--button-primary)"
                      />
                    </div>
                    {menuOpen === collection.id && (
                      <div
                        className={styles.menuDropdown}
                        style={{ position: "absolute", right: 0 }} // Ensure it stays within the container
                        onMouseLeave={() => setMenuOpen(null)}
                      >
                        <p onClick={() => handleRenameClick(collection)}>
                          Rename
                        </p>
                        <p onClick={() => handleDeleteClick(collection.id)}>
                          Delete
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {/* selected collection details */}
      {selectedCollection && (
        <div className={styles.selectedCollection}>
          <div
            onClick={() => setSelectedCollection(null)}
            className={styles.scHeader}
          >
            <IoChevronBackOutline />
            <p>{selectedCollection.name}</p>
          </div>
          <div className={styles.containerPills}>
            {["Tracks", "Playlists", "Packs"].map((tab) => (
              <div
                key={tab}
                className={`${styles.pills} ${
                  selectedTab === tab ? styles.selectedPill : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          {/* Content Section Based on Selected Tab */}
          <div className={styles.scData}>
            {selectedTab === "Tracks" &&
              (formattedTracks.length > 0 ? (
                <>
                  <TrackCard trackCard={formattedTracks} />
                </>
              ) : (
                <p className={styles.noDataMessage}>No tracks found.</p>
              ))}
            {selectedTab === "Playlists" &&
              (formattedPlaylists.length > 0 ? (
                <div>
                  <PlaylistCard
                    packCards={formattedPlaylists}
                    packType="likesplaylist"
                    handlePlaylistDelete={removePlaylist}
                    showDeleteIcon={true}
                  />
                </div>
              ) : (
                <p className={styles.noDataMessage}>No playlists found.</p>
              ))}
            {selectedTab === "Packs" &&
              (formattedPacks.length > 0 ? (
                <div>
                  <PackCard
                    packCards={formattedPacks}
                    packType="likespacks"
                    handlePackDelete={removePack}
                    showDeleteIcon={true}
                  />
                </div>
              ) : (
                <p className={styles.noDataMessage}>No packs found.</p>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCollection;
