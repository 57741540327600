import React, { useState, useContext, useEffect } from "react";
import styles from "../styles/Bmm.module.css";
import { IoClose } from "react-icons/io5";
import CustomInput from "../components/CustomInput/CustomInput";
import CustomButton from "../components/CustomButton/CustomButton";
import { validateInput } from "../utils/regex";
import { contactsales } from "../service/SupportService";
import useMixpanel from "../service/MixpanelService";
import { ToastContext } from "../utils/ToastContext";
import Login from "./Login";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const BMMModal = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    instaLink: "",
  }); // setting the form fields data
  const [errors, setErrors] = useState({
    email: "",
    instaLink: "",
  }); // setting the form fields errors
  const [isClosing, setIsClosing] = useState(false); // Start shrinking
  const [showCircle, setShowCircle] = useState(false); // Show mini-circle UI
  const [hideOverlay, setHideOverlay] = useState(false); // Hide overlay
  const [Collapse, setCollapse] = useState(false); // checking open or mini mized state

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loading, setLoading] = useState(false); // Add loading state

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // toggle login modal

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // checking the state of global player for changing position of the button
  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  const location = useLocation(); // hook to get the current location i.e. url

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 481;
  // const isTablet = windowWidth <= 768;

  //   console.log(isVisible, "modal");
  useEffect(() => {
    const bmmkey = localStorage.getItem("bmm_close");
    if (bmmkey) {
      setShowCircle(true);
      setCollapse(true);
    }
    // console.log(bmmkey, "bmm");
  }, []);

  // Handle input changes dynamically
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Validate the form i.e. regex before submitting
  const validateForm = () => {
    let newErrors = {
      email: validateInput(formData.email, "email"),
      instaLink: validateInput(formData.instaLink, "instaProfile"),
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error); // Returns true if no errors
  };

  // Handle form submission submit fucntion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    // first check whether login or not
    if (!isAuthenticated) {
      setIsLoginModalOpen(true);
      setLoading(false);
      return;
    }

    // second check the form validation
    if (!validateForm()) {
      //   console.log("Validation failed");
      setLoading(false);
      return;
    }

    // preparing the payload and call the api
    const payload = {
      form_id: 2,
      email: formData.email.trim(),
      name: "",
      message: formData.instaLink.trim(),
      mobile: "",
      companyName: "",
    };

    try {
      const contact = await contactsales(payload);
      //   console.log(contact);
      if (contact?.error?.code === 0) {
        showToast("BMM", "Request Submitted Successfully", "success");
        // Track the contact sales event
        trackUserEvent("BMM", formData);
        // Reset form fields to default state
        setFormData({
          email: "",
          instaLink: "",
        });
      } else {
        showToast("BMM", "Failed to submit request", "error");
      }
    } catch (error) {
      //   console.log(error, "Error in Submitting query");
      showToast("BMM", "Error in Submitting query", "error");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  // close the modal
  const handleClose = () => {
    localStorage.setItem("bmm_close", true);
    // Reset form data and errors
    setFormData({
      email: "",
      instaLink: "",
    });

    setErrors({
      email: "",
      instaLink: "",
    });

    setIsClosing(true); // Start shrinking animation

    setTimeout(() => {
      setHideOverlay(true); // Hide overlay when shrinking ends
      setShowCircle(true); // Show the mini-circle at the right position
    }, 1000); // Delay to match animation duration

    setTimeout(() => {
      //   setShowCircle(false); // Hide the mini-circle after movement
      setIsClosing(false);
      setCollapse(true);
      //   onClose();
    }, 1000); // Adjust timing for smooth disappearance
  };

  // trackign the form in mix panel
  const trackUserEvent = (eventName, formData) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const eventData = {
      url: window.location.href,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      name: formData.name,
      companyName: formData.companyName,
      message: formData.message,
      phoneNumber: formData.phoneNumber,
    };

    trackEvent(eventName, eventData);
  };

  const resetState = () => {
    setFormData({
      email: "",
      instaLink: "",
    });

    setErrors({
      email: "",
      instaLink: "",
    });

    setIsClosing(false);
    setShowCircle(false);
    setHideOverlay(false);
    setCollapse(false);
    setLoading(false);
    localStorage.removeItem("bmm_close");
  };

  if (!isVisible && !isClosing) return null;

  // Hide modal completely if the user is on /checkout
  if (location.pathname === "/checkout") return null;

  return (
    <>
      {!hideOverlay && !Collapse && (
        <div
          className={`${styles.modalOverlay} ${
            isClosing ? styles.fadeOut : ""
          }`}
        >
          <div
            className={`${styles.modalContainer} ${
              isClosing ? styles.shrinkMoveAnimation : ""
            }`}
          >
            {isMobile ? (
              <div className={styles.innerContentM}>
                <img
                  src="https://cdn.hooprsmash.com/web/promotional/footer/bmm-t.webp"
                  alt="Brand Music Map"
                  className={styles.bmmmobileimg}
                />
                <div className={styles.contentM}>
                  <div onClick={handleClose} className={styles.closeIconMain}>
                    <IoClose cursor="pointer" size={24} color="#000" />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.mheader}>
                      <p className={styles.mHtxt}>
                        GET IT NOW. ABSOLUTELY FREE!
                      </p>
                      <p className={styles.mSubtxt}>
                        Share your brand Instagram handle link and receive your
                        Brand Music Map today.
                      </p>
                    </div>
                    <form
                      className={styles.formContainer}
                      onSubmit={handleSubmit}
                    >
                      <CustomInput
                        fontSize="14px"
                        marginTop="5px"
                        paddingLeft="22px"
                        labelOpcaity="30%"
                        labelTop="15px"
                        fontLabelColor="#BFBFBF"
                        fontSizeLabel="14px"
                        border="1px solid #BFBFBF"
                        height="50px"
                        placeholder="Instagram Link"
                        borderRadius="5px"
                        value={formData.instaLink}
                        onChange={(e) =>
                          handleInputChange("instaLink", e.target.value)
                        }
                        showRequired={true}
                        spanText="Paste your brand's Instagram handle link"
                        error={errors.instaLink}
                      />
                      <CustomInput
                        fontSize="14px"
                        marginTop="5px"
                        paddingLeft="22px"
                        labelOpcaity="30%"
                        labelTop="15px"
                        fontLabelColor="#BFBFBF"
                        fontSizeLabel="14px"
                        border="1px solid #BFBFBF"
                        height="50px"
                        placeholder="Email Address"
                        borderRadius="5px"
                        value={formData.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                        showRequired={true}
                        spanText="Receive your Brand Music Map on this email ID"
                        error={errors.email}
                      />
                      <CustomButton
                        text={loading ? "Please Wait" : "Get Brand Music Map"}
                        textColor="#fff"
                        backgroundColor="var(--button-primary)"
                        borderRadius="30px"
                        // fontSize="18px"
                        fontSize={windowWidth <= 768 ? "15px" : "18px"}
                        fontWeight="550"
                        fontFamily="Raleway"
                        // height="56px"
                        height={windowWidth <= 768 ? "40px" : "56px"}
                        width="100%"
                        loader={loading ? true : false}
                        iconColor="#fff"
                      />
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.innerContent}>
                <div onClick={handleClose} className={styles.closeIconMain}>
                  <IoClose cursor="pointer" size={24} color="#000" />
                </div>
                <div className={styles.content}>
                  <div className={styles.mheader}>
                    <p className={styles.mHtxt}>GET IT NOW. ABSOLUTELY FREE!</p>
                    <p className={styles.mSubtxt}>
                      Share your brand Instagram handle link and receive your
                      Brand Music Map today.
                    </p>
                  </div>
                  <form
                    className={styles.formContainer}
                    onSubmit={handleSubmit}
                  >
                    <CustomInput
                      fontSize="14px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      labelTop="15px"
                      fontLabelColor="#BFBFBF"
                      fontSizeLabel="14px"
                      border="1px solid #BFBFBF"
                      height="50px"
                      placeholder="Instagram Link"
                      borderRadius="5px"
                      value={formData.instaLink}
                      onChange={(e) =>
                        handleInputChange("instaLink", e.target.value)
                      }
                      showRequired={true}
                      spanText="Paste your brand's Instagram handle link"
                      error={errors.instaLink}
                    />
                    <CustomInput
                      fontSize="14px"
                      marginTop="5px"
                      paddingLeft="22px"
                      labelOpcaity="30%"
                      labelTop="15px"
                      fontLabelColor="#BFBFBF"
                      fontSizeLabel="14px"
                      border="1px solid #BFBFBF"
                      height="50px"
                      placeholder="Email Address"
                      borderRadius="5px"
                      value={formData.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      showRequired={true}
                      spanText="Receive your Brand Music Map on this email ID"
                      error={errors.email}
                    />

                    <CustomButton
                      text={
                        loading ? "Please Wait" : "Get Your Brand Music Map"
                      }
                      textColor="#fff"
                      backgroundColor="var(--button-primary)"
                      borderRadius="30px"
                      // fontSize="18px"
                      fontSize={windowWidth <= 768 ? "15px" : "18px"}
                      fontWeight="550"
                      fontFamily="Raleway"
                      // height="56px"
                      height={windowWidth <= 768 ? "40px" : "56px"}
                      width="100%"
                      loader={loading ? true : false}
                      iconColor="#fff"
                    />
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showCircle && Collapse && (
        <div
          className={`${styles.miniCircle} ${
            isGlobalPlayerActive && isMobile ? styles.miniCircleWithPlayer : ""
          }`}
          onClick={resetState}
        >
          {isMobile ? (
            <img
              src="https://cdn.hooprsmash.com/web/promotional/footer/bmmicon-m.webp"
              alt="Brand Music Map"
            />
          ) : (
            <img
              src="https://cdn.hooprsmash.com/web/promotional/footer/bmmicon.webp"
              alt="Brand Music Map"
            />
          )}
        </div>
      )}
      <Login
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default BMMModal;
