import axios from "axios";
import config from "../utils/config";
import { urls } from "../utils/AppManager";
import { getAuthHeaders } from "../utils/headers";

export const contactus = async (payload) => {
  const url = `${config.baseUrl}${urls.support}/contactUs`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const contactsales = async (payload) => {
  const url = `${config.baseUrl}${urls.support}/contactSales`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const requestQuote = async (payload) => {
  const url = `${config.baseUrl}${urls.support}/request-quote`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};

export const requestSong = async (payload) => {
  const url = `${config.baseUrl}${urls.support}/requestASong`;
  const headers = getAuthHeaders();
  try {
    const res = await axios.post(url, payload, { headers });
    return res.data;
  } catch (error) {
    console.error("Error liking pack:", error);
    return { data: [] };
  }
};
