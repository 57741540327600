// utils/regex.js
export const validateInput = (value, type) => {
  const regexPatterns = {
    email: {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: "Enter a valid email address",
    },
    instagram: {
      regex:
        /^(?:https?:\/\/)?(?:www\.)?(?:instagram\.com\/)(?:reels?\/|p\/|reel\/)([A-Za-z0-9_-]+)(?:\/)?(?:\?.*)?$/,
      errorMessage: "Enter a valid Instagram link",
    },
    pan: {
      regex: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
      errorMessage: "Invalid PAN Number",
      allowEmpty: true, // Allow empty values
    },
    gst: {
      regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z][Z][0-9A-Z]$/,
      errorMessage: "Invalid GST Number",
      allowEmpty: true, // Allow empty values
    },
    instaProfile: {
      regex:
        /^https?:\/\/(?:www\.)?instagram\.com\/(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}(?:\/)?(?:\?[^\/]*)?$/,
      errorMessage: "Enter a valid Instagram profile link",
    },
  };

  if (!regexPatterns[type]?.allowEmpty && !value.trim()) {
    return "Required to complete the form"; // Default error for empty fields
  }

  // If type is provided and value is empty, check if allowEmpty is true
  if (type && !value.trim()) {
    return regexPatterns[type]?.allowEmpty
      ? ""
      : "Required to complete the form";
  }

  // If no type is passed, only check for empty field
  if (!type && !value.trim()) {
    return "Required to complete the form"; // No error if the field is filled
  }

  // If type exists in regexPatterns and value does not match the regex, return error
  if (regexPatterns[type] && !regexPatterns[type].regex.test(value)) {
    return regexPatterns[type].errorMessage;
  }

  return ""; // No error if validation passes
};
