import React, { useState, useEffect } from "react";
import { IoArrowUp } from "react-icons/io5";
import { useSelector } from "react-redux";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // checking the state of global player for changing position of the button
  const isGlobalPlayerActive = useSelector(
    (state) => state.ui.globalPlayerActive
  );

  // checking the state of the modal
  const isModalActive = useSelector((state) => state.modal.isActive);

  const showOptions = useSelector((state) => state.ui.showOptions); // Get showOptions state

  const toggleVisibility = () => {
    if (window.pageYOffset > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // checking global player status to apply conditional css OR   // Hide the button if the modal is active
  // const overlayStyle = isGlobalPlayerActive
  //   ? styles.btnWithPlayer
  //   : styles.button;
  const overlayStyle =
    isModalActive || showOptions
      ? { display: "none" }
      : isGlobalPlayerActive
      ? styles.btnWithPlayer
      : styles.button;

  return (
    <div style={styles.outerFull}>
      {isVisible && (
        <div
          onClick={scrollToTop}
          // style={styles.button}
          style={{ ...overlayStyle }}
        >
          <IoArrowUp size={30} />
        </div>
      )}
    </div>
  );
};

const styles = {
  button: {
    position: "fixed",
    bottom: "20px",
    right: "15px",
    width: "50px",
    height: "50px",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
  },
  btnWithPlayer: {
    position: "fixed",
    bottom: "100px",
    right: "15px",
    width: "50px",
    height: "50px",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
  },
  outerFull: {
    width: "100%",
  },
};

export default ScrollToTopButton;
