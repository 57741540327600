// firebaseConfig.js
import { initializeApp } from "firebase/app";

// STAGING CONFIG
const stagingConfig = {
  apiKey: "AIzaSyCzpVO7IrVuaRYtGuVgg6ZM_-R2m_9deWk",
  authDomain: "hoopr-select-staging.firebaseapp.com",
  projectId: "hoopr-select-staging",
  storageBucket: "hoopr-select-staging.firebasestorage.app",
  messagingSenderId: "120242217783",
  appId: "1:120242217783:web:10b62fdfe3a2bc763b70b2",
};

// PRODUCTION CONFIG
const productionConfig = {
  apiKey: "AIzaSyCycrhs20MjVywQEOWZZhslCPLZ-ct2wrQ",
  authDomain: "hoopr-select-production.firebaseapp.com",
  projectId: "hoopr-select-production",
  storageBucket: "hoopr-select-production.firebasestorage.app",
  messagingSenderId: "910304257947",
  appId: "1:910304257947:web:ab354093834b6c1373233e",
};

// Determine which config to use based on the environment
const firebaseConfig =
  process.env.REACT_APP_ENV === "production" ? productionConfig : stagingConfig;

console.log(process.env.REACT_APP_ENV);

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export { firebaseApp };
