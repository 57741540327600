import React, { useState, useEffect } from "react";
import config from "../../utils/config";
import randomDefaultImage from "../../utils/RandomDefaultImage";

const BgImageDiv = ({ type, id, divClass, src = "", bg = false }) => {
  const [backgroundImage, setBackgroundImage] = useState("");

  // ALLOW TYPE TO COME IN ANY CASE, REMOVE DEVELOPER OVERHEAD
  type = type.toLowerCase();

  let defaultType = "";
  let extension = "";
  let bgfolder = "";
  let bgext = "";

  // console.log(type);

  //  SWITCH CASE WAS PREFERRED INSTEAD OF THE IF-ELSE CLAUSES
  switch (type) {
    case "cat":
      defaultType = "square";
      extension = ".webp";
      bgfolder = "bg-img";
      bgext = ".png";
      break;
    case "genre":
      defaultType = "genre";
      extension = ".webp";
      break;
    case "language":
      defaultType = "pack";
      extension = ".webp";
      break;
    case "mood":
      defaultType = "square";
      extension = ".webp";
      break;
    case "pack":
      defaultType = "pack";
      extension = ".webp";
      break;
    case "track":
      defaultType = "track";
      extension = ".webp";
      break;
    default:
      defaultType = "track";
      extension = ".webp";
  }

  useEffect(() => {
    const img = new Image();
    let imageUrl = "";
    if (src === "") {
      let awsBaseURL = `${config.s3imgUrl}web/${type}s`;
      if (bg) {
        awsBaseURL += `/${bgfolder}`;
      }
      imageUrl = awsBaseURL + "/" + id + (bg ? bgext : extension);
    } else {
      imageUrl = src;
    }

    // console.log(imageUrl);

    img.onload = () => {
      setBackgroundImage(imageUrl);
    };

    img.onerror = () => {
      setBackgroundImage(randomDefaultImage(defaultType));
    };

    img.src = imageUrl; // Start loading the image

    // Cleanup function to avoid memory leaks
    return () => {
      setBackgroundImage(""); // Reset on unmount
    };
  }, [id]);

  return (
    <div
      className={divClass}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      {/* You can add additional content inside the div if needed */}
    </div>
  );
};

export default BgImageDiv;

// export default ImgOnError;
