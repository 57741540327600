// import React, { useState, useEffect } from "react";
import React, { useState } from "react";
import config from "../../utils/config";
import randomDefaultImage from "../../utils/RandomDefaultImage";

const CustomImg = ({ type, id, imgClass, alt, src }) => {
  // ALLOW TYPE TO COME IN ANY CASE, REMOVE DEVELOPER OVERHEAD
  type = type.toLowerCase();

  let defaultType = "";
  let extension = "";

  //  SWITCH CASE WAS PREFERRED INSTEAD OF THE IF-ELSE CLAUSES
  switch (type) {
    case "mood":
      defaultType = "square";
      extension = ".webp";
      break;
    case "genre":
      defaultType = "genre";
      extension = ".webp";
      break;
    case "track":
      defaultType = "track";
      extension = ".webp";
      break;
    case "pack":
      defaultType = "pack";
      extension = ".webp";
      break;
    case "playlist":
      defaultType = "pack";
      extension = ".webp";
      break;
    case "cat":
      defaultType = "square";
      extension = ".webp";
      break;
    case "language":
      defaultType = "pack";
      extension = ".webp";
      break;
    default:
      defaultType = "track";
      extension = ".webp";
  }

  let imageUrl = "";
  if (src === "") {
    const awsBaseURL = `${config.s3imgUrl}web/${type}s`;
    imageUrl = awsBaseURL + "/" + id + extension;
    // console.log(imageUrl);
  } else {
    imageUrl = src;
  }

  const [imageSrc, setImageSrc] = useState(imageUrl);

  const handleError = () => {
    setImageSrc(randomDefaultImage(defaultType));
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={imgClass}
      onError={handleError}
      loading="lazy"
    />
  );
};

export default CustomImg;
