import config from "./config";

function setMetaTags({
  title = "Music Licensing for Video, Film & Advertising | Hoopr Smash",
  description = "License songs for video, film, and advertising on Hoopr Smash. Access a wide range of music tracks to enhance your projects, with easy and flexible licensing options.",
  // ogTitle = "Music Licensing for Video, Film & Advertising | Hoopr Smash",
  // ogDescription = "License songs for video, film, and advertising on Hoopr Smash. Access a wide range of music tracks to enhance your projects, with easy and flexible licensing options.",
  ogImage = `${config.s3imgUrl}web/logos/smash-bright.png`,
  canonical = window.location.href,
  lang = "en",
}) {
  // Set Title
  document.title = title;

  // Set Meta Tags
  const metaTags = [
    { name: "description", content: description },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:image", content: ogImage },
  ];

  metaTags.forEach(({ name, property, content }) => {
    let metaTag = document.querySelector(
      name ? `meta[name="${name}"]` : `meta[property="${property}"]`
    );
    if (!metaTag) {
      metaTag = document.createElement("meta");
      if (name) metaTag.setAttribute("name", name);
      if (property) metaTag.setAttribute("property", property);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute("content", content);
  });

  // Set Canonical Tag
  let canonicalTag = document.querySelector("link[rel='canonical']");
  if (!canonicalTag) {
    canonicalTag = document.createElement("link");
    canonicalTag.setAttribute("rel", "canonical");
    document.head.appendChild(canonicalTag);
  }
  canonicalTag.setAttribute("href", canonical);

  // Handle Multi-language (Optional)
  document.documentElement.lang = lang;
}

export default setMetaTags;
