import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    globalPlayerActive: false,
    showOptions: false, // Add new state
  },
  reducers: {
    setGlobalPlayerActive(state, action) {
      state.globalPlayerActive = action.payload;
    },
    setShowOptionsState(state, action) {
      state.showOptions = action.payload; // Update showOptions state
    },
  },
});

export const { setGlobalPlayerActive, setShowOptionsState } = uiSlice.actions;
export default uiSlice.reducer;
