import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/MyAccountCss/Profile.module.css";
import { FiPlus, FiEdit } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import Select from "react-select";
import {
  userAllAddress,
  createAddress,
  addressUpdate,
  getUserProfile,
  getCity,
  getCountry,
  getState,
} from "../../service/UserService";
import Login from "../Login";
import { ToastContext } from "../../utils/ToastContext";

const MyProfile = () => {
  const [billingInfo, setBillingInfo] = useState([]); // user billing info data
  const [defaultBillingChecked, setDefaultBillingChecked] = useState(false); // state for checkbox of default billing info
  const [showModal, setShowModal] = useState(false); // Modal visibility state for displaying the user billing info
  const [phoneNumber, setPhoneNumber] = useState(""); // phone number
  const [editIndex, setEditIndex] = useState(null); // Track index of the billing info being edited
  const [popupMsg, setPopupMsg] = useState(null); // state to manage the message to be displayed in popup modal
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle thankyou(purchase success) popup modal
  const [userProfile, setUserProfile] = useState(null); // state to amange the user prfile info
  const [isIncompleteProfile, setIsIncompleteProfile] = useState(false); // state to manage the profile completion status
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // toggle login modal

  // Input states for user info billing
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [pincode, setPincode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [selectedOption, setSelectedOption] = useState(""); // state to manage the selected user billing details

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [isEditing, setIsEditing] = useState(false); // flag to control useEffect triggers

  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage

  const [addressLoading, setAddressLoading] = useState(false); // loading state for save/update address btn

  // get the user profile
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        // console.log(response, "user profile");

        if (response.error.code === 0) {
          // Ensure the data exists and is structured as expected
          const profile = response.data.profile?.[0] || null;
          setUserProfile(profile);
          // Check if profile is incomplete
          if (
            !profile?.name ||
            !profile?.email ||
            !profile?.mobile ||
            !profile?.companyName ||
            !profile?.companyType
          ) {
            setIsIncompleteProfile(true);
            let userData = JSON.parse(localStorage.getItem("userData")) || {};

            // update to the new values
            userData.companyName = profile?.companyName;
            userData.companyType = profile?.companyType;
            userData.email = profile?.email;
            userData.name = profile?.name;

            // Save updated data back to localStorage
            localStorage.setItem("userData", JSON.stringify(userData));
          } else {
            setIsIncompleteProfile(false);
          }
        } else {
          setUserProfile(null);
          setIsIncompleteProfile(true); // No profile means it's incomplete
        }
      } catch (error) {
        // console.error("Error fetching user profile:", error);
        setUserProfile(null);
        setIsIncompleteProfile(true); // No profile means it's incomplete
      }
    };
    fetchUserProfile();
  }, []);

  // use effect to fetch the user address for the billing info
  useEffect(() => {
    const fetchUserAddress = async () => {
      const userAddress = await userAllAddress();
      // console.log(userAddress, "address");

      // Map the API response
      let billingData = userAddress.data.map((item) => ({
        companyName: item.firstName || "",
        phoneNumber: item.mobile || "",
        email: item.email,
        addressLine1: item.addressLine1 || "",
        addressLine2: item.addressLine2 || "",
        city: item.city_id || "",
        state: item.state_id || "",
        country: item.country_id || "",
        pincode: item.pincode || "",
        gstNumber: item.gstNumber || "",
        panNumber: item.panNumber || "",
        defaultBillingChecked: item.isDefault,
        addressId: item.id,
        createdAt: new Date(item.createdAt), // Convert to Date for sorting
      }));

      // Sort: Place the most recent default address at the top
      billingData.sort((a, b) => {
        if (a.defaultBillingChecked === "Y" && b.defaultBillingChecked === "N")
          return -1;
        if (a.defaultBillingChecked === "N" && b.defaultBillingChecked === "Y")
          return 1;
        return b.createdAt - a.createdAt; // Sort by latest createdAt if both are same type
      });

      // Update billingInfo in one go
      setBillingInfo(billingData);

      // Select the first "Y" default (since it's already sorted)
      const defaultIndex = billingData.findIndex(
        (data) => data.defaultBillingChecked === "Y"
      );
      setSelectedOption(defaultIndex !== -1 ? defaultIndex.toString() : "0");
    };

    fetchUserAddress();
  }, []);

  // get the country options
  useEffect(() => {
    const fetchCountry = async () => {
      const country = await getCountry();
      // console.log(country, "country");
      const countryData = country.data.countries.map((item) => ({
        value: item.id, // country id
        label: item.name, // country name
      }));
      setCountryOptions(countryData);
    };
    fetchCountry();
  }, []);

  //  get the state options
  useEffect(() => {
    if (country && !isEditing) {
      const fetchState = async () => {
        const countryId = country.value;
        // console.log(countryId, "country id");

        const stateResp = await getState(countryId);
        if (stateResp.error.code === 0) {
          setStateOptions(
            stateResp.data.states.map((state) => ({
              value: state.id,
              label: state.name,
            }))
          );
        } else {
          showToast("Error", "Failed to fetch state options", "error");
        }
      };
      fetchState();
    }
  }, [country]);

  // get the city options
  useEffect(() => {
    if (state && !isEditing) {
      const fetchCity = async () => {
        const cityResp = await getCity(state.value, country.value);
        if (cityResp.error.code === 0) {
          setCityOptions(
            cityResp.data.cities.map((city) => ({
              value: city.id,
              label: city.name,
            }))
          );
        } else {
          showToast("Error", "Failed to fetch city options", "error");
        }
      };
      fetchCity();
    }
  }, [state]);

  // handle check box for default billing info
  const handleBillCheckboxChange = () => {
    setDefaultBillingChecked(!defaultBillingChecked);
  };

  // to display the user bill info add modal
  const toggleModal = () => {
    if (!showModal) {
      // Clear form fields only when opening the modal for a new entry
      setCompanyName("");
      setPhoneNumber("");
      setEmail("");
      setAddressLine1("");
      setAddressLine2("");
      setCity(null);
      setState(null);
      setCountry(null);
      setPincode("");
      setGstNumber("");
      setPanNumber("");
      setDefaultBillingChecked(false);
    }
    setShowModal(!showModal);
  };

  // handle phone number input
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // function to save user bill info details
  const handleSave = async (e) => {
    e.preventDefault();
    setAddressLoading(true);

    // Regex to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Email", "Please enter a valid email address.", "error");
      setAddressLoading(false);
      return;
    }

    const payload = {
      firstName: companyName,
      mobile: phoneNumber,
      email: email,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city_id: city?.value || "",
      state_id: state?.value || "",
      country_id: country?.value || "",
      pincode: pincode,
      gstNumber,
      panNumber,
      isDefault: defaultBillingChecked ? "Y" : "N",
    };
    // console.log(payload);

    // Check for required fields (other than city, state, panNumber, gstNumber)
    const requiredFields = {
      companyName,
      phoneNumber,
      email,
      addressLine1,
      addressLine2,
      country: country?.value,
      city: city?.value,
      state: state?.value,
      // panNumber,
      pincode,
    };

    const emptyFields = Object.entries(requiredFields)
      .filter(([key, value]) => !value) // Find empty fields
      .map(([key]) => key); // Get the field names

    if (emptyFields.length > 0) {
      showToast("Billing Info", `Incomplete Billing Details`, "error");
      setAddressLoading(false);
      return;
    }

    const addAddress = await createAddress(payload);
    // console.log(addAddress, "billing info added");

    if (addAddress.error.code === 0) {
      showToast("Billing Info", "Billing Info Added", "success");
      // console.log(addAddress, "billing info added");
      // Update the billing info state
      setBillingInfo((prevInfo) => [
        ...prevInfo,
        {
          companyName,
          phoneNumber,
          email,
          addressLine1,
          addressLine2,
          city: city?.value || "",
          state: state?.value || "", // state id
          country: country?.value || "", // country id
          pincode,
          gstNumber,
          panNumber,
          defaultBillingChecked,
          addressId: addAddress.data.id,
        },
      ]);
      // Reset and close modal
      setShowModal(false);
      setAddressLoading(false);
    } else {
      setAddressLoading(false);
      showToast("Billing Info", "Failed to add Billing Info", "error");
    }
  };

  // function to edit the selected billing info
  const handleEditBillingInfo = async (index) => {
    setIsEditing(true); // Set the flag to prevent useEffect triggers

    const info = billingInfo[index];

    // console.log(info, "info edit billing");

    // Find the corresponding options for city, state, and country
    const selectedCountry = countryOptions.find(
      (option) => option.value === info.country
    );

    // Fetch state options based on the selected country
    const stateResp = await getState(info.country);
    if (stateResp.error.code === 0) {
      const stateOptions = stateResp.data.states.map((state) => ({
        value: state.id,
        label: state.name,
      }));
      setStateOptions(stateOptions);

      const selectedState = stateOptions.find(
        (option) => option.value === info.state
      );

      // Fetch city options based on the selected state
      const cityResp = await getCity(info.state, info.country);
      if (cityResp.error.code === 0) {
        const cityOptions = cityResp.data.cities.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCityOptions(cityOptions);

        const selectedCity = cityOptions.find(
          (option) => option.value === info.city
        );

        // Populate the form fields with the selected billing info
        setEditIndex(index);
        setCompanyName(info.companyName ? info.companyName : "companyname");
        setPhoneNumber(info.phoneNumber || "");
        setEmail(info.email || "");
        setAddressLine1(info.addressLine1 || "");
        setAddressLine2(info.addressLine2 || "");
        setCity(selectedCity || null);
        setState(selectedState || null);
        setCountry(selectedCountry || null);
        setPincode(info.pincode || "");
        setGstNumber(info.gstNumber || "");
        setPanNumber(info.panNumber || "");
        setDefaultBillingChecked(info.defaultBillingChecked || false);
        setShowModal(true);
      } else {
        showToast("Error", "Failed to fetch city options", "error");
      }
    } else {
      showToast("Error", "Failed to fetch state options", "error");
    }
  };

  // function to update the selected billing info
  const handleUpdate = async (e) => {
    e.preventDefault();
    setAddressLoading(true);

    // Regex to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      showToast("Email", "Please enter a valid email address.", "error");
      setAddressLoading(false);
      return;
    }

    const payload = {
      firstName: companyName,
      mobile: phoneNumber,
      email: email,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city_id: city?.value || "",
      state_id: state?.value || "",
      country_id: country?.value || "",
      pincode: pincode,
      gstNumber,
      panNumber,
      isDefault: defaultBillingChecked ? "Y" : "N",
    };
    // console.log(payload);

    // Check for required fields (other than city, state, panNumber, gstNumber)
    const requiredFields = {
      companyName,
      phoneNumber,
      email,
      addressLine1,
      // addressLine2,
      country: country?.value,
      city: city?.value,
      state: state?.value,
      // panNumber,
      pincode,
    };

    const emptyFields = Object.entries(requiredFields)
      .filter(([key, value]) => !value) // Find empty fields
      .map(([key]) => key); // Get the field names

    if (emptyFields.length > 0) {
      showToast("Billing Info", `Incomplete Billing Details`, "error");
      setAddressLoading(false);
      return;
    }

    const updateAddress = await addressUpdate(
      billingInfo[editIndex].addressId,
      payload
    );
    // console.log(updateAddress, "billing info updated");

    if (updateAddress.error.code === 0) {
      showToast("Billing Info", "Billing Info Updated", "success");
      // Update the billing info state
      setBillingInfo((prevInfo) =>
        prevInfo.map((info, index) =>
          index === editIndex
            ? {
                ...info,
                companyName,
                phoneNumber,
                email,
                addressLine1,
                addressLine2,
                city: city?.value || "",
                state: state?.value || "", // state id
                country: country?.value || "", // country id
                pincode,
                gstNumber,
                panNumber,
                defaultBillingChecked,
              }
            : info
        )
      );
      // Reset and close modal
      setShowModal(false);
      setIsEditing(false);
      setAddressLoading(false);
    } else {
      setAddressLoading(false);
      showToast("Billing Info", "Failed to update Billing Info", "error");
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px", // Custom width
    }),
  };

  return (
    <div className={styles.profileContainer}>
      {isIncompleteProfile && (
        <div
          onClick={() => setIsLoginModalOpen(true)}
          className={styles.missingProfile}
        >
          You have not completed your profile! To get proper updates and
          notifications complete your profile.
        </div>
      )}
      <div className={styles.profileDetails}>
        <div className={styles.pdHeader}>
          <h1 className={styles.pHeading}>Personal Details</h1>
          <FiEdit
            onClick={() => setIsLoginModalOpen(true)}
            cursor="pointer"
            color="#464646"
            size={18}
          />
        </div>
        <hr className={styles.breakLine}></hr>
        <div className={styles.pdContent}>
          {userProfile ? (
            <>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Name</span>
                <p className={styles.pdDescription}>{userProfile.name || ""}</p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Phone</span>
                <p className={styles.pdDescription}>
                  {userProfile.mobile ? `+91 ${userProfile.mobile}` : ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Email</span>
                <p className={styles.pdDescription}>
                  {userProfile.email || ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Company</span>
                <p className={styles.pdDescription}>
                  {userProfile.companyName || ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Type of Company</span>
                <p className={styles.pdDescription}>
                  {userProfile.companyType || ""}
                </p>
              </div>
            </>
          ) : (
            <p className={styles.noDataText}>No profile details available.</p>
          )}
        </div>
      </div>
      {/* billing info section */}
      <div className={styles.billDetails}>
        <div className={styles.billSection}>
          <h1 className={styles.pHeading}>Billing Details</h1>
          <div onClick={toggleModal} className={styles.bdAddition}>
            <span className={styles.addNewText}>Add New</span>
            <FiPlus />
          </div>
        </div>
        <hr className={styles.breakLine}></hr>
        <div className={styles.billingInfoContainer}>
          {billingInfo.length > 0 ? (
            <>
              {billingInfo.map((info, index) => (
                <div key={index} className={styles.billInfo}>
                  <div className={styles.billUserInfo}>
                    <input
                      type="radio"
                      value={index}
                      name="billingInfo"
                      checked={selectedOption === index.toString()}
                      onChange={() => setSelectedOption(index.toString())}
                    />
                    <div className={styles.bsiName}>
                      <p className={styles.username}>{info.companyName}</p>
                      <div className={styles.emailPhone}>
                        <span>{info.phoneNumber}</span>
                        <p className={styles.bullet}>{info.email}</p>
                      </div>
                    </div>
                    <div
                      onClick={() => handleEditBillingInfo(index)}
                      className={styles.edit}
                    >
                      <FiEdit cursor="pointer" color="#464646" size={18} />
                    </div>
                  </div>
                  <div className={styles.gstpanContainer}>
                    <div className={styles.gstpanBillInfo}>
                      <p className={styles.gstpanHeading}>GSTIN:</p>
                      <span className={styles.gstpanNumber}>
                        {info.gstNumber || "N/A"}
                      </span>
                    </div>
                    <div className={styles.gstpanBillInfo}>
                      <p className={styles.gstpanHeading}>PAN:</p>
                      <span className={styles.gstpanNumber}>
                        {info.panNumber || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className={styles.noDataText}>
              No data found. Please add the billing info.
            </p>
          )}
        </div>
      </div>
      {/* billing info popup modal */}
      {showModal && (
        <form
          onSubmit={isEditing ? handleUpdate : handleSave}
          className={styles.modalBackdrop}
        >
          <div className={styles.modalBackdrop} onClick={toggleModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()} // Prevent backdrop click from closing modal
            >
              <div className={styles.modalHeader}>
                <p className={styles.headerText}>Add Billing Information</p>
                <IoIosClose
                  onClick={() => setShowModal(false)}
                  color="#000"
                  cursor="pointer"
                  size={32}
                />
              </div>
              <div className={styles.billingForm}>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Company / Your Name"
                  borderRadius="8px"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <div className={styles.phoneInputProfile}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Email"
                  borderRadius="8px"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 1"
                  borderRadius="8px"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 2"
                  borderRadius="8px"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
                <div className={styles.state}>
                  <Select
                    value={country}
                    onChange={setCountry}
                    styles={customStyles}
                    options={countryOptions}
                    placeholder="Select Country"
                  />
                  <Select
                    value={city}
                    onChange={setCity}
                    styles={customStyles}
                    options={cityOptions}
                    placeholder="Select State"
                  />
                </div>
                <div className={styles.state}>
                  <Select
                    value={state}
                    onChange={setState}
                    styles={customStyles}
                    options={stateOptions}
                    placeholder="Select City"
                  />
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="40px"
                    placeholder="Pincode"
                    borderRadius="8px"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
                <div className={styles.gstpan}>
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="GSTIN Number"
                    borderRadius="8px"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="PAN Number"
                    borderRadius="8px"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                  />
                </div>
                <div className={styles.billingCheckbox}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={defaultBillingChecked}
                      onChange={handleBillCheckboxChange}
                      className={styles.checkbox}
                    />
                    <span className={styles.checkboxText}>
                      Set as default billing information
                    </span>
                  </label>
                </div>
                <div className={styles.saveBtn}>
                  <CustomButton
                    // text="Save"
                    text={addressLoading ? "Please Wait" : "Save"}
                    backgroundColor="var(--button-primary)"
                    borderRadius="50px"
                    width="399px"
                    height="50px"
                    textColor="#fff"
                    fontSize="18px"
                    fontFamily="Raleway"
                    fontWeight="550"
                    loader={addressLoading ? true : false}
                    iconColor="#fff"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <Login
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </div>
  );
};

export default MyProfile;
