import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/RequestSong.module.css";
import { IoIosClose } from "react-icons/io";
import CustomInput from "../components/CustomInput/CustomInput";
import CustomButton from "../components/CustomButton/CustomButton";
import PopUpModal from "../components/PopUpModal/PopUpModal";
import { ToastContext } from "../utils/ToastContext";
import { requestSong } from "../service/SupportService";
import useMixpanel from "../service/MixpanelService";
import { useDispatch } from "react-redux";
import { activateModal, deactivateModal } from "../utils/redux/modalSlice";

const RequestSong = ({ isOpen, onClose }) => {
  const [isRequestOpen, setIsRequestOpen] = useState(isOpen); // Sync with parent initially
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle thankyou(request success) popup modal
  const [popupMsg, setPopupMsg] = useState(null); // state to manage the message to be displayed in popup modal
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
    phoneNumber: "",
    songInfo: "",
  });

  const [emailError, setEmailError] = useState(""); // State to store email error message
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsRequestOpen(isOpen);
    // console.log(isOpen, "modal state");
    if (isOpen == true) {
      dispatch(activateModal()); // action send to redux to hide the scrolltop button
    }
  }, [isOpen]);

  // Function to close the modal
  const closeModal = () => {
    dispatch(deactivateModal()); // update redux to display the scroll button
    setIsRequestOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber" && !/^\d*$/.test(value)) {
      return; // Allow only digits for phoneNumber
    }

    if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
      if (!emailRegex.test(value) && value !== "") {
        setEmailError("Invalid email format"); // Set error message
      } else {
        setEmailError(""); // Clear error message
      }
    }

    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    // Check if any form field is empty or contains only whitespace
    const isEmpty = Object.values(formData).some(
      (value) => !value || value.toString().trim() === ""
    );

    if (isEmpty) {
      showToast("Contact Sales", "All fields are mandatory.", "error");
      setLoading(false);
      return;
    }

    const payload = {
      form_id: 3,
      email: formData.email.trim(),
      name: formData.name.trim(),
      projectBrief: formData.message.trim(),
      mobile: formData.phoneNumber.trim(),
      companyName: formData.companyName.trim(),
      songInfo: formData.songInfo.trim(),
    };

    try {
      const requestResp = await requestSong(payload);
      //   console.log(requestResp);
      if (requestResp?.error?.code === 0) {
        showToast("Request Song", "Request Submitted Successfully", "success");
        // Track the request license event
        trackUserEvent("requestsong", formData);
        dispatch(deactivateModal()); // update redux to display the scroll button
        // closeModal();
      } else {
        showToast("Request Song", "Failed to submit request", "error");
      }
    } catch (error) {
      console.log(error, "Error in Submitting query");
      showToast("Request Song", "Error in Submitting query", "error");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const trackUserEvent = (eventName, formData) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const eventData = {
      url: window.location.href,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      name: formData.name,
      companyName: formData.companyName,
      message: formData.message,
      phoneNumber: formData.phoneNumber,
      songInfo: formData.songInfo,
    };

    trackEvent(eventName, eventData);
  };

  if (!isRequestOpen) return null; // Don't render if the modal is closed

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.header}>
          <p className={styles.headingtext}>Request a Song</p>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>
        <p className={styles.subHeading}>
          Can't find a track on Hoopr Smash? Submit a request here.
        </p>

        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <div className={`${styles.content} ${styles.formgroup}`}>
            <div className={styles.formgroup}>
              <p className={styles.formHeading}>Song Info</p>
              <CustomInput
                placeholder="Enter Youtube or spotify link here"
                height="50px"
                borderRadius="4px"
                border="1px solid #bfbfbf"
                marginTop="5px"
                value={formData.songInfo}
                onChange={(e) => handleInputChange("songInfo", e.target.value)}
              />
            </div>

            <div className={styles.formgroup}>
              <p className={styles.formHeading}>User Info</p>
              <CustomInput
                placeholder="Name"
                height="50px"
                borderRadius="4px"
                border="1px solid #bfbfbf"
                marginTop="5px"
                value={formData.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </div>

            <div className={styles.phoneInput}>
              <select className={styles.countryCode}>
                <option value="+91">+91</option>
              </select>
              <input
                type="text"
                placeholder="Enter Phone Number"
                className={styles.phoneNumber}
                value={formData.phoneNumber}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
                maxLength={10}
              />
            </div>

            <div className={styles.formgroup}>
              <CustomInput
                placeholder="Email"
                height="50px"
                borderRadius="4px"
                border="1px solid #bfbfbf"
                marginTop="5px"
                // type="email"
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
              {emailError && <p className={styles.errorMsg}>{emailError}</p>}
            </div>

            <div className={styles.formgroup}>
              <CustomInput
                placeholder="Company Name"
                height="50px"
                borderRadius="4px"
                border="1px solid #bfbfbf"
                marginTop="5px"
                value={formData.companyName}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
              />
            </div>

            <div className={styles.formgroup}>
              <div className={styles.textareaContainer}>
                <textarea
                  placeholder="Message"
                  className={styles.textarea}
                  onChange={(e) => handleInputChange("message", e.target.value)}
                />
              </div>
            </div>

            <div className={styles.formgroup}>
              <CustomButton
                text={loading ? "Please Wait" : "Submit Request"}
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                // width="397px"
                height="50px"
                textColor="#fff"
                fontSize="18px"
                fontFamily="Raleway"
                fontWeight="550"
                loader={loading ? true : false}
                iconColor="#fff"
              />
            </div>
          </div>
        </form>
      </div>

      <PopUpModal
        modalData={popupMsg}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOutsideClick={closeModal}
      />
    </div>
  );
};

export default RequestSong;
