import React, { useState, useEffect, useContext } from "react";
import styles from "../styles/RequestLicense.module.css";
import { IoIosClose } from "react-icons/io";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import CustomInput from "../components/CustomInput/CustomInput";
import CustomButton from "../components/CustomButton/CustomButton";
import randomDefaultImage from "../utils/RandomDefaultImage";
import PopUpModal from "../components/PopUpModal/PopUpModal";
import { requestQuote } from "../service/SupportService";
import { ToastContext } from "../utils/ToastContext";
import useMixpanel from "../service/MixpanelService";
// import { TbLoader } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { activateModal, deactivateModal } from "../utils/redux/modalSlice";

const RequestLicense = ({ isOpen, onClose, licenseData }) => {
  const [isRequestOpen, setIsRequestOpen] = useState(isOpen); // Sync with parent initially
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle thankyou(request success) popup modal
  const [popupMsg, setPopupMsg] = useState(null); // state to manage the message to be displayed in popup modal
  const [formData, setFormData] = useState({
    campaignSelected: null,
    companyName: "",
    licenseDuration: null,
    licenseType: null,
    territory: null,
    projectBrief: "",
    startDate: "",
  });
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();

  // console.log(licenseData, "license data");

  // Sync `isCartOpen` with `isOpen` prop whenever it changes
  useEffect(() => {
    setIsRequestOpen(isOpen);
    // console.log(isOpen, "modal state");
    if (isOpen == true) {
      dispatch(activateModal()); // action send to redux to hide the scrolltop button
    }
  }, [isOpen]);

  // Function to close the modal
  const closeModal = () => {
    dispatch(deactivateModal()); // update redux to display the scroll button
    setIsRequestOpen(false); // Close modal locally
    onClose(); // Notify the parent to close the modal
  };

  // Handle input changes dynamically
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    // Check if any value in formData is empty or null
    if (
      Object.values(formData).some(
        (value) => !value || (typeof value === "string" && !value.trim())
      )
    ) {
      showToast("Request Quote", "All fields are mandatory.", "error");
      setLoading(false);
      return;
    }

    // console.log(licenseData, "license data");

    // cretaign payload
    const payload = {
      trackCodes: [licenseData.trackCode],
      campaignType: {
        type: "",
        platform: "",
        budget: "",
        targetAudience: "",
        duration: formData.licenseDuration?.value,
      },
      companyName: formData.companyName.trim(),
      duration: formData.licenseDuration?.value,
      projectBrief: formData.projectBrief.trim(),
      licenseStartDate: formData.startDate,
      territoryOptions: formData.territory,
      licenseType: formData.licenseType?.value,
    };

    try {
      const response = await requestQuote(payload);
      // console.log("Request Response:", response);
      if (response?.error?.code === 0) {
        setPopupMsg({
          title: "Thank You for Your Request",
          subTitle: "Our Sales Team Will Contact You Soon",
          btntext: "License more tracks",
          btnredirect: "/",
        });
        setIsModalOpen(true);
        // closeModal();
        // Track the request license event
        trackUserEvent("requestquote-completed", formData);
        dispatch(deactivateModal()); // update redux to display the scroll button
      } else {
        showToast("Request Quote", "Failed to submit request", "error");
      }
    } catch (error) {
      console.log(error, "Error in Submitting query");
      showToast("Request Quote", "Error in Submitting query", "error");
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const trackUserEvent = (eventName, formData) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const eventData = {
      url: window.location.href,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      campaignSelected: formData.campaignSelected?.value,
      companyName: formData.companyName,
      licenseDuration: formData.licenseDuration?.value,
      territory: formData.territory?.value,
      projectBrief: formData.projectBrief,
      startDate: formData.startDate,
      licenseType: formData.licenseType?.value,
    };

    trackEvent(eventName, eventData);
  };

  //  city options
  const campaignOptions = [
    { value: "agency", label: "Agency" },
    { value: "brand", label: "Brand" },
    { value: "content-studio", label: "Content Studio" },
    { value: "independent-filmmaker", label: "Independent Filmmaker" },
    {
      value: "individual-creator-influencer",
      label: "Individual Creator / Influencer",
    },
    { value: "ott-platform", label: "OTT Platform" },
    { value: "production-house", label: "Production House" },
    { value: "radio-station", label: "Radio Station" },
    { value: "tv-channel", label: "TV Channel" },
    { value: "Other", label: "Other" },
  ];

  const licenseDurationOptions = [
    { value: "one", label: "1 Months" },
    { value: "three", label: "3 Months" },
    { value: "six", label: "6 Months" },
    { value: "twelve", label: "12 Months" },
    { value: "thirtysix", label: "3 Years" },
    { value: "thirtysixplus", label: ">3 Years" },
  ];

  const licenseTypeOptions = [
    { value: "exclusive", label: "Exclusive" },
    { value: "non-exclusive", label: "Non-Exclusive" },
  ];

  const territoryOptions = [
    { value: "worlwide", label: "Worldwide" },
    { value: "india", label: "India" },
  ];

  if (!isRequestOpen) return null; // Don't render if the modal is closed

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.header}>
          <p className={styles.headingtext}>Request a Quote</p>
          <IoIosClose
            onClick={closeModal}
            color="#000"
            cursor="pointer"
            size={34}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className={styles.content}>
            <div className={styles.cardContainer}>
              <div
                className={styles.cardImg}
                style={{
                  backgroundImage: `url(${
                    licenseData?.img || randomDefaultImage("track")
                  })`,
                }}
              ></div>
              <div className={styles.cardInfo}>
                <p className={styles.cardTitle}>
                  {licenseData?.title
                    ? licenseData.title.length > 40
                      ? `${licenseData.title.substring(0, 40)}...`
                      : licenseData.title
                    : "Name"}
                </p>
                <span className={styles.cardSubTitle}>
                  {licenseData?.subTitle
                    ? licenseData.subTitle.length > 40
                      ? `${licenseData.subTitle.substring(0, 40)}...`
                      : licenseData.subTitle
                    : ""}
                </span>
              </div>
            </div>

            <div className={styles.container}>
              <p className={styles.title}>
                You are looking to license the tracks for
              </p>
              <CustomDropdown
                options={campaignOptions}
                placeholder="Licensing for"
                onChange={(value) =>
                  handleInputChange("campaignSelected", value)
                }
                value={formData.campaignSelected}
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>Start Date</p>
              <input
                type="date"
                className={styles.dateInput}
                value={formData.startDate}
                onChange={(e) => handleInputChange("startDate", e.target.value)}
                required
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>Company Name</p>
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #BFBFBF"
                labelTop="15px"
                fontLabelColor="#BFBFBF"
                height="50px"
                placeholder="Name of company you want a license for"
                borderRadius="8px"
                value={formData.companyName}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>Duration of License</p>
              <CustomDropdown
                options={licenseDurationOptions}
                placeholder="Select a duration"
                onChange={(value) =>
                  handleInputChange("licenseDuration", value)
                }
                value={formData.licenseDuration}
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>Type of License</p>
              <CustomDropdown
                options={licenseTypeOptions}
                placeholder="Select a type"
                onChange={(value) => handleInputChange("licenseType", value)}
                value={formData.licenseType}
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>
                Select the territory you wish to get the clearance for
              </p>
              <CustomDropdown
                options={territoryOptions}
                placeholder="Select territory"
                onChange={(value) => handleInputChange("territory", value)}
                value={formData.territory}
              />
            </div>

            <div className={styles.container}>
              <p className={styles.title}>Project Brief</p>
              <textarea
                className={styles.textArea}
                placeholder="Please share details of the brief..."
                value={formData.projectBrief}
                onChange={(e) =>
                  handleInputChange("projectBrief", e.target.value)
                }
              ></textarea>
            </div>

            <div className={styles.container}>
              <CustomButton
                text={loading ? "Please Wait" : "Submit Request"}
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="408px"
                height="50px"
                textColor="#fff"
                fontSize="18px"
                fontFamily="Raleway"
                fontWeight="550"
                type="submit"
                loader={loading ? true : false}
                iconColor="#fff"
              />
            </div>
          </div>
        </form>
      </div>

      <PopUpModal
        modalData={popupMsg}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOutsideClick={closeModal}
      />
    </div>
  );
};

export default RequestLicense;
